import React, { useState } from 'react';
import './PreHeader.css';
// import handburger from './assets/img/handburger2.png';
import { Link } from 'react-router-dom';
import { Usedrop, UsedropReset, UsedropUpdate } from './ReleaseContext';
import handburger from "./assets/handburger/handburger (1).png";
import { MdOutlineArrowOutward } from "react-icons/md";
import { CiCalendar } from "react-icons/ci";



function PreHeader(props) {
    const [webdrop, setDrop] = useState(false); // Initial state of drop, assuming it starts as false

    const webdropping = () => {
        setDrop(!drop); // Toggle the value of drop
    };
    const drop = Usedrop();
    const dropping = UsedropUpdate();
    const droppie = UsedropReset();


    const gothroughOne = (e) => {
        console.log(e.target);
        window.location.href = "/about";
    };

    const gothroughTwo = (e) => {
        console.log(e.target);
        window.location.href = "/blog/home";
    };

    const gothroughThree = (e) => {
        console.log(e.target);
        window.location.href = "/products/features";
    };

    const headClassName = webdrop ? 'HeaderDrop' : 'Header';

    return (
        <>
            <div className='top-header-cover' onClick={droppie}>
                <div className='header-container' >
                    <div className='capitalflo-logo'>
                        <Link to="/"> <img
                            src="https://capitalflophotos.s3.ap-south-1.amazonaws.com/footerlogo.png" className='cap'
                            alt="logo" />
                        </Link>
                    </div>
                    <div className='header-center-content'>
                        <ul className='center-content'>
                            <li><Link to="/about">About Us</Link></li>
                            {/* <li><Link to="https://capitalflo.co/blog/home">Blog</Link></li> */}
                            {/* <li><Link to="https://capitalflo.co/blog/home">Book a Free Call</Link></li> */}
                            <li><Link to="/products/features">Products</Link></li>
                            {/* <li><Link  to="/business" style={{ color: '#60acce' }}>
                                    For Business
                                <MdOutlineArrowOutward />
                            </Link></li>  */}
                            <li><Link style={{ color: '#60acce' }} onClick={(e) => {
                                if (window.location.pathname !== '/') {
                                    e.preventDefault();
                                    window.location.href = '/#book-a-free-call';
                                    setTimeout(() => {
                                        document.querySelector("#book-a-call-main-listing").scrollIntoView({ behavior: 'smooth' });
                                    }, 500);
                                } else {
                                    e.preventDefault();
                                    document.querySelector("#book-a-call-main-listing").scrollIntoView({ behavior: 'smooth' });
                                }
                            }} >
                                Book a Free Call
                                <MdOutlineArrowOutward />
                            </Link></li>
                        </ul>
                    </div>
                    <div className='header-right-content'>
                        <div className='signup'
                            onClick={() => window.location.href = "/login"}>Try Now
                        </div>
                        {/* <div className='signin'
                            onClick={() => window.location.href = "https://capitalflo.co/login"}>Sign In
                        </div> */}
                        <div className='dropdown-icon' onClick={dropping}>
                            <img src={handburger} alt={'menu'} onClick={dropping}></img>
                        </div>
                    </div>
                </div>
                {drop ? <div className='mobdrops'>
                    <div className='mob-link-options'>
                        <div className='mob-link-left-options'>
                            <div className='eachdropmob' ><Link to="/about">About
                                Us</Link></div>
                            <div className='eachdropmob' ><Link
                                to="/blog/home">Blog</Link>
                            </div>
                            <div className='eachdropmob' ><Link
                                to="/products/features">Products</Link>
                            </div>
                            {/* <div className='book-a-call-button'> */}
                            <div className='eachdropmob' ><Link style={{ color: '#60acce' }}
                                onClick={(e) => {
                                    if (window.location.pathname !== '/') {
                                        e.preventDefault();
                                        window.location.href = '/#book-a-free-call';
                                        setTimeout(() => {
                                            document.querySelector("#book-a-call-main-listing").scrollIntoView({ behavior: 'smooth' });
                                        }, 500);
                                    } else {
                                        e.preventDefault();
                                        document.querySelector("#book-a-call-main-listing").scrollIntoView({ behavior: 'smooth' });
                                    }
                                }}>
                                <CiCalendar style={{ marginRight: '5px', fontSize: '20px' }} />Book a Free Call
                            </Link>
                            </div>
                            {/* </div> */}
                        </div>
                        <div className='mob-link-right-options'>
                            <div className='eachdropmob'><Link
                                to="/business" style={{ color: '#60acce' }}>For Business <MdOutlineArrowOutward /></Link>
                            </div>
                        </div>
                    </div>
                    <div className='spacingbetween'></div>
                    <div className='drop-sign'>
                        <div className='sign-up'
                            onClick={() => window.location.href = "/login"}>Try Now
                        </div>
                    </div>
                </div> : ''}
                {drop && <div className='desktop-drops' style={{ transition: 'opacity 1s ease', opacity: drop ? 1 : 0 }}>
                    <div className='desktop-drop-links-container'>
                        <div className='eachdrop-desktop' ><Link
                            to="/blog/home">Blog</Link>
                        </div>
                        <a className='eachdrop-desktop' onClick={() => window.location.href = "/business"} style={{ color: '#60acce' }}>
                            For Business
                            <MdOutlineArrowOutward />
                        </a>
                        {/* <a className='eachdrop-desktop'  style={{ color: '#60acce', }}
                            onClick={(e) => {
                                if (window.location.pathname !== '/') {
                                    e.preventDefault();
                                    window.location.href = '/#book-a-free-call';
                                    setTimeout(() => {
                                        document.querySelector("#book-a-call-main-listing").scrollIntoView({ behavior: 'smooth' });
                                    }, 500);
                                } else {
                                    e.preventDefault();
                                    document.querySelector("#book-a-call-main-listing").scrollIntoView({ behavior: 'smooth' });
                                }
                            }}>Book a Free Call
                            <MdOutlineArrowOutward />
                        </a> */}

                    </div>
                </div>}
            </div>
        </>
    )
}

export default PreHeader

