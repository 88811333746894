import {useEffect} from 'react';

function SetTokenAndRedirect() {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');

    useEffect(() => {
        console.log('Token:', token);
        if (token) {
            console.log('Setting cookie and redirecting...');
            setCookie('jwt', token, 7);
            setCookie('X-SRC', 'ios', 7);
            window.location.href = `https://api.capitalflo.co/api/users/authorize?token=${encodeURIComponent(token)}`;
        }
    }, [token]);


    // Function to set a cookie
    function setCookie(name, value, days) {
        const expires = new Date(Date.now() + days * 24 * 60 * 60 * 1000).toUTCString();
        document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; domain=.capitalflo.co; path=/; samesite=None; secure`;
    }

    return null;
}

export default SetTokenAndRedirect;
