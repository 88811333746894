import React, {useEffect, useState} from 'react'
import {Link, useNavigate, useParams} from "react-router-dom";
import {MdOutlineLockReset} from "react-icons/md";
import {FiLogOut} from "react-icons/fi";
import {AiOutlineDelete, AiOutlineLogout} from "react-icons/ai";
import ConfirmationPopup from "./ConfirmationPopup";
import axios from "axios";
import "./AccountSettings.css";
import Logheader from '../PostHeader';
import { MdInfoOutline } from "react-icons/md";
import { isDemoAccountUsed } from '../utils/CheckIfDemo';
import DemoBlockerPopup from './DemoBlockerPopup';
import LOGIN from "./Login";



function AccountSettings() {
    const [showPopup, setShowPopup] = useState(false);
    const [Tokens, setTokens] = useState(null);
    const [showDemoPopup, setShowDemoPopup] = useState(false);

    const navigate = useNavigate();

    function clearCookie(name) {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }
    const tokenize = async () => {
        try {
            const response = await axios.get(
                "https://api.capitalflo.co/api/users/protect-user",
                {withCredentials: true}
            );
            console.log(response);

            if (response.data.message === "Cookie not found") {
                setTokens(null);
            } else {
                console.log(response.data.jwtToken);
                setTokens(response.data.jwtToken);
            }
        } catch (err) {
            console.log(err.response.data.message);
            setTokens(null);
        }
    };
    const Logout = async (e) => {
        e.preventDefault();
        console.log(Tokens)
        try {
            const response = await axios.post(
                `https://api.capitalflo.co/api/users/logout`, {},
                {
                    headers: {
                        Authorization: `Bearer ${Tokens}`,
                    },
                    withCredentials: true
                }
            );

            console.log(response.data);
            clearCookie("jwt");
            localStorage.clear();
            navigate(`/logout/`);
            navigate('/login');
        } catch (error) {
            console.error(error.response);
        }
    };
    const LogoutAll = async (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        try {
            const response = await axios.post(
                `https://api.capitalflo.co/api/users/logout-all`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${Tokens}`,
                    },
                    withCredentials: true,
                }
            );
            console.log(response.data);
            clearCookie("jwt");
            localStorage.clear();
            navigate('/login');
        } catch (error) {
            console.error(error.response);
        }
    };

    const handleResetPassword = () => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        navigate('/reset-password');
    }

    const DeleteAccount = () => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        setShowPopup(true); // Show the confirmation popup
    };
    useEffect(() => {
        tokenize();
    }, []);
    const handleDeleteConfirm = async () => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Perform account deletion logic here
        try {
            console.log(Tokens)
            const response = await axios.delete(
                "https://api.capitalflo.co/api/users/delete-user-account",
                {
                    headers: {
                        Authorization: `Bearer ${Tokens}`,
                    },
                    withCredentials: true,
                },
            );
            console.log(response.data);
            clearCookie("jwt");
            localStorage.clear();
            navigate('/login');
        } catch (error) {
            console.error(error.response);
        }
        setShowPopup(false); // Hide the popup
    };
    const handleDeleteCancel = () => {
        setShowPopup(false); // Hide the popup
    };

    const closePopup = () => {
        setShowDemoPopup(false);
    }
    return (
        <div style={{
                width: '100vw',
                display: 'flex',
                flexDirection: 'column',

            }}>
            <Logheader/>
            {showDemoPopup && <DemoBlockerPopup closePopup={closePopup} />}
            <div className='accountSettings' style={{
                marginLeft:'13vw',
                marginTop:'150px'
            }}>
                <ul>
                    <li>
                        <Link to="/info" className="link">
                            {/*<span className="count">5. </span>*/}
                            <MdInfoOutline className="icon" style={{color:'#424242'}} />
                            <p style={{
                                fontSize:'23px'
                            }}> Info</p>
                        </Link>
                    </li>
                    <li onClick={handleResetPassword}>
                        <Link className="link">
                            {/*<span className="count">5. </span>*/}
                            <MdOutlineLockReset className="icon" style={{color:'#424242'}} />
                            <p style={{
                                fontSize:'23px'
                            }}>Reset Password</p>
                        </Link>
                    </li>
                    <li onClick={Logout}>
                        <Link  className="link">
                            {/*<span className="count">6. </span>*/}
                            <FiLogOut className="icon" />
                            <p style={{
                                fontSize:'23px'
                            }}>Log Out</p>
                        </Link>
                    </li>
                    <li onClick={LogoutAll}>
                        <Link  className="link">
                            {/*<span className="count">7. </span>*/}
                            <AiOutlineLogout className="icon" />
                            <p style={{
                                fontSize:'23px'
                            }}>Log Out from all devices.</p>
                        </Link>
                    </li>
                    <li>
                        <Link  className="link">
                            {/*<span className="count">8. </span>*/}
                            <AiOutlineDelete className="icon"/>
                            <p style={{
                                fontSize:'23px'
                            }} onClick={DeleteAccount}>Delete Account</p>
                        </Link>
                    </li>
                    {showPopup && (
                        <ConfirmationPopup
                            message="Are you sure you want to delete your account?"
                            onConfirm={handleDeleteConfirm}
                            onCancel={handleDeleteCancel}
                        />
                    )}
                </ul>

            </div>
        </div>

    )
}
export default AccountSettings;