import {ReleaseProvider} from './ReleaseContext';
import {Outlet, Route, Routes} from 'react-router-dom';
import Homepage from './pages/Homepage.js';
import FinancialAdvisor from './pages/FinancialAdvisor';
import Discover from './pages/Discover';
// import Homepage from './pages/Home.js';
// import Login from './pages/Login.js';
// import Signup from './pages/Signup';
import Services from './pages/Services';
import Transaction from './pages/Transaction';
import Budget from './pages/Budget';
import Spending from './pages/Spending';
import Planbudget from './Planbudget';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import ProtectedRoute from './ProtectedRoute';
import About from './pages/About';
import Contact from './pages/Contact';
import BankInsights from './pages/Bank/BankInsights';
import Profile from './pages/Profile';
import ContactUs from './pages/ContactUs';
import TFP from './pages/Bank/Tfp';
import Goals from './pages/Goals'
import Banks from './pages/Bank/Bankstatements'
import ResetPassword from './pages/Resetpassword'
import OTPValidationPage from './pages/OTPValidationPage'
import Blogs from './pages/Blogs'
import Products from './pages/Products'
import Faq from './pages/Faq'
import FeedBack from './pages/feedback';
import ScrollToTopOnPageChange from './ScrollToTopOnPageChange';
import SetTokenAndRedirect from './SetTokenAndRedirect';
import PostDetail from "./blog/components/PostDetails";
import AdminLogin from "./pages/AdminLogin"
import AdminBlogs from "./admin/adminBlog";
import PostBlog from "./admin/BlogEditor";
import AllFeaturedPage from "./pages/featuredAll";
import AllBlogsPage from "./pages/blogsAll";
import UserProfile from "./pages/UserProfile";
import SupportAndFeedback from "./pages/SupportAndFeedback";
import AccountSettings from "./pages/AccountSettings";
import Business from "./pages/Business.js";
import userManual from "./pages/userManual";
import InfoPage from "./pages/InfoPage";
import Startup from './pages/Startup.js';
import FinancialHealth from "./pages/FinancialHealth";
import EmergencyFundRatio from "./pages/FInancial Health/EmergencyFundRatio";
import LiquidAssets from "./pages/FInancial Health/LiquidAssets";
import AnnualDebtService from "./pages/FInancial Health/AnnualDebtService";
import DebtToAssets from "./pages/FInancial Health/DebtToAssets";
import Savings from "./pages/FInancial Health/Savings";
import MonthlyCashFlowPosition from "./pages/FInancial Health/MonthlyCashFlowPosition";
import AnnualCashflowPosition from "./pages/FInancial Health/AnnualCashflowPosition";
import FinancialNetworth from "./pages/FInancial Health/FinancialNetworth";
import LiquidAssetstoAnnualExpenses from "./pages/FInancial Health/LiquidAssetstoAnnualExpenses";
import NetWorthToAnnualExpenses from "./pages/FInancial Health/NetWorthToAnnualExpenses";
import LifeInsurance from "./pages/FInancial Health/LifeInsurance";
import HealthInsurance from "./pages/FInancial Health/HealthInsurance";
import TfpAssets from './pages/Bank/tfpAssets.js';
function App() {
    return (
        <ReleaseProvider>
            <div className="App">
                <Routes>
                    <Route element={<ProtectedRoute/>}>
                        <Route element={<Services/>} path="/services" exact/>
                        <Route element={<Transaction/>} path="/transaction" exact/>
                        <Route element={<Planbudget/>} path="/plan-budget" exact/>
                        <Route element={<Spending/>} path="/spending" exact/>
                        <Route element={<Planbudget/>} path="/plan-budget" exact/>
                        <Route element={<Budget/>} path="/budget" exact/>
                        <Route element={<BankInsights/>} path="/bank-statement-insights" exact/>
                        <Route element={<Contact/>} path="/contact" exact/>
                        <Route element={<TFP/>} path="/total-financial-position" exact/>
                        <Route element={<TfpAssets/>} path="/tfp-assets" exact/>
                        <Route element={<Goals/>} path="/goals" exact/>
                        <Route element={<FinancialHealth/>} path="/financial-health" exact/>
                        <Route element={<EmergencyFundRatio/>} path="/emergency-fund-ratio" exact/>
                        <Route element={<LiquidAssets/>} path="/liquid-assets-to-networth" exact/>
                        <Route element={<AnnualDebtService/>} path="/annual-debt-service" exact/>
                        <Route element={<DebtToAssets/>} path="/debt-to-assets" exact/>
                        <Route element={<Savings/>} path="/savings" exact/>
                        <Route element={<MonthlyCashFlowPosition/>} path="/monthly-cashflow-position" exact/>
                        <Route element={<AnnualCashflowPosition/>} path="/annual-cashflow-position" exact/>
                        <Route element={<FinancialNetworth/>} path="/financial-networth" exact/>
                        <Route element={<LiquidAssetstoAnnualExpenses/>} path="/liquid-assets-to-annual-expenses" exact/>
                        <Route element={<NetWorthToAnnualExpenses/>} path="/networth-to-annual-expenses" exact/>
                        <Route element={<LifeInsurance/>} path="/life-insurance" exact/>
                        <Route element={<HealthInsurance/>} path="/health-insurance" exact/>
                        <Route element={<Banks/>} path="/bank-statements" exact/>
                        <Route element={<ResetPassword/>} path="/reset-password" exact/>
                        <Route element={<FeedBack/>} path="/feedback" exact/>
                        <Route element={<AdminLogin/>} path="/adm-log" exact/>
                        <Route element={<AdminBlogs/>} path="/adm-blogs" exact/>
                        <Route element={<PostBlog/>} path="/post-blog" exact/>
                        <Route element={<FinancialAdvisor/>} path="/financial-advisor" exact/>
                        <Route element={<Discover/>} path="/discover" exact/>
                        <Route element={<Profile/>} path="/profile" exact/>
                        <Route element={<UserProfile/>} path="/profile/user-profile/:email" exact/>
                        <Route element={<SupportAndFeedback/>} path="/profile/support-and-feedback" exact/>
                        <Route element={<userManual/>} path="/profile/user-manual/" exact/>
                        <Route element={<AccountSettings/>} path="/profile/account-settings/" exact/>
                        <Route element={<InfoPage/>} path="/info" exact/>
                    </Route>
                    <Route element={<OTPValidationPage/>} path="/authentication" exact/>
                    <Route element={<Homepage/>} path="/" exact/>
                    {/* <Route element={<Login/>} path="/login"/> */}
                    <Route element={<Startup/>} path="/login"/>
                    <Route element={<Business/>} path="/business"/>
                    <Route element={<Blogs/>} path="/blog"/>
                    <Route element={<Products/>} path="/products/:keyword?"/>
                    <Route element={<About/>} path="/about" exact/>
                    {/* <Route element={<Signup/>} path="/signup" exact/> */}
                    <Route element={<Faq/>} path="/faqs" exact/>
                    <Route path="/blog">
                        <Route index element={<Blogs/>}/>
                        <Route element={<Outlet/>}>
                            <Route path="featured" element={<AllFeaturedPage/>}/>
                            <Route path="home" element={<Blogs/>}/>
                            <Route path="all" element={<AllBlogsPage/>}/>
                            <Route path=":slug" element={<PostDetail/>}/>
                        </Route>
                    </Route>
                    <Route element={<Privacy/>} path="/privacy_policy" exact/>
                    <Route element={<Terms/>} path="/terms-of-service" exact/>
                    <Route element={<ContactUs/>} path="/contactUs" exact/>
                    <Route element={<SetTokenAndRedirect/>} path="/set-token-and-redirect" exact/>
                </Routes>
                <ScrollToTopOnPageChange/>
            </div>
        </ReleaseProvider>
    );
}


export default App;
