import { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CgSpinner } from "react-icons/cg";


const ProtectedRoute = () => {
    const [jwtToken, setJwtToken] = useState(null);
    const [isTokenSet, setIsTokenSet] = useState(false);
    const [showToast, setShowToast] = useState(false);

    function deleteAllCookies() {
        let Cookies = document.cookie.split(';');
        // set past expiry to all cookies
        for (let i = 0; i < Cookies.length; i++) {
            document.cookie = Cookies[i] + "=; expires=" + new Date(0).toUTCString();
        }
    }

    useEffect(() => {
        if (showToast) {
            toast.error('Your demo account has expired. Please log in with a full account.')
        }
    }, [showToast])
    const checkTokenIfDemo = async () => {
        // TODO 
        const response = await axios.get(
            `https://api.capitalflo.co/api/users/user-profile`,
            {
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                },
                withCredentials: true
            }
        ); 
        if (response.data.mobile === '7623817993') {
            console.log("Using Demo account");
            const demoAccountSetupTime = localStorage.getItem('demoAccountSetupTime');
            const currentTime = Date.now();
            const oneHour = 1000 * 60 * 60;
            console.log('TIMEEE current',currentTime);
            console.log('TIMEEE start',demoAccountSetupTime);
            if (currentTime - demoAccountSetupTime > oneHour) {
                setShowToast(true);
                console.log("Demo account usage time has expired");
                localStorage.removeItem('demoAccountSetupTime');
                document.cookie = `jwt=; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
                deleteAllCookies();
                setTimeout(() => {
                    window.location.replace('/login');
                }, 3000)
                return;
            } else {
                console.log('DEMOOO TIMEE')
                console.log("Time left for demo account usage: ", oneHour - (currentTime - demoAccountSetupTime))
            }
        } 
        else {
            console.log("Not using demo account");
            localStorage.removeItem('demoAccountSetupTime');
            setShowToast(false);
        }
    }

    useEffect(() => {
        const getJwtFromCookies = async () => {
            try {
                const response = await axios.get('https://api.capitalflo.co/api/users/protect-user', { withCredentials: true });
                if (response.data.message === 'Cookie not found') {
                    console.log('cookies is not here');
                    console.log(response.data.message);
                    setJwtToken(null);
                    localStorage.clear();
                    setIsTokenSet(true);
                } else {
                    console.log('cookies is here');
                    console.log(response.data.jwtToken);
                    setJwtToken(response.data.jwtToken);
                    setIsTokenSet(true);
                }
            } catch (err) {
                console.log(err.response.data.message);
                setJwtToken(null);
                localStorage.clear();
                setIsTokenSet(true);
            }
        };
        getJwtFromCookies();
        checkTokenIfDemo();
    }, []);

    if (!isTokenSet) {
        return null;
    }

    return jwtToken ? (
        <div>
            <Outlet />
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </div>
    ) : <Navigate to="/login" />;
};

export default ProtectedRoute;

