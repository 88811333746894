import Logheader from '../../PostHeader.js'

import React, { useEffect, useLayoutEffect, useState } from 'react';
// import Header from '../../Header';
import '../Spending.css';
import axios from "axios";
import avgspend from '../../assets/img/avgspend.png';
// import shopping from '../../assets/img/shopping.png';
// import cutlery from '../../assets/img/cutlery.png';
// import miscellaneous from '../../assets/img/miscellaneous.png';
import { IoIosArrowDropdown } from "react-icons/io";
// import Calender from '../Calenderdemo'
// import Monthselector from '../Monthselector'
import StatementsSelector from '../StatementSelector'

import bill1 from '../../assets/img/bill1.png'
import loan from '../../assets/img/loan.png'
import food from '../../assets/img/food.png'
import invest from '../../assets/img/economic.png'
import withdrawal from '../../assets/img/withdrawal.png'
import tax from '../../assets/img/tax.png'
import cheque from '../../assets/img/cheque.png'
import fuelstation from '../../assets/img/gas-station.png'
// import fastfood from '../../assets/img/fastfood.png'
import personalcare from '../../assets/img/personalcare.png'
import home from '../../assets/img/home.png'
import education from '../../assets/img/education.png'
import travel from '../../assets/img/travel.png'
import savings from '../../assets/img/savings.png'
import businesservice from '../../assets/img/businesservice.png'
import transfer from '../../assets/img/transfer.png'
import other from '../../assets/img/other.png'
import health from '../../assets/img/health.png'
import medicine from '../../assets/img/medicine.png'
import tag from '../../assets/img/tag.png'
import shop from '../../assets/img/shop.png'
import rent from '../../assets/img/rent.png'
import cinema from '../../assets/img/cinema.png'
import entercat from '../../assets/img/entercategory.png'
import { GrCircleInformation } from "react-icons/gr";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import LoadingAnimation from "../../assets/Loading.svg";
import {Link, useParams} from "react-router-dom";
import { Helmet } from 'react-helmet';


function BankInsights() {
    const [Tokens, setTokens] = useState('');
    const [ShowRecur, setRecurr] = useState(false);
    const [categoryTotals, setCategoryTotals] = useState([]);
    const [allcategoryTotals, setAllCategoryTotals] = useState([]);
    const [totalamt, totalMonthlySpent] = useState(0);
    const [filterTransactions, setFilteredTransactions] = useState([]);
    const [RecurringDta, setRecurringDta] = useState();
    const [savedIncome, setIncomeSave] = useState();
    const [incomeShow, ShowIncome] = useState();
    const [Focusing, ShowFocus] = useState(false);
    const [statement, setStatement] = useState(1);
    const [Year, setYear] = useState(new Date());
    const [Month, setRealMonth] = useState(new Date());
    const [avgdaily, setAvdDaily] = useState(0);
    const [isInfoVisibleAvgDaily, setisInfoVisibleAvgDaily] = useState(false)
    const [isInfoVisibleBankInsights, setisInfoVisibleBankInsights] = useState(false)
    const [loading, setLoading] = useState(true);
    // const [dayPass, setDaysPassed] = useState(0);
    // const [value, setValue] = useState(new Date())

    const dropppingMerchant = () => {
        setRecurr(!ShowRecur);
    }
    const sortedCategories = Object.entries(categoryTotals)
        .sort(([, totalA], [, totalB]) => totalB - totalA)
        .map(([category]) => category);
    const filteredCategories = sortedCategories.filter(category => category !== 'Enter Category');

    filteredCategories.unshift('Enter Category');


    const handleStatementChange = (statementNo) => {
        setStatement(statementNo);
        console.log(statementNo);
    }

    const handleIncome = (e) => {
        setIncomeSave(e.target.value)
    }
    const IncomeFocus = () => {
        ShowFocus(true)
        ShowIncome(null)

    }

    const tokenize = async () => {
        try {
            const response = await axios.get(
                "https://api.capitalflo.co/api/users/protect-user",
                { withCredentials: true }
            );
            if (response.data.message === "Cookie not found") {
                setTokens(null);
            } else {
                setTokens(response.data.jwtToken);
            }
        } catch (err) {
            console.log(err.response.data.message);
            setTokens(null);
        }
    };

    useEffect(() => {
        tokenize();
    }, []);


    const categoryImages = {
        'Enter Category': entercat,
        'Shopping': shop,
        'Food & Drinks': food,
        'Entertainment': cinema,
        'Groceries': food,
        'Business Services': businesservice,
        'Personal Care': personalcare,
        'Medicines': medicine,
        'Health': health,
        'Education': education,
        'Investment': invest,
        'Bills/Utilities': bill1,
        'Loan/EMI': loan,
        'Fuel/Transport': fuelstation,
        'Rent': rent,
        'Home': home,
        'Transfer': transfer,
        'Savings/Goal': savings,
        'Others': other,
        'Your Category': tag,
        'Travel': travel,
        'Cash/Withdraw': withdrawal,
        'Cheque': cheque,
        'Taxes': tax,
    };

    useEffect(() => {
        const getAllTransactions = async () => {
            setLoading(true)
            console.log("getting all transactions $$$$$$$$$$$ for " + statement);
            try {
                const response = await axios.post(
                    `https://api.capitalflo.co/api/users/statement-all-transaction`,
                    {
                        statement: statement
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${Tokens}`,
                        },
                        withCredentials: true
                    }
                );

                console.log(response.data);
                console.log('filtered');
                console.log(response.data[0]);
                if (response.data) {
                    setFilteredTransactions(response.data);
                    const filteredTransactions = response.data
                    const categoriesToFilter = ['Enter Category', 'Shopping', 'Food & Drinks', 'Entertainment', 'Groceries', 'Business Services', 'Personal Care', 'Medicines', 'Health', 'Education', 'Investment', 'Bills/Utilities', 'Loan/EMI', 'Fuel/Transport', 'Rent', 'Home', 'Transfer', 'Savings/Goal', 'Others', 'Your Category', 'Travel', 'Cash/Withdraw', 'Cheque', 'Taxes'];
                    setAllCategoryTotals(categoriesToFilter);

                    const newCategoryTotals = {};

                    categoriesToFilter.forEach(category => {
                        const categoryTransactions = filteredTransactions.filter(transaction => transaction.category === category);
                        const totalAmount = categoryTransactions.reduce((total, transaction) => total + transaction.amount, 0);
                        newCategoryTotals[category] = totalAmount || 0;
                    });

                    // Update state with objects instead of arrays
                    setAllCategoryTotals(newCategoryTotals);
                    setCategoryTotals(newCategoryTotals);
                    setLoading(false)
                } else {
                    setLoading(false)
                    setFilteredTransactions([])
                    setAllCategoryTotals({})
                    setCategoryTotals({})
                }

            } catch (error) {
                setFilteredTransactions([])
                setAllCategoryTotals({});
                setCategoryTotals({});
                console.error(error);
                setLoading(false)
            }
        };
        console.log('going to fetch');
        getAllTransactions();
    }, [statement]);


    useEffect(() => {
        const spendingRecur = async () => {
            try {
                console.log("getting all recurring for " + statement);
                const response = await axios.post(
                    `https://api.capitalflo.co/api/users/get-bank-spending-statement`,
                    {
                        statement: statement
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${Tokens}`,
                        },
                        withCredentials: true
                    }
                );
                // if (response.data == null) {
                //     console.log('Failed');
                //     setAvdDaily(0)
                //     setRecurringDta([])
                //     totalMonthlySpent(0);
                // } else {
                if (response.data) {
                    let responses = response.data;
                    console.log(response);
                    setAvdDaily(responses.avgDaily)
                    totalMonthlySpent(responses.totalSpending);
                    setRecurringDta(responses.merchantArray);
                } else {
                    setAvdDaily(0)
                    setRecurringDta([])
                    totalMonthlySpent(0);
                }

            } catch (err) {
                console.log(err.response.data);
                setTokens(null);
                setAvdDaily(0)
                setRecurringDta([])
                totalMonthlySpent(0);
            }
        }
        spendingRecur()
    }, [Tokens, statement])

    const saveIncome = async () => {
        try {
            const income = savedIncome;
            const response = await axios.post(
                'https://api.capitalflo.co/api/users/save-income',
                { income },
                {
                    headers: {
                        Authorization: `Bearer ${Tokens}`,
                    },
                    withCredentials: true,
                }
            );

        } catch (err) {
            console.error('Error saving income:', err);
        }
    };

    const showInfoAvgDailySpend = () => {
        setisInfoVisibleAvgDaily(true)
    }
    const hideInfoAvgDailySpend = () => {
        setisInfoVisibleAvgDaily(false)
    }
    const showInfoBankInsights = () => {
        setisInfoVisibleBankInsights(!isInfoVisibleBankInsights)
    }
    const hideInfoBankInsights = () => {
        setisInfoVisibleBankInsights(false)
    }
    useEffect(() => {
        const fastIncome = async () => {
            const response = await axios.get("https://api.capitalflo.co/api/users/save-income",
                {
                    headers: {
                        Authorization: `Bearer ${Tokens}`,
                    },
                    withCredentials: true,
                }
            )
            if (response.data.data.income) {
                ShowIncome(response.data.data.income)
            }
        }
        fastIncome();
    }, [])
    // if (loading) {
    //     return <div className='blog_loading_animation'><img src={LoadingAnimation} alt=""/></div>
    // }
    const selectedStatement = (e) => {
        const selectedValue = e.target.value;
        handleStatementChange(selectedValue);
        setStatement(selectedValue)
    }

    useEffect(() => {
        const closeIButton = (e) => {
            if (e.target.namespaceURI !== 'http://www.w3.org/2000/svg') {
                setisInfoVisibleBankInsights(false)
            }
        }
        document.addEventListener('click', closeIButton)
        return () => document.body.removeEventListener('click', closeIButton)

    }, []);
    return (
        <div style={{
            width: '100vw',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
        }}>
            {/* <Header user={true} /> */}
            <Logheader />
            <Helmet>
                <title>Bank Insights</title>
                <meta name="description"
                    content="check your bank insights" />
            </Helmet>
            <div style={{
                width: '100vw',
                alignSelf: 'center',
            }}>
                <div className='mainheader'>
                    <h2 style={{
                        marginLeft: '34vw'
                    }}>Bank Statement Insights</h2>
                    <span onClick={showInfoBankInsights} >
                        <div style={{ fontSize: '24px', paddingBottom: '35px', paddingLeft: '10px' }}>
                            <GrCircleInformation />
                        </div>
                        <div
                            className={isInfoVisibleBankInsights ? "isInfoVisibleBankInsights visible" : "isInfoVisibleBankInsights"}>
                            <p>To understand how to use this feature go to user manual in settings or click
                            </p>
                            <Link to='/products/usermanual#statement' style={{
                                color: '#004AAD'
                            }}>Statement Analyzer</Link>
                        </div>
                    </span>
                </div>
                <div className='toplayer'>
                    <div className="insightarraow" style={{
                        left: '7vw',
                        top: '35vh'
                    }}>
                        <p>
                            <Link to={`https://capitalflo.co/bank-statements`}>
                                <BsArrowLeft className="arraows" />
                            </Link>
                        </p>
                        <Link to={`https://capitalflo.co/bank-statements`}>Statement</Link>
                    </div>
                </div>
                {loading ? <div className='blog_loading_animation'><img src={LoadingAnimation} alt="" /></div> :
                    <>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: 'auto'
                        }}>
                            <div className='innerlayer' style={{
                                marginTop: '20vh',
                                marginLeft: '15vw'
                            }}>
                                <img src={avgspend} alt="" style={{
                                    marginLeft: '10vw',
                                    height: '60px',
                                    width: '60px'
                                }} />
                                <p style={{ display: 'flex', fontWeight: '400', fontSize: '20px', gridTemplateColumns: '100px 35px', marginBottom: '0px', marginLeft: '7vw' }}>Avg Daily
                                    <span onMouseOver={showInfoAvgDailySpend} onMouseOut={hideInfoAvgDailySpend}>
                                        <div style={{ fontSize: '24px', paddingLeft: '1vw' }}>
                                            <GrCircleInformation />
                                        </div>
                                        <div className={isInfoVisibleAvgDaily ? "isInfoVisibleAvgDaily visible" : "isInfoVisibleAvgDaily"}>
                                            <p>Average daily spend is calculated from total fixed + variable expenses vs true/actual  average daily spend is based on variable expenses such as  food, shopping entertainment, etc.
                                            </p>
                                        </div>
                                    </span>
                                </p>
                                <span style={{ marginLeft: '5px', display: 'flex', alignItems: 'center' }}>
                                    <span style={{ fontWeight: '400', fontSize: '20px', marginLeft: '7vw' }}>spend:</span>
                                    <span className='avgdaily' style={{ paddingLeft: '8px' }}>{Math.round(avgdaily)}</span>
                                </span>
                            </div>

                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                position: 'absolute',
                                top: '58vh',
                                left: '35vw',
                            }}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '440px',
                                    gap: '20px',

                                }}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        zIndex: '2'
                                        // gap:'30px',
                                        // marginLeft:'120px'
                                    }}>
                                        {/*<StatementsSelector onSelectStatement={handleStatementChange}/>*/}
                                        <div className='selectMonth'>
                                            <select name='month' value={statement} onChange={selectedStatement}>
                                                <option value='1'>Statement 1</option>
                                                <option value='2'>Statement 2</option>
                                            </select>
                                        </div>
                                    </div>

                                    <p style={{
                                        fontSize: '19px',
                                        color: 'rgb(66, 66, 66)',
                                        marginLeft: '10px',
                                        marginBottom: '10px'
                                    }}>{filterTransactions[0] ? filterTransactions[0].fileName : "Statement.pdf"}</p>
                                </div>
                                <div className='totalspend'>Total Spent:<span className='spenttotal'> {totalamt}</span>

                                </div>
                            </div>
                            <div className='recurringDrop' onClick={dropppingMerchant}>
                                <h5>Recurring Merchants</h5>
                                <IoIosArrowDropdown className='dropdownIcon' />
                            </div>
                            <div className='appearingDiv'>
                                {ShowRecur && (
                                    <div id="recurringMerchantModal" className="merchant-modal">
                                        <div className="merchant-modal-body">
                                            <h2>Recurring Merchants</h2>
                                            <div className='merchant-list'>
                                                {RecurringDta ? RecurringDta.map((each, i) => (
                                                    <ul className='merchant-item' key={i}>
                                                        <li><span>{i + 1}.</span> {each}</li>
                                                    </ul>
                                                )) : ''}
                                            </div>
                                            <button onClick={() => setRecurr(false)}>Close</button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='minimalHeader' style={{
                            marginTop: '160px'
                        }}>
                            <p>Categories</p>
                        </div>
                        <div className='insights'>
                            {filteredCategories.map((category, index) => (
                                <div className='spending-category' key={index}>
                                    <div className='spendcategory'>
                                        <img src={categoryImages[category]} alt='' />
                                    </div>
                                    <div>
                                        <h2>{category}</h2>
                                    </div>
                                    <div>
                                        <h2>{isNaN(categoryTotals[category]) ? '0' : Math.round(categoryTotals[category])}</h2>
                                    </div>
                                </div>
                            ))}
                            <div className='bottomSpace'></div>
                        </div>
                    </>
                }
            </div>
        </div>
    );
}

export default BankInsights;
