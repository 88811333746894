import React from 'react';
import './Business.css';
import { Link } from 'react-router-dom';
import { Usedrop, UsedropReset, UsedropUpdate } from '../ReleaseContext';
import { Helmet } from "react-helmet";
import PreHeader from '../PreHeader';

const Business = () => {
    const structuredData = {
        "@context": "http://schema.org",
        "@type": "WebPage",
        "name": "For Business",
        "description": "Bank Statement Analyser for Business",
        "url": "https://capitalflo.co/business",
    };
    const drop = Usedrop();
    const dropping = UsedropUpdate();
    const droppie = UsedropReset();

    return (
        <div>
            <Helmet>
                <title>For Business</title>
                <meta name="description"
                    content="Bank Statement Analyser for Business" />
                <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
            </Helmet>
            <div className='business_page' onClick={droppie}>
                <PreHeader />
                <div className='coming_soon'>
                    <h1>Coming Soon!</h1>
                    <p>Stay tuned for some exciting business features. We are working hard to bring you an enhanced experience.</p>
                    <Link to='/'><button>Go Back Home</button></Link>
                </div>
            </div>
        </div>
    )
}

export default Business