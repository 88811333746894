import React, {useEffect, useState} from "react";
import "./Transaction.css";
import apple from "../assets/storeimg/App Store.png";
import google from "../assets/storeimg/Google Play.png";
import Logheader from "../PostHeader";
import {GrCircleInformation} from "react-icons/gr";
import {Link} from "react-router-dom";

function Transaction() {
    const [isInfoVisibleTransactions, setisInfoVisibleTransactions] = useState(false)
    const showInfoTransactions = () => {
        setisInfoVisibleTransactions(!isInfoVisibleTransactions)
    }
    const hideInfoTransactions = () => {
        setisInfoVisibleTransactions(false)
    }
//
//     const [addtxts, setaddtxts] = useState(false);
//     const [edittxt, setedittxt] = useState(false);
//     const [selectedexp, setselectedexp] = useState(null);
//     const [altxtdta, setAllTxnData] = useState(null);
//     const [totalamt, totalMonthlySpent] = useState();
//     const [month, setMonthly] = useState('');
//     const [settotalDaily, settotalDailySpent] = useState('');
//
//
//     const [Tokens, setTokens] = useState('');
//
//     const txtdetailing = (e) => {
//         const listNum = e.target.dataset.index;
//         setselectedexp(altxtdta[listNum]);
//         setedittxt(true);
//     };
//
//     const addtxt = () => {
//         setaddtxts(true);
//     };
//
//     const popupHadler = () => {
//         setaddtxts(false);
//     };
//
//     const popupEditxt = () => {
//         setedittxt(false);
//     };
//
//     const getingtxt = (details) => {
//         console.log(details);
//     };
//
//     const marchant = true;
//
//
//     const tokenize = async () => {
//         try {
//             const response = await axios.get(
//                 "https://api.capitalflo.co/api/users/protect",
//                 {withCredentials: true}
//             );
//             if (response.data.message === "Cookie not found") {
//                 setTokens(null);
//             } else {
//                 setTokens(response.data.jwtToken);
//             }
//         } catch (err) {
//             console.log(err.response.data.message);
//             setTokens(null);
//         }
//     };
//
//     useEffect(() => {
//         tokenize();
//     });
//
//
//     useLayoutEffect(() => {
//         const getAllTransactions = async () => {
//             try {
//                 const response = await axios.get(
//                     `https://api.capitalflo.co/api/users/all-transactions`,
//                     {
//                         headers: {
//                             Authorization: `Bearer ${Tokens}`,
//                         },
//                         withCredentials: true
//                     }
//                 );
//                 totalMonthlySpent(Math.round(response.data[response.data.length - 3].totalMonthlySpent));
//                 setMonthly(response.data[response.data.length - 1].month);
//                 settotalDailySpent(Math.round(response.data[response.data.length - 2].totalDailySpent));
//
//                 setAllTxnData(response.data);
//
//             } catch (error) {
//                 console.error(error);
//             }
//         };
//
//         if (Tokens) {
//             getAllTransactions();
//         }
//     }, [Tokens]);
//
//     const ScrollOnCondition = ({altxtdta}) => {
//         const elementRef = useRef(null);
//
//         useEffect(() => {
//             if (altxtdta && altxtdta.length > 3) {
//                 const scrollPositionSession = localStorage.getItem('scrollPosition');
//                 console.log("scrollPositionSession" + scrollPositionSession);
//                 if (scrollPositionSession) {
//                     setTimeout(() => {
//                         window.scrollTo({
//                             top: parseInt(scrollPositionSession),
//                             behavior: 'smooth'
//                         });
//                         localStorage.removeItem('scrollPosition');
//                     }, 800);
//                 }
//             }
//         }, []);
//
//         return (
//             <p className="titlesBank" id="titlesBank" ref={elementRef}>
//                 Bank Transactions
//             </p>
//         );
//     };
//
//
//     return (
//         <React.Fragment>
//             {addtxts && (
//                 <Transactionmodel Ongoing={getingtxt} onConfirm={popupHadler}/>
//             )}
//             {altxtdta && altxtdta.length > 3 ? <ScrollOnCondition altxtdta={altxtdta}/> : null}
//             {edittxt && (
//                 <>
//                     {/* <div className='editoverlay' onClick={popupEditxt}></div> */}
//                     <ModalEdit
//                         onConfirm={popupEditxt}
//                         expense={selectedexp}
//
//                     />
//                 </>
//             )}
//             {/* <Header user={true} /> */}
//             <Logheader/>
//             <div className="transactionpage">
//                 <div className="Heading">
//                     <p>Transactions</p>
//                 </div>
//
//                 <div className="Spendinsightarraow">
//                     <p>
//                         <Link to="https://capitalflo.co/spending"><BsArrowRight className="erow"/></Link>
//                     </p>
//                     <Link to="https://capitalflo.co/spending">Insights</Link>
//                 </div>
//
//                 <div className="topcategory">
//                     <div>
//                         <img src={spent} alt=""/>
//                         <p>Spent today- <span>{settotalDaily}</span></p>
//                     </div>
//                     <div className="addtxt" onClick={addtxt}>
//                         <img src={add} alt=""/>
//                         <p>Add transaction</p>
//                     </div>
//                 </div>
//                 <div className="subheading">
//                     <p className="subhead">
//                         Spent this month- <span>{totalamt}</span>
//                     </p>
//                     <div className="mainpath">
//                         {altxtdta !== null ? (
//                             altxtdta.map((each, i) => {
//                                 if (!each.amount) {
//                                     return null;
//                                 }
//                                 let CATEGORY;
//                                 if (each.category) {
//                                     CATEGORY = each.category
//                                 } else {
//                                     CATEGORY = 'Enter Category'
//                                 }
//                                 const matchingCategory = allCategories.find((item) => item.category === CATEGORY);
//                                 const imageSrc = matchingCategory ? matchingCategory.image : '';
//
//                                 return (
//                                     <React.Fragment key={i}>
//
//                                         <div className="listexpense" onClick={txtdetailing}>
//                                             <div className="count">
//                                                 <p>{i + 1}</p>
//                                             </div>
//                                             <div className={each.notes ? "expense" : ""} data-index={i}>
//
//                                                 <div className={each.notes ? "innerExpense" : "expensenonnotes"}
//                                                      data-index={i}>
//                                                     <div data-index={i}>
//                                                         {!marchant ? (
//                                                             <div className="retry" data-index={i}>
//                                                                 <a href="#">
//                                                                     <img src={refresh} alt=""/>
//                                                                 </a>
//                                                             </div>
//                                                         ) : (
//                                                             ""
//                                                         )}
//                                                         <div className="date" data-index={i}>
//                                                             {each.category === 'Enter Category' ?
//                                                                 <RiErrorWarningLine data-index={i}
//                                                                                     className="warning"/> : ""}
//                                                             <div className="innerdate" data-index={i}>
//                                                                 {each.date}
//                                                             </div>
//                                                             <div className="innertime" data-index={i}>
//                                                                 {each.time}
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                     <div className={each.notes ? "merchant" : "merchantnonnotes"}
//                                                          data-index={i}>
//                                                         <p className="merchantcapital" data-index={i}>
//                                                             {each.merchantName}
//                                                         </p>
//                                                         <hr/>
//                                                         {!marchant ? (
//                                                             <div className="entercat" data-index={i}>
//                                                                 <a href="#">Enter Category </a>
//                                                             </div>
//                                                         ) : (
//                                                             <div className="bottommerchant" data-index={i}>
//                                                                 <div className="merch">
//                                                                     {/* <IconContext.Provider value={{ color: '#000', size: '38px', className: "shopIcon" }}> */}
//
//                                                                     {each.category === 'Enter Category' ? "" :
//                                                                         <div className="shopIcon" data-index={i}>
//                                                                             <a href="#">
//                                                                                 <img src={imageSrc} alt=""
//                                                                                      data-index={i}/>
//                                                                             </a>
//                                                                         </div>
//                                                                     }
//                                                                 </div>
//                                                                 {each.category == 'Enter Category' ?
//                                                                     <div className="shopEnter" data-index={i}>
//                                                                         {each.category}
//                                                                     </div> : <div className="shop" data-index={i}>
//                                                                         {each.category
//                                                                         }
//                                                                     </div>}
//                                                             </div>
//                                                         )}
//                                                     </div>
//                                                     <div className="price" data-index={i}>
//                           <span data-index={i}>
//                             <BiRupee data-index={i}/>
//                           </span>
//                                                         {Math.round(each.amount)}
//                                                     </div>
//                                                 </div>
//
//                                                 {each.notes ? <div className="noting" data-index={i}>
//                                                     <div className="alignnote" data-index={i}>
//                                                         <CgTag className="notetag" data-index={i}/>
//                                                         <p data-index={i}>{each.notes}</p>
//                                                     </div>
//                                                 </div> : ""}
//                                                 {/*
//                       <div className="noting" data-index={i}>
//                        <div className="alignnote" data-index={i}>
//                         <CgTag className="notetag" data-index={i}/>
//                         <p data-index={i}>Coffee and tea</p>
//                        </div>
//                       </div>  */}
//
//                                             </div>
//
//                                         </div>
//                                     </React.Fragment>
//                                 );
//                             })
//                         ) : (
//                             <h1>No expense</h1>
//                         )}
//                         <div className='bottomSpace'></div>
//                     </div>
//                 </div>
//             </div>
//         </React.Fragment>
//     );
    //return a sample img
    useEffect(() => {
        const closeIButton = (e) => {
            if(e.target.namespaceURI!=='http://www.w3.org/2000/svg'){
                setisInfoVisibleTransactions(false)
            }
        }
        document.addEventListener('click', closeIButton)
        return ( )=>document.body.removeEventListener('click', closeIButton)

    }, []);


    return (
        <React.Fragment>
            <div style={{
                width: '100vw',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
            }
            }>
                <Logheader/>
                <div className="title" style={{
                    display:'flex',
                    flexDirection:'row',
                    marginTop:'20vh',
                }}>
                    <p style={{
                        fontSize:'38px'
                    }}>Auto Track Transactions</p>
                    <span >
                            <div style={{fontSize: '24px', paddingLeft: '10px', marginTop:'10px'}} onClick={showInfoTransactions}>
                              <GrCircleInformation/>
                            </div>
                            <div
                                className={isInfoVisibleTransactions ? "isInfoVisibleTransactions visible" : "isInfoVisibleTransactions"}>
                                <p>To understand how to use this feature go to user manual in settings or click
                            </p>
                            <Link to='/products/usermanual#transactions' style={{
                                color:'#004AAD'
                            }}>Auto track Transactions</Link>
                            </div>
                    </span>
                </div>
                <div className="center-box">
                    <div className="box">
                        {/*<form>*/}
                        {/*    <script src="https://checkout.razorpay.com/v1/payment-button.js"*/}
                        {/*            data-payment_button_id="pl_Nafq5Mu0N4e9mz" async></script>*/}
                        {/*</form>*/}
                        <h2 className="title">Note: Daily Expense Auto Tracking Feature is only available on mobile apps.</h2>
                        {/*<p className="description">Download our app to keep track of your ongoing expenses automatically & be aware while spending. </p>*/}
                        {/* <p className="description">Note: Expense Tracking Feature is only available on mobile apps. </p> */}
                        <div className="download-links">
                            <a className="download-link" href="https://apps.apple.com/us/app/capitalflo/id6468636479"
                               target="_blank" rel="noopener noreferrer">
                                <img src={apple} className='appleimage' alt="Download on the App Store"/>
                            </a>
                            <a className="download-link"
                               href="https://play.google.com/store/apps/details?id=com.capitalfloandroid"
                               target="_blank"
                               rel="noopener noreferrer">
                                <img src={google} className='appleimage' alt="Get it on Google Play"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );


}

export default Transaction;

