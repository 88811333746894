import React, { useEffect, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './BlogEditor.css';
import axios from "axios";
import { useLocation, useNavigate } from 'react-router-dom';
// import H3Icon from './path/to/h3-icon.svg';
// import H4Icon from './path/to/h4-icon.svg';
const icons = Quill.import('ui/icons');
icons['header'][3] = icons.header[3] = `
<svg width="17px" height="12px" viewBox="0 0 17 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="h3" fill="currentColor">
            <path d="M9,1 L9,11 C9,11.5522847 8.55228475,12 8,12 C7.44771525,12 7,11.5522847 7,11 L7,7 L2,7 L2,11 C2,11.5522847 1.55228475,12 1,12 C0.44771525,12 0,11.5522847 0,11 L0,1 C-1.11022302e-16,0.44771525 0.44771525,0 1,0 C1.55228475,0 2,0.44771525 2,1 L2,5 L7,5 L7,1 C7,0.44771525 7.44771525,0 8,0 C8.55228475,0 9,0.44771525 9,1 Z" id="Shape" fill-rule="nonzero"></path>
            <text id="3" font-family="PingFangSC-Semibold, PingFang SC" font-size="9" font-weight="500">
                <tspan x="11.3" y="11">3</tspan>
            </text>
        </g>
    </g>
</svg>`;
icons['header'][4] = icons.header[4] = `
<svg width="17px" height="12px" viewBox="0 0 17 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="h4" fill="currentColor">
            <path d="M9,1 L9,11 C9,11.5522847 8.55228475,12 8,12 C7.44771525,12 7,11.5522847 7,11 L7,7 L2,7 L2,11 C2,11.5522847 1.55228475,12 1,12 C0.44771525,12 0,11.5522847 0,11 L0,1 C-1.11022302e-16,0.44771525 0.44771525,0 1,0 C1.55228475,0 2,0.44771525 2,1 L2,5 L7,5 L7,1 C7,0.44771525 7.44771525,0 8,0 C8.55228475,0 9,0.44771525 9,1 Z" id="Shape" fill-rule="nonzero"></path>
            <text id="4" font-family="PingFangSC-Semibold, PingFang SC" font-size="9" font-weight="500">
                <tspan x="11.3" y="11">4</tspan>
            </text>
        </g>
    </g>
</svg>`;
icons['header'][0] = icons.header[0] = `
<svg width="17px" height="12px" viewBox="0 0 17 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="h3" fill="currentColor">
            <path d="M9,1 L9,11 C9,11.5522847 8.55228475,12 8,12 C7.44771525,12 7,11.5522847 7,11 L7,7 L2,7 L2,11 C2,11.5522847 1.55228475,12 1,12 C0.44771525,12 0,11.5522847 0,11 L0,1 C-1.11022302e-16,0.44771525 0.44771525,0 1,0 C1.55228475,0 2,0.44771525 2,1 L2,5 L7,5 L7,1 C7,0.44771525 7.44771525,0 8,0 C8.55228475,0 9,0.44771525 9,1 Z" id="Shape" fill-rule="nonzero"></path>
            <text id="3" font-family="PingFangSC-Semibold, PingFang SC" font-size="9" font-weight="500">
                <tspan x="11.3" y="11">0</tspan>
            </text>
        </g>
    </g>
</svg>`;


function BlogEditor() {
    const [title, setTitle] = useState('');
    const [author, setAuthor] = useState('');
    const [excerpt, setExcerpt] = useState('');
    const [slug, setSlug] = useState('');
    const [categories, setCategories] = useState([]);
    const [featuredImage, setFeaturedImage] = useState('');
    const [content, setContent] = useState('');
    const quillRef = useRef(null);
    const [Tokens, setTokens] = useState("");
    const location = useLocation();
    const navigate = useNavigate();


    useEffect(() => {
        if (location.state && location.state.blog) {
            const { content, title, author, excerpt, slug, categories, featuredImage } = location.state.blog;
            setContent(convertContentToHTML(content));
            setTitle(title);
            setAuthor(author.name);
            setExcerpt(excerpt);
            setSlug(slug);
            setCategories(categories[0].name);
            setFeaturedImage(featuredImage.url);
        }
    }, [location.state]);

    function convertContentToHTML(content) {
        let html = '';

        content.forEach((item) => {
            switch (item.type) {
                case 'paragraph':
                    if (item.style === 'bold' && item.value.hyperlink) {
                        html += `<p><strong><a href="${item.value.hyperlink}">${item.value.text}</a></strong></p>`;
                    } else if (item.style === 'bold') {
                        html += `<p><strong>${item.value.text}</strong></p>`;
                    } else if (item.value.hyperlink) {
                        html += `<p><a href="${item.value.hyperlink}">${item.value.text}</a></p>`;
                    } else {
                        html += `<p>${item.value.text}</p>`;
                    }
                    break;
                case 'heading-one':
                    html += `<h1>${item.value.text}</h1>`;
                    break;
                case 'heading-two':
                    html += `<h2>${item.value.text}</h2>`;
                    break;
                case 'heading-three':
                    html += `<h3>${item.value.text}</h3>`;
                    break;
                case 'heading-four':
                    html += `<h4>${item.value.text}</h4>`;
                    break;
                case 'image':
                    html += `<img src="${item.value.url}" alt="Image" />`;
                    if (item.value.hyperlink) {
                        html = `<a href="${item.value.hyperlink}">${html}</a>`;
                    }
                    break;
                case 'video':
                    html += `<iframe width="560" height="315" src="${item.value.url}" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />`;
                    break;
                case 'link':
                    html += `<a href="${item.value.hyperlink}">${item.value.text}</a>`;
                    break;
                default:
                    break;
            }
        });

        return html;
    }

    const handleContentChange = (value) => {
        console.log(content)
        setContent(value);
        console.log(content)
    };

    const tokenize = async () => {
        try {
            const response = await axios.get(
                "https://api.capitalflo.co/api/users/protect-user",
                { withCredentials: true }
            );
            console.log(response);

            if (response.data.message === "Cookie not found") {
                setTokens(null);
            } else {
                console.log(response.data.jwtToken);
                setTokens(response.data.jwtToken);
            }
        } catch (err) {
            console.log(err.response.data.message);
            setTokens(null);
        }
    };
    useEffect(() => {
        tokenize();
    }, []);

    const handleInsertImage = () => {
        const range = quillRef.current.getEditor().getSelection();
        const value = prompt('Enter image URL:');
        if (value) {
            if (range) {
                quillRef.current.getEditor().insertEmbed(range.index, 'image', value, 'user');
            } else {
                const length = quillRef.current.getEditor().getLength();
                quillRef.current.getEditor().insertEmbed(length, 'image', value, 'user');
            }
        }
    };
    const handleInsertVideo = () => {
        const range = quillRef.current.getEditor().getSelection();
        const value = prompt('Enter Video URL:');
        if (value) {
            if (range) {
                quillRef.current.getEditor().insertEmbed(range.index, 'video', value, 'user');
            } else {
                const length = quillRef.current.getEditor().getLength();
                quillRef.current.getEditor().insertEmbed(length, 'video', value, 'user');
            }
        }
    };

    function extractImage(imageNode, node) {
        const imageSrc = imageNode.getAttribute("src");
        const imageLink = node.querySelector("a");
        const imageObj = createContentObject("image", { url: imageSrc });

        if (imageLink) {
            const imageLinkUrl = imageLink.getAttribute("href");
            imageObj.value.hyperlink = imageLinkUrl;
        }
        return imageObj;
    }

    function extractVideo(videoNode, node) {
        const videoSrc = videoNode.getAttribute("src");
        return createContentObject("video", { url: videoSrc });
    }

    function convertBlogDataToSend(content) {
        console.log(content);
        const convertedContent = [];

        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = content;

        const nodes = tempDiv.childNodes;
        console.log(nodes);
        for (let i = 0; i < nodes.length; i++) {
            const node = nodes[i];

            if (node.nodeName === "P") {
                const imageNode = node.querySelector("img");
                const videoNode = node.querySelector("iframe");
                if (imageNode) {
                    const imageObj = extractImage(imageNode, node);
                    convertedContent.push(imageObj);
                }
                if (videoNode) {
                    const videoObj = extractVideo(videoNode, node);
                    console.log(videoObj);
                    convertedContent.push(videoObj);
                }
                const paragraphText = node.textContent;

                const boldTextElements = node.querySelectorAll("strong");

                if (boldTextElements.length === 0) {
                    const contentObj = createContentObject("paragraph", { text: paragraphText });
                    convertedContent.push(contentObj);
                } else {
                    let currentIndex = 0;

                    boldTextElements.forEach((boldTextElement) => {
                        const boldText = boldTextElement.innerText;
                        const startIndex = paragraphText.indexOf(boldText, currentIndex);
                        const endIndex = startIndex + boldText.length;

                        if (startIndex > currentIndex) {
                            const nonBoldText = paragraphText.substring(currentIndex, startIndex);
                            const contentObj = createContentObject("paragraph", { text: nonBoldText });
                            convertedContent.push(contentObj);
                        }

                        const boldTextContentObj = createContentObject("paragraph", { text: boldText }, "bold");
                        convertedContent.push(boldTextContentObj);

                        const hyperlinkElement = boldTextElement.closest("a");
                        if (hyperlinkElement) {
                            const hyperlinkUrl = hyperlinkElement.href.replace(/^(?:https?:\/\/)?(?:www\.)?localhost:3000\//, '');
                            const hyperlinkContentObj = createContentObject("link", {
                                text: hyperlinkElement.textContent,
                                hyperlink: hyperlinkUrl
                            });

                            // Check if the hyperlink object already exists in the convertedContent array
                            const existingHyperlinkObj = convertedContent.find(obj => obj.type === 'paragraph' && obj.value.text === hyperlinkElement.textContent);
                            if (!existingHyperlinkObj) {
                                convertedContent.push(hyperlinkContentObj);
                            } else {
                                existingHyperlinkObj.value.hyperlink = hyperlinkUrl;
                            }
                        }

                        currentIndex = endIndex;
                    });
                    if (currentIndex < paragraphText.length) {
                        const remainingText = paragraphText.substring(currentIndex);
                        const contentObj = createContentObject("paragraph", { text: remainingText });
                        convertedContent.push(contentObj);
                    }
                }
            } else if (node.nodeName === "H1") {
                const headingText = node.textContent;
                const contentObj = createContentObject("heading-one", { text: headingText });
                convertedContent.push(contentObj);
            } else if (node.localName === "iframe") {
                console.log(node);
                const videoSrc = node.getAttribute("src");
                console.log(videoSrc);
                const videoObj = createContentObject("video", { url: videoSrc });
                convertedContent.push(videoObj);
            } else if (node.nodeName === "H2") {
                const headingText = node.textContent;
                const contentObj = createContentObject("heading-two", { text: headingText });
                convertedContent.push(contentObj);
            } else if (node.nodeName === "H3") {
                const headingText = node.textContent;
                const contentObj = createContentObject("heading-three", { text: headingText });
                convertedContent.push(contentObj);
            } else if (node.nodeName === "H4") {
                const headingText = node.textContent;
                const contentObj = createContentObject("heading-four", { text: headingText });
                convertedContent.push(contentObj);
            }
        }

        console.log(convertedContent);
        return convertedContent;
    }

    function createContentObject(type, value, style = "") {
        return {
            type: type,
            value: value,
            style: style
        };
    }


    const getCookie = (cookieName) => {
        const cookies = document.cookie.split('; ');
        for (const cookie of cookies) {
            const [name, value] = cookie.split('=');
            if (name === cookieName) {
                return value;
            }
        }
        return null; // Return null if the cookie is not found
    }

    const deleteBlog = async () => {
        //alert user if pressed ok then only delete else cancel
        const confirmed = window.confirm("Are you sure you want to delete the blog?");
        if (confirmed) {
            try {
                const url = `https://api.capitalflo.co/api/users/delete-blogs/${encodeURI(location.state.blog.slug)}`;
                const response = await axios.put(url,
                    {
                        adminToken: getCookie('adminToken')
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${Tokens}`,
                        },
                        withCredentials: true,
                    }
                );
                console.log(response);
                if (response.status === 200) {
                    alert("Blog deleted successfully");
                    navigate('/adm-blogs');
                } else {
                    console.log("Blog deletion failed");
                }
            } catch (e) {
                console.log(e);
                alert("Blog deletion failed");
            }
        }
    }
    const handlePostBlog = async () => {
        if (!title || !author || !excerpt || !categories || !featuredImage || !content) {
            alert("Please fill all the fields");
            return;
        }
        const categoryString = categories;
        const categorySlug = categoryString.toLowerCase().replace(/ /g, '-');

        const categoriesObject = [{
            name: categoryString,
            slug: categorySlug
        }];
        const featuredImageObject = {
            url: featuredImage
        }
        const authorObject = {
            name: author,
            bio: '',
            photo: ''
        }
        const convertedContent = convertBlogDataToSend(content);
        let response;
        try {
            if (location.state && location.state.blog) {
                const url = `https://api.capitalflo.co/api/users/blogs/${encodeURI(location.state.blog.slug)}`;
                response = await axios.put(url,
                    {
                        title,
                        author: authorObject,
                        excerpt,
                        slug,
                        categories: categoriesObject,
                        featuredImage: featuredImageObject,
                        content: convertedContent,
                        adminToken: getCookie('adminToken')
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${Tokens}`,
                        },
                        withCredentials: true,
                    }
                );
            } else {
                response = await axios.post('https://api.capitalflo.co/api/users/create-blog',
                    {
                        title,
                        author: authorObject,
                        excerpt,
                        slug,
                        categories: categoriesObject,
                        featuredImage: featuredImageObject,
                        content: convertedContent,
                        adminToken: getCookie('adminToken')
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${Tokens}`,
                        },
                        withCredentials: true,
                    }
                );
            }
            console.log(response);
            if (response.status === 200) {
                console.log("Blog posted successfully");
                // alert that blog was posted/updated
                alert(response.data.message);
                // setTitle("");
                // setAuthor("");
                // setExcerpt("");
                // setSlug("");
                // setCategories("");
                // setFeaturedImage("");
                // setContent("");
                // delete location.state.blog;
            } else {
                console.log("Blog posting failed");
                alert(response.data.message);
            }
        } catch (e) {
            console.log(e);
            alert("Blog posting failed");
        }
    };

    const modules = {
        toolbar: [
            [{ 'header': 0 }, { 'header': 1 }, { 'header': 2 }, { 'header': 3 }, { 'header': 4 }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link'],
            ['clean']
        ],
    };
    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "video",
    ];

    return (
        <div className="complete-blog">
            <h1>Blog Editor</h1>
            <div className="blog-editor-container">
                <div className='blog-form' style={{ height: '70vh' }}>
                    <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Title"
                        style={{ height: "10%" }} />
                    <input type="text" value={author} onChange={(e) => setAuthor(e.target.value)} placeholder="Author"
                        style={{ height: "10%", width: "100%" }} />
                    <textarea value={excerpt} onChange={(e) => setExcerpt(e.target.value)} placeholder="Excerpt"
                        style={{ height: "20%", width: "100%" }} />
                    <input type="text" value={slug} onChange={(e) => setSlug(e.target.value)} placeholder="Slug"
                        style={{ height: "10%", width: "100%" }} />
                    <input type="text" value={categories} onChange={(e) => setCategories(e.target.value)}
                        placeholder="Category" style={{ height: "10%", width: "100%" }} />
                    <input type="url" value={featuredImage} onChange={(e) => setFeaturedImage(e.target.value)}
                        placeholder="Featured Image" style={{ height: "10%", width: "100%" }} />
                    <button className="post-button" style={{ marginTop: "10%", borderRadius: "10px" }}
                        onClick={handlePostBlog}>
                        {location.state && location.state.blog ? "Update Blog" : "Post Blog"}
                    </button>
                    {location.state && location.state.blog ? (
                        <button className="delete-button"
                            style={{ marginTop: "10%", borderRadius: "10px", backgroundColor: "indianred" }}
                            onClick={deleteBlog}>
                            Delete Blog
                        </button>
                    ) : null}
                </div>
                <div className="editor-blog">
                    <div className='editor-insert'>
                        <div className="editor-toolbar">
                            <button className="toolbar-button"
                                style={{ paddingLeft: "2%", paddingRight: "2%", borderRadius: "10px" }}
                                onClick={handleInsertImage}>
                                Insert Image
                            </button>
                        </div>
                        <div className="editor-toolbar">
                            <button className="toolbar-button"
                                style={{ paddingLeft: "2%", paddingRight: "2%", borderRadius: "10px" }}
                                onClick={handleInsertVideo}>
                                Insert Video
                            </button>
                        </div>
                    </div>
                    <ReactQuill
                        ref={quillRef}
                        value={content}
                        onChange={handleContentChange}
                        placeholder="Write your blog here..."
                        className="editor"
                        formats={formats}
                        modules={modules}
                    />
                </div>
            </div>
        </div>
    );
}

export default BlogEditor;
