import React, {useEffect, useState} from 'react';
import './adminlogin.css';
import {useNavigate} from "react-router-dom";
import axios from "axios";

function AdminLogin() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [Tokens, setTokens] = useState("");


    const tokenize = async () => {
        try {
            const response = await axios.get(
                "https://api.capitalflo.co/api/users/protect-user",
                {withCredentials: true}
            );
            console.log(response);

            if (response.data.message === "Cookie not found") {
                setTokens(null);
            } else {
                console.log(response.data.jwtToken);
                setTokens(response.data.jwtToken);
            }
        } catch (err) {
            console.log(err.response.data.message);
            setTokens(null);
        }
    };
    useEffect(() => {
        tokenize();
    }, []);

    const axiosInstance = axios.create({
        baseURL: 'https://api.capitalflo.co', // Set your backend API base URL here
        withCredentials: true, // Include credentials (cookies) with the request
    });

    const handleLogin = async () => {
        console.log("=============")
        try {
            const response = await axiosInstance.post('/api/users/adm-login', {
                    user: username,
                    password: password,
                },
                {
                    headers: {
                        Authorization: `Bearer ${Tokens}`,
                    },
                    withCredentials: true,
                }
            );
            // Handle successful login
            if (response.status === 200) {
                const adminToken = response.data.adminToken;
                document.cookie = 'adminToken=' + adminToken + '; max-age=900000; path=/;';
                localStorage.setItem('adminToken', adminToken);
                navigate('/adm-blogs');
            } else {
                console.log("Invalid credentials");
            }
        } catch (error) {
            console.log("Error", error);
        }
    };
    return (
        <div className="admin-login-container">
            <h2>Are you sure that you are an admin?</h2>
            <form>
                <div className="form-group">
                    <label htmlFor="username">Username:</label>
                    <input
                        type="text"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password:</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
            </form>
            <button className="submit" onClick={handleLogin}>
                Login
            </button>
        </div>
    );
}

export default React.memo(AdminLogin);
