import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import mixpanel from "./utils/Mixpanel";


const pageMap = {
    '/services': 'Services',
    '/transaction': 'Transactions',
    '/plan-budget': 'Planbudget',
    '/spending': 'Insights',
    '/budget': 'Budget',
    '/bank-statement-insights': 'BankInsights',
    '/profile': 'Profile',
    '/contact': 'Contact',
    '/total-financial-position': 'TFP',
    '/bank-statements': 'Banks',
    '/reset-password': 'ResetPassword',
    '/feedback': 'FeedBack',
    '/authentication': 'OTPValidationPage',
    '/post/:slug': 'PostDetail',
    '/': 'Homepage',
    '/login': 'Login',
    '/blog/home': 'Blogs',
    '/blog': 'Blogs',
    '/products': 'Products',
    '/about': 'About',
    '/signup': 'Signup',
    '/faqs': 'Faq',
    '/privacy_policy': 'Privacy',
    '/terms-of-service': 'Terms',
    '/contactUs': 'ContactUs',
    '/set-token-and-redirect': 'SetTokenAndRedirect',
};

function ScrollToTopOnPageChange() {
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/transaction' || location.pathname === '/bank-statements') {
            return;
        }

        const pageName = pageMap[location.pathname];
        if (pageName) {
            mixpanel.track(`Web ${pageName} Page`);
        } else {
            mixpanel.track('Web Page View', {
                path: location.pathname,
            });
        }

        window.scrollTo(0, 0);
    }, [location]);

    return null;
}

export default ScrollToTopOnPageChange;
