import React, {useEffect, useState} from "react";
import "./Budget.css";
import total from "../assets/img/total.png";
import totalbudget from "../assets/img/totalbudget.png";
import Loginheader from "../Logheader";
import addsetbutton from "../assets/img/addbutton.png";
import backicon from "../assets/img/backicon.png";
import Popup from "../setbudget";
import axios from "axios";
import rent from "../assets/img/rent.png";
import loan from "../assets/img/loan.png";
import Bills from "../assets/img/bill1.png";
import Fuel from "../assets/img/gas-station.png";
import Groceries from "../assets/img/food.png";
import Food from "../assets/img/fastfood.png";
import Shopping from "../assets/img/shop.png";
import Entertainment from "../assets/img/cinema.png";
import Health from "../assets/img/health.png";
import Medicines from "../assets/img/medicine.png";
import PersonalCare from "../assets/img/personalcare.png";
import Education from "../assets/img/education.png";
import Home from "../assets/img/home.png";
import Travel from "../assets/img/travel.png";
import Investment from "../assets/img/economic.png";
import Savings from "../assets/img/savings.png";
import Business from "../assets/img/businesservice.png";
import Cash from "../assets/img/withdrawal.png";
import Transfer from "../assets/img/transfer.png";
import Tax from "../assets/img/tax.png";
import Others from "../assets/img/other.png";
import Cheque from "../assets/img/cheque.png";
import YourCategory from "../assets/img/tag.png";
import {Link} from "react-router-dom";

function Budget() {
    const [style, setStyle] = useState({});
    const [showPopup, setShowPopup] = useState(false);
    const [Tokens, setTokens] = useState("");
    const [budgetAmount, setBudgetAmount] = useState("");
    const [categoryAmounts, setCategoryAmounts] = useState({});
    const [selectedRecuring, setRecur] = useState([]);
    const [selectedTotalSpending, setTotalSpending] = useState("");
    const [selectedAvg, setAvg] = useState("");
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedTxt, setTxt] = useState([]);
    const [month, setMonth] = useState("Monthly");
    const [budgetDataUpdated, setBudgetDataUpdated] = useState(false);


    const [totalspending, setTotal] = useState("");
    const [spentCategory, setSpentCategory] = useState([]);

    let jsonData = {};

    // const handleDateSelected = (date) => {
    //   setSelectedDate(date);
    //   console.log('From Insights');
    //   console.log(date);
    // };

    const handleUpdateBudget = (newBudgetData) => {
        // Update the state with new budget data
        setBudgetAmount(newBudgetData.totalbudget);
        setCategoryAmounts(newBudgetData.categoriesBudget);
    };
    const handleSaveBudget = (newBudgetData) => {
        // Update the state with new budget data
        setBudgetAmount(newBudgetData.budgetAmount);
        setCategoryAmounts(newBudgetData.categoryAmounts);
        setBudgetDataUpdated(true); // Set the flag to trigger refresh
    };

    useEffect(() => {
        // Check if the budget data has been updated
        if (budgetDataUpdated) {
            // Trigger page refresh
            window.location.reload();
        }
    }, [budgetDataUpdated]);

    const categoryImages = [
        total,
        Groceries,
        Food,
        Shopping,
        Entertainment,
        rent,
        loan,
        Bills,
        Fuel,
        Health,
        Medicines,
        PersonalCare,
        Education,
        Home,
        Travel,
        Investment,
        Savings,
        Business,
        Cash,
        Transfer,
        Tax,
        Others,
        Cheque,
        YourCategory,
        // Add more image URLs for each category in the same order
    ];

    const categoryNames = [
        "Total",
        "Groceries",
        "Food & Drinks",
        "Shopping",
        "Entertainment",
        "Rent",
        "Bills/Utilities",
        "Loan/EMI",
        "Fuel/Transport",
        "Health",
        "Medicines",
        "Personal Care",
        "Education",
        "Home",
        "Travel",
        "Investment",
        "Savings/Goals",
        "Business",
        "Cash/Withdraw",
        "Transfer",
        "Tax",
        "Others",
        "Cheque",
        "Your Category",
    ];

    const rearrangedCategoryAmounts = {};
    categoryNames.forEach((categoryName) => {
        rearrangedCategoryAmounts[categoryName] = categoryAmounts[categoryName];
    });

    // console.log(rearrangedCategoryAmounts);

    setTimeout(() => {
        const newStyle = {
            opacity: 1,
            width: `${70}%`,
        };

        setStyle(newStyle);
    }, 200);

    const handleAddBudgetClick = () => {
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const handleBackButtonClick = () => {
        // Add logic to navigate back to the plan budget page
        // For example, you can use React Router or any other navigation method here
        // For now, let's just log a message to the console
        console.log("Going back to plan budget page");
    };

    const tokenize = async () => {
        try {
            const response = await axios.get(
                "https://api.capitalflo.co/api/users/protect-user",
                {withCredentials: true}
            );
            console.log(response);

            if (response.data.message === "Cookie not found") {
                setTokens(null);
            } else {
                console.log(response.data.jwtToken);
                setTokens(response.data.jwtToken);
            }
        } catch (err) {
            console.log(err.response.data.message);
            setTokens(null);
        }
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(
                "https://api.capitalflo.co/api/users/userbudget",
                {
                    headers: {
                        Authorization: `Bearer ${Tokens}`,
                    },
                    withCredentials: true,
                }
            );
            console.log("retreive:", response.data);
            if (response.data.message === "User not found") {
                // If user not found, set default values
                setBudgetAmount("");
                setCategoryAmounts({});
            } else {
                // If user is found, update the state with retrieved data
                // const { totalbudget, categoriesBudget } = response.data;
                setBudgetAmount(response.data.setbudgets.totalbudget || "");
                setCategoryAmounts(response.data.setbudgets.categoriesBudget || {});
            }
        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {
        tokenize();
        fetchData();
    }, []);

    // console.log(categoryAmounts)
    const filteredCategories = Object.keys(rearrangedCategoryAmounts).filter(
        (category) => rearrangedCategoryAmounts[category] !== undefined
    );
    const fetchspending = async () => {
        let date = selectedDate;
        try {
            const response = await axios.post(
                'https://api.capitalflo.co/api/users/web-spending',
                {date},
                {
                    headers: {
                        Authorization: `Bearer ${Tokens}`,
                        'Content-Type': 'application/json; charset=utf-8',

                    },
                    withCredentials: true,
                }
            );

            if (response.status !== 200) {
                throw new Error(`Request failed with status: ${response.status}`);
            }

            // Assuming the response data is already in JSON format
            const jsonData = response.data;

            console.log('################');
            console.log('FROM HERE', jsonData);
            // console.log("total:",jsonData.totalMonthlySpent)
            setTotal(Math.round(jsonData.totalMonthlySpent))
            setSpentCategory(jsonData.categoryTotalAmountArray)

            setTxt(jsonData.categoryTotalAmountArray);
            jsonData.categoryTotalAmountArray.sort((a, b) => b.amount - a.amount);
            setRecur(jsonData.merchantArray);
            setTotalSpending(Math.round(jsonData.totalMonthlySpent));
            setAvg(Math.round(jsonData.avgDaily));
        } catch (error) {
            console.error('Error getting data:', error);
        }
    };

    useEffect(() => {
        fetchspending();
        tokenize();
        const selectedMonth = selectedDate.toLocaleString('default', {month: 'long'});
        console.log(selectedMonth + "$$$$");
        setMonth(selectedMonth);
    }, [selectedDate])
    console.log("datasss:", jsonData)

    // jsonData = {
    //   totalMonthlySpent: 36140,
    //   merchantArray: [
    //     "paytmqr2810050501011wzieeooveuz",
    //     "zomatoorder1",
    //     "gpay",
    //     "devanshisingh911999",
    //     "devanshisingh911999okhdfcbank",
    //     "euronetgpay",
    //     "tiwariprashant704",
    //     "sanjaykumargupta0108",
    //     "grofers",
    //     "bharatpe09897685965yesbankltd",
    //     null,
    //     "zomato",
    //     "paytm",
    //   ],
    //   categoryTotalAmountArray: [
    //     {
    //       category: "Enter Category",
    //       amount: 27947,
    //     },
    //     {
    //       category: "Business Services",
    //       amount: 3931,
    //     },
    //     {
    //       category: "Bills/Utilities",
    //       amount: 3577,
    //     },
    //     {
    //       category: "Food & Drinks",
    //       amount: 3061,
    //     },
    //     {
    //       category: "Bills",
    //       amount: 110,
    //     },
    //     {
    //       category: "Entertainment",
    //       amount: 463,
    //     },
    //     {
    //       category: "Groceries",
    //       amount: 180,
    //     },
    //     {
    //       category: "Shopping",
    //       amount: 180,
    //     },
    //   ],
    //   avgDaily: 120476.6,
    // };
    // setTotal(jsonData.totalMonthlySpent)
    // setSpentCategory(jsonData.categoryTotalAmountArray)
    console.log("total:", jsonData.totalMonthlySpent);
    console.log("categoryTotalAmountArray:", jsonData.categoryTotalAmountArray);

    return (
        <div>
            {/* <Header user={true} /> */}
            <Loginheader/>
            <div className="middleoptions">
                <div className="back-button">
                    <Link to="https://capitalflo.co/plan-budget">
                        <img src={backicon} alt=""/>
                    </Link>
                </div>
                {/* <div className="plan-budget-link">
    <Link to="https://capitalflo.co/plan-budget" className="planbudgetText">Plan Budget</Link>
  </div> */}
                <div className="budgetoptions">
                    <img className="totalimg" src={totalbudget} alt=""/>
                    <p>Set Budget</p>
                    {/* <button onClick={handleAddBudgetClick}> */}
                    <img
                        className="addbudget"
                        src={addsetbutton}
                        alt=""
                        onClick={handleAddBudgetClick}
                    />
                    {/* </button> */}
                </div>
                {showPopup && (
                    <Popup
                        onClose={handleClosePopup}
                        onSave={handleSaveBudget} // This function updates state in Budget component
                        onUpdateBudget={handleUpdateBudget} // This function updates state in Budget component
                    />
                )}

            </div>

            <div className="mainbudget">Budget Reports</div>
            <div className="budgetdetails shifted-table-container">
                <table className="budget-table ">
                    <thead>
                    <tr>
                        <th className="category-cell">Category</th>
                        <th>Budget</th>
                        <th>Spent</th>
                        <th>Balance</th>
                    </tr>
                    </thead>
                    <tbody>
                    {categoryNames.map((category, index) => {
                        const categoryValue =
                            index === 0
                                ? budgetAmount
                                : rearrangedCategoryAmounts[category];
                        const spentAmount =
                            index === 0
                                ? totalspending
                                : spentCategory.find(
                                (item) => item.category === category
                            )?.amount || 0;

                        if (categoryValue !== "" && categoryValue !== null) {
                            const balance = categoryValue - spentAmount; // Calculate the balance
                            return (
                                <tr key={category}>
                                    <td className="category-cell">
                      <span className="category-cell-content">
                        <img
                            src={categoryImages[index]}
                            alt=""
                            className="category-image"
                        />
                          {index === 0 ? "Total" : category}
                      </span>
                                    </td>
                                    <td>{categoryValue}</td>
                                    <td>{Math.round(spentAmount)}</td>
                                    <td>{Math.round(balance)}</td>
                                </tr>
                            );
                        } else {
                            return null;
                        }
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Budget;
