import React from 'react';
import {Cell, Pie, PieChart, Tooltip} from 'recharts';

const data = [
    {Category: 'shopping', amount: 5000},
    {Category: 'Rent', amount: 2000},
    {Category: 'Transportation', amount: 1000},
    {Category: 'Health', amount: 10000},
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent, index}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

const CustomTooltip = ({active, payload}) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p style={{color: 'black'}}>{`${payload[0].payload.Category} : ${payload[0].value}`}</p>
            </div>
        );
    }

    return null;
};

const PieChartComponent = () => {
    return (
        <div className="chart-container">
            <PieChart width={400} height={400}>
                <Pie
                    data={data}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="amount"
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                    ))}
                </Pie>
                <Tooltip content={<CustomTooltip/>}/>
            </PieChart>
            <style>
                {`.recharts-wrapper {
                    overflow: hidden;
                }`}
            </style>
        </div>
    );
};

export default PieChartComponent;
