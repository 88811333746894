import React, {useEffect, useState} from 'react';
import Logheader from '../PostHeader';
import './Resetpassword.css';
import Done from '../assets/376-check-mark.mp4';
import axios from 'axios';
import {BiShow} from 'react-icons/bi';
import {useNavigate} from 'react-router-dom';


function Resetpassword() {

    const [submitted, setSubmitted] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmnewPassword, setConfirmNewPassword] = useState('');
    const [tokens, setTokens] = useState('');
    const [message, setMessage] = useState('');
    // const [showPassword, setShowPassword] = useState(false);
    const [showPasswordcurrent, setShowcurrentpassword] = useState(false);
    const [showNewPassword, setShownewpassword] = useState(false);
    const [showPasswordconfirm, setShowconfirmpassword] = useState(false);
    const [signOutAllDevices, setSignOutAllDevices] = useState(false);
    const navigate = useNavigate();


    const handleSignOutAllDevicesChange = (e) => {
        setSignOutAllDevices(e.target.checked);
    };

    function clearCookie(name) {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }

    const togglePasswordVisibility = (e) => {
        const inputElement = e.target.parentElement.querySelector('input');

        if (inputElement) {
            const fieldId = inputElement.id;

            if (fieldId === 'new-password') {
                setShowcurrentpassword(false);
                setShownewpassword(!showNewPassword);
                setShowconfirmpassword(false);
            } else if (fieldId === 'current-password') {
                setShowcurrentpassword(!showPasswordcurrent);
                setShownewpassword(false);
                setShowconfirmpassword(false);
            } else if (fieldId === 'confirm-password') {
                setShowcurrentpassword(false);
                setShownewpassword(false);
                setShowconfirmpassword(!showPasswordconfirm);
            }
        }
    };


    const currentPasswordSave = (e) => {
        setCurrentPassword(e.target.value);
        console.log(e.target.value);
    };

    const newPasswordSave = (e) => {
        setNewPassword(e.target.value);
        console.log(e.target.value);
    };

    const confirmPasswordSave = (e) => {
        console.log(e.target.value);
        setConfirmNewPassword(e.target.value)
    };

    // const handleDrop = (event) => {
    //     event.preventDefault();
    //   };

    //   const handlePaste = (event) => {
    //     event.preventDefault();
    //   };

    //   const handleCopy = (event) => {
    //     event.preventDefault();
    //   };

    function disablePaste(event) {
        event.preventDefault(); // Prevents the default paste behavior
    }

    const tokenize = async () => {
        try {
            const response = await axios.get(
                "https://api.capitalflo.co/api/users/protect-user",
                {
                    headers: {
                        Authorization: `Bearer ${tokens}`,
                    },
                    withCredentials: true,
                }
            );
            if (response.data.message === "Cookie not found") {
                setTokens(null);
            } else {
                setTokens(response.data.jwtToken);
            }
        } catch (err) {
            console.log(err.response.data.message);
            setTokens(err.response.data.error);
        }
    };

    useEffect(() => {
        tokenize();
    }, []);

    useEffect(() => {
        if (confirmnewPassword && newPassword) {
            if (confirmnewPassword !== newPassword) {
                setMessage('New password and confirm password do not match');
            } else {
                setMessage('');
            }
        }
    }, [confirmnewPassword, newPassword]);


    const changePassword = async () => {
        console.log('Requesting to reset password');
        console.log('Reset password');
        try {
            const response = await axios.post(
                "https://api.capitalflo.co/api/users/resetPassword",
                {
                    currentPassword,
                    newPassword,
                },
                {
                    headers: {
                        Authorization: `Bearer ${tokens}`,
                    },
                    withCredentials: true,
                }
            );
            console.log(response.data);
            if (response.data.message === "Password changed successfully" && !response.data.error) {
                setMessage('Password changed successfully');
                setSubmitted(true);

                if (signOutAllDevices) {
                    const response = await axios.post(
                        `https://api.capitalflo.co/api/users/logout-all`,
                        {},
                        {
                            headers: {
                                Authorization: `Bearer ${tokens}`,
                            },
                            withCredentials: true,
                        }
                    );
                    console.log(response.data);
                    clearCookie("jwt");
                    localStorage.clear();
                    navigate('/login');
                }
            }
        } catch (error) {
            console.log(error.response.data.error);
            setMessage(error.response.data.error);
            console.error(error);
        }
    };


    return (
        <div style={{
            width: '100vw',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
        }
        }>
            <Logheader/>
            {!submitted ? (
                <div className='resetpass'>
                    <div>
                        <h1>Reset Your Password</h1>
                    </div>
                    <form>
                        <div>{message ? <p className='ErrMsg'>{message}</p> : ''}</div>
                        <div className='resetFields'>
                            <label htmlFor='current-password'>Current Password</label>
                            <div>
                                <input
                                    type={showPasswordcurrent ? 'text' : 'password'}
                                    id='current-password'
                                    name='current_password'
                                    required
                                    onChange={currentPasswordSave}
                                />
                                <BiShow className='showIcon' onClick={togglePasswordVisibility}/>
                            </div>

                            <label htmlFor='new-password'>New Password</label>
                            <div>
                                <input
                                    type={showNewPassword ? 'text' : 'password'}
                                    id='new-password'
                                    name='new_password'
                                    required
                                    onChange={newPasswordSave}
                                />
                                <BiShow className='showIcon' onClick={togglePasswordVisibility}/>
                            </div>
                            <label htmlFor='confirm-password'>Confirm New Password</label>
                            <div>
                                <input
                                    type={showPasswordconfirm ? 'text' : 'password'}
                                    id='confirm-password'
                                    name='confirm_password'
                                    onPaste={disablePaste}
                                    required
                                    onChange={confirmPasswordSave}
                                />
                                <BiShow className='showIcon' onClick={togglePasswordVisibility}/>
                            </div>
                            <label htmlFor='sign-out-all-devices'>
                                <div id='checkbox'><input
                                    type='checkbox'
                                    id='sign-out-all-devices'
                                    name='sign_out_all_devices'
                                    checked={signOutAllDevices}
                                    onChange={handleSignOutAllDevicesChange}
                                /></div>
                                Sign out from all devices
                            </label>

                            <input
                                className='resetpassSubmit'
                                type='button'
                                value='Submit'
                                onClick={changePassword}
                            />
                        </div>
                    </form>
                </div>
            ) : (
                <div className='Resetpassanimation'>
                    <div className='messageofreset'>
                        <h2>{message}</h2>
                    </div>
                    <video autoPlay muted>
                        <source src={Done} type='video/mp4'/>
                        Your browser does not support the video tag.
                    </video>
                </div>
            )}
        </div>
    );

}

export default Resetpassword;
