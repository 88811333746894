import React, { useEffect, useLayoutEffect, useState } from 'react';
import './Spending.css';
import axios from "axios";
import avgspend from '../assets/img/avgspend.png';
import PieChartComponent from './PieChartComponent';
import Logheader from '../Logheader.js';
import { IoIosArrowDropdown } from "react-icons/io";
import { AiOutlineCheck } from "react-icons/ai";
import Monthselector from './Monthselector'
import calenderImg from '../assets/img/calender.png'

import bill1 from '../assets/img/bill1.png'
import loan from '../assets/img/loan.png'
import food from '../assets/img/food.png'
import invest from '../assets/img/economic.png'
import withdrawal from '../assets/img/withdrawal.png'
import tax from '../assets/img/tax.png'
import cheque from '../assets/img/cheque.png'
import fuelstation from '../assets/img/gas-station.png'
import personalcare from '../assets/img/personalcare.png'
import home from '../assets/img/home.png'
import education from '../assets/img/education.png'
import travel from '../assets/img/travel.png'
import savings from '../assets/img/savings.png'
import businesservice from '../assets/img/businesservice.png'
import transfer from '../assets/img/transfer.png'
import other from '../assets/img/other.png'
import health from '../assets/img/health.png'
import medicine from '../assets/img/medicine.png'
import tag from '../assets/img/tag.png'
import shop from '../assets/img/shop.png'
import rent from '../assets/img/rent.png'
import cinema from '../assets/img/cinema.png'
import entercat from '../assets/img/entercategory.png'


function Spending() {
    const [Tokens, setTokens] = useState('');
    const [ShowRecur, setRecurr] = useState(false);
    const [categoryTotals, setCategoryTotals] = useState([]);
    const [allcategoryTotals, setAllCategoryTotals] = useState([]);
    const [totalamt, totalMonthlySpent] = useState(0);
    const [filterTransactions, setFilteredTransactions] = useState([]);
    const [RecurringDta, setRecurringDta] = useState();
    const [savedIncome, setIncomeSave] = useState();
    const [incomeShow, ShowIncome] = useState();
    const [Focusing, ShowFocus] = useState(false);
    const [avgdaily, setAvdDaily] = useState(0);
    const [month, setMonth] = useState(new Date());
    const [Year, setYear] = useState(new Date());
    const [Month, setRealMonth] = useState(new Date());

    const dropppingMerchant = () => {
        setRecurr(!ShowRecur);
    }
    const sortedCategories = Object.entries(categoryTotals)
        .sort(([, totalA], [, totalB]) => totalB - totalA)
        .map(([category]) => category);
    const filteredCategories = sortedCategories.filter(category => category !== 'Enter Category');

    filteredCategories.unshift('Enter Category');


    const handleMonthChange = (monthvalue) => {
        setMonth(monthvalue);
        console.log(monthvalue);
    }

    const handleIncome = (e) => {
        setIncomeSave(e.target.value)
    }
    const IncomeFocus = () => {
        ShowFocus(true)
        ShowIncome(null)

    }

    const tokenize = async () => {
        try {
            const response = await axios.get(
                "https://api.capitalflo.co/api/users/protect-user",
                { withCredentials: true }
            );
            if (response.data.message === "Cookie not found") {
                setTokens(null);
            } else {
                setTokens(response.data.jwtToken);
            }
        } catch (err) {
            setTokens(null);
        }
    };

    useEffect(() => {
        tokenize();
    }, []);


    const categoryImages = {
        'Enter Category': entercat,
        'Shopping': shop,
        'Food & Drinks': food,
        'Entertainment': cinema,
        'Groceries': food,
        'Business Services': businesservice,
        'Personal Care': personalcare,
        'Medicines': medicine,
        'Health': health,
        'Education': education,
        'Investment': invest,
        'Bills/Utilities': bill1,
        'Loan/EMI': loan,
        'Fuel/Transport': fuelstation,
        'Rent': rent,
        'Home': home,
        'Transfer': transfer,
        'Savings/Goal': savings,
        'Others': other,
        'Your Category': tag,
        'Travel': travel,
        'Cash/Withdraw': withdrawal,
        'Cheque': cheque,
        'Taxes': tax,
    };


    useLayoutEffect(() => {
        const getAllTransactions = async () => {
            try {
                const response = await axios.get(
                    `https://api.capitalflo.co/api/users/all-transactions`,
                    {
                        headers: {
                            Authorization: `Bearer ${Tokens}`,
                        },
                        withCredentials: true
                    }
                );

                const currentDate = month;
                const currentYear = currentDate.getFullYear();
                const currentMonth = currentDate.getMonth() + 1;

                const TODAY = new Date();
                const dayOfMonth = TODAY.getDate();


                setYear(currentYear);
                setRealMonth(currentMonth);
                const filteredTransactions = response.data.filter(transaction => {
                    if (!transaction.date || typeof transaction.date !== 'string') {
                        return false; // Skip undefined or non-string date
                    }
                    let transactionYear, transactionMonth, transactionDay;
                    const transactionDateParts = transaction.date.split('/');
                    if (transactionDateParts.length === 3) {
                        [transactionDay, transactionMonth, transactionYear] = transactionDateParts.map(Number);
                        if (transactionYear < 100) {
                            transactionYear += 2000;
                        }
                    } else {
                        return false; // Invalid date format
                    }
                    return (
                        transactionYear === currentYear &&
                        transactionMonth === currentMonth
                    );
                });

                setFilteredTransactions(filteredTransactions);
                // totalMonthlySpent(Math.round(response.data[response.data.length - 3]?.totalMonthlySpent || 0));


                let leastDay;


                filteredTransactions.forEach(transaction => {
                    const transactionDate = new Date(transaction.date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"));
                    const transactionMonth = transactionDate.getMonth() + 1;
                    const transactionDay = transactionDate.getDate();
                    if (transactionMonth === Month && (!leastDay || transactionDay < leastDay)) {
                        leastDay = transactionDay;
                    }
                });

                if (TODAY.getMonth() == currentDate.getMonth() && TODAY.getFullYear() == currentDate.getFullYear()) {
                    const DayLeft = dayOfMonth - leastDay
                    const AvgDaily = totalamt / (DayLeft + 1);
                    setAvdDaily(AvgDaily);
                } else {
                    const AvgDaily = totalamt / 30;
                    setAvdDaily(AvgDaily);
                }

                const categoriesToFilter = ['Enter Category', 'Shopping', 'Food & Drinks', 'Entertainment', 'Groceries', 'Business Services', 'Personal Care', 'Medicines', 'Health', 'Education', 'Investment', 'Bills/Utilities', 'Loan/EMI', 'Fuel/Transport', 'Rent', 'Home', 'Transfer', 'Savings/Goal', 'Others', 'Your Category', 'Travel', 'Cash/Withdraw', 'Cheque', 'Taxes'];
                setAllCategoryTotals(categoriesToFilter);

                const newCategoryTotals = {};

                categoriesToFilter.forEach(category => {
                    const categoryTransactions = filteredTransactions.filter(transaction => transaction.category === category);
                    const totalAmount = categoryTransactions.reduce((total, transaction) => total + transaction.amount, 0);
                    newCategoryTotals[category] = totalAmount || 0;
                });

                // Update state with objects instead of arrays
                setAllCategoryTotals(newCategoryTotals);
                setCategoryTotals(newCategoryTotals);
            } catch (error) {
                console.error(error);
            }
        };

        if (Tokens) {
            getAllTransactions();
        }
    }, [Tokens, totalamt, month, Month]);

    useEffect(() => {
        const spendingRecur = async () => {
            try {
                const response = await axios.post(
                    `https://api.capitalflo.co/api/users/spending`,
                    { month: Month, year: Year },
                    {
                        headers: {
                            Authorization: `Bearer ${Tokens}`,
                        },
                        withCredentials: true
                    }
                );

                if (response.data) {
                    setRecurringDta(response.data.merchantArray);
                    totalMonthlySpent(Math.round(response.data.totalSpending || 0));
                }

            } catch (err) {
                setTokens(null);
            }
        };

        spendingRecur();
    }, [Tokens, month, Year, setRecurringDta, totalMonthlySpent, Month]);


    const saveIncome = async () => {
        try {
            const income = savedIncome; // Assuming `savedIncome` is the variable that holds the income value


            const response = await axios.post(
                'https://api.capitalflo.co/api/users/save-income',
                { income },
                {
                    headers: {
                        Authorization: `Bearer ${Tokens}`,
                    },
                    withCredentials: true,
                }
            );

            console.log('Income saved successfully:', response.data.message);
        } catch (err) {
            console.error('Error saving income:', err);
        }
    };

    useEffect(() => {
        const fastIncome = async () => {
            const response = await axios.get("https://api.capitalflo.co/api/users/save-income",
                {
                    headers: {
                        Authorization: `Bearer ${Tokens}`,
                    },
                    withCredentials: true,
                }
            )

            if (response.data.data.income) {
                ShowIncome(response.data.data.income)
            }
        }
        fastIncome();
    }, [])


    return (
        <div>
            {/* <Header user={true} /> */}
            <Logheader />
            <div className='mainheader'>
                Spending Insights
            </div>


            <div className='toplayer'>
                <div className='innerlayer'>
                    <img src={avgspend} alt="" />
                    <p>Avg Daily<br />spend-<span className='avgdaily'> {Math.round(avgdaily)}</span></p>
                </div>
                <div>
                    {/* <img src={graph} alt="" /> */}
                    <PieChartComponent />
                </div>
                <div>
                    {/* <Calendar value={value} onChange={(d) => setValue(d)} className="calender"/> */}
                    {/* <Calender/> */}
                    <img src={calenderImg} alt="" className="calenderimg"></img>
                    <Monthselector onSelectMonth={handleMonthChange} />
                </div>
            </div>


            <div className='totalspend'>Spent this month- <span className='spenttotal'> {totalamt}</span>
                {/* <div className='recur'>
                <p>Categories</p>
                <span>Recurring Merchant <IoIosArrowDropdown className='dropdownRecur'/></span>
                <div className=''></div>
            </div> */}
            </div>
            {/* <div className='minimalHeader'>
        <p>Categories</p>
      </div> */}
            {!incomeShow ? <div className='IncomeInput'>
                <div><p>Income</p></div>
                <input className='inputSalary'
                    type="number"
                    placeholder=""
                    onChange={handleIncome}
                />
                <div className='buttons' onClick={saveIncome}>
                    <AiOutlineCheck />
                </div>
            </div> :
                <div className='IncomeInput'>
                    <div><p>Income</p></div>
                    <input className='inputSalary'
                        type="number"
                        placeholder=""
                        value={Focusing ? '' : incomeShow}
                        onFocus={IncomeFocus}
                        onChange={handleIncome}
                    />
                    <div className='buttons' onClick={saveIncome}>
                        <AiOutlineCheck />
                    </div>
                </div>
            }

            <div className='recurringDrop' onClick={dropppingMerchant}>

                <h5>Recurring Merchants</h5>
                <IoIosArrowDropdown className='dropdownIcon' />
            </div>
            <div className='appearingDiv'>
                {ShowRecur ? <div>
                    <div className='recurFields'>
                        {RecurringDta ? RecurringDta.map((each, i) => (
                            <ul className='listingRecur' key={i}>
                                <li><span>{i + 1}.</span> {each}</li>
                            </ul>
                        )) : ''}
                    </div>
                </div> : ''}
            </div>
            <div className='minimalHeader'>
                <p>Categories</p>
            </div>
            <div className='insights'>
                {filteredCategories.map((category, index) => (
                    <div className='spending-category' key={index}>
                        <div className='spendcategory'>
                            <img src={categoryImages[category]} alt='' />
                        </div>
                        <div>
                            <h2>{category}</h2>
                        </div>
                        <div>
                            <h2>{Math.round(categoryTotals[category])}</h2>
                        </div>
                    </div>
                ))}
                <div className='bottomSpace'></div>
            </div>

        </div>
    );
}

export default Spending;
