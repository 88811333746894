import React, {useEffect, useLayoutEffect, useState} from 'react'
import {CgProfile} from "react-icons/cg";
import {Link, useParams} from "react-router-dom";
import {TiContacts} from "react-icons/ti";
import {MdOutlineFeedback} from "react-icons/md";
import Logheader from '../PostHeader';


function SupportAndFeedback() {
    return (
        <div style={{
                width: '100vw',
                display: 'flex',
                flexDirection: 'column',

            }}>
            <Logheader/>
            <div style={{
                marginTop:'150px',
                marginLeft:'13vw'
            }}>
                <Link to="https://capitalflo.co/contact" style={{ textDecoration: 'none', color: 'inherit' , display:'flex', flexDirection:'row', padding:29}}>
                    {/*<span className="count third">3. </span>*/}
                    <TiContacts className="icon" style={{width:'25px', height:'25px', marginTop:'2px' , marginRight:'5px'}}/>
                    <p style={{
                        fontSize:'23px'
                    }}>Contact Us</p>
                </Link>


                <Link to="https://capitalflo.co/feedback" style={{ textDecoration: 'none', color: 'inherit' , display:'flex', flexDirection:'row', padding:29}}>
                    {/*<span className="count">4. </span>*/}
                    <MdOutlineFeedback className="icon" style={{width:'25px', height:'25px', marginTop:'4px' , marginRight:'5px'}}/>
                    <p style={{
                        fontSize:'23px'
                    }}>Instant Feedback</p>
                </Link>

            </div>
        </div>

    )
}
export default SupportAndFeedback;