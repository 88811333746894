import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { CgProfile } from 'react-icons/cg';
import { TiContacts } from 'react-icons/ti';
import { FiLogOut } from 'react-icons/fi';
import { AiOutlineBank, AiOutlineDelete, AiOutlineLogout } from 'react-icons/ai';
import { MdOutlineFeedback, MdOutlineLockReset, MdHeadsetMic, MdSettingsApplications, MdShare, MdSettings } from 'react-icons/md';
import { IoSettingsOutline } from "react-icons/io5";
import { BiSupport } from "react-icons/bi";
import { BsArrowRight } from "react-icons/bs";

import { Link, useNavigate } from 'react-router-dom';
import "./Profile.css";
import Logheader from '../PostHeader';
import ConfirmationPopup from './ConfirmationPopup';
import LoadingAnimation from "../assets/Loading.svg";
import { ShareSocial } from 'react-share-social'
import { FaRegAddressBook } from "react-icons/fa";
import { isDemoAccountUsed } from '../utils/CheckIfDemo';
import DemoBlockerPopup from './DemoBlockerPopup';



function Profile(props) {
    const [Tokens, setTokens] = useState('');
    const [email, setEmail] = useState('');
    const [banks, setBanks] = useState([]);
    const navigate = useNavigate();
    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showDemoPopup, setShowDemoPopup] = useState(false);



    console.log()
    const DeleteAccount = () => {
        setShowPopup(true); // Show the confirmation popup
    };

    const handleDeleteConfirm = async () => {
        // Perform account deletion logic here
        try {
            console.log(Tokens)
            const response = await axios.delete(
                "https://api.capitalflo.co/api/users/delete-user-account",
                {
                    headers: {
                        Authorization: `Bearer ${Tokens}`,
                    },
                    withCredentials: true,
                },
            );
            console.log(response.data);
            clearCookie("jwt");
            localStorage.clear();
            navigate('/login');
        } catch (error) {
            console.error(error.response);
        }
        setShowPopup(false); // Hide the popup
    };


    const handleDeleteCancel = () => {
        setShowPopup(false); // Hide the popup
    };
    const tokenize = async () => {
        try {
            const response = await axios.get(
                "https://api.capitalflo.co/api/users/protect-user",
                {
                    headers: {
                        Authorization: `Bearer ${Tokens}`,
                    },
                    withCredentials: true
                }
            );
            if (response.data.message === "Cookie not found") {
                setTokens(null);
            } else {
                setTokens(response.data.jwtToken);
            }
        } catch (err) {
            console.log(err.response.data.message);
            setTokens(null);
        }
    };

    const setUserEmail = async () => {
        setLoading(true)
        try {
            const response = await axios.get(
                `https://api.capitalflo.co/api/users/user-profile`,
                {
                    headers: {
                        Authorization: `Bearer ${Tokens}`,
                    },
                    withCredentials: true
                }
            );
            if (response.data.message === "User not found") {
                setEmail(null);
            } else {
                console.log(response.data);
                setEmail(response.data.email || response.data.mobile);
                setBanks(response.data.banks || []);
            }
            setLoading(false)
        } catch (err) {
            setEmail(null);
            setLoading(false)
        }
    };


    function clearCookie(name) {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }


    const Logout = async (e) => {
        e.preventDefault();
        console.log(Tokens)
        try {
            const response = await axios.post(
                `https://api.capitalflo.co/api/users/logout`, {},
                {
                    headers: {
                        Authorization: `Bearer ${Tokens}`,
                    },
                    withCredentials: true
                }
            );

            console.log(response.data);
            clearCookie("jwt");
            localStorage.clear();
            navigate(`/logout/`);
            navigate('/login');
        } catch (error) {
            console.error(error.response);
        }
    };

    const LogoutAll = async (e) => {
        try {
            const response = await axios.post(
                `https://api.capitalflo.co/api/users/logout-all`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${Tokens}`,
                    },
                    withCredentials: true,
                }
            );
            console.log(response.data);
            clearCookie("jwt");
            localStorage.clear();
            navigate('/login');
        } catch (error) {
            console.error(error.response);
        }
    };


    // const DeleteAccount = async (e) => {
    //   e.preventDefault();

    //   const confirmed = window.confirm("Are you sure you want to delete your account? ");

    //   if (confirmed) {
    //     console.log(Tokens)
    //     try {
    //       const response = await axios.delete(
    //         `https://api.capitalflo.co/api/users/delete-account`,
    //         {}, // Pass any necessary data for account deletion
    //         {
    //           headers: {
    //             Authorization: `Bearer ${Tokens}`,
    //           },
    //           withCredentials: true,
    //         }
    //       );
    //       console.log(response.data);
    //       clearCookie("jwt");
    //       navigate('/login');
    //     } catch (error) {
    //       console.error(error.response);
    //     }
    //   }
    // };

    // const DeleteAccount = async (e) => {
    //   try {
    //     const response = await axios.post(
    //       `https://api.capitalflo.co/api/users/logout-all`,
    //       {},
    //       {
    //         headers: {
    //           Authorization: `Bearer ${Tokens}`,
    //         },
    //         withCredentials: true,
    //       }
    //     );
    //     console.log(response.data);
    //     clearCookie("jwt");
    //     navigate('/login');
    //   } catch (error) {
    //     console.error(error.response);
    //   }
    // };

    const handleDemoAccount = () => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
    }

    useEffect(() => {
        tokenize();
        setUserEmail();
    }, []);
    if (loading) {
        return <div className='blog_loading_animation'><img src={LoadingAnimation} alt="" /></div>
    }
    const handleShare = () => {
        return (<ShareSocial
            url="url_to_share.com"
            socialTypes={['facebook', 'twitter', 'reddit', 'linkedin']}
        />)
    }
    const closePopup = () => {
        setShowDemoPopup(false);
    }

    return (
        <div style={{
            width: '100vw',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
        }
        }><Logheader />
            {showDemoPopup && <DemoBlockerPopup closePopup={closePopup} />}
            <div className='accountLinks'>
                <ul>
                    <li  className='LiStyles'>
                        <Link to={`/profile/user-profile/${encodeURI(email)}`}>
                            {/*<span className="count">1. </span>*/}
                            <CgProfile className="icon" />
                            {/*<p>{email}</p>*/}
                            <p>User Profile</p>

                        </Link>
                        <Link to={`/profile/user-profile/${encodeURI(email)}`} >
                            <BsArrowRight   style={{ fontSize: '20px' , color:'#424242' }}/>
                        </Link>
                    </li>
                    <div className='email-profile-container'>
                        <p style={{ marginLeft: '50px', paddingTop: "2px" }}>{email}</p>
                        {isDemoAccountUsed() && (
                            <div>
                                <button className='demo-account-button' onClick={handleDemoAccount}>Demo</button>
                            </div>
                        )}
                    </div>
                    <li className='LiStyles'>
                        <Link >
                            {/*<span className="count">2. </span>*/}
                            <AiOutlineBank className="icon" />
                            <p className='Banks'>Banks:</p>
                        </Link>
                        <Link>
                            <BsArrowRight  style={{ fontSize: '20px', color:'#424242'  }}/>
                        </Link>
                    </li>
                    {banks.map((each, i) => (
                        <li key={i}><Link style={{
                            height: '40px',
                        }}><span className='specialBank'>{i + 1}. {each}</span><p></p></Link></li>
                    ))}
                    {/*<li>*/}
                    {/*    <Link to="https://capitalflo.co/contact">*/}
                    {/*        /!*<span className="count third">3. </span>*!/*/}
                    {/*        <TiContacts className="icon"/>*/}
                    {/*        <p>Contact Us</p>*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <Link to="https://capitalflo.co/feedback">*/}
                    {/*        /!*<span className="count">4. </span>*!/*/}
                    {/*        <MdOutlineFeedback className="icon"/>*/}
                    {/*        <p>Instant Feedback</p>*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                    <li className='LiStyles'>
                        <Link to="/profile/support-and-feedback">
                            <BiSupport className="icon" />
                            <p >Support and Feedback</p>
                        </Link>
                        <Link to="/profile/support-and-feedback">
                            <BsArrowRight  style={{ fontSize: '20px', color:'#424242'  }}/>
                        </Link>
                    </li>
                    <li className='LiStyles'>
                        <Link to={`/products/usermanual`}>
                            <FaRegAddressBook className="icon" style={{
                                height: '28px',
                                color: '#424242'
                            }} />
                            <p>User Manual</p>

                        </Link>
                        <Link to={`/products/usermanual`}>
                            <BsArrowRight style={{ fontSize: '20px', color:'#424242' }} />
                        </Link>
                    </li>
                    <li className='LiStyles'>
                        <Link to={`/profile/account-settings/`}>
                            <IoSettingsOutline className="icon" />
                            <p>Account Settings</p>
                        </Link>
                        <Link to={`/profile/account-settings/`}>
                            <BsArrowRight style={{ fontSize: '20px' , color:'#424242' }} />
                        </Link>
                    </li>

                    {/*<li>*/}
                    {/*    <Link to="https://capitalflo.co/reset-password">*/}
                    {/*        /!*<span className="count">5. </span>*!/*/}
                    {/*        <MdOutlineLockReset className="icon"/>*/}
                    {/*        <p>Reset Password</p>*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                    {/*<li onClick={Logout}>*/}
                    {/*    <Link>*/}
                    {/*        /!*<span className="count">6. </span>*!/*/}
                    {/*        <FiLogOut className="icon"/>*/}
                    {/*        <p>Log Out</p>*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                    {/*<li onClick={LogoutAll}>*/}
                    {/*    <Link>*/}
                    {/*        /!*<span className="count">7. </span>*!/*/}
                    {/*        <AiOutlineLogout className="icon"/>*/}
                    {/*        <p>Log Out from all devices.</p>*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <Link>*/}
                    {/*        /!*<span className="count">8. </span>*!/*/}
                    {/*        <AiOutlineDelete className="icon"/>*/}
                    {/*        <p onClick={DeleteAccount}>Delete Account</p>*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                    {/*{showPopup && (*/}
                    {/*    <ConfirmationPopup*/}
                    {/*        message="Are you sure you want to delete your account?"*/}
                    {/*        onConfirm={handleDeleteConfirm}*/}
                    {/*        onCancel={handleDeleteCancel}*/}
                    {/*    />*/}
                    {/*)}*/}

                </ul>
            </div>

            <div className='bottomSpace'></div>
        </div>
    )
}

export default Profile
