import React, { useState } from 'react';
import './DemoBlockerPopup.css';


const DemoBlockerPopup = ({ closePopup }) => {

    return (
        <div>
            <div className="demo-overlay">
                <div className="demo-overlay-content">
                    <div className='demo-popup-header'>
                        <h1>Demo Account </h1>
                    </div>
                    <p>You are currently using a Demo Account. To experience our product, <a href="/login">Sign Up Now →</a> </p>
                    <button onClick={closePopup}>Close</button>
                </div>
            </div>
        </div>
    );
}

export default DemoBlockerPopup;