import React from 'react'
import Header from '../PreHeader';
import './About.css';
import {Link} from 'react-router-dom';
import {IoLogoLinkedin} from 'react-icons/io';
import {AiFillInstagram} from 'react-icons/ai';
import {BsYoutube} from 'react-icons/bs';
import {RiTwitterXFill} from 'react-icons/ri';
import {Helmet} from "react-helmet";
import {Usedrop, UsedropReset, UsedropUpdate} from "../ReleaseContext";

function About() {

    const structuredData = {
        "@context": "http://schema.org",
        "@type": "WebPage",
        "name": "About Us",
        "description": "Reach your goals and make better financial decisions with our financial planning tools.",
        "url": "https://capitalflo.co/about",
    };
    const drop = Usedrop();
    const dropping = UsedropUpdate();
    const droppie = UsedropReset();



    return (
        <div className='aboutus-webpage'>
            <Helmet>
                <title>About Us</title>
                <meta name="description"
                      content="Reach your goals and make better financial decisions with our financial planning tools."/>
                <script type="application/ld+json">{JSON.stringify(structuredData)}</script>

            </Helmet>
            <Header/>
            <div className='blankie'></div>
            <div className="aboutContent" onClick={droppie}>
                <div className="lineWrapper">
                    <p><span></span>Your personal financial planner!
                        <br/>
                        <span></span>Capitalflo provides important financial planning tools such as a bank statement analyser, budgeting tools, financial health check up, and more…
                        <br/>
                        <span></span>View your entire financial position in one picture and receive personalized
                        insights to know your spending patterns and make better financial decisions.
                        <br/>
                        <span></span>When you think personal financial planning, you think Capitalflo.
                        <br/>
                        <span></span>Your Financial Future is worth investing in..
                        {/*<span></span>Ready to begin your financial journey? Visit our website or download the Play Store*/}
                        {/*or App Store app today and experience the future of financial planning!*/}
                    </p>

                    <div className='socialmediaIcons'>
                        <p>Follow us on:</p>
                        <div className='footersocialabout'>
                            <ul>
                                {/* <li></li> */}
                                <li><Link to="https://www.linkedin.com/company/capitalflo"><IoLogoLinkedin
                                    className='linkedin'/></Link></li>
                                <li><Link to="https://www.instagram.com/capitalflo.co/"><AiFillInstagram
                                    className='instagram'/></Link></li>
                                <li><Link to="https://twitter.com/Capitalfloclub"><RiTwitterXFill className='twitter'/></Link>
                                </li>
                                <li><Link to="https://www.youtube.com/@Capitalflo"><BsYoutube
                                    className='youtube'/></Link></li>
                            </ul>
                        </div>
                    </div>

                    <div className='enquirydiv'>
                        <span className='enquiry'>For Feedback & enquiries contact us at:   </span><span><a
                        href="mailto:support@capitalflo.co" style={{color: "#2b80f0"}}>admin@capitalflo.co</a></span>
                    </div>
                </div>
            </div>

            <div className='spacie'></div>
        </div>
    )
}

export default About
