import React, {useEffect, useState} from "react";
import axios from "axios";
import "./Planbudget.css";
import budgetIMG from './assets/img/Userhome3.png'
// import Header from '../Header'
// import categorybudget from './assets/img/categorybudget.png'
import {RiErrorWarningLine} from "react-icons/ri";
import {GrCircleInformation} from "react-icons/gr";
import Logheader from "./PostHeader.js";
import add from "./assets/img/addd.png";
import Popup from "./setbudget";
import LoadingAnimation from "./assets/Loading.svg";
import {Link} from 'react-router-dom'
import { isDemoAccountUsed } from './utils/CheckIfDemo';
import DemoBlockerPopup from './pages/DemoBlockerPopup';
import { Helmet } from "react-helmet";
function PlanBudget() {
    const [loansEmiAmount, setLoansEmiAmount] = useState("");
    const [rentAmount, setrentAmount] = useState("");
    const [electricityAmount, setelectricityAmount] = useState("");
    const [insuranceAmount, setInsuranceAmount] = useState("");
    const [internetAmount, setinternetAmount] = useState("");
    const [mobilePhoneAmount, setmobilePhoneAmount] = useState("");
    const [groceriesAmount, setGroceriesAmount] = useState("");
    const [fuelTransportAmount, setFuelTransportAmount] = useState("");
    const [foodDrinksAmount, setFoodDrinksAmount] = useState("");
    const [shoppingAmount, setShoppingAmount] = useState("");
    const [entertainmentAmount, setEntertainmentAmount] = useState("");
    const [savingsAmount, setSavingsAmount] = useState("");
    const [investmentAmount, setInvestmentAmount] = useState("");
    const [isTyping, setIsTyping] = useState(false);
    const [isinfo, setShowInfo] = useState(false);
    const [userDefinedFixedRows, setUserDefinedFixedRows] = useState([]); // Separate state for user-defined fixed expenses
    const [userDefinedVariableRows, setUserDefinedVariableRows] = useState([]); // Separate state for user-defined variable expenses
    const [totalSum, setTotalSum] = useState(0);
    const [incomeAmount, setIncomeAmount] = useState("");
    const [fixedRows, setFixedRows] = useState([]); // Separate state for fixed expenses
    const [variableRows, setVariableRows] = useState([]); // Separate state for variable expenses
    const [Tokens, setTokens] = useState("");
    const [budget, setBudget] = useState({}); // Changed from [] to {}
    const [budgetvariable, setBudgetVariable] = useState({});
    const [totalSumFixedExpenses, setTotalSumFixedExpenses] = useState(0);
    const [totalSumVariableExpenses, setTotalSumVariableExpenses] = useState(0);
    const [showPopup, setShowPopup] = useState(false);
    // const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [deletedRows, setDeletedRows] = useState([]);
    const [isDeleted, setDeletedFlag] = useState(false);
    const [deletedVariableRows, setDeletedVariableRows] = useState([]);
    const [isVariableDeleted, setVariableDeletedFlag] = useState(false);
    const [isInfoVisible, setIsInfoVisible] = useState(false);
    const [isMIInfoVisible, setIsMIInfoVisible] = useState(false);
    const [isPBInfoVisible, setisPBInfoVisible] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showDemoPopup, setShowDemoPopup] = useState(false);


    const [isSaved, setisSaved] = useState(true);

    const [tracker, setTracker] = useState([])
    const showInfo = () => {
        setIsInfoVisible(true);
    };
    const showMIInfo = () => {
        setIsMIInfoVisible(true)
    }
    const hideMIInfo = () => {
        setIsMIInfoVisible(false)
    }
    const showPBInfo = () => {
        setisPBInfoVisible(!isPBInfoVisible)
    }
    const hidePBInfo = () => {
        setisPBInfoVisible(false)
    }
    const hideInfo = () => {
        setIsInfoVisible(false);
    };

    const handleAddBudgetClick = () => {
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const handlesavePopup = () => {
        setShowPopup(false);
    };

    const tokenize = async () => {
        try {
            const response = await axios.get(
                "https://api.capitalflo.co/api/users/protect-user",
                {withCredentials: true}
            );
            console.log(response);

            if (response.data.message === "Cookie not found") {
                setTokens(null);
            } else {
                console.log(response.data.jwtToken);
                setTokens(response.data.jwtToken);
            }
        } catch (err) {
            console.log(err.response.data.message);
            setTokens(null);
        }
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                "https://api.capitalflo.co/api/users/planBudget",
                {
                    headers: {
                        Authorization: `Bearer ${Tokens}`,
                    },
                    withCredentials: true,
                }
            );
            console.log("retreive:", response.data);
            if (response.data.message === "User not found") {
                setBudgetVariable(null);
                setBudget(null);
            } else {
                console.log("retreive:", response.data);
                setLoansEmiAmount(response.data.budgetfixed.loan || "");
                setrentAmount(response.data.budgetfixed.rent || "");
                setelectricityAmount(response.data.budgetfixed.electricity || "");
                setInsuranceAmount(response.data.budgetfixed.insurance || "");
                setinternetAmount(response.data.budgetfixed.internet || "");
                setmobilePhoneAmount(response.data.budgetfixed.mobile_phone || "");

                const userDefinedFixedExpenses =
                    response.data.budgetfixed.user_defined_fixed_expenses || [];
                setFixedRows(userDefinedFixedExpenses);

                setGroceriesAmount(response.data.budgetvariable.groceries || "");
                setFuelTransportAmount(
                    response.data.budgetvariable.fuel_transport || ""
                );
                setFoodDrinksAmount(response.data.budgetvariable.food_drinks || "");
                setShoppingAmount(response.data.budgetvariable.shopping || "");
                setEntertainmentAmount(
                    response.data.budgetvariable.entertainment || ""
                );
                setSavingsAmount(response.data.budgetvariable.savings || "");
                setInvestmentAmount(response.data.budgetvariable.investment || "");
                const userDefinedVariableExpenses =
                    response.data.budgetvariable.user_defined_variable_expenses || [];
                setVariableRows(userDefinedVariableExpenses);

                setTotalSumFixedExpenses(response.data.totalExpense.fixed_total || "");
                setTotalSumVariableExpenses(
                    response.data.totalExpense.variable_total || ""
                );
                setTotalSum(response.data.totalExpense.total_fixed_variable || "");

                setIncomeAmount(response.data.income || "");
            }
            setLoading(false);
        } catch (err) {
            // setBudget(null);
            // setBudgetVariable(null);
            setLoading(false);
            console.log(err);
        }
    };

    useEffect(() => {
        console.log('current saved state',isSaved)
    }, [isSaved]);
    useEffect(() => {
        tokenize();
        fetchData();
    }, []);
    // useEffect(() => {
    //   // Calculate the total sum whenever totalSumVariableExpenses or totalSumFixedExpenses changes
    //   setTotalSum(totalSumVariableExpenses + totalSumFixedExpenses);
    // }, [totalSumVariableExpenses, totalSumFixedExpenses]);

    //   useEffect(() => {
    //     console.log("Budget Variable:", budgetvariable);
    //   }, [budgetvariable]);

    console.log("user input fixed field:", userDefinedFixedRows);

    const handleRowAdd = () => {
        const newRow = {title: "", amount: ""};
        setFixedRows((prevRows) => {
            return [newRow, ...prevRows];
        });
    };

    const handleInputChange = () => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // This function will be called whenever the user types in any input field
        setIsTyping(true); // Set isTyping to true to show the "Save" button
    };

    const handleRowAddVariable = () => {
        const newRow = {title: "", amount: ""};
        setVariableRows((prevRows) => {
            return [newRow, ...prevRows];
        });
    };

    const handleRowTitleChangeFixed = (index, e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        const updatedRows = [...fixedRows];
        updatedRows[index].title = e.target.value;
        setFixedRows(updatedRows);
    };

    const handleRowAmountChangeFixed = (index, e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        const updatedRows = [...fixedRows];
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        updatedRows[index].amount = isNaN(intValue) ? "" : intValue;
        setFixedRows(updatedRows);
    };

    const handleRowTitleChangeVariable = (index, e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        const updatedRows = [...variableRows];
        updatedRows[index].title = e.target.value;
        setVariableRows(updatedRows);
    };

    const handleRowAmountChangeVariable = (index, e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        const updatedRows = [...variableRows];
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        updatedRows[index].amount = isNaN(intValue) ? "" : intValue;
        setVariableRows(updatedRows);
    };

    const handleLoansEmiAmountChange = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setLoansEmiAmount(intValue);
        } else {
            setLoansEmiAmount("");
        }
        handleInputChange();
    };

    const handleRentAmountChange = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setrentAmount(intValue);
        } else {
            setrentAmount("");
        }
        handleInputChange();
    };

    const handleElectricityAmountChange = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setelectricityAmount(intValue);
        } else {
            setelectricityAmount("");
        }
        handleInputChange();
    };

    const handleInsuranceAmountChange = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setInsuranceAmount(intValue);
        } else {
            setInsuranceAmount("");
        }
        handleInputChange();
    };

    const handleInternetAmountChange = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setinternetAmount(intValue);
        } else {
            setinternetAmount("");
        }
        handleInputChange();
    };

    const handleMobilephoneAmountChange = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setmobilePhoneAmount(intValue);
        } else {
            setmobilePhoneAmount("");
        }
        handleInputChange();
    };

    //
    const handleGroceriesAmountChange = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setGroceriesAmount(intValue);
        } else {
            setGroceriesAmount("");
        }
        handleInputChange();
    };

    const handleFuelTransportAmountChange = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setFuelTransportAmount(intValue);
        } else {
            setFuelTransportAmount("");
        }
        handleInputChange();
    };

    const handleFoodDrinksAmountChange = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setFoodDrinksAmount(intValue);
        } else {
            setFoodDrinksAmount("");
        }
        handleInputChange();
    };

    const handleIncomeChange = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setIncomeAmount(intValue);
        } else {
            setIncomeAmount("");
        }
        handleInputChange();
    };

    const handleShoppingAmountChange = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setShoppingAmount(intValue);
        } else {
            setShoppingAmount("");
        }
        handleInputChange();
    };

    const handleEntertainmentAmountChange = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setEntertainmentAmount(intValue);
        } else {
            setEntertainmentAmount("");
        }
        handleInputChange();
    };

    const handleSavingsAmountChange = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setSavingsAmount(intValue);
        } else {
            setSavingsAmount("");
        }
        handleInputChange();
    };

    const handleInvestmentAmountChange = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setInvestmentAmount(intValue);
        } else {
            setInvestmentAmount("");
        }
        handleInputChange();
    };

    const handleRowDeleteFixed = (index) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Show a confirmation dialog before deleting the row
        const confirmDelete = window.confirm("Confirm Delete?");

        if (confirmDelete) {
            // Create a copy of the fixedRows state

            const updatedFixedRows = [...fixedRows];

            // Get the amount of the row to be deleted
            const deletedAmount = isNaN(updatedFixedRows[index].amount)
                ? 0
                : updatedFixedRows[index].amount;

            // Remove the row at the specified index
            updatedFixedRows.splice(index, 1);

            // Update the state with the new rows
            setFixedRows(updatedFixedRows);

            const updatedtotalSumFixed = isNaN(totalSumFixedExpenses)
                ? 0
                : totalSumFixedExpenses;
            setTotalSumFixedExpenses(updatedtotalSumFixed - deletedAmount);

            const updatedtotalExpense = isNaN(totalSum) ? 0 : totalSum;
            setTotalSum(updatedtotalExpense - deletedAmount);
            // handleSaveDataToDb();
            setIsTyping(false);
            setDeletedFlag(true);
            setDeletedRows(fixedRows);
            // handleSaveDataToDb();
        }
    };

    useEffect(() => {
        if (isDeleted) {
            handleSaveDataToDb();
            setDeletedFlag(false);
        }
    }, [deletedRows]);

    const handleRowDeleteVariable = (index) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Show a confirmation dialog before deleting the row
        const confirmDelete = window.confirm("Confirm Delete?");

        if (confirmDelete) {
            // Create a copy of the variableRows state
            const updatedVariableRows = [...variableRows];

            // Get the amount of the row to be deleted
            const deletedAmount = isNaN(updatedVariableRows[index].amount)
                ? 0
                : updatedVariableRows[index].amount;

            // Remove the row at the specified index
            updatedVariableRows.splice(index, 1);

            // Update the state with the new rows
            setVariableRows(updatedVariableRows);

            const updatedtotalSumVariable = isNaN(totalSumVariableExpenses)
                ? 0
                : totalSumVariableExpenses;
            setTotalSumVariableExpenses(updatedtotalSumVariable - deletedAmount);

            const updatedtotalExpense = isNaN(totalSum) ? 0 : totalSum;
            setTotalSum(updatedtotalExpense - deletedAmount);
            setIsTyping(false);
            setVariableDeletedFlag(true);
            setDeletedVariableRows(fixedRows);
        }
    };

    useEffect(() => {
        if (isVariableDeleted) {
            handleSaveDataToDb();
            setVariableDeletedFlag(false);
        }
    }, [deletedVariableRows]);

    console.log(budgetvariable);

    const formatNumber = (number) => {
        const parts = number.toString().split(".");
        if (parts.length === 2) {
            // If there's a decimal part, limit it to two decimal places
            return parts[0] + "." + parts[1].slice(0, 2);
        } else {
            // If there's no decimal part, just return the original number
            return number.toString();
        }
    };
    const abbreviateNumber = (number) => {
        if (isNaN(number) || !isFinite(number) || number === 0) {
            return '0'; // Handle NaN or Infinity case, return a default value
        }
        const sign = Math.sign(number);
        const absoluteNumber = Math.abs(number);

        const digits = absoluteNumber.toString().length;

        if (digits > 3 && digits <= 5) {
            // Abbreviate thousands
            const result = (sign * absoluteNumber / 1000);
            return result % 1 !== 0 ? formatNumber(result) + 'K' : result.toFixed(0) + 'K';
        } else if (digits >= 6 && digits <= 7) {
            // Abbreviate lakhs
            const result = (sign * absoluteNumber / 100000);
            return result % 1 !== 0 ? formatNumber(result) + 'L' : result.toFixed(0) + 'L';
        } else if (digits >= 8) {
            // Abbreviate crores
            const result = (sign * absoluteNumber / 10000000);
            return result % 1 !== 0 ? formatNumber(result) + 'Cr' : result.toFixed(0) + 'Cr';
        } else {
            // Default to the original number for smaller values
            return (sign * absoluteNumber).toFixed(0).trim();
        }
    };
    // Function to handle adding a user-defined row for fixed expenses
    const handleUserDefinedRowAddFixed = () => {
        const newRow = {title: "", amount: ""};
        setUserDefinedFixedRows((prevRows) => [...prevRows, newRow]);
    };

    // Function to handle adding a user-defined row for variable expenses
    const handleUserDefinedRowAddVariable = () => {
        const newRow = {title: "", amount: ""};
        setUserDefinedVariableRows((prevRows) => [...prevRows, newRow]);
    };

    // Function to handle title change for user-defined fixed row
    const handleUserDefinedRowTitleChangeFixed = (index, e) => {
        const updatedRows = [...userDefinedFixedRows];
        updatedRows[index].title = e.target.value;
        setUserDefinedFixedRows(updatedRows);
    };

    // Function to handle amount change for user-defined fixed row
    const handleUserDefinedRowAmountChangeFixed = (index, e) => {
        const updatedRows = [...userDefinedFixedRows];
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        updatedRows[index].amount = isNaN(intValue) ? "" : intValue;
        setUserDefinedFixedRows(updatedRows);
    };

    // Function to handle title change for user-defined variable row
    const handleUserDefinedRowTitleChangeVariable = (index, e) => {
        const updatedRows = [...userDefinedVariableRows];
        updatedRows[index].title = e.target.value;
        setUserDefinedVariableRows(updatedRows);
    };

    // Function to handle amount change for user-defined variable row
    const handleUserDefinedRowAmountChangeVariable = (index, e) => {
        const updatedRows = [...userDefinedVariableRows];
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        updatedRows[index].amount = isNaN(intValue) ? "" : intValue;
        setUserDefinedVariableRows(updatedRows);
    };

    useEffect(() => {
        const closeIButton = (e) => {
            if(e.target.namespaceURI!=='http://www.w3.org/2000/svg'){
                setisPBInfoVisible(false)
            }
        }
        document.addEventListener('click', closeIButton)
        return ( )=>document.body.removeEventListener('click', closeIButton)

    }, []);
    const  handleSaveDataToDb = async () => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        const allFixedExpenses = [
            {title: "Insurance", amount: insuranceAmount},
            {title: "Loans/EMI", amount: loansEmiAmount},
            {title: "Rent", amount: rentAmount},
            {title: "Electricity", amount: electricityAmount},
            // {title: "Water", amount: waterAmount},
            {title: "Internet", amount: internetAmount},
            {title: "Mobile phone", amount: mobilePhoneAmount},
            ...fixedRows, // Add user-defined fixed expenses here
        ];

        const variableExpenses = [
            {title: "Groceries", amount: groceriesAmount},
            {title: "Fuel/Transport", amount: fuelTransportAmount},
            {title: "Food and drinks", amount: foodDrinksAmount},
            {title: "Shopping", amount: shoppingAmount},
            {title: "Entertainment", amount: entertainmentAmount},
            {title: "Savings", amount: savingsAmount},
            {title: "Investments", amount: investmentAmount},
            ...variableRows,
        ];

        const totalSumFixed = allFixedExpenses.reduce(
            (acc, row) =>
                acc + (isNaN(parseInt(row.amount)) ? 0 : parseInt(row.amount)),
            0
        );

        // Calculate total sum of variable expenses
        const totalSumVariable = variableExpenses.reduce(
            (acc, value) =>
                acc + (isNaN(parseInt(value.amount)) ? 0 : parseInt(value.amount)),
            0
        );

        setTotalSumFixedExpenses(totalSumFixed);
        setTotalSumVariableExpenses(totalSumVariable);
        const totalSum = totalSumFixed + totalSumVariable;

        // Update the total sum state with the calculated total sum
        setTotalSum(totalSum);

        const total = {
            fixedTotal: totalSumFixed,
            variableTotal: totalSumVariable,
            fixedVariableTotal: totalSum,
        };

        // Combine all data into one object
        const data = {
            insurance: insuranceAmount,
            loan: loansEmiAmount,
            rent: rentAmount,
            electricity: electricityAmount,
            internet: internetAmount,
            mobilePhone: mobilePhoneAmount,
            // Save user-defined fixed expenses to the database
            userDefinedFixedExpenses: fixedRows,
        };

        console.log("userDefinedFixedExpenses", fixedRows);

        // const totalData = {
        //   totalexpense: totalSum,
        // };
        const inputIncome = {
            income: incomeAmount, // Make sure `incomeAmount` is defined and holds the correct value for income.
        };

        const datav = {
            groceries: groceriesAmount,
            fuelTransport: fuelTransportAmount,
            foodDrinks: foodDrinksAmount,
            shopping: shoppingAmount,
            entertainment: entertainmentAmount,
            savings: savingsAmount,
            investment: investmentAmount,
            userDefinedVariableExpenses: variableRows,
        };
        // console.log(combinedData);

        await axios
            .put(
                "https://api.capitalflo.co/api/users/planBudget-fixed-data",
                {data},
                {
                    headers: {
                        Authorization: `Bearer ${Tokens}`,
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            )
            .then((response) => {
                console.log("Data successfully sent to the server:", response.data);
            })
            .catch((error) => {
                console.error("Error sending data to the server:", error);
            });

        await axios
            .put(
                "https://api.capitalflo.co/api/users/planBudget-variable-data",
                {datav},
                {
                    headers: {
                        Authorization: `Bearer ${Tokens}`,
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            )
            .then((response) => {
                // Handle the response from the server if needed
                console.log("Data successfully sent to the server:", response.data);
            })
            .catch((error) => {
                // Handle errors if any
                console.error("Error sending data to the server:", error);
            });

        await axios
            .put(
                "https://api.capitalflo.co/api/users/planBudget-income-data",
                {inputIncome},
                {
                    headers: {
                        Authorization: `Bearer ${Tokens}`,
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            )
            .then((response) => {
                console.log(
                    "Income Data successfully sent to the server:",
                    response.data
                );
            })
            .catch((error) => {
                console.error("Error sending data to the server:", error);
            });

        await axios
            .put(
                "https://api.capitalflo.co/api/users/planBudget-totalexpense-data",
                {total},
                {
                    headers: {
                        Authorization: `Bearer ${Tokens}`,
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            )
            .then((response) => {
                console.log(
                    "Total Expense successfully sent to the server:",
                    response.data
                );
            })
            .catch((error) => {
                console.error("Error sending data to the server:", error);
            });
        setIsTyping(false);
    };

    if (loading) {
        return <div className='blog_loading_animation'><img src={LoadingAnimation} alt=""/></div>
    }
    const closePopup = () => {
        setShowDemoPopup(false);
    }

    return (
        <div style={{
            width: '100vw',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
        }
        }>             {/* <Header user={true} /> */}
            <Logheader/>
            <Helmet>
                <title>Plan Budget</title>
                <meta name="description"
                    content="Plan your monthly budget" />
            </Helmet>
            {showDemoPopup && <DemoBlockerPopup closePopup={closePopup} />}
            <div>
                <div className="mainplanheading">
                    <img src={budgetIMG} style={{
                        width: '50px',
                        height: '50px'
                    }} alt=""/>
                    <h2>Plan Monthly Budget
                        <span className="infobtn" onClick={showPBInfo} >
                  <span style={{width: '24px'}}> </span><GrCircleInformation style={{fontSize: '24px'}}/>
                </span>
                        <div className={isPBInfoVisible ? "info-PB visible" : "info-PB"}>
                            <p>To understand how to use this feature go to user manual in settings.
                            </p>
                            <Link to='/products/usermanual#budget' style={{
                                color:'#004AAD',
                                paddingLeft:'5px',
                                fontSize:'18px'
                            }}>Budget Planner</Link>
                        </div>
                    </h2>
                    {/*<div>*/}
                    {/*    <Link to="https://capitalflo.co/budget">*/}
                    {/*        <img src={reportbudget} alt=""/>*/}
                    {/*    </Link>*/}
                    {/*    <p className="reportheading">Reports</p>*/}
                    {/*</div>*/}
                </div>
                {/*<div className="middleoptionss">*/}
                {/*    <div className="budgetoptionss">*/}
                {/*        <img className="totalimg" src={totalbudget} alt=""/>*/}
                {/*        <p>Set Budget</p>*/}
                {/*        /!* <button onClick={handleAddBudgetClick}> *!/*/}
                {/*        <img*/}
                {/*            className="addbudget"*/}
                {/*            src={addsetbutton}*/}
                {/*            alt=""*/}
                {/*            onClick={handleAddBudgetClick}*/}
                {/*        />*/}
                {/*        /!* </button> *!/*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/* Conditionally render the popup */}
                {/* <div className={`planbudget ${showPopup ? "overlay" : ""}`}></div> */}
                {showPopup &&
                    <Popup onClose={handleClosePopup} onSave={handlesavePopup} onUpdateBudget={handlesavePopup}/>}
            </div>

            <div className="outerreference">
                {/*<div className="reference">*/}
                {/*    <RiErrorWarningLine/>*/}
                {/*    <p>Writing your expenses reduces expenditure about 10% on average!</p>*/}
                {/*</div>*/}
                <div className="income-table">
                    {/* <div className={isInfoVisible ? "info-popup visible" : "info-popup"}>
               <p>Monthly Salary</p>
              </div> */}
                    <table>
                        <tr>
                            <th className="IncomeTable">Income
                                <span className="infobtn" onMouseOver={showInfo} onMouseOut={hideInfo}>
                  <span style={{width: '20px'}}> </span><GrCircleInformation style={{fontSize: '20px'}}/>
                </span>
                                <div className={isInfoVisible ? "info-popup visible" : "info-popup"}>
                                    <p>Monthly Income Post Tax</p>
                                </div>
                            </th>

                            <td>
                                <input
                                    type="text"
                                    className="integer-input"
                                    placeholder="Enter amount"
                                    value={isSaved===false ?incomeAmount:abbreviateNumber(incomeAmount)}
                                    onChange={handleIncomeChange}
                                    onFocus={() => {
                                        console.log('changing is saved value to false')
                                        setisSaved(false);
                                    }}
                                    onBlur={() => {
                                        console.log('changing is saved value to true')
                                        setisSaved(true);
                                    }}
                                />
                            </td>
                        </tr>
                        {/* <InfoModal isOpen={isInfoVisible} onClose={hideInfo} /> */}
                    </table>
                </div>
                <div className="wrap">
                    <div className="table-container">
                        <table>
                            <tr>
                                <th className="FirstRow">
                                    Fixed Expenses{" "}
                                    <img
                                        src={add}
                                        className="mainFirstRow"
                                        alt=""
                                        onClick={handleRowAdd}
                                    />
                                </th>
                                <th>Amount</th>
                            </tr>

                            {fixedRows.map(
                                (
                                    row,
                                    index // Add slice() and reverse() here
                                ) => (
                                    <tr key={index}>
                                        <td style={{position: "relative"}}>
                      <span
                          onClick={() => handleRowDeleteFixed(index)}
                          className="delete-cross"
                      >
                        &#10006; {/* Unicode character for cross sign */}
                      </span>
                                            <input
                                                type="text"
                                                className="integer-input"
                                                placeholder="Expense"
                                                value={row.title}
                                                onChange={(e) => {
                                                    handleInputChange();
                                                    handleRowTitleChangeFixed(index, e);
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                className="integer-input"
                                                placeholder="Enter amount"
                                                value={isSaved===false ?row.amount:abbreviateNumber(row.amount)}
                                                onChange={(e) => {
                                                    handleRowAmountChangeFixed(index, e);
                                                    handleInputChange();
                                                }}
                                                onFocus={() => {
                                                    console.log('changing is saved value to false')
                                                    setisSaved(false);
                                                }}
                                                onBlur={() => {
                                                    console.log('changing is saved value to true')
                                                    setisSaved(true);
                                                }}

                                            />
                                        </td>
                                    </tr>
                                )
                            )}
                            <tr>
                                <td>Insurance</td>
                                <td>
                                    <input
                                        type="text"
                                        className="integer-input"
                                        placeholder="Enter amount"
                                        value={isSaved===false ?insuranceAmount:abbreviateNumber(insuranceAmount)}
                                        onChange={handleInsuranceAmountChange}
                                        onFocus={() => {
                                            console.log('changing is saved value to false')
                                            setisSaved(false);
                                        }}
                                        onBlur={() => {
                                            console.log('changing is saved value to true')
                                            setisSaved(true);
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Loans/EMI</td>
                                <td>
                                    <input
                                        type="text"
                                        className="integer-input"
                                        placeholder="Enter amount"
                                        value={isSaved===false ?loansEmiAmount:abbreviateNumber(loansEmiAmount)}
                                        onChange={handleLoansEmiAmountChange}
                                        onFocus={() => {
                                            console.log('changing is saved value to false')
                                            setisSaved(false);
                                        }}
                                        onBlur={() => {
                                            console.log('changing is saved value to true')
                                            setisSaved(true);
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Rent</td>
                                <td>
                                    <input
                                        type="text"
                                        className="integer-input"
                                        placeholder="Enter amount"
                                        value={isSaved===false ?rentAmount:abbreviateNumber(rentAmount)}
                                        onChange={handleRentAmountChange}
                                        onFocus={() => {
                                            console.log('changing is saved value to false')
                                            setisSaved(false);
                                        }}
                                        onBlur={() => {
                                            console.log('changing is saved value to true')
                                            setisSaved(true);
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Electricity</td>
                                <td>
                                    <input
                                        type="text"
                                        className="integer-input"
                                        placeholder="Enter amount"
                                        value={isSaved===false ?electricityAmount:abbreviateNumber(electricityAmount)}
                                        onChange={handleElectricityAmountChange}
                                        onFocus={() => {
                                            console.log('changing is saved value to false')
                                            setisSaved(false);
                                        }}
                                        onBlur={() => {
                                            console.log('changing is saved value to true')
                                            setisSaved(true);
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Internet</td>
                                <td>
                                    <input
                                        type="text"
                                        className="integer-input"
                                        placeholder="Enter amount"
                                        value={isSaved===false ?internetAmount:abbreviateNumber(internetAmount)}
                                        onChange={handleInternetAmountChange}
                                        onFocus={() => {
                                            console.log('changing is saved value to false')
                                            setisSaved(false);
                                        }}
                                        onBlur={() => {
                                            console.log('changing is saved value to true')
                                            setisSaved(true);
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Mobile phone</td>
                                <td>
                                    <input
                                        type="text"
                                        className="integer-input"
                                        placeholder="Enter amount"
                                        onChange={handleMobilephoneAmountChange}
                                        value={isSaved===false ?mobilePhoneAmount:abbreviateNumber(mobilePhoneAmount)}
                                        onFocus={() => {
                                            console.log('changing is saved value to false')
                                            setisSaved(false);
                                        }}
                                        onBlur={() => {
                                            console.log('changing is saved value to true')
                                            setisSaved(true);
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                            </tr>
                            <tr></tr>
                            <tr>
                                <td className="sumTotal">Total</td>
                                <td>{isSaved===false?totalSumFixedExpenses:abbreviateNumber(totalSumFixedExpenses)}</td>
                            </tr>
                        </table>
                    </div>

                    <div className="table-container-massive ">
                        <table>
                            <tr>
                                <th className="FirstRow">
                                    Variable Expenses
                                    <span>
                    <img
                        src={add}
                        className="mainFirstRow"
                        alt=""
                        onClick={handleRowAddVariable}
                    />
                  </span>
                                </th>
                                <th>Amount</th>
                            </tr>

                            {variableRows.map(
                                (
                                    row,
                                    index // Add slice() and reverse() here
                                ) => (
                                    <tr key={index}>
                                        <td style={{position: "relative"}}>
                                            {/* Display the cross sign (X) inside the title input field */}
                                            <span
                                                onClick={() => handleRowDeleteVariable(index)}
                                                className="delete-cross"
                                            >
                        &#10006; {/* Unicode character for cross sign */}
                      </span>
                                            <input
                                                type="text"
                                                className="integer-input"
                                                placeholder="Expense"
                                                value={row.title}
                                                onChange={(e) => {
                                                    handleRowTitleChangeVariable(index, e)
                                                    handleInputChange();
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                className="integer-input"
                                                placeholder="Enter amount"
                                                onChange={(e) => {
                                                    handleRowAmountChangeVariable(index, e);
                                                    handleInputChange();
                                                }}
                                                value={isSaved===false ?row.amount:abbreviateNumber(row.amount)}
                                        onFocus={() => {
                                            console.log('changing is saved value to false')
                                            setisSaved(false);
                                        }}
                                        onBlur={() => {
                                            console.log('changing is saved value to true')
                                            setisSaved(true);
                                        }}

                                            />
                                        </td>
                                    </tr>
                                )
                            )}
                            <tr>
                                <td>Groceries</td>
                                <td>
                                    <input
                                        type="text"
                                        className="integer-input"
                                        placeholder="Enter amount"
                                        value={isSaved===false ?groceriesAmount:abbreviateNumber(groceriesAmount)}
                                        onFocus={() => {
                                            console.log('changing is saved value to false')
                                            setisSaved(false);
                                        }}
                                        onBlur={() => {
                                            console.log('changing is saved value to true')
                                            setisSaved(true);
                                        }}
                                        onChange={handleGroceriesAmountChange}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Fuel/Transport</td>
                                <td>
                                    <input
                                        type="text"
                                        className="integer-input"
                                        placeholder="Enter amount"
                                        value={isSaved===false ?fuelTransportAmount:abbreviateNumber(fuelTransportAmount)}
                                        onFocus={() => {
                                            console.log('changing is saved value to false')
                                            setisSaved(false);
                                        }}
                                        onBlur={() => {
                                            console.log('changing is saved value to true')
                                            setisSaved(true);
                                        }}
                                        onChange={handleFuelTransportAmountChange}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Food and drinks</td>
                                <td>
                                    <input
                                        type="text"
                                        className="integer-input"
                                        placeholder="Enter amount"
                                        value={isSaved===false ?foodDrinksAmount:abbreviateNumber(foodDrinksAmount)}
                                        onFocus={() => {
                                            console.log('changing is saved value to false')
                                            setisSaved(false);
                                        }}
                                        onBlur={() => {
                                            console.log('changing is saved value to true')
                                            setisSaved(true);
                                        }}
                                        onChange={handleFoodDrinksAmountChange}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Shopping</td>
                                <td>
                                    <input
                                        type="text"
                                        className="integer-input"
                                        placeholder="Enter amount"
                                        value={isSaved===false ?shoppingAmount:abbreviateNumber(shoppingAmount)}
                                        onFocus={() => {
                                            console.log('changing is saved value to false')
                                            setisSaved(false);
                                        }}
                                        onBlur={() => {
                                            console.log('changing is saved value to true')
                                            setisSaved(true);
                                        }}
                                        onChange={handleShoppingAmountChange}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Entertainment</td>
                                <td>
                                    <input
                                        type="text"
                                        className="integer-input"
                                        placeholder="Enter amount"
                                        value={isSaved===false ?entertainmentAmount:abbreviateNumber(entertainmentAmount)}
                                        onFocus={() => {
                                            console.log('changing is saved value to false')
                                            setisSaved(false);
                                        }}
                                        onBlur={() => {
                                            console.log('changing is saved value to true')
                                            setisSaved(true);
                                        }}
                                        onChange={handleEntertainmentAmountChange}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Savings</td>
                                <td>
                                    <input
                                        type="text"
                                        className="integer-input"
                                        placeholder="Enter amount"
                                        value={isSaved===false ?savingsAmount:abbreviateNumber(savingsAmount)}
                                        onFocus={() => {
                                            console.log('changing is saved value to false')
                                            setisSaved(false);
                                        }}
                                        onBlur={() => {
                                            console.log('changing is saved value to true')
                                            setisSaved(true);
                                        }}
                                        onChange={handleSavingsAmountChange}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Investments</td>
                                <td>
                                    <input
                                        type="text"
                                        className="integer-input"
                                        placeholder="Enter amount"
                                        value={isSaved===false ?investmentAmount:abbreviateNumber(investmentAmount)}
                                        onFocus={() => {
                                            console.log('changing is saved value to false')
                                            setisSaved(false);
                                        }}
                                        onBlur={() => {
                                            console.log('changing is saved value to true')
                                            setisSaved(true);
                                        }}
                                        onChange={handleInvestmentAmountChange}
                                    />
                                </td>
                            </tr>
                            <tr></tr>
                            <tr>
                                <td className="sumTotal">Total</td>
                                <td>{isSaved===false?totalSumVariableExpenses:abbreviateNumber(totalSumVariableExpenses)}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div className="Total-Expense">
                    <table>
                        <tr>
                            <th className="Total Expense">Total Expenses</th>

                            <td>{isSaved===false?totalSum:abbreviateNumber(totalSum)}</td>
                        </tr>
                    </table>
                </div>
                <div className="MI_ME">
                    <table>
                        <tr>
                            <th className="Total Expense">Monthly Income - Monthly Expenses
                                <span className="infobtn" onMouseOver={showMIInfo} onMouseOut={hideMIInfo}>
                  <span style={{width: '20px'}}> </span><GrCircleInformation style={{fontSize: '20px'}}/>
                </span>
                                <div className={isMIInfoVisible ? "info-MI visible" : "info-MI"}>
                                    <p>Surplus (+) / Deficit (-)</p>
                                </div>
                            </th>

                            <td>{isSaved===false?incomeAmount - totalSum:abbreviateNumber(incomeAmount - totalSum)}</td>
                            {console.log("total expenses",totalSumVariableExpenses)}
                        </tr>
                    </table>
                </div>
                {isTyping && (
                    // <div className="sticky-container">
                    //  <div className="savebackground">
                    // <div className="saveButton">
                    <div className="sticky-container">
                        <div className="savebackgroundPlan">
                            <div className="savedButton" onClick={handleSaveDataToDb}>Save Data</div>
                        </div>
                    </div>
                )}
                {/* {isTyping && (
          <div className="saveButtonBelow">
            <button onClick={handleSaveDataToDb}>Save Data </button>
          </div>
        )} */}
                <div className='bottomSpace'></div>

            </div>
        </div>
    );
}

export default PlanBudget;
