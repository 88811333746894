import bill1 from '../assets/img/bill1.png'
import loan from '../assets/img/loan.png'
import food from '../assets/img/food.png'
import invest from '../assets/img/economic.png'
import withdrawal from '../assets/img/withdrawal.png'
import tax from '../assets/img/tax.png'
import cheque from '../assets/img/cheque.png'
import fuelstation from '../assets/img/gas-station.png'
import fastfood from '../assets/img/fastfood.png'
import personalcare from '../assets/img/personalcare.png'
import home from '../assets/img/home.png'
import education from '../assets/img/education.png'
import travel from '../assets/img/travel.png'
import savings from '../assets/img/savings.png'
import businesservice from '../assets/img/businesservice.png'
import transfer from '../assets/img/transfer.png'
import other from '../assets/img/other.png'
import health from '../assets/img/health.png'
import medicine from '../assets/img/medicine.png'
import tag from '../assets/img/tag.png'
import shop from '../assets/img/shop.png'
import rent from '../assets/img/rent.png'
import cinema from '../assets/img/cinema.png'

const allCategories =
    [{
        id: 1,
        image: rent,
        category: "Rent"
    },
        {
            id: 2,
            image: bill1,
            category: "Bills/Utilities"
        },
        {
            id: 3,
            image: loan,
            category: "Loan/EMI"
        },
        {
            id: 4,
            image: fuelstation,
            category: "Fuel/Transport"
        },
        {
            id: 5,
            image: food,
            category: "Groceries"
        },
        {
            id: 6,
            image: fastfood,
            category: "Food & Drinks"
        },
        {
            id: 7,
            image: shop,
            category: "Shopping"
        },
        {
            id: 8,
            image: cinema,
            category: "Entertainment"
        },
        {
            id: 9,
            image: health,
            category: "Health"
        },
        {
            id: 10,
            image: medicine,
            category: "Medicines"
        },
        {
            id: 11,
            image: personalcare,
            category: "Personal Care"
        },
        {
            id: 12,
            image: education,
            category: "Education"
        },
        {
            id: 13,
            image: home,
            category: "Home"
        },
        {
            id: 14,
            image: travel,
            category: "Travel"
        },
        {
            id: 15,
            image: invest,
            category: "Investment"
        },
        {
            id: 16,
            image: savings,
            category: "Savings/Goals"
        },
        {
            id: 17,
            image: businesservice,
            category: "Business Services"
        },
        {
            id: 18,
            image: withdrawal,
            category: "Cash/Withdraw"
        },
        {
            id: 19,
            image: transfer,
            category: "Transfer"
        },
        {
            id: 20,
            image: tax,
            category: "Taxes"
        },
        {
            id: 21,
            image: other,
            category: "Others"
        },
        {
            id: 22,
            image: cheque,
            category: "Cheque"
        },
        {
            id: 23,
            image: tag,
            category: "Your Category"
        },
    ];

export default allCategories;
