import React, {useEffect, useState} from 'react';
import './Edittransaction.css';
import {AiOutlineDelete} from 'react-icons/ai';
import allCategories from '../assets/icons';
import {GrAdd, GrSubtract} from 'react-icons/gr';
import axios from 'axios';
import Editcard from './Editcard';
import {MdOutlineKeyboardBackspace} from 'react-icons/md';
import { isDemoAccountUsed } from '../utils/CheckIfDemo';
import DemoBlockerPopup from './DemoBlockerPopup';

import {Logtail} from '@logtail/browser';

const logtail = new Logtail("KUux1aBAeVonXhnjXTZrUmpT");


function Edittransaction(props) {
    const [slidedoenimg, setslidedoenimg] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [Merchant, setMerchant] = useState('');
    const [amount, setAmount] = useState('');
    const [date, setDate] = useState('');
    const [notes, setNotes] = useState('');
    const [chooseCategory, switchChooseCategory] = useState(false);
    const [updateAllCategory, setUpdateAllCategory] = useState(false);
    const [renderMessage, setRenderMessage] = useState(false);
    const [Tokens, setTokens] = useState("");
    const [showDemoPopup, setShowDemoPopup] = useState(false);



    const [isFocused, setIsFocused] = useState(false); // State to track focus status
    const {expense} = props;
    const {Currentstatement} = props
    console.log("Current Statement",Currentstatement)


    const tokenize = async () => {
        try {
            const response = await axios.get(
                "https://api.capitalflo.co/api/users/protect-user",
                {withCredentials: true}
            );
            if (response.data.message === "Cookie not found") {
                setTokens(null);
            } else {
                setTokens(response.data.jwtToken);
                console.log(response.data.jwtToken);
            }
        } catch (err) {
            console.log(err.response.data.message);
            setTokens(null);
        }
    };

    useEffect(() => {
        tokenize();
    }, []);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const [customcategory, setCustomCategory] = useState([]);

    const handleCategoryChange = (event) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        setMerchant(event.target.value);
    };

    const handleAmountChange = (event) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        setAmount(event.target.value);
    };

    const handleDateChange = (event) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        setDate(event.target.value);
    };

    // const handleNoteChange = (event) => {
    //     const trimmedValue = event.target.value.trim();
    //     setNotes(trimmedValue);
    // };
    const handleNoteChange = (event) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        const inputValue = event.target.value;
        const words = inputValue.split(/\s+/); // Split input by whitespace

        // Use the first two words or less
        const twoOrLessWords = words.slice(0, 2).join(' ');
        setNotes(twoOrLessWords);
    };


    const handleSubmit = (event) => {
        event.preventDefault();
    };

    const slidedown = (e) => {
        setslidedoenimg(!slidedoenimg);
        switchChooseCategory(!chooseCategory);
    }
    const setChooseCategory = (e) => {
        switchChooseCategory(!chooseCategory);
    }
    const closeeditwindow = () => {
        props.onConfirm();
    }
    const imgselect = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        const selectedCategory = allCategories[e.target.dataset.index];
        setSelectedImage(selectedCategory.icon ? selectedCategory.icon : selectedCategory.image);
        setSelectedCategory(selectedCategory.category);
        setslidedoenimg(!slidedoenimg);
        switchChooseCategory(false);
    };

    const Notes = notes

    async function autoTrackUpdateOne(response, transactionId, category, notes) {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        response = await axios.put(
            `https://api.capitalflo.co/api/users/transaction/${transactionId}`,
            {category, notes},
            {withCredentials: true}
        );
    }

    async function bankTransactionUpdateOne(response, transactionId, category, notes) {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        response = await axios.put(
            `https://api.capitalflo.co/api/users/bank-transaction-edit/${transactionId}`,
            {category, notes},
            {withCredentials: true}
        );
    }

    async function autoTrackUpdateAll(response, merchantName, category) {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        console.log(merchantName);
        console.log(category);
        console.log(Tokens);
        console.log('---------------------')

        response = await axios.put(
            `https://api.capitalflo.co/api/users/gmail-transaction-all-category/${encodeURIComponent(merchantName)}`,
            {category},
            {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${Tokens}`,
                    "Content-Type": "application/json; charset=utf-8",
                },
            },
        );
    }

    async function bankTransactionUpdateAll(response, merchantName, category) {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        response = await axios.put(
            `https://api.capitalflo.co/api/users/bank-statement-transaction-all/${encodeURIComponent(merchantName)}`,
            {category},
            {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${Tokens}`,
                    "Content-Type": "application/json; charset=utf-8",
                },
            },
        );
    }

    const submitTxt = async (e) => {
        e.preventDefault();
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        const detailing = {
            id: expense._id,
            category: selectedCategory,
            notes: Notes

        };

        setCustomCategory((prevState) => [...prevState, detailing]);
        setMerchant('');
        setAmount('');
        setDate('');
        setNotes('');

        const transactionId = detailing.id;
        const category = detailing.category;
        const notes = detailing.notes;
        const merchantName = expense.merchantName;

        try {
            let response = '';
            if (expense.time) {
                if ((selectedCategory !== "Enter Category" || notes !== "") && !updateAllCategory) {
                    console.log("not updating for all");
                    await autoTrackUpdateOne(response, transactionId, category, notes); // Pass the notes value
                } else if ((selectedCategory !== "Enter Category" && notes === "") && updateAllCategory) {
                    console.log("Updating for all");
                    await autoTrackUpdateAll(response, merchantName, selectedCategory);
                } else if ((selectedCategory !== "Enter Category" && notes !== "") && updateAllCategory) {
                    console.log("Updating for all and one both");
                    await autoTrackUpdateOne(response, transactionId, category, notes); // Pass the notes value
                    await autoTrackUpdateAll(response, merchantName, selectedCategory);
                }
            } else {
                if ((selectedCategory !== "Enter Category" || notes !== "") && !updateAllCategory) {
                    console.log("not updating for all");
                    await bankTransactionUpdateOne(response, transactionId, category, notes); // Pass the notes value
                } else if ((selectedCategory !== "Enter Category" && notes === "") && updateAllCategory) {
                    console.log("Updating for all");
                    await bankTransactionUpdateAll(response, merchantName, selectedCategory);
                } else if ((selectedCategory !== "Enter Category" && notes !== "") && updateAllCategory) {
                    console.log("Updating for all and one both");
                    await bankTransactionUpdateOne(response, transactionId, category, notes); // Pass the notes value
                    await bankTransactionUpdateAll(response, merchantName, selectedCategory);
                }
            }
            console.log(response);
            props.onConfirm();
            localStorage.setItem('scrollPosition', window.pageYOffset);
            localStorage.setItem('Statement', JSON.stringify(Currentstatement))
            setTimeout(() => {
                window.location.reload();
            }, 500);

        } catch
            (error) {
            console.error('Error updating transaction:', error);
        }
    };
    const handleCheckboxChange = () => {
        setUpdateAllCategory(prevState => !prevState);
    };
    useEffect(() => {
        console.log("category: ", selectedCategory);
        console.log("checkbox: ", updateAllCategory);
        if (updateAllCategory && selectedCategory === null) {
            console.log("Blocking checkbox")
            setUpdateAllCategory(false);
            setRenderMessage(true);
            setTimeout(async () => {
                setRenderMessage(false);
            }, 3000);
        }
    }, [updateAllCategory]);

    const deleteTxt = () => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        let URL;
        if (expense.time) {
            URL = `https://api.capitalflo.co/api/users/transaction/${expense._id}`
        } else {
            URL = `https://api.capitalflo.co/api/users/bank-transaction-delete/${expense._id}`
        }
        console.log(URL)
        if (window.confirm('Are you sure you want to delete this transaction?')) {
            localStorage.setItem('scrollPosition', window.pageYOffset);
            axios
                .delete(URL, {withCredentials: true})
                .then(() => {
                    props.onConfirm();
                    window.location.reload();
                })
                .catch((error) => {
                    console.error('Error deleting transaction:', error);
                });
        }
    };
    const handleClearCategory = () => {
        setSelectedImage(null);
        setSelectedCategory(null);
    }
    const closePopup = () => {
        setShowDemoPopup(false);
    }

    return (
        <div className='completemodal'>
            {showDemoPopup && <DemoBlockerPopup closePopup={closePopup} />}
            <div className='editoverlay' onClick={closeeditwindow}></div>
            <Editcard>
                <div className='editcategory' >
                    <h2 style={{
                        marginTop:'6px',
                        marginLeft:'9px',
                    }}>Choose Category </h2>
                    {/*<div><AiOutlineClose className='closingbtn' onClick={closeeditwindow} /></div>*/}
                    <div style={{
                        display:'flex',
                        flexDirection:'row',
                        gap:'30vh'
                    }}>
                        <div className='editaddcategory' onClick={() => {
                            slidedown();
                            setChooseCategory();
                        }} style ={{borderColor: selectedCategory !== null ? "grey" : "red"
                        }}>
                            {!selectedImage ?
                                (chooseCategory ? <MdOutlineKeyboardBackspace className='back'/> :
                                    <GrAdd className='addiconcategory'/>)
                                : <img src={selectedImage} alt="" style={{
                                    marginLeft:'10px',
                                }}/>
                            }

                        </div>
                        {/*{slidedoenimg?(selectedImage?<div className='editaddcategory' style={{*/}
                        {/*    borderColor:'grey'*/}
                        {/*}} onClick={handleClearCategory}>*/}
                        {/*    {selectedImage?<GrSubtract className='addiconcategory'/>:""}*/}
                        {/*</div>:""):''}*/}
                        {slidedoenimg?<div className='editaddcategory' onClick={handleClearCategory}>
                            <p>Clear</p>
                        </div>:''}
                    </div>
                </div>
                {slidedoenimg && (
                    <div className='categoryimages'>
                        {/*<h2>Select Category</h2>*/}
                        <div className='imagelistout'>
                            {allCategories.map((each, i) => {
                                return (
                                    <div
                                        key={i}
                                        className='eachimage'
                                        onClick={imgselect}
                                        data-index={i}
                                    >
                                        {each.icon ? (
                                            each.icon
                                        ) : (
                                            <img src={each.image} alt='' data-index={i} onClick={imgselect}/>
                                        )}
                                        <h3 data-index={i}>{each.category}</h3>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
                {!chooseCategory ? <div className='editcategoryForm'>
                    <form>
                        <div className='editlabeled'>
                            <label>
                                <div className='editnamelabel'>Merchant</div>
                                <input
                                    type='text'
                                    value={expense.merchantName}
                                    onChange={handleCategoryChange}
                                />
                            </label>
                        </div>

                        <div className='editlabeled'>
                            <label>
                                <div className='editnamelabel'>Amount</div>
                                <input
                                    type='number'
                                    value={expense.amount}
                                    onChange={handleAmountChange}
                                />
                            </label>
                        </div>

                        <div className='labeled'>
                            <label>
                                <div className='editnamelabel'>Date</div>
                                <input
                                    type='text'
                                    value={expense.date}

                                    onChange={handleDateChange}
                                />
                            </label>
                        </div>

                        <div className='labeled'>

                            <div className='editnamelabel'>Notes</div>
                            <input
                                type='text'
                                value={isFocused ? notes : expense.notes}
                                onChange={handleNoteChange}
                                onFocus={handleFocus}
                            />

                        </div>
                        <div className='labeled'>
                            {renderMessage && (
                                <div className={'messageText'}>Category not selected.</div>
                            )}
                            <div className={'checkBox'}>
                                <input
                                    type="checkbox"
                                    checked={updateAllCategory}
                                    style={{
                                        backgroundColor: updateAllCategory ? "rgba(0,74,173,0.88)" : "rgba(166,166,169,0.72)",
                                        width: 30,
                                        height: 30,
                                    }}
                                    onChange={() => setUpdateAllCategory(!updateAllCategory)}
                                />
                                <div className='updateForAll'>Update category for
                                    all {(expense.merchantName).toUpperCase().substring(0, 14)}</div>
                            </div>
                        </div>
                        <div className='editcategorybtn'>
                            <button onClick={submitTxt}>Update</button>
                        </div>

                        <div onClick={deleteTxt}>
                            <AiOutlineDelete className='deletebtn'/>
                        </div>
                    </form>
                </div> : ''}
            </Editcard>
        </div>
    );
}

export default Edittransaction;

