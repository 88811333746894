import React from 'react';

function ConfirmationPopup({message, onConfirm, onCancel}) {
    // const handleCancel = (e) => {
    //     e.stopPropagation();
    //     onCancel();
    //   };
    return (
        <div className="popup-container">
            <div className="popup-Delete">
                <p>{message}</p>
                <div className="popup-buttons">
                    <button style={{
                        backgroundColor:'#D2042D'
                    }} onClick={onConfirm}>Confirm</button>
                    <button onClick={onCancel}>Cancel</button>
                </div>
            </div>
        </div>
    );
}

export default ConfirmationPopup;
