import React, {useState} from 'react';
import './OTPValidationPage.css'
import axios from 'axios';
import {useNavigate} from 'react-router-dom';

const OTPValidationPage = () => {
    const [otp, setOTP] = useState('');
    // const [Tokens, setTokens] = useState('');
    const [valid, setValid] = useState(true);

    const navigate = useNavigate();

    const handleOTPChange = (e) => {
        setOTP(e.target.value);
        if (valid === false) {
            setValid(true);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validate the OTP
        if (otp.length === 6) {
            // Perform the OTP validation logic here
            console.log('OTP is valid:', otp);
        } else {
            console.log('Invalid OTP');
        }
    };


    // const tokenize = async () => {
    //     try {
    //         const response = await axios.get(
    //             "https://api.capitalflo.co/api/users/protect",
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${Tokens}`,
    //                 },
    //                 withCredentials: true
    //             }
    //         );
    //         if (response.data.message === "Cookie not found") {
    //             setTokens(null);
    //         } else {
    //             setTokens(response.data.jwtToken);
    //         }
    //     } catch (err) {
    //         console.log(err.response.data.message);
    //         setTokens(null);
    //     }
    // };
    // useEffect(() => {
    //     tokenize();
    // }, []);
    const getTokens = () => {
        const cookieString = document.cookie;
        const cookieArray = cookieString.split(';');
        for (const cookie of cookieArray) {
            const [name, value] = cookie.split('=');
            if (name.trim() === 'jwt') {
                return value.trim();
            }
        }
        return null;
    };

    const verifying = async () => {
        const tokens = getTokens();
        console.log(tokens);
        await axios.post('https://api.capitalflo.co/api/users/otp-authentication', {
                otp
            },
            {
                params: { tokens }, // Adding tokens as a query parameter
                headers: {
                    authorization: `Bearer ${tokens}`,
                },
                withCredentials: true
            }
        )
            .then((response) => {
                console.log(response.data);
                if (response.data.status == true) {
                    navigate('/services')
                } else {
                    setValid(false);
                }
            })
            .catch((error) => {
                console.error(error);
            });

    }

    return (
        <>
            {/*<Loginheader/>*/}
            {/* <div>
    <h1>OTP Validation</h1>
    </div> */}
            <div className='otpsession'>
                <form onSubmit={handleSubmit} className='otpForm'>
                    <div>
                        <h2>OTP Validation</h2>
                        <input
                            type="number"
                            placeholder="Enter OTP"
                            value={otp}
                            onChange={handleOTPChange}
                            maxLength={6}
                        />
                        {!valid && <div style={{color: 'darkred'}}>Incorrect OTP, Please retry.</div>}
                    </div>
                    <div className='otpsbt' onClick={verifying}>Enter</div>
                </form>
            </div>
        </>
    );
};

export default OTPValidationPage;
