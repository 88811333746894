import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import axios from "axios";

function AdminBlogs() {
    const navigate = useNavigate();
    const [allBlogs, setAllBlogs] = useState([]);


    useEffect(() => {
        fetchData().then(() => {
            if (allBlogs.length > 0) {
                console.log("Number of blogs:", allBlogs.length);
            }
        });
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get("https://api.capitalflo.co/api/users/blogs");
            const blogs = response.data.blogs;
            setAllBlogs(blogs);
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const handlePostBlog = () => {
        //check if local storage adminToken is set
        if (localStorage.getItem('adminToken')) {
            navigate('/post-blog');
        } else {
            navigate('/adm-log');
        }
    };
    const handleBlogClick = (blog) => {
        // setSelectedBlog(blog);
        //navigate to /post-blogs passing blogs as a parameter
        navigate('/post-blog', {
            state: {
                blog: blog,
            }
        })
    };


    return (
        <div className="admin-blogs">
            <div className="admin-login-container">
                <h2>Post a new blog</h2>
                <form>
                    <button className="submit" onClick={handlePostBlog}>
                        Post Blog
                    </button>
                </form>
            </div>
            {/*add a horizonatal line */}
            <h1>Edit Blog</h1>
            <div className="blog-tiles-container">
                {Array.from({length: allBlogs.length}).map((_, index) => {
                    const blog = allBlogs[allBlogs.length - 1 - index];
                    return (
                        <div key={blog.id} className="blog-tile" onClick={() => handleBlogClick(blog)}>
                            <img src={blog.featuredImage.url} alt={blog.title} className="blog-image"/>
                            <h3 className="blog-title">{blog.title}</h3>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default AdminBlogs;
