// import { getAnalytics } from "firebase/analytics";
import {getAuth, GoogleAuthProvider} from "firebase/auth";
// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyCIOvelQ3z6Edias_ykRxg1gPYaVpm7k9o",
//   authDomain: "capitalflo-v1.firebaseapp.com",
//   projectId: "capitalflo-v1",
//   storageBucket: "capitalflo-v1.appspot.com",
//   messagingSenderId: "1050146542298",
//   appId: "1:1050146542298:web:87f1ab862d90ccf3cb0be3",
//   measurementId: "G-QRKM0MK77Y"
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyCu6ZBqu5sRih_6uL5CKl3ajbGpFPoTTF8",
//   authDomain: "sign-in-972b5.firebaseapp.com",
//   projectId: "sign-in-972b5",
//   storageBucket: "sign-in-972b5.appspot.com",
//   messagingSenderId: "161251203517",
//   appId: "1:161251203517:web:5e927cc137eb22ae025ad4"
// };

const firebaseConfig = {
    apiKey: "AIzaSyCu6ZBqu5sRih_6uL5CKl3ajbGpFPoTTF8",
    authDomain: "sign-in-972b5.firebaseapp.com",
    projectId: "sign-in-972b5",
    storageBucket: "sign-in-972b5.appspot.com",
    messagingSenderId: "161251203517",
    appId: "1:161251203517:web:5e927cc137eb22ae025ad4"
};


// const firebaseConfig = {
//   apiKey: "AIzaSyCu-5Z-8aUBvqou7cxxkmIgGNhOGXvHkzE",
//   authDomain: "capitalflo-f1068.firebaseapp.com",
//   projectId: "capitalflo-f1068",
//   storageBucket: "capitalflo-f1068.appspot.com",
//   messagingSenderId: "968328880602",
//   appId: "1:968328880602:web:228f8046682c052fd620e3",
//   measurementId: "G-N9HHHT2HXJ"
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyDIYwfaeAo_O5mPDkm9JfxEq2aVJgtqQbE",
//   authDomain: "capitalflo-69f41.firebaseapp.com",
//   projectId: "capitalflo-69f41",
//   storageBucket: "capitalflo-69f41.appspot.com",
//   messagingSenderId: "730375413407",
//   appId: "1:730375413407:web:8f11138d3627b7b0b70caa",
//   measurementId: "G-W7DZFYMN2P"
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
// const analytics = getAnalytics(app);
