import React, {useEffect} from 'react'
import './Terms.css';

export default function Terms() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div>
            <div className='termHead'>
                <h1>Terms of Service</h1>
            </div>
            {/* <p className='lasttag'>Last modified: October 31, 2022</p> */}
            <div className='termContent'>
                <h3>Website Terms and Conditions of Use</h3>
                <h4>1. Terms</h4>
                <p>By accessing this Website, accessible from capitalflo.co, you are agreeing to be bound by these
                    Website Terms and Conditions of Use and agree that you are responsible for the agreement with any
                    applicable local laws. If you disagree with any of these terms, you are prohibited from accessing
                    this site. The materials contained in this Website are protected by copyright and trade mark
                    law.</p>
                <br/>
                <h4>2. Use License</h4>
                <p>Permission is granted to temporarily download one copy of the materials on Capitalflo's Website for
                    personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of
                    title, and under this license you may not:

                    modify or copy the materials;
                    use the materials for any commercial purpose or for any public display;
                    attempt to reverse engineer any software contained on Capitalflo's Website;
                    remove any copyright or other proprietary notations from the materials; or
                    transferring the materials to another person or "mirror" the materials on any other server.
                    This will let Capitalflo to terminate upon violations of any of these restrictions. Upon
                    termination, your viewing right will also be terminated and you should destroy any downloaded
                    materials in your possession whether it is printed or electronic format. These Terms of Service has
                    been created with the help of the Terms Of Service Generator.</p>
                <br/>
                <h4>3. Disclaimer</h4>
                <p>All the materials on Capitalflo’s Website are provided "as is". Capitalflo makes no warranties, may
                    it be expressed or implied, therefore negates all other warranties. Furthermore, Capitalflo does not
                    make any representations concerning the accuracy or reliability of the use of the materials on its
                    Website or otherwise relating to such materials or any sites linked to this Website.</p>
                <br/>
                <h4>4. Limitations</h4>
                <p>Capitalflo or its suppliers will not be hold accountable for any damages that will arise with the use
                    or inability to use the materials on Capitalflo’s Website, even if Capitalflo or an authorize
                    representative of this Website has been notified, orally or written, of the possibility of such
                    damage. Some jurisdiction does not allow limitations on implied warranties or limitations of
                    liability for incidental damages, these limitations may not apply to you.</p>
                <br/>
                <h4>5. Revisions and Errata</h4>
                <p>The materials appearing on Capitalflo’s Website may include technical, typographical, or photographic
                    errors. Capitalflo will not promise that any of the materials in this Website are accurate,
                    complete, or current. Capitalflo may change the materials contained on its Website at any time
                    without notice. Capitalflo does not make any commitment to update the materials.</p>
                <br/>
                <h4>6. Links</h4>
                <p>Capitalflo has not reviewed all of the sites linked to its Website and is not responsible for the
                    contents of any such linked site. The presence of any link does not imply endorsement by Capitalflo
                    of the site. The use of any linked website is at the user’s own risk.</p>
                <br/>
                <h4>7. Site Terms of Use Modifications</h4>
                <p>Capitalflo may revise these Terms of Use for its Website at any time without prior notice. By using
                    this Website, you are agreeing to be bound by the current version of these Terms and Conditions of
                    Use.</p>
                <br/>
                <h4>8. Your Privacy</h4>
                <p>Please read our Privacy Policy.</p>
                <br/>
                <h4>9. Governing Law</h4>
                <p>Any claim related to Capitalflo's Website shall be governed by the laws of in without regards to its
                    conflict of law provisions.</p>

            </div>
            <div className='bottomSpace'></div>
        </div>
    )
}
