import React, { useState } from 'react';
import './Startup.css';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import GoogleSignInButton from './GoogleSignInButton'
import Header from '../PreHeader'
import apple from '../assets/App_Store_(iOS)-Badge-Logo.wine.svg'
import google from '../assets/Google_Play-Badge-Logo.wine.svg'
import mixpanel from "../utils/Mixpanel";
import { Helmet } from "react-helmet";
import CapitalfloMob from '../assets/img/1.png';
import CapitalfloBanner from '../assets/img/capitalflobanner.png'
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { auth } from "./configure";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import OtpInput from "otp-input-react";
import { CgSpinner } from "react-icons/cg";
import { MdOutlineArrowOutward } from "react-icons/md";
import { Usedrop, UsedropReset, UsedropUpdate } from '../ReleaseContext';




const Startup = () => {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [email, setemail] = useState('');
    const [credential, setCredential] = useState('');
    const [password, setpassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [invalid, setInvalid] = useState('');

    const [otp, setOtp] = useState("");
    const [ph, setPh] = useState("");
    const [loading, setLoading] = useState(false);
    const [showOTP, setShowOTP] = useState(false);
    const [user, setUser] = useState(null);
    const [credentialChanged, setCredentialChanged] = useState(false);

    function onCaptchVerify() {
        if (!window.recaptchaVerifier) {
            console.log("called captch verify");
            window.recaptchaVerifier = new RecaptchaVerifier(
                "recaptcha-container",
                {
                    size: "invisible",
                    'callback': (response) => {
                        console.log(response);
                    },
                    'expired-callback': () => {
                        toast.error("reCAPTCHA expired. Please try again.");
                    }
                },
                auth,
            );
        }
    }

    const sendOTPMobileNumber = async (mobileNumber) => {
        setIsLoading(true);
        console.log("Sending OTP to mobile number:", mobileNumber);
        try {
            const response = await axios.post("https://api.capitalflo.co/api/users/android-phone-login", { mobile: mobileNumber },
                {

                    withCredentials: true,
                },
            );
            console.log(response.data);
            const token = response.data.token;
            if (!response.data.createdAt) {
                await localStorage.setItem("firstTimeSignUp", "true");
            }
            document.cookie = `jwt=${token}; path=/;`;
            document.cookie = `jwt=${token}; domain=.capitalflo.co; path=/;`;
            document.cookie = `jwt=${token}; domain=capitalflo.co; path=/;`;
            document.cookie = `jwt=${token}; domain=api.capitalflo.co; path=/;`;
            await onSignup(mobileNumber);
            // setIsLoading(false);
        } catch (error) {
            console.log("something wrong in the android-phone request");
            console.log("Error:", error);
            setIsLoading(false);
        }
    };

    function onSignup() {
        setIsLoading(true);
        onCaptchVerify();

        const appVerifier = window.recaptchaVerifier;

        let formatPh = "+91" + credential;

        signInWithPhoneNumber(auth, formatPh, appVerifier)
            .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
                setIsLoading(false);
                setShowOTP(true);
                toast.success("OTP sent successfully!");
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
        setCredential('');
        formatPh = "";
    }
    const getTokens = () => {
        const cookieString = document.cookie;
        const cookieArray = cookieString.split(';');
        for (const cookie of cookieArray) {
            const [name, value] = cookie.split('=');
            if (name.trim() === 'jwt') {
                return value.trim();
            }
        }
        return null;
    };


    function onOTPVerify() {
        setIsLoading(true);
        const token = getTokens();
        window.confirmationResult
            .confirm(otp)
            .then(async (res) => {
                console.log(res);
                setUser(res.user);
                setIsLoading(false);
                toast.success("OTP verified successfully!");
                if (res.user.accessToken) {
                    const response = await axios.post("https://api.capitalflo.co/api/users/otp-validate",
                        { otp: otp },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                            withCredentials: true,
                        },
                    )
                    console.log(response.data);
                    setTimeout(() => {
                        localStorage.clear();
                        navigate("/services");
                    }, 1000)
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
                toast.error("Invalid OTP!");

            });
    }

    const signinemail = (e) => {
        setCredential(e.target.value);
        setCredentialChanged(true);
    };

    const sendOTPEmail = async email => {
        setIsLoading(true);
        console.log("Sending OTP to email:", email);
        try {
            localStorage.clear();
            const response = await axios.post(
                "https://api.capitalflo.co/api/users/android-phone-login",
                { email },
            );
            console.log('RESPONSSEE DATATAA', response.data);
            const token = response.data.token;
            console.log(token);
            setCredential('');
            if (!response.data.createdAt) {
                await localStorage.setItem("firstTimeSignUp", "true");
            }
            mixpanel.track('Logged In Web', { 'Login Type': 'Email' });
            document.cookie = `jwt=${token.trim()}; path=/;`;
            let expires = new Date(Date.now() + 1000 * 60 * 60 * 24 * 60); // One day from now
            document.cookie = "jwt=" + encodeURIComponent(token) +
                "; expires=" + expires.toUTCString() +
                "; path=/; domain=api.capitalflo.co; secure";
            document.cookie = "jwt=" + encodeURIComponent(token) +
                "; expires=" + expires.toUTCString() +
                "; path=/; domain=.capitalflo.co; secure";
            navigate('/authentication');
        } catch (error) {
            console.log("Error:", error);
            toast.error('There was an error sending OTP. Please try again.', {
                position: "bottom-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    };

    const isValidEmail = credential => {
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        return emailRegex.test(credential);
    };

    const isValidMobileNumber = credential => {
        const phoneRegex = /^\d{10}$/;
        return phoneRegex.test(credential);
    };

    const handleContinue = () => {
        localStorage.clear();
        if (isValidEmail(credential)) {
            // Send OTP to the email address
            sendOTPEmail(credential);
        } else if (isValidMobileNumber(credential)) {
            // Send OTP to the mobile number
            sendOTPMobileNumber(credential);
            // onSignup(credential);
        } else {
            toast.error('Please enter a valid email address or phone number.', {
                position: "bottom-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    };
    const handleDemoLogin = async () => {
        if (window.innerWidth <= 600) {
            toast.info(`This feature is not available on mobile web browser.
Download our mobile app to experience the demo and other features.`, { autoClose: 5000 });
            const response = await axios.get(
                "https://api.capitalflo.co/api/users/demo-account",
                {
                    params: { source: "mobile-web" },
                    withCredentials: true
                }
            );
            return;
        }
        setIsLoading(true);
        try {
            const response = await axios.get(
                "https://api.capitalflo.co/api/users/demo-account",
                {
                    params: { source: "web" },
                    withCredentials: true
                }
            );
            console.log(response);
            if (response.data.token) {
                const token = response.data.token;
                console.log(token);
                document.cookie = `jwt=${token}; path=/;`;
                let expires = new Date(Date.now() + 1000 * 60 * 60);
                document.cookie = "jwt=" + token +
                    "; expires=" + expires.toUTCString() +
                    "; path=/; domain=api.capitalflo.co; secure";
                document.cookie = "jwt=" + token +
                    "; expires=" + expires.toUTCString() +
                    "; path=/; domain=.capitalflo.co; secure";
                document.cookie = "jwt=" + token +
                    "; expires=" + expires.toUTCString() +
                    "; path=/; domain=capitalflo.co; secure";
                localStorage.setItem("demoAccountSetupTime", Date.now().toString());
                toast.info("Demo account setup successful. Welcome to Capitalflo", { autoClose: 3000 });
                setTimeout(() => {
                    navigate('/services');
                }, 1500)
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log("Error:", error);
            toast.error('There was an error logging in. Please try again.', {
                position: "bottom-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    }


    const structuredData = {
        "@context": "http://schema.org",
        "@type": "WebPage",
        "name": "Login",
        "description": "Sign in to Capitalflo to take control of your financial journey.",
        "url": "https://capitalflo.co/login",
    };
    const drop = Usedrop();
    const dropping = UsedropUpdate();
    const droppie = UsedropReset();


    return (
        <div className='signin-webpage'>
            <Helmet>
                <title>Login</title>
                <meta name="description"
                    content="Sign in to Capitalflo to take control of your financial journey." />
                <script type="application/ld+json">{JSON.stringify(structuredData)}</script>

            </Helmet>
            <Header />
            <div className='signin-body' onClick={droppie}>
                <div className='left-body'>
                    <div className='capitalflo-mob-img'>
                        <img src={CapitalfloMob} alt="Capitalflo Mob" />

                    </div>
                    <div className='playstationStartup'>
                        <Link className='appStoreImg' to="https://apps.apple.com/us/app/capitalflo/id6468636479"
                            target="_blank">
                            <img src={apple} alt="" />
                        </Link>
                        <Link className='googleplayImg'
                            to="https://play.google.com/store/apps/details?id=com.capitalfloandroid"
                            target="_blank">
                            <img src={google} alt="" />
                        </Link>
                    </div>
                </div>
                <div className='right-body'>
                    <div className='top-right-body'>
                        <div className='capitalflo-banner-img'>
                            <img src={CapitalfloBanner} alt="Capitalflo Banner" className='capitalflo-banner-img' />
                        </div>
                        <div className='capitalflo-tagline'>
                            <p>Your Personal Financial Planner</p>
                        </div>
                        <div className='login-options'>
                            <div className='googlesign'>
                                <GoogleSignInButton type={'login'} />
                            </div>
                            {/* <HiOutlineDotsHorizontal className='dots-option' onClick={() => {
                                toast.info('More sign in options will be available soon!', {
                                    position: "bottom-right",
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "dark",
                                });
                            }}
                            /> */}
                        </div>
                    </div>
                    <div className='or-text'>
                        <p>or</p>
                    </div>
                    <div className='bottom-right-body'>
                        <div className='inputfield'>
                            <input
                                type='text'
                                placeholder='Mobile Number or Email'
                                onChange={signinemail}
                            />
                        </div>
                        {showOTP ? (
                            <>
                                <div className="bg-white text-emerald-500 w-fit mx-auto p-4 rounded-full">
                                    {/* <BsFillShieldLockFill size={30} /> */}
                                </div>
                                <label
                                    htmlFor="otp"
                                    className="otp-text"
                                >
                                    Enter your OTP
                                </label>
                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    OTPLength={6}
                                    otpType="number"
                                    disabled={false}
                                    autoFocus
                                    className="otp-container "
                                ></OtpInput>
                                <button
                                    onClick={onOTPVerify}
                                    className="otp-verify-button"
                                >
                                    {isLoading && showOTP && (
                                        <CgSpinner size={20} className="mt-1 animate-spin" style={{ marginTop: 0 }} />
                                    )}
                                    <span>Verify OTP</span>
                                </button>
                            </>
                        ) : (
                            credentialChanged && (
                                 <div className='login-button' onClick={handleContinue}>
                                Continue
                            </div>
                            )
                           
                        )}
                        <div>
                            {isLoading && !showOTP && (
                                <CgSpinner size={30} className="mt-1 animate-spin" />
                            )}
                        </div>
                        <div className='demo-text' onClick={() => { handleDemoLogin() }}>
                            <p>Skip to demo
                            <MdOutlineArrowOutward style={{ color: "#60acce", marginLeft: "5px" }} />
                            </p>
                        </div>
                        <div className='playstationStartup-mob'>
                            <Link className='appStoreImg' to="https://apps.apple.com/us/app/capitalflo/id6468636479"
                                target="_blank">
                                <img src={apple} alt="" />
                            </Link>
                            <Link className='googleplayImg'
                                to="https://play.google.com/store/apps/details?id=com.capitalfloandroid"
                                target="_blank">
                                <img src={google} alt="" />
                            </Link>
                        </div>
                        <ToastContainer
                            position="bottom-right"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="dark"
                        />
                    </div>
                    <div id="recaptcha-container" className='recaptcha-container'></div>


                    <div className='terms-startup-container'>
                        <p>By continuing, you agree to our</p><br />
                        <p><a href="/terms-of-service">Terms of Service</a>         <a href="/privacy_policy">Privacy Policy</a></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Startup;
