import React from 'react';

import './WelcomePopup.css';

function WelcomePopup({message, onCancel}) {
    // const handleCancel = (e) => {
    //     e.stopPropagation();
    //     onCancel();
    //   };
    return (
        <div className="popup-container-welcome">
            <div className="popup-Delete-welcome">
                <p>{message}</p>
                <div className="popup-buttons-welcome">
                    <button style={{
                        backgroundColor:'#004aad'
                    }} onClick={onCancel}>OK</button>
                </div>
            </div>
        </div>
    );
}

export default WelcomePopup;
