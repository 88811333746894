import React, { useRef, useEffect } from 'react'
import PreHeader from '../PreHeader';
import PostHeader from '../PostHeader';
import apple from "../assets/storeimg/App Store.png";
import google from "../assets/storeimg/Google Play.png";
import { Link } from 'react-router-dom';
import './Products.css';
import Helmet from 'react-helmet';
import { useParams } from 'react-router-dom';
import { Usedrop, UsedropReset, UsedropUpdate } from '../ReleaseContext';



function Products() {

    // useEffect(() => {
    //     if (window.innerWidth <= 600) {
    //         document.getElementById('video-container-demo').innerHTML = '<iframe src="https://www.youtube.com/embed/GGDSSEfGR-4" title="New video title" frameborder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen; web-share" allowfullscreen></iframe>';
    //     } else {
    //         document.getElementById('video-container-demo').innerHTML = '<iframe src="https://www.youtube.com/embed/UQmC0vYd9zA" title="How we can help you?" frameborder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen; web-share" allowfullscreen></iframe>';
    //     }
    // }, []);

    const { keyword } = useParams();
    const planBudget = useRef(null);
    const bankStatement = useRef(null)
    const transactions = useRef(null)
    const tfp = useRef(null)
    const goals = useRef(null)

    let structuredData = {
        "@context": "http://schema.org",
        "@type": "WebPage",
        "name": "Features | Capitalflo",
        "description": "What products and features does Capitalflo provide?",
        "url": "https://capitalflo.co/products",
    };
    if (keyword === 'usermanual') {
        structuredData = {
            "@context": "http://schema.org",
            "@type": "WebPage",
            "name": "User Manual | Capitalflo",
            "description": "What products and features does Capitalflo provide?",
            "url": "https://capitalflo.co/products",
        };
    }
    function scrollToComponent() {
        if (window.location.hash === '#budget') {
            console.log('got the paraaammm')
            setTimeout(() => {
                planBudget?.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
            }, 100)
        }
        else if (window.location.hash === '#statement') {
            console.log('got the paraaammm')
            setTimeout(() => {
                bankStatement?.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
            }, 100)
        }
        else if (window.location.hash === '#transactions') {
            console.log('got the paraaammm')
            setTimeout(() => {
                transactions?.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
            }, 100)
        }
        else if (window.location.hash === '#tfp') {
            console.log('got the paraaammm')
            setTimeout(() => {
                tfp.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
            }, 100)
        }
        else if (window.location.hash === '#goals') {
            console.log('got the paraaammm')
            setTimeout(() => {
                goals.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
            }, 100)
        }
    }
    const drop = Usedrop();
    const dropping = UsedropUpdate();
    const droppie = UsedropReset();


    useEffect(() => scrollToComponent(), [])
    return (
        <div className='products_page'>
            <Helmet>
                <title>{keyword === 'usermanual' ? 'User Manual' : 'Features'} | Capitalflo</title>
                <meta name="description"
                    content="What products and features does Capitalflo provide?" />
                <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
            </Helmet>
            {keyword === "usermanual" ? <PostHeader /> : <PreHeader />}
            <div className='products__container' onClick={droppie}>
                {keyword !== "usermanual" ? (
                    <div className='products__heading_container'>

                        <p>The Financial Planning of a New Generation!</p>

                        <div class="products_video-features">
                        <iframe src="https://www.youtube.com/embed/GGDSSEfGR-4" title="How we can help you?" frameBorder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen; web-share" allowFullScreen="allowFullScreen"></iframe>
                            {/* {
                            window.innerWidth <= 600 ?
                                <iframe src="https://www.youtube.com/embed/GGDSSEfGR-4" title="How we can help you?" frameBorder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen; web-share" allowFullScreen="allowFullScreen"></iframe>
                                :
                                <iframe src="https://www.youtube.com/embed/UQmC0vYd9zA" title="How we can help you?" frameBorder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen; web-share" allowFullScreen="allowFullScreen"></iframe>
                            } */}
                        </div>
                        {/* <p style={{ fontSize: '16px' }}>Check out what you can <a href="/blog/what-i-achieved-with-capitalflo">acheive with Capitalflo →</a></p> */}
                    </div>
                ) : (
                    <div className='products__heading_container'>
                        <h1>User Manual</h1>
                        <p>Your guide to use Capitalflo like a pro!</p>

                        <div class="products_video-features">
                        <iframe src="https://www.youtube.com/embed/GGDSSEfGR-4" title="New video title" frameBorder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen; web-share" allowFullScreen="allowFullScreen"></iframe>

                            {/* {window.innerWidth <= 600 ?
                                <iframe src="https://www.youtube.com/embed/GGDSSEfGR-4" title="New video title" frameBorder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen; web-share" allowFullScreen="allowFullScreen"></iframe>
                                :
                                <iframe src="https://www.youtube.com/embed/UQmC0vYd9zA" title="How we can help you?" frameBorder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen; web-share" allowFullScreen="allowFullScreen"></iframe>
                            } */}
                        </div>
                        {/* <p style={{ fontSize: '16px' }}>Check out what you can <a href="/blog/what-i-achieved-with-capitalflo">acheive with Capitalflo →</a></p> */}
                    </div>
                )}
                <h1 style={{marginBottom:'1%'}}>Features</h1>
                <div className='products_list'>
                    <div className='video_text_product text_first_then_video_conatiner' ref={bankStatement} >
                        <div className='products_video-container_text'>
                            <h2>Statement Analyser</h2>
                            <p>Upload your monthly Bank Statement and get to know your spending patterns in a few minutes.</p>
                        </div>
                        <div class="products_video-features">
                        <iframe src="https://www.youtube.com/embed/uWlscXSgx88" title="My Bank Statement and My Money?" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" allowFullScreen="allowFullScreen"></iframe>

                            {/* {window.innerWidth <= 600 ?
                                <iframe src="https://www.youtube.com/embed/uWlscXSgx88" title="My Bank Statement and My Money?" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" allowFullScreen="allowFullScreen"></iframe>
                                :
                                <iframe src="https://www.youtube.com/embed/hrLqnv-pX_U" title="My Bank Statement and My Money?" frameborder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" allowFullScreen="allowFullScreen"></iframe>
                            } */}
                        </div>
                    </div>
                    <div className='video_text_product video_first_then_text_conatiner' ref={planBudget} >
                        <div class="products_video-features">
                        <iframe src="https://www.youtube.com/embed/ziGO4ck-ZNE" title="Budget or no budget?" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" allowFullScreen="allowFullScreen"></iframe>

                            {/* {window.innerWidth <= 600 ?
                                <iframe src="https://www.youtube.com/embed/ziGO4ck-ZNE" title="Budget or no budget?" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" allowFullScreen="allowFullScreen"></iframe>
                                :
                                <iframe src="https://www.youtube.com/embed/oU99pSgriYI" title="Budget or no budget?" frameborder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen; web-share" allowFullScreen="allowFullScreen"></iframe>
                            } */}
                        </div>
                        <div className='products_video-container_text' >
                            <h2>Budget Planner</h2>
                            <p>Plan your monthly budget & know how much you can save, invest and spend.</p>
                        </div>
                    </div>
                    <div className='video_text_product text_first_then_video_conatiner' ref={tfp} >
                        <div className='products_video-container_text'>
                            <h2>Total Financial Position</h2>
                            <p>Get insights of your financial health based on your assets, liabilities, income & expenses.</p>
                        </div>
                        <div class="products_video-features">
                        <iframe src="https://www.youtube.com/embed/xnFVwdoGJtI" title="Have you ever wanted to know your net worth?" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" allowFullScreen="allowFullScreen"></iframe>

                            {/* {window.innerWidth <= 600 ?
                                <iframe src="https://www.youtube.com/embed/xnFVwdoGJtI" title="Have you ever wanted to know your net worth?" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" allowFullScreen="allowFullScreen"></iframe>
                                :
                                <iframe src="https://www.youtube.com/embed/PMAQjF9sCCM" title="Have you ever wanted to know your net worth?" frameborder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" allowFullScreen="allowFullScreen"></iframe>
                            } */}
                        </div>
                    </div>
                    <div className='video_text_product video_first_then_text_conatiner' ref={goals} >
                        <div class="products_video-features">
                        <iframe src="https://www.youtube.com/embed/v6bSSPT6YGY" title="What it takes to achieve your dream car and house?" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" allowFullScreen="allowFullScreen"></iframe>

                            {/* {window.innerWidth <= 600 ?
                                <iframe src="https://www.youtube.com/embed/v6bSSPT6YGY" title="What it takes to achieve your dream car and house?" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" allowFullScreen="allowFullScreen"></iframe>
                                :
                                <iframe src="https://www.youtube.com/embed/sIhcKAJE8Jw" title="What it takes to achieve your dream car and house?" frameborder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" allowFullScreen="allowFullScreen"></iframe>
                            } */}
                        </div>
                        <div className='products_video-container_text'>
                            <h2>Achieve Goals</h2>
                            <p>Plan your short, medium and long term goals & be financially prepared for the future.</p>
                        </div>
                    </div>
                    <div className='video_text_product text_first_then_video_conatiner' >
                    <div className='products_video-container_text' ref={transactions} >
                            <h2>Autotrack Transactions</h2>
                            <p>Track your ongoing expenses automatically & be aware while spending.</p>
                        </div>
                        <div class="products_video-features">
                        <iframe src="https://www.youtube.com/embed/zFx_AxyM_OI" title="How to know where your money went?" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" allowFullScreen="allowFullScreen"></iframe>

                            {/* {window.innerWidth <= 600 ?
                                <iframe src="https://www.youtube.com/embed/zFx_AxyM_OI" title="How to know where your money went?" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" allowFullScreen="allowFullScreen"></iframe>
                                :
                                <iframe src="https://www.youtube.com/embed/Myq2z6y8EQ4" title="How to know where your money went?" frameborder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" allowFullScreen="allowFullScreen"></iframe>
                            } */}
                        </div>
                    
                    </div>

                    {/* <div className='video_text_product'>
                        <div class="products_video-container_video">
                            <iframe src="https://www.youtube.com/embed/hkBWVwr7yXQ" title="How to show live Firebase data in your Flutterflow app" frameborder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" allowfullscreen></iframe>
                        </div>
                        <div className='products_video-container_text'>
                            <h2>Net Worth</h2>
                            <p>Our technology is designed to help you make better financial decisions. We use machine learning to predict your future cash flow and help you make better decisions.</p>
                        </div>
                    </div>
                    <div className='video_text_product'>
                        <div className='products_video-container_text'>
                            <h2>Set Budget</h2>
                            <p>Our technology is designed to help you make better financial decisions. We use machine learning to predict your future cash flow and help you make better decisions.</p>
                        </div>
                        <div class="products_video-container_video">
                            <iframe src="https://www.youtube.com/embed/yopCfhepAsM" title="Build UI for Flutter in Minutes: Introducing FlutterFlow UI package" frameborder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" allowfullscreen></iframe>
                        </div>
                    </div> */}
                </div>
                <div className='download-buttons'>
                    <Link to="https://apps.apple.com/us/app/capitalflo/id6468636479" target="_blank"><img
                        src={apple}
                        alt="" /></Link>
                    <Link className='google-download'
                        to="https://play.google.com/store/apps/details?id=com.capitalfloandroid"
                        target="_blank"><img
                            src={google}
                            alt="" /></Link>
                </div>
                <div className='products_bottom_heading_container'>
                    {/* <h2>Capitalflo</h2>
                    <p>Your Personal Financial Planner</p> */}
                    {/* <button className='sign-up-button' onClick={() => window.location.href = '/signup'}>Sign up now</button> */}
                    <p>Want to try our product for free?<a href="/login"> Click here →</a></p>
                </div>
                <div className='copyright-blog' style={{ alignSelf: 'center', padding: '20px 0', fontSize: '12px' }}>Copyright © 2023 Silicon Murphy (OPC) Private Limited.</div>
            </div>
        </div>
    )
}

export default Products
