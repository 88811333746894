import React, {useEffect, useState} from 'react';
import ReactDOM from "react-dom";
import Card from './Card';
import {GrAdd} from 'react-icons/gr';
import {MdOutlineKeyboardBackspace} from 'react-icons/md';
import axios from 'axios';
import allCategories from '../assets/icons';

import './Transactionmodel.css';

const Backdrop = (props) => {
    return (
        <div className='backdrop' onClick={props.onConfirm}></div>
    );
};

const Modaloverlay = (props) => {

    const [slidedoenimg, setslidedoenimg] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [Merchant, setMerchant] = useState('');
    const [amount, setAmount] = useState('');
    const [datetxt, setDate] = useState('');
    const [dateshow, showDate] = useState('');
    const [currdate, setcurrdate] = useState('');
    const [noteshow, showNote] = useState('');
    const [customcategory, setcustomcategory] = useState([]);
    const [chooseCategory, switchChooseCategory] = useState(false);


    const handleCategoryChange = (event) => {
        if (event.target.value) {
            setMerchant(event.target.value);
        } else {
            setMerchant('');
        }
    };
    useEffect(() => {
        // Set the default date when the component mounts
        const currentDate = new Date();
        const formattedDate = formatDate(currentDate);
        setDate(formattedDate); // Update the state with the formatted date

        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Pad month with leading zero if needed
        const day = String(currentDate.getDate()).padStart(2, '0'); // Pad day with leading zero if needed
        const value = `${year}-${month}-${day}`;
        setcurrdate(value); // Update the state with the formatted date in "YYYY-MM-DD" format
    }, []);

    const formatDate = (date) => {
        const day = ('0' + date.getDate()).slice(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const handleAmountChange = (event) => {
        setAmount(event.target.value);
    };

    const handleNoteChange = (event) => {
        showNote(event.target.value)
        console.log(event.target.value);
    }

    const handleDateChange = (event) => {
        const {value} = event.target;
        showDate(value); // Update the state with the selected date from the date picker
        const date = new Date(value);
        const formattedDate = date.toLocaleDateString('en-GB'); // "01/03/2023"
        setDate(formattedDate);
    };


    const handleSubmit = (event) => {
        event.preventDefault();
    };

    const slidedown = (e) => {
        setslidedoenimg(!slidedoenimg);
        switchChooseCategory(!chooseCategory);
    }
    const setChooseCategory = (e) => {
        switchChooseCategory(!chooseCategory);
    }
    const imgselect = (e) => {
        const selectedCategory = allCategories[e.target.dataset.index];
        setSelectedImage(selectedCategory.icon ? selectedCategory.icon : selectedCategory.image);
        setSelectedCategory(selectedCategory.category);
        setslidedoenimg(!slidedoenimg);
        switchChooseCategory(false);
    };


    const submitTxt = (event) => {
        event.preventDefault();

        const timestamp = Date.now();
        const date = new Date(timestamp);
        let hour = date.getHours() % 12;
        hour = hour ? hour : 12; // if hour is 0, set it to 12
        const minute = ('0' + date.getMinutes()).slice(-2);
        const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
        const timeString = `${hour}:${minute} ${ampm}`;
        console.log(timeString); // Output: "12:08 PM"
        const dateToSend = dateshow ? dateshow : currdate;
        const formattedDate = dateToSend.split("-").reverse().join("/");

        const detailing = {
            category: selectedCategory,
            merchantName: Merchant,
            amount: amount,
            date: formattedDate,
            notes: noteshow,
            time: timeString
        };

        setcustomcategory(prevState => [
            ...prevState,
            detailing
        ]);

        setMerchant('');
        setAmount('');
        setDate('');
        showNote('');
        props.Ongoing(customcategory);
        props.onConfirm();

        console.log('custom');
        console.log(detailing);

        axios.post("https://api.capitalflo.co/api/users/transaction", detailing, {
            withCredentials: true
        })
            .then(res => {
                console.log(res.data);
                window.location.reload(); // Reload the page after the request is successful
            })
            .catch(err => {
                console.log('Error Adding &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&');
                console.log(err);
            });
    };

    return (
        <React.Fragment>
            <Card>
                <div className='popupmodel'>
                    {/* <p>Create Transaction</p> */}
                    <div className='category'>
                        <h2>Choose Category</h2>
                    </div>
                    <div className='addcategory' onClick={() => {
                        slidedown();
                        setChooseCategory();
                    }}>
                        {!selectedImage ?
                            (chooseCategory ? <MdOutlineKeyboardBackspace className='back'/> :
                                <GrAdd className='addiconcategory'/>)
                            : <img src={selectedImage} alt=""/>
                        }
                    </div>
                    {slidedoenimg && <div className='categoryimages'>
                        <h2>Select Category </h2>
                        <div className='imagelistout'>
                            {allCategories.map((each, i) => {
                                return (
                                    <div key={i} className='eachimage' onClick={imgselect} data-index={i}>
                                        {each.icon ? each.icon :
                                            <img src={each.image} alt="" data-index={i} onClick={imgselect}/>
                                        }
                                        <h3 data-index={i}>{each.category}</h3>
                                    </div>
                                )
                            })}
                        </div>
                    </div>}
                    {!chooseCategory ? <div className='categoryForm'>
                        <form onSubmit={handleSubmit}>
                            <div className='labeled'>
                                <label>
                                    <div className='namelabel'>Merchant</div>
                                    <input type="text" value={Merchant} onChange={handleCategoryChange}/>
                                </label>
                            </div>

                            <div className='labeled'>
                                <label>
                                    <div className='namelabel'>Amount</div>
                                    <input type="number" value={amount} onChange={handleAmountChange}/>
                                </label>
                            </div>

                            <div className='labeled'>
                                <label>
                                    <div className='namelabel'>Date</div>
                                    <input type="date" id="date" name="date" value={dateshow ? dateshow : currdate}
                                           onChange={handleDateChange}/>
                                </label>
                            </div>

                            <div className='labeled'>
                                <label>
                                    <div className='namelabel'>Notes</div>
                                    <input type="text" value={noteshow} onChange={handleNoteChange}/>
                                </label>
                            </div>

                            <div className='categorybtn'>
                                <button onClick={submitTxt}>Create</button>
                            </div>

                        </form>
                    </div> : ''}
                </div>
            </Card>

        </React.Fragment>
    )
}

function Transactionmodel(props) {
    const {Ongoing} = props;

    return (
        <React.Fragment>
            {ReactDOM.createPortal(<Backdrop onConfirm={props.onConfirm}/>, document.getElementById('backdrop-root'))}
            {ReactDOM.createPortal(<Modaloverlay Ongoing={Ongoing} onConfirm={props.onConfirm}
                                                 addtxts={props.addtxts}/>, document.getElementById('overlay-root'))}
        </React.Fragment>
    );
}


export default Transactionmodel
