import React, {useEffect, useState} from 'react';
import './PostCard.css'; // Import the CSS file
import moment from "moment";
import {Link} from "react-router-dom";
import {getComments} from "../query";

const Postcard = ({post}) => {
    const [commentsfetch, setCommentsfetch] = useState([]);
    const [checkComments, setCheckComments] = useState(false);
    const [checkCommentsLength, setCheckCommentsLength] = useState(-1);

    let slug = post['slug']
    useEffect(() => {
        if (checkCommentsLength == -1) {
            getComments(slug).then((result) => {

                setCommentsfetch(result);
                setCheckComments(true)
            });
            if (checkComments === true) {
                setCheckCommentsLength(commentsfetch.comments.length)
            }
        }
    }, [commentsfetch, checkCommentsLength]);

    const handleCommentsClicked = () => {
        if (checkCommentsLength > 0) {
            window.location.href = `/blog/${post.slug}#comments`;
        }
    };
    const excerptWords = post.excerpt.split(' ');
    const truncatedExcerpt = excerptWords.slice(0, 30).join(' ');

    const finalExcerpt = `${truncatedExcerpt} ...`;

    return (
        <div className="post-card">
            {/*<div className="image-container">*/}
            {/*<img*/}
            {/*    src={post.featuredImage.url}*/}
            {/*    alt={post.title}*/}
            {/*    height="30px"*/}
            {/*    width="30px"*/}
            {/*    className="post-image"*/}
            {/*/>*/}
            {/*</div>*/}
            <h1 className="post-title">
                <Link to={`/blog/${post.slug}`}>{post.title}</Link>
            </h1>
            <div className="post-author">
                {/*<img*/}
                {/*    alt={post.author.name}*/}
                {/*    height="30px"*/}
                {/*    width="30px"*/}
                {/*    className="author-avatar"*/}
                {/*    src={post.author.photo.url}*/}
                {/*/>*/}
                {/*<p className="author-name">{post.author.name}</p>*/}
                {/*<span className="date-text">*/}
                {/*    {moment(post.createdAt).format('MMM DD, YYYY')}*/}
                {/*</span>*/}
                <div className="post-date">
                    <span className="date-text" style={{
                        fontSize: "16px"
                    }}>
                    {moment(post.createdAt).format('MMM DD, YYYY')}
                </span>
                    <div className='commentsLengthBlue' style={{
                        fontSize: "16px", cursor: "pointer"
                    }} onClick={handleCommentsClicked}>
                        <div style={{
                            color: '#004aad',
                            fontWeight: '470',
                            marginRight: '5px',
                            marginLeft: '20px',
                        }}>
                            {checkCommentsLength || 0} {' '}</div>
                        comments
                    </div>


                </div>
            </div>
            <div className="excerpt-post">
                <p className="post-excerpt">{finalExcerpt}</p>
            </div>

            <div className="read-more">
                <Link to={`/blog/${post.slug}`}>READ MORE</Link>
            </div>

            <div className="image-title-container">
                <div className="image-container">
                    <Link to={`/blog/${post.slug}`}>
                        <img
                            src={post.featuredImage.url}
                            alt={post.title}

                            className="post-image"
                        />
                    </Link>
                </div>
                {/*<div className="post-title-container">*/}
                {/*    <Link to={`/blog/${post.slug}`}>{post.title}</Link>*/}
                {/*</div>*/}

            </div>

        </div>
    );
};

export default Postcard;
