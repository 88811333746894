import React from 'react'
import {Link} from "react-router-dom";

import Logheader from '../PostHeader';
import { MdOutlinePrivacyTip } from "react-icons/md";
import { RiBookMarkLine } from "react-icons/ri";


function InfoPage() {
    return (
        <div style={{
            width: '100vw',
            display: 'flex',
            flexDirection: 'column',

        }}>
            <Logheader/>
            <div style={{
                marginLeft:'200px',
                marginTop:'150px'
            }}>
                <Link to="/privacy_policy" style={{ textDecoration: 'none', color: 'inherit' , display:'flex', flexDirection:'row', padding:29}}>
                    {/*<span className="count third">3. </span>*/}
                    <MdOutlinePrivacyTip className="icon" style={{width:'25px', height:'25px', marginTop:'2px' , marginRight:'5px'}}/>
                    <p style={{
                        fontSize:'23px'
                    }}>Privacy Policy</p>
                </Link>


                <Link to="/terms-of-service" style={{ textDecoration: 'none', color: 'inherit' , display:'flex', flexDirection:'row', padding:29}}>
                    {/*<span className="count">4. </span>*/}
                    <RiBookMarkLine className="icon" style={{width:'25px', height:'25px', marginTop:'4px' , marginRight:'5px'}}/>
                    <p style={{
                        fontSize:'23px'
                    }}>Terms of Service</p>
                </Link>

            </div>
        </div>

    )
}
export default InfoPage