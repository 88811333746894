import React, {useState, useRef, useEffect} from 'react'
import {useParams} from "react-router-dom";
import Logheader from '../PostHeader';
import {Input} from "@mui/material";
import './UserProfile.css'
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isDemoAccountUsed } from '../utils/CheckIfDemo';
import DemoBlockerPopup from './DemoBlockerPopup';
function UserProfile() {
    const { email } = useParams();
    const [name, setName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [age, setAge] = useState(0)
    const [dob, setDob] = useState('');
    const [city, setCity] = useState('')
    const dateInputRef = useRef(null);
    const [Tokens, setTokens] = useState("");
    const [showDemoPopup, setShowDemoPopup] = useState(false);
    const [isChanged, setIsChanged] = useState(false)
    const tokenize = async () => {
        try {
            const response = await axios.get(
                "https://api.capitalflo.co/api/users/protect-user",
                {withCredentials: true}
            );
            console.log(response);

            if (response.data.message === "Cookie not found") {
                setTokens(null);
            } else {
                console.log(response.data.jwtToken);
                setTokens(response.data.jwtToken);
            }
        } catch (err) {
            console.log(err.response.data.message);
            setTokens(null);
        }
    };
    const fetchData = async() => {
        try{
            const response = await axios.get('https://api.capitalflo.co/api/users/get-user-details',{
                headers: {
                    Authorization: `Bearer ${Tokens}`,
                },
                withCredentials: true,
            })
            console.log('responseee', response.data.profileDetails.dob)
            setName(response.data.profileDetails.name)
            setDob(response.data.profileDetails.dob)
            setCity(response.data.profileDetails.city)
        }
        catch (error){
            console.log("Error retreiving data", error)
        }
    }
    useEffect(()=>{
        const age = calculateAge(dob)
        console.log('calc agee', dob)
        setAge(age)
    },[dob])

    useEffect(() => {
        tokenize();
        fetchData();
    }, []);
    const containsSymbols = (str) => {
        // Regular expression to match any character that is not a letter (a-z, A-Z)
        const regex = /^[a-zA-Z\s]+$/;
        // Test if the string contains any symbol
        return regex.test(str);
    };
    const handleSubmit = async() =>{
        if (isDemoAccountUsed()) {
          setShowDemoPopup(true);
          return;
        }
        console.log('data being sent', {name, dob, city, isChanged})
        if(isChanged && (dob && name && city)){
            if(containsSymbols(name)&&containsSymbols(city)){
                const body = { name, dob, city };
                await axios.post('https://api.capitalflo.co/api/users/user-details-save',
                    body,
                    {
                        headers: {
                            Authorization: `Bearer ${Tokens}`,
                            "Content-Type": "application/json",
                        },
                        withCredentials: true,
                    }
                )
                .then((response) => {
                    console.log("Data successfully sent to the server:", response.data);
                    setIsChanged(false)
                    // alert('Your user Details Submitted Successfully')
                    toast.success(" User details submitted successfully");

                })
                .catch((error) => {
                    console.error("Error sending data to the server:", error);
                });
            }
            else {
                toast.error('Name and City input fields can only contain alphabets')
            }
        }
        else {
            if(dob && name && city){
                toast.info('User Details has already been saved')

            }
            else {
                toast.error('Input fields empty')
            }

        }

    }
    const calculateAge = (dob) => {
        const dobDate = new Date(dob);
        const today = new Date();
        let age = today.getFullYear() - dobDate.getFullYear();
        const monthDiff = today.getMonth() - dobDate.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dobDate.getDate())) {
            age--;
        }
        if(age<0){
            return 0;
        }
        return age;
    };
    const handleDateChange = (e) => {
        setIsChanged(true)
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        console.log('Valueee', e.target.value)
        const newDob = e.target.value;
        setDob(newDob);
        const newAge = calculateAge(newDob);
        console.log('agee',newAge)
        setAge(newAge);
    };
    const openDatePicker = () => {
        // Simulate a click event on the date input field to open the date picker
        dateInputRef.current.click();
    };
    const closePopup = () => {
        setShowDemoPopup(false);
    }
    return (
        <div style={{

        }} className='outerForm'>
            <Logheader/>
            {showDemoPopup && <DemoBlockerPopup closePopup={closePopup} />}
            <div  className='form'>
                <p style={{
                    marginBottom:'20px',
                    marginTop:'50px',
                    fontSize: '25px',
                    color:'white'
                }}>User Profile</p>
                <div className='inputLines'>
                    <label style={{
                        color:'white'
                    }}>Email / Mob No.*</label>
                    <input
                        type="text"
                        id="email"
                        name="email"
                        value={email}
                        readOnly='true'
                        style={{ height:'5vh', borderRadius:'5px'}}
                    />
                </div>
                <div className='inputLines'>
                    <label style={{marginTop:'10px',color:'white'}}>Name*</label>
                    <input
                        type="integer-input"
                        id="name" name="name"
                        style={{ height:'5vh', borderRadius:'5px'}}
                        value={name}
                        onChange={(e)=>{
                            if (isDemoAccountUsed()) {
                                setShowDemoPopup(true);
                                return;
                            }
                            setName(e.target.value)
                            setIsChanged(true)
                        }}
                    />
                </div>
                <div style={{marginTop:'20px'}}>
                    <label style={{marginTop:'10px',color:'white'}}>Age*</label>
                    <div  className='ageInput'>
                        <input
                            type="integer-input"
                            id="age" name="age"
                            value={age?age:''}
                            onChange={(e)=>{
                                if (isDemoAccountUsed()) {
                                    setShowDemoPopup(true);
                                    return;
                                }
                                setAge(e.target.value)
                                setIsChanged(true)
                            }}
                            readOnly='true'
                            onClick={openDatePicker}
                        />
                        <input
                            type="date"
                            id="date" name="age"
                            ref={dateInputRef}
                            value={dob}
                            onChange={handleDateChange}

                        />
                    </div>
                </div>
                </div>
            <ToastContainer
                position="bottom-right"
                autoClose={1200}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
                <div className='inputLines'>
                    <label style={{marginTop:'10px',color:'white'}}>City*</label>
                    <input
                        type="text"
                        id="city"
                        name="city"
                        style={{ height:'5vh', borderRadius:'5px'}}
                        value={city}
                        onChange={(e)=>{
                            if (isDemoAccountUsed()) {
                                setShowDemoPopup(true);
                                return;
                            }
                            setCity(e.target.value)
                            setIsChanged(true)
                        }}
                    />
                </div>
            <div style={{display:'flex', flexDirection:'column', marginTop:'40px',width:'22vw', marginBottom:'70px'}}>

                <input type="submit" value="submit" style={{backgroundColor:'#49c6ab', color:'white', borderRadius:'35px'}} onClick={handleSubmit}/>

            </div>


        </div>
    )
}
export default UserProfile;