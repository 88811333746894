import axios from "axios";


const {GraphQLClient} = require('graphql-request');


// const graphqlEndpoint = 'https://api-ap-south-1.hygraph.com/v2/clmftadtk0ua001tf0e3r4klo/master';
const graphqlEndpoint = 'https://api-ap-south-1.hygraph.com/v2/clmob5ke82f2401rt98bt6ow3/master'; // Replace with your Hygraph GraphQL endpoint


const graphqlQuery = `
  query MyQuery {
    postsConnection {
      edges {
        node {
          author {
            bio
            id
            name
            photo {
              url
            }
          }
          createdAt
          slug
          title
          excerpt
          featuredImage {
            url
          }
          categories {
            name
            slug
          }
        }
      }
    }
  }
`;

const postDetailsQuery = `
  query GetPostDetails($slug : String!) {
    post(where: {slug: $slug}) {
      title
      excerpt
      featuredImage {
        url
      }
      author {
        name
        bio
        photo {
          url
        }
      }
      createdAt
      slug
      content {
        raw
      }
      categories {
        name
        slug
      }
    }
  }
`;

const commentQuery = ` query GetComments($slug:String!) {
      comments(where: {post: {slug:$slug}}){
        name
        createdAt
        comment
      }
    }`
const getRecentQuery = `
        query GetPostDetails {
            posts(
                orderBy: createdAt_DESC
                last: 3
            ) {
                
                title
                excerpt
                featuredImage {
                    url
                }
                createdAt
                slug
            }
        }
    `;

const getRelatedQuery = `
        query GetPostDetails($slug: String!, $categories: [String!]) {
            posts(
                where: { slug_not: $slug, AND: { categories_some: { slug_in: $categories } } }
                last: 3
            ) {
                title
                featuredImage {
                    url
                }
                createdAt
                slug
            }
        }
    `;

// export const fetchData = async () => {
//     try {
//         const client = new GraphQLClient(graphqlEndpoint);
//         const { postsConnection } = await client.request(graphqlQuery);
//
//
//         // console.log("length:",postsConnection.edges.length )
//
//         console.log("length:", postsConnection.edges.length)
//         if (postsConnection.edges.length > 0) {
//             const slug = postsConnection.edges[1].node.slug;
//             const postDetails = await client.request(postDetailsQuery, { slug });
//
//             console.log("$$$$$$$$$$$$");
//             console.log(postDetails);
//
//             // Return the postDetails
//             return postsConnection;
//         } else {
//             // console.log("length:" )
//             console.log("No posts found.");
//             return null; // Return null or any other appropriate value if no posts are found
//         }
//     } catch (error) {
//         console.error('Error:', error);
//         throw error; // Rethrow the error for handling at a higher level if needed
//     }
// };

// export const fetchData = async () => {
//     try {
//         const client = new GraphQLClient(graphqlEndpoint);
//         const { postsConnection } = await client.request(graphqlQuery);
//
//         console.log("Number of posts:", postsConnection.edges.length);
//
//         if (postsConnection.edges.length > 0) {
//             const slug = postsConnection.edges[0].node.slug; // Get the slug of the first post
//             console.log("Slug of the first post:", slug);
//
//             const postDetails = await client.request(postDetailsQuery, { slug });
//             console.log("Post details:", postDetails);
//
//             return postsConnection;
//         } else {
//             console.log("No posts found.");
//             return postsConnection; // Return postsConnection even if no posts are found
//         }
//     } catch (error) {
//         console.error('Error:', error);
//         throw error;
//     }
// };

export const fetchData = async () => {
    try {
        const response = await axios.get("https://api.capitalflo.co/api/users/blogs");
        const blogs = response.data.blogs;
        console.log("Number of blogs:", blogs.length);

        if (blogs.length > 0) {
            // const firstBlogSlug = blogs[0].slug;
            // console.log("Slug of the first blog:", firstBlogSlug);
            // Fetch details of the first blog using its slug
            // const blogDetailsResponse = await axios.get(`https://api.capitalflo.co/api/users/blogs/${firstBlogSlug}`);
            // const blogDetails = blogDetailsResponse.data;
            // console.log("Details of the first blog:", blogDetails);
            return blogs;
        } else {
            console.log("No blogs found.");
            return blogs;
        }
    } catch (error) {
        console.error("Error:", error);
        throw error;
    }
};


// export const fetchPostDetails = async (slug) => {
//     try {
//         const client = new GraphQLClient(graphqlEndpoint);
//         const postDetails = await client.request(postDetailsQuery, { slug });
//
//         return postDetails;
//     } catch (error) {
//         console.error('Error fetching post details:', error);
//         throw error; // Rethrow the error for handling at a higher level if needed
//     }
// };
export const fetchPostDetails = async (slug) => {
    try {
        const response = await axios.get(`https://api.capitalflo.co/api/users/blogs/${slug}`);
        const blog = response.data.blog;
        console.log("Blog fetched successfully:", blog);

        return blog;
    } catch (error) {
        console.error("Error fetching blog:", error);
        throw error;
    }
};


// export const getComments = async (slug) => {
//     try {
//         const client = new GraphQLClient(graphqlEndpoint);
//         const commentsDetails = await client.request(commentQuery, { slug });
//
//         return commentsDetails;
//     } catch (error) {
//         console.error('Error fetching Comments details:', error);
//         throw error; // Rethrow the error for handling at a higher level if needed
//     }
// }

export const getComments = async (slug) => {
    try {
        const response = await axios.get(`https://api.capitalflo.co/api/users/blogs/${slug}/comments`);
        // const comments = response.data.comments;
        const comments = response.data;
        console.log("Comments fetched successfully:", comments);

        return comments;
    } catch (error) {
        console.error("Error fetching comments:", error);
        throw error;
    }
};
// export const submitComment = async (obj) => {
//     const result = await fetch('https://api.capitalflo.co/api/users/post-comment', {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(obj),
//     });
//     console.log(result.json())
//
//     return result.json();
// };


export const submitComment = async (commentObj) => {
    try {
        const {name, email, comment, slug} = commentObj;

        const response = await axios.post(`https://api.capitalflo.co/api/users/blogs/${slug}/comments`, {
            text: comment,
            email,
            name,
        });

        const commentData = response.data.comment;
        console.log("Comment posted successfully:", commentData);

        return commentData;
    } catch (error) {
        console.error("Error posting comment:", error);
        throw error;
    }
};

// export const getRecentPosts = async () => {
//     try {
//         const client = new GraphQLClient(graphqlEndpoint);
//         const recentDetails = await client.request(getRecentQuery);
//
//         return recentDetails;
//     } catch (error) {
//         console.error('Error fetching Recent details:', error);
//         throw error; // Rethrow the error for handling at a higher level if needed
//     }
// };

export const getRecentPosts = async () => {
    try {
        const response = await axios.get("https://api.capitalflo.co/api/users/blogs/recent");
        const recentPosts = response.data.posts;
        console.log("Recent posts fetched successfully:", recentPosts);

        return recentPosts;
    } catch (error) {
        console.error("Error fetching recent posts:", error);
        throw error;
    }
};

export const getRecommendedPosts = async (category, slug) => {
    try {
        const response = await axios.post("https://api.capitalflo.co/api/users/blog/recommended", {category});
        let recommendedPosts = response.data.blogs;
        if (slug) {
            recommendedPosts = recommendedPosts.filter(post => post.slug !== slug);
        }
        console.log("Recommended posts fetched successfully:", recommendedPosts);
        return recommendedPosts;
    } catch (error) {
        console.error("Error fetching recent posts:", error);
        throw error;
    }
};

// export const getSimilarPosts = async (categories, slug) => {
//     try {
//         const client = new GraphQLClient(graphqlEndpoint);
//         const relatedDetails = await client.request(getRelatedQuery,{ slug });
//
//         return relatedDetails;
//     } catch (error) {
//         console.error('Error fetching Related details:', error);
//         throw error; // Rethrow the error for handling at a higher level if needed
//     }
// };


