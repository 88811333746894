import React, {useEffect, useState} from 'react';
import axios from 'axios'; // Import axios for making API requests
import './feedback.css';
import PostHeader from "../PostHeader"; // Make sure to import your CSS file
import { isDemoAccountUsed } from '../utils/CheckIfDemo';
import DemoBlockerPopup from './DemoBlockerPopup';

function FeedBack() {
    const [feedback, setFeedback] = useState('');
    const [Tokens, setTokens] = useState("");
    const [showDemoPopup, setShowDemoPopup] = useState(false);


    const tokenize = async () => {
        try {
            const response = await axios.get(
                "https://api.capitalflo.co/api/users/protect-user",
                {withCredentials: true}
            );
            console.log(response);

            if (response.data.message === "Cookie not found") {
                setTokens(null);
            } else {
                console.log(response.data.jwtToken);
                setTokens(response.data.jwtToken);
            }
        } catch (err) {
            console.log(err.response.data.message);
            setTokens(null);
        }
    };
    useEffect(() => {
        tokenize();

    }, []);
    const handleSubmit = async () => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        try {
            // Make an API POST request to save feedback
            if (feedback) {
                const response = await axios.post(
                    'https://api.capitalflo.co/api/users/user-feedback-instant',
                    {feedback:feedback, source:'Web'}, {
                        headers: {
                            Authorization: `Bearer ${Tokens}`,
                            "Content-Type": "application/json",
                        },
                        withCredentials: true,
                    }
                );
                alert('Thank you! Your feed back has been submitted, We will get in touch.');
                setFeedback('');
            }
            else {
                alert("Please provide feedback before submitting.");
            }

                // Assuming the API returns a success message
                // if (response.data.message === 'Feedback saved successfully') {
                //     alert('Feedback saved successfully');
                // } else {
                //     alert('Failed to save feedback');
                // }
            }
        catch
            (error)
            {
                console.log('Error saving feedback:', error);
                alert('Error saving feedback');

            }

    };
    const closePopup = () => {
        setShowDemoPopup(false);
    }

    return (
        <div className="feedback-container">
            <PostHeader/>
            {showDemoPopup && <DemoBlockerPopup closePopup={closePopup} />}
            <div className="feedback-form">
                <div style={{paddingBottom: '60px'}}></div>
                <h1>Instant Feedback</h1>
                <textarea
                    className="feedback-textbox"
                    placeholder="Enter your feedback"
                    value={feedback}
                    onChange={(e) => {
                        if (isDemoAccountUsed()) {
                            setShowDemoPopup(true);
                            return;
                        }
                        setFeedback(e.target.value)}}
                ></textarea>
                <button className="submit-feedback-btn" onClick={handleSubmit}>
                    Submit
                </button>
            </div>
        </div>
    );
}

export default FeedBack;
