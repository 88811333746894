// ResponsiveModal.js
import React from 'react';
import './ResponsiveModal.css';

function ResponsiveModal({isOpen, onClose}) {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="Responsivemodal">
                <div className="modal-content">
                    {/* Your modal content goes here */}
                    <div className='haedingRes'>General Support</div>
                    <p>This mobile browser isn't supported for using the software. We recommend using the Capitalflo
                        mobile app</p>
                </div>
                <button className="modal-close-button" onClick={onClose}>
                    Close
                </button>
            </div>
        </div>
    );
}

export default ResponsiveModal;
