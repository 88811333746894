import React, {useEffect, useState} from "react";
import "./setbudget.css"; // Create a separate CSS file for the popup styles
import axios from "axios";

import rent from "./assets/img/rent.png";
import loan from "./assets/img/loan.png";
import Bills from "./assets/img/bill1.png";
import Fuel from "./assets/img/gas-station.png";
import Groceries from "./assets/img/food.png";
import Food from "./assets/img/fastfood.png";
import Shopping from "./assets/img/shop.png";
import Entertainment from "./assets/img/cinema.png";
import Health from "./assets/img/health.png";
import Medicines from "./assets/img/medicine.png";
import PersonalCare from "./assets/img/personalcare.png";
import Education from "./assets/img/education.png";
import Home from "./assets/img/home.png";
import Travel from "./assets/img/travel.png";
import Investment from "./assets/img/economic.png";
import Savings from "./assets/img/savings.png";
import Business from "./assets/img/businesservice.png";
import Cash from "./assets/img/withdrawal.png";
import Transfer from "./assets/img/transfer.png";
import Tax from "./assets/img/tax.png";
import Others from "./assets/img/other.png";
import Cheque from "./assets/img/cheque.png";
import YourCategory from "./assets/img/tag.png"
import {Link} from "react-router-dom";

const Popup = ({onClose, onSave, onUpdateBudget}) => {
    const [budgetAmount, setBudgetAmount] = useState("");
    const [Tokens, setTokens] = useState("");// State to store the budget amount
    const categoryNames = [
        "Groceries",
        "Food & Drinks",
        "Shopping",
        "Entertainment",
        "Rent",
        "Loan/EMI",
        "Bills/Utilities",
        "Fuel/Transport",
        "Health",
        "Medicines",
        "Personal Care",
        "Education",
        "Home",
        "Travel",
        "Investment",
        "Savings/Goals",
        "Business",
        "Cash/Withdraw",
        "Transfer",
        "Tax",
        "Others",
        "Cheque",
        "Your Category"
    ];
    const [categoryAmounts, setCategoryAmounts] = useState({});

    const tokenize = async () => {
        try {
            const response = await axios.get(
                "https://api.capitalflo.co/api/users/protect-user",
                {withCredentials: true}
            );
            console.log(response);

            if (response.data.message === "Cookie not found") {
                setTokens(null);
            } else {
                console.log(response.data.jwtToken);
                setTokens(response.data.jwtToken);
            }
        } catch (err) {
            console.log(err.response.data.message);
            setTokens(null);
        }
    };

    const fetchData = async () => {
        try {
            const response = await axios.get("https://api.capitalflo.co/api/users/userbudget", {
                headers: {
                    Authorization: `Bearer ${Tokens}`,
                },
                withCredentials: true,
            });
            console.log("retreive:", response.data);
            if (response.data.message === "User not found") {
                // If user not found, set default values
                setBudgetAmount("");
                setCategoryAmounts({});
            } else {
                // If user is found, update the state with retrieved data
                // const { totalbudget, categoriesBudget } = response.data;
                setBudgetAmount(response.data.setbudgets.totalbudget || "");
                setCategoryAmounts(response.data.setbudgets.categoriesBudget || {});
            }
        } catch (err) {
            console.log(err)
        }
    };
    useEffect(() => {
        tokenize();
        fetchData();
    }, []);

    const handleNumericInput = (e) => {
        const input = e.target;
        const value = input.value;

        // Allow only numeric values (including decimal point)
        if (!/^\d*\.?\d*$/.test(value)) {
            // If the input is not numeric, prevent the default action
            e.preventDefault();
        }
    };

    const handleInputChange = (e) => {
        setBudgetAmount(e.target.value);
    };

    const handleCategoryChange = (category) => (e) => {
        setCategoryAmounts({
            ...categoryAmounts,
            [category]: e.target.value,
        });
    };

    const handleSaveClick = async () => {
        const dataToSave = {
            totalbudget: budgetAmount,
            categoriesBudget: categoryAmounts,
        };

        try {
            await axios.put(
                "https://api.capitalflo.co/api/users/setbudget",
                dataToSave,
                {
                    headers: {
                        Authorization: `Bearer ${Tokens}`,
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            );


            onSave(dataToSave);
            console.log("Popup should be closing now");
            onUpdateBudget(dataToSave);
            onClose();
            console.log("Popup should be closing now");
        } catch (error) {
            console.error("Error saving data:", error.message);
        }
    };


    // Array of image URLs for each category
    const categoryImages = [
        Groceries,
        Food,
        Shopping,
        Entertainment,
        rent,
        loan,
        Bills,
        Fuel,
        Health,
        Medicines,
        PersonalCare,
        Education,
        Home,
        Travel,
        Investment,
        Savings,
        Business,
        Cash,
        Transfer,
        Tax,
        Others,
        Cheque,
        YourCategory
        // Add more image URLs for each category in the same order
    ];

    // const handleSaveClick = async () => {
    //   console.log("clicking on save button")
    // };
    return (
        <div className="popup">
            <h2>Set Budget</h2>
            <div className="scroll-container">
                <div className="input-container">
                    <label htmlFor="budgetAmount" className="label">
                        Total Budget
                    </label>
                    <input
                        placeholder="Enter amount"
                        type="number"
                        id="budgetAmount"
                        value={budgetAmount}
                        onChange={handleInputChange}

                    />
                </div>
                <div className="category-container">
                    <p>Categories</p>
                    {categoryNames.map((category, index) => (
                        <div className="input-container-cat" key={index}>
                            <label htmlFor={`categoryAmount_${index}`} className="textcat">
                                <img src={categoryImages[index]} alt={category}/>
                                {category}
                            </label>
                            <input
                                placeholder="Enter amount"
                                type="number"
                                id={`categoryAmount_${index}`}
                                value={categoryAmounts[category] || ""}
                                onChange={handleCategoryChange(category)}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <button onClick={onClose} className="buttonclose">Close</button>
            <button onClick={handleSaveClick} className="buttonsave">Save</button>
            <Link to="https://capitalflo.co/budget">
                <h3 style={{textAlign: "center"}} className="bottomText">
                    View all Budgets in Report Page!
                </h3>
            </Link>


        </div>
    );
};

export default Popup;
