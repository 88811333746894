import Logheader from "../../PostHeader";
import goal from "../../assets/img/goal.png";
import financial from '../../assets/financialHealth.png'
import {GrCircleInformation, GrStatusGood} from "react-icons/gr";
import {Link} from "react-router-dom";
import add from "../../assets/img/addd.png";
import React, {useEffect, useState} from "react";
import axios from "axios";
import LoadingAnimation from "../../assets/Loading.svg";
import WelcomePopup from "../WelcomePopup";
import financeImg from '../../assets/financialhealth/8.png'
import {MdOutlineDangerous} from "react-icons/md";
import {CgDanger} from "react-icons/cg";
import {BsArrowLeft} from "react-icons/bs";
import tick from "../../assets/financialhealth/tick.png";

import { isDemoAccountUsed } from '../../utils/CheckIfDemo';
import DemoBlockerPopup from '.././DemoBlockerPopup';
import titleIButton from '../../assets/financialhealth/iButton.png'

function FinancialNetworth () {
    const [assets, setAssets] = useState('')
    const [liabilities, setLiabilities] = useState('')
    const [netWorth, setNetWorth] = useState('')
    const [isInfoVisible, setIsInfoVisible] = useState(false)
    const [isSaved, setisSaved] = useState(true)
    const [hover, setHover] = useState(false);
    const [Tokens, setTokens] = useState("")
    const [loading, setLoading] = useState(true);
    const [showPopup, setShowPopup] = useState(false)
    const [statusCheck, setStatusCheck] = useState()
    const [color, setColor] = useState('')
    const [iconD, setIconD] = useState(false)
    const [icon, setIcon] = useState(false)
    const [iconW, setIconW] = useState(false)
    const [width, setWidth] = useState(false)    
    const [showDemoPopup, setShowDemoPopup] = useState(false);


        const showInfoFinancialHealth = (e) => {
            e.stopPropagation();
            setIsInfoVisible(!isInfoVisible)
        }
    useEffect(() => {
        const closeIButton = (e) => {
            if(e.target.namespaceURI!=='http://www.w3.org/2000/svg'){
                setIsInfoVisible(false)
            }
        }
        document.addEventListener('click', closeIButton)
        return ( )=>document.body.removeEventListener('click', closeIButton)

    }, []);


    const closePopup = () => {
        setShowDemoPopup(false);
    }
    const handleValueChange = (event) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        const expandedValue = expandAbbreviatedNumber(event.target.value);

        if(event.target.name === 'assets'){
            setAssets(expandedValue)
        }
        else if (event.target.name === 'liabilities'){
            setLiabilities(expandedValue)
        }
    }
    const tokenize = async () => {
        try {
            const response = await axios.get(
                "https://api.capitalflo.co/api/users/protect-user",
                {withCredentials: true}
            );
            console.log(response);

            if (response.data.message === "Cookie not found") {
                setTokens(null);
            } else {
                console.log(response.data.jwtToken);
                setTokens(response.data.jwtToken);
            }
        } catch (err) {
            console.log(err.response.data.message);
            setTokens(null);
        }
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                "https://api.capitalflo.co/api/users/financial_health_get",
                {
                    headers: {
                        Authorization: `Bearer ${Tokens}`,
                    },
                    withCredentials: true,
                }
            );
            console.log("************")
            console.log("retreive:", response.data);
            console.log("************")
            if (response.data.message === "User not found") {
                console.log("user not found")
            } else {
                setAssets(response.data.total_lifetime_position_details?response.data.total_lifetime_position_details.total_assets:'')
                setLiabilities(response.data.total_lifetime_position_details?response.data.total_lifetime_position_details.total_liabilities:'')
                setNetWorth(response.data.total_lifetime_position_details?response.data.total_lifetime_position_details.networth:'')
            }
            setLoading(false);
        } catch (err) {
            // setBudget(null);
            // setBudgetVariable(null);
            setLoading(false);
            console.log(err);
        }
    };
    const handleSave = async() => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        let data = {
            total_assets:assets,
            total_liabilities: liabilities,
            networth: netWorth,
        }
        console.log('data sent is ', data)
        await axios
            .post('https://api.capitalflo.co/api/users/financial_health',
                {
                    type:'totalLifetimePosition',
                    details: data},
                {
                    headers: {
                        Authorization: `Bearer ${Tokens}`,
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            ).then((response) => {
                console.log(
                    "Total Expense successfully sent to the server:",
                    response.data
                );
                setShowPopup(true)
            }).catch((error) => {
                console.error("Error sending data to the server:", error);
            });
    }
    useEffect(() => {
        tokenize();
        fetchData();

    }, []);
    useEffect(() => {
        const calcMonthlyExpenses = () => {
            const Assets = parseFloat(assets);
            const Liabilities = parseFloat(liabilities);
            if (!isNaN(Assets) && !isNaN(Liabilities) && Assets !==  0 && Liabilities !== 0) {
                setNetWorth((Assets - Liabilities).toFixed(2));
            } else {
                setNetWorth('');
            }
        };
        calcMonthlyExpenses();
    }, [assets, liabilities]);
    const formatNumber = (number) => {
        const parts = number.toString().split(".");
        if (parts.length === 2) {
            // If there's a decimal part, limit it to two decimal places
            return parts[0] + "." + parts[1].slice(0, 2);
        } else {
            // If there's no decimal part, just return the original number
            return number.toString();
        }
    };
    useEffect(() => {
        if(netWorth < 0)
            {
                setColor('#f5ad05')
                setStatusCheck('Requires your Attention')
                setIconW(true)
                setIcon(false)
                setIconD(false)
                setWidth('290px')
            }
            else if (netWorth > 0){
            setColor('green')
            setStatusCheck('All Good')
            setIcon(true)
            setIconW(false)
            setIconD(false)
            setWidth('130px')
        }
    }, [netWorth]);

    const expandAbbreviatedNumber = (abbreviated) => {
        if (typeof abbreviated !== 'string') {
            return '';
        }

        const regex = /^([-+]?[0-9]*\.?[0-9]+)([KkLlCcRr]?)$/;
        const match = abbreviated.trim().match(regex);

        if (!match) {
            return '';
        }

        const number = parseFloat(match[1]);
        const suffix = match[2].toUpperCase();

        switch (suffix) {
            case 'K':
                return number * 1000;
            case 'L':
                return number * 100000;
            case 'C':
            case 'CR':
                return number * 10000000;
            default:
                return number;
        }
    };

    const abbreviateNumber = (number) => {
        if (isNaN(number) || !isFinite(number) || number === 0) {
            return '0'; // Handle NaN or Infinity case, return a default value
        }
        const sign = Math.sign(number);
        const absoluteNumber = Math.abs(number);

        const digits = absoluteNumber.toString().length;

        if (digits > 3 && digits <= 5) {
            // Abbreviate thousands
            const result = (sign * absoluteNumber / 1000);
            return result % 1 !== 0 ? formatNumber(result) + 'K' : result.toFixed(0) + 'K';
        } else if (digits >= 6 && digits <= 7) {
            // Abbreviate lakhs
            const result = (sign * absoluteNumber / 100000);
            return result % 1 !== 0 ? formatNumber(result) + 'L' : result.toFixed(0) + 'L';
        } else if (digits >= 8) {
            // Abbreviate crores
            const result = (sign * absoluteNumber / 10000000);
            return result % 1 !== 0 ? formatNumber(result) + 'Cr' : result.toFixed(0) + 'Cr';
        } else {
            // Default to the original number for smaller values
            return (sign * absoluteNumber).toFixed(0).trim();
        }
    };
    const handleCancel = () => {
        setShowPopup(false)
    }
    if (loading) {
        return <div className='blog_loading_animation'><img src={LoadingAnimation} alt=""/></div>
    }
    return (
        <div style={{
            width: '100vw',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
        }
        }>

            <Logheader/>
            <div className="upperFh">
                <div className='FHArrow'>
                    <div className="financialHArrow">
                        <p>
                            <Link to={`https://capitalflo.co/financial-health`}>
                                <BsArrowLeft className="arraowsFh"/>
                            </Link>
                        </p>
                    </div>
                </div>
                <img src={financeImg} alt=""/>
                <p>Total Lifetime Position or Net Worth</p>
                <span onClick={showInfoFinancialHealth} >
                              <img src={titleIButton} alt="i" style={{width:'25px', height:'25px'}} />
                </span>
                <div
                    className={isInfoVisible? "info-popupFH visible" : "info-popupFH"}>
                    <p style={{lineHeight:'20px'}}>Snapshot of your entire financial life at one moment in time, representing your financial health.
                    </p>
                </div>
            </div>
            <div style={{marginTop:'80px', paddingBottom:'40px'}}>
                <form className='financial-form' >
                    <div style={{marginBottom:'30px', display:'flex', flexDirection:'column', gap:'30px'}}>
                    <label className="labelFH" style={{ fontSize:'24px',fontStyle:'italic'}}></label>
                        <label style={{ fontSize:'24px', fontWeight:'800', fontStyle:'italic'}}>Formula = Total Assets - Total Liabilities
                        </label>
                    </div>
                    <div className='emergencyFhFlex'>
                        <label className='annualFhLeft'>Total Assets </label>
                        <input className='emergencyFhRight' name="assets" onChange={handleValueChange} value={isSaved===false?assets:abbreviateNumber(assets)} onFocus={() => {
                            console.log('changing is saved value to false')
                            setisSaved(false);
                        }}
                               onBlur={() => {
                                   console.log('changing is saved value to true')
                                   setisSaved(true);
                               }}/>
                    </div>
                    {showDemoPopup && <DemoBlockerPopup closePopup={closePopup} />}

                    <div className='emergencyFhFlex'>
                        <label className='annualFhLeft'>Total Liabilities</label>
                        <input className='emergencyFhRight' name="liabilities" onChange={handleValueChange} value={isSaved===false?liabilities:abbreviateNumber(liabilities)} onFocus={() => {
                            console.log('changing is saved value to false')
                            setisSaved(false);
                        }}
                               onBlur={() => {
                                   console.log('changing is saved value to true')
                                   setisSaved(true);
                               }}/>
                    </div>
                    <div className='emergencyFhFlex'>
                        <label className='annualFhLeft' style={{fontWeight:'800'}}>Total Lifetime Position/Net Worth</label>
                        <input className='emergencyFhRight' value={isSaved===false?netWorth:abbreviateNumber(netWorth)} onFocus={() => {
                            console.log('changing is saved value to false')
                            setisSaved(false);
                        }}
                               onBlur={() => {
                                   console.log('changing is saved value to true')
                                   setisSaved(true);
                               }}
                               style={{fontWeight:'600'}}
                               readOnly/>
                    </div>
                    <div className='feedbackFh'>
                        <label className='feedbackFhLeft' style={{fontWeight:'800'}}>Feedback</label>
                        <div className='resultFH'>
                            {icon && (
                                <img src={tick} alt='tick' style={{
                                    height:'25px',
                                    width:'25px',
                                    fill:'green',
                                    fontWeight: '800',
                                    animation: 'scaleAnimation 0.5s ease-in-out',
                                    objectFit:'contain'
                                }}/>
                            )}
                            {iconD && (
                                <MdOutlineDangerous
                                    style={{
                                        fontSize: '25px',
                                        color: color,
                                        fontWeight: '800',
                                        animation: 'scaleAnimation 0.5s ease-in-out'
                                    }}
                                />
                            )}
                            {iconW && (
                                <CgDanger
                                    style={{
                                        fontSize: '25px',
                                        color: color,
                                        fontWeight: '800',
                                        animation: 'scaleAnimation 0.5s ease-in-out'
                                    }}
                                />
                            )}
                            <textarea className='feedbackFhRight'  style={{color:"#424242", width:width,paddingLeft: '10px',}} value={statusCheck} readOnly={true}/>
                        </div>
                    </div>
                    <div>
                        <button style={{backgroundColor: hover ? '#005bb5' : '#004aad',
                            color: 'white',
                            border: 'none',
                            padding: '12px 20px',
                            fontSize: '22px',
                            cursor: 'pointer',
                            textAlign: 'center',
                            width: '100%',
                            borderRadius: '5px',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                            transition: 'background-color 0.3s ease, transform 0.3s ease',
                            transform: hover ? 'scale(1.02)' : 'scale(1)',
                        }}
                                onMouseOver={() => setHover(true)}
                                onMouseOut={() => setHover(false)}
                                type={'button'}
                                onClick={handleSave}
                        >Save </button>
                    </div>
                    {showPopup&&<WelcomePopup
                        message={"Details Submitted Successfully"}
                        onCancel={handleCancel}
                    />}
                </form>
            </div>
        </div>
    )
}
export default FinancialNetworth;