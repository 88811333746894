import React, { useEffect, useState } from 'react';
import moment from 'moment';
import './PostDetails.css';
import { useParams } from 'react-router-dom';
import CommentsForm from './CommentForm';
import Comments from './Comments';
import Header from '../../PreHeader';
import { Usedrop } from '../../ReleaseContext';
import { fetchPostDetails } from '../query';
import RecommendedPosts from "./RecommendedPosts";
import LoadingAnimation from "../../assets/Loading.svg";
import { Helmet } from "react-helmet";


const PostDetail = ({ post }) => {
    const { slug } = useParams();
    const [showScrollToTop, setShowScrollToTop] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [postDetails, setPostDetails] = useState(null);


    useEffect(() => {
        async function fetchDetails() {
            try {
                const details = await fetchPostDetails(slug);
                setPostDetails(details);
            } catch (error) {
                console.error('Error fetching post details:', error);
            }
        }

        fetchDetails();
    }, [slug]);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    // useEffect(() => {
    //     if (window.location.href.includes('#comments')) {
    //         setTimeout(() => {
    //             if (postDetails && postDetails.comments) {
    //                 const commentsElement = document.getElementById('comments');
    //                 const offset = commentsElement.offsetTop - (window.innerHeight * 0.15);
    //                 window.scrollTo({ top: offset, behavior: 'smooth' });
    //             }
    //         }, 500);
    //     }
    // }, [postDetails]);


    useEffect(() => {
        function handleScroll() {
            const scrollThreshold = 200;
            const shouldShow = window.scrollY > scrollThreshold;
            setShowScrollToTop(shouldShow);
        }

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    // const getContentFragment = (index, text, obj, type) => {
    //     let modifiedText = text; // Define modifiedText here
    //
    //     if (obj) {
    //         if (obj.bold) {
    //             modifiedText = <b key={index}>{text}</b>;
    //         }
    //
    //         if (obj.italic) {
    //             modifiedText = <em key={index}>{text}</em>;
    //         }
    //
    //         if (obj.underline) {
    //             modifiedText = <u key={index}>{text}</u>;
    //         }
    //     }
    //
    //     switch (type) {
    //         // Rest of the code...
    //     }
    //
    //     return modifiedText;
    // };

    function renderContent(contentObject) {
        // const style = contentObject.style || {};
        const style = contentObject.style ? { fontWeight: contentObject.style } : {};
        switch (contentObject.type) {
            case 'heading-one':
                if (contentObject.value.hyperlink) {
                    return (
                        <a
                            key={contentObject._id}
                            href={contentObject.value.hyperlink}
                            style={style}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <h1>{contentObject.value.text}</h1>
                        </a>
                    );
                } else {
                    return <h1 key={contentObject._id} style={style}>{contentObject.value.text}</h1>;
                }
            case 'heading-two':
                if (contentObject.value.hyperlink) {
                    return (
                        <a
                            key={contentObject._id}
                            href={contentObject.value.hyperlink}
                            style={style}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <h2>{contentObject.value.text}</h2>
                        </a>
                    );
                } else {
                    return <h2 key={contentObject._id} style={style}>{contentObject.value.text}</h2>;
                }
            case 'heading-three':
                if (contentObject.value.hyperlink) {
                    return (
                        <a
                            key={contentObject._id}
                            href={contentObject.value.hyperlink}
                            style={style}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <h3>{contentObject.value.text}</h3>
                        </a>
                    );
                } else {
                    return <h3 key={contentObject._id} style={style}>{contentObject.value.text}</h3>;
                }
            case 'heading-four':
                if (contentObject.value.hyperlink) {
                    return (
                        <a
                            key={contentObject._id}
                            href={contentObject.value.hyperlink}
                            style={style}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <h4>{contentObject.value.text}</h4>
                        </a>
                    );
                } else {
                    return <h4 key={contentObject._id} style={style}>{contentObject.value.text}</h4>;
                }
            case 'paragraph':
                if (contentObject.value.hyperlink) {
                    return (
                        <a
                            key={contentObject._id}
                            href={contentObject.value.hyperlink}
                            // style={style}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {formatText(contentObject.value.text)}
                        </a>
                    );
                } else {
                    return (
                        <p key={contentObject._id} style={style}>
                            {formatText(contentObject.value.text)}
                        </p>
                    );
                }
            case 'image':
                if (contentObject.value.hyperlink) {
                    return (
                        <a
                            key={contentObject._id}
                            href={contentObject.value.hyperlink}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={contentObject.value.url} alt="" style={style} />
                        </a>
                    );
                } else {
                    return <img key={contentObject._id} src={contentObject.value.url} alt="" style={style} />;
                }
            case 'video':
                console.log(contentObject.value.url)
                console.log('***********************************')
                return (
                    <div class="video-container">
                        <iframe
                            // width="560"
                            // height="315"
                            key={contentObject._id}
                            src={encodeURI(contentObject.value.url)}
                            frameBorder="0"
                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
                            allowFullScreen
                        ></iframe>
                    </div>
                )
            case 'link':
                return (
                    <a
                        key={contentObject._id}
                        href={contentObject.value.hyperlink}
                        style={style}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {contentObject.value.text}
                    </a>
                );

            // Add more cases for other content types if needed
            default:
                return null;
        }
    }

    function formatText(text) {
        const strongRegex = /\*\*(.*?)\*\*/g;
        const emRegex = /``(.*?)``/g;
        const hyperlinkRegex = /@@(.*?)@@/g;

        return text.split(strongRegex).map((part, index) => {
            if (index % 2 === 0) {
                if (typeof part === 'string') {
                    return part.split(emRegex).map((subpart, subindex) => {
                        if (subindex % 2 === 0) {
                            if (typeof subpart === 'string') {
                                return subpart.split(hyperlinkRegex).map((subsubpart, subsubindex) => {
                                    if (subsubindex % 2 === 0) {
                                        return subsubpart;
                                    } else {
                                        const hyperlinkPart = subsubpart.replace(/@@/g, '').split('::');
                                        return <a key={subsubindex} href={hyperlinkPart[1]}>{hyperlinkPart[0]}</a>;
                                    }
                                });
                            } else {
                                return <em key={subindex}>{subpart}</em>;
                            }
                        } else {
                            return <em key={subindex}>{subpart}</em>;
                        }
                    });
                } else {
                    return <strong key={index}>{part}</strong>;
                }
            } else {
                return <strong key={index}>{part}</strong>;
            }
        });
    }
    if (!postDetails) {
        return <div className='blog_loading_animation'><img src={LoadingAnimation} alt="" /></div>
    }


    return (
        <>
            <div className="post-detail">
                <Helmet>
                    <title>{postDetails.title}</title>
                    <meta name="description"
                        content={postDetails.excerpt} />
                    <script async src="https://www.googletagmanager.com/gtag/js?id=G-HN9W8ZF9K8"></script>
                    <script>
                        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-HN9W8ZF9K8');
        `}
                    </script>
                </Helmet>
                <Header />
                <div className="post-content">
                    <input
                        type="text"
                        placeholder="Search posts"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="searchBarEach"
                    />
                    <div className="blog-content">
                        <h1 className="post-title">{postDetails.title}</h1>
                        <div className="post-meta">
                            {/* Author information */}
                            {/*<div className="post-author">*/}
                            {/*    <img*/}
                            {/*        alt={postDetails.author.name}*/}
                            {/*        height="30px"*/}
                            {/*        width="30px"*/}
                            {/*        className="author-avatar"*/}
                            {/*        src={postDetails.author.photo.url}*/}
                            {/*    />*/}
                            {/*    <p className="author-name">{postDetails.author.name}</p>*/}
                            {/*</div>*/}

                            {/* Post comments */}
                            {postDetails.comments && (
                                <div className="post-comments" onClick={() => {
                                    // const commentsElement = document.getElementById('comments');
                                    // const offset = commentsElement.offsetTop - (window.innerHeight * 0.15);
                                    // window.scrollTo({ top: offset, behavior: 'smooth' });
                                }}>
                                    {/* Replace with the actual comment count */}
                                    <div className="category-block">
                                        <div style={{
                                            background: '#004aad',
                                            color: 'white',
                                            paddingRight: '5px',
                                            paddingLeft: '5px',
                                            marginRight: '5px'
                                        }}>
                                            {postDetails.comments.length}
                                        </div>
                                        Comments
                                    </div>
                                </div>
                            )}

                            <div className="post-date">
                                <svg xmlns="http://www.w3.org/2000/svg" className="date-icon" viewBox="0 0 24 24"
                                    stroke="#004aad">
                                    {/* SVG path for the date icon */}
                                </svg>
                                <span
                                    className="date-text">{moment(postDetails.createdAt).format('MMM DD, YYYY')}</span>
                            </div>

                            <div className="category-block">
                                Category:
                                <div style={{
                                    color: '#004aad',
                                    paddingLeft: '5px',
                                    cursor: 'pointer'
                                }}>{postDetails.categories[0].name}</div>
                            </div>
                        </div>

                        <div className="post-image-container">
                            <img src={postDetails.featuredImage.url} alt="" className="postdetail-image" />
                        </div>

                        {/* Render blog content */}
                        {postDetails.content.map(renderContent)}

                    </div>
                    <RecommendedPosts category={postDetails.categories[0]} slug={slug} />
                    {/* <Comments slug={slug} /> */}
                    <CommentsForm slug={slug} />

                    {showScrollToTop && (
                        <button className="scroll-to-top" onClick={handleScrollToTop}>
                            Scroll to Top
                        </button>
                    )}
                </div>
            </div>
        </>
    );
};

export default PostDetail;
