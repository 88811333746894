import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter } from 'react-router-dom';


const clientid = '161251203517-jo9vt6geo4sjmpf7o61et7qb55sl99uu.apps.googleusercontent.com'
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        {/* <BrowserRouter> */}
        <GoogleOAuthProvider clientId={clientid}>
            <BrowserRouter basename="/" hashType="slash">
                <App />
            </BrowserRouter>
        </GoogleOAuthProvider>
        {/* </BrowserRouter> */}
    </React.StrictMode>
);
