import Logheader from "../../PostHeader";
import goal from "../../assets/img/goal.png";
import financial from '../../assets/financialHealth.png'
import {GrCircleInformation, GrStatusGood} from "react-icons/gr";
import {Link} from "react-router-dom";
import add from "../../assets/img/addd.png";
import React, {useEffect, useState} from "react";
import {BsArrowLeft, BsArrowRight} from "react-icons/bs";
import LoadingAnimation from "../../assets/Loading.svg";
import axios from "axios";
import WelcomePopup from "../WelcomePopup";
import liquid from '../../assets/financialhealth/2.png'
import {MdOutlineDangerous} from "react-icons/md";
import {CgDanger} from "react-icons/cg";
import tick from "../../assets/financialhealth/tick.png";
import { isDemoAccountUsed } from '../../utils/CheckIfDemo';
import DemoBlockerPopup from '.././DemoBlockerPopup';
import titleIButton from '../../assets/financialhealth/iButton.png'


function LiquidAssets () {
    const [liquidAssets, setLiquidAssets] = useState('')
    const [totalAssets, setTotalAssets] = useState('');
    const [totalLiabilities, setTotalLiabilities] = useState('');
    const [netWorth, setNetWorth] = useState('');
    const [LiquidityRatio, setLiquidityRatio] = useState('');
    const [isSaved, setisSaved] = useState(true)
    const [isInfoVisible, setIsInfoVisible] = useState(false)
    const [hover, setHover] = useState(false);
    const [Tokens, setTokens] = useState("")
    const [loading, setLoading] = useState(true);
    const [showPopup, setShowPopup] = useState(false)
    const [info , showInfo] = useState(false)
    const [statusCheck, setStatusCheck] = useState()
    const [color, setColor] = useState('')
    const [iconD, setIconD] = useState(false)
    const [icon, setIcon] = useState(false)
    const [iconW, setIconW] = useState(false)
    const [width, setWidth] = useState(false)
    const [showDemoPopup, setShowDemoPopup] = useState(false);
    const [labelInfo , setLabelInfo] = useState('')

    const showInfoFinancialHealth = (e) => {
        e.stopPropagation();
        setIsInfoVisible(!isInfoVisible)
    }
    const showInfoLiquid = () => {
        showInfo(!info)
    }
    const tokenize = async () => {
        try {
            const response = await axios.get(
                "https://api.capitalflo.co/api/users/protect-user",
                {withCredentials: true}
            );
            console.log(response);

            if (response.data.message === "Cookie not found") {
                setTokens(null);
            } else {
                console.log(response.data.jwtToken);
                setTokens(response.data.jwtToken);
            }
        } catch (err) {
            console.log(err.response.data.message);
            setTokens(null);
        }
    };
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                "https://api.capitalflo.co/api/users/financial_health_get",
                {
                    headers: {
                        Authorization: `Bearer ${Tokens}`,
                    },
                    withCredentials: true,
                }
            );
            console.log("************")
            console.log("retreive:", response.data);
            console.log("************")
            if (response.data.message === "User not found") {
                console.log("user not found")
            } else {
                setLiquidAssets(response.data.liquid_assets_to_networth_details?response.data.liquid_assets_to_networth_details.liquid_assets:'')
                setTotalAssets(response.data.liquid_assets_to_networth_details?response.data.liquid_assets_to_networth_details.total_assets:'')
                setTotalLiabilities(response.data.liquid_assets_to_networth_details?response.data.liquid_assets_to_networth_details.total_liabilities:'')
                setNetWorth(response.data.liquid_assets_to_networth_details?response.data.liquid_assets_to_networth_details.net_worth:'')
                setLiquidityRatio(response.data.liquid_assets_to_networth_details?response.data.liquid_assets_to_networth_details.liquid_assets_to_net_worth:'')
            }
            setLoading(false);
        } catch (err) {
            // setBudget(null);
            // setBudgetVariable(null);
            setLoading(false);
            console.log(err);
        }
    };
    useEffect(() => {
        const closeIButton = (e) => {
            if(e.target.namespaceURI!=='http://www.w3.org/2000/svg'){
                setIsInfoVisible(false)
                showInfo(false)
            }
        }
        document.addEventListener('click', closeIButton)
        return ( )=>document.body.removeEventListener('click', closeIButton)

    }, []);
    const handleSave = async() => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        let data = {
            liquid_assets: liquidAssets,
            total_assets:totalAssets,
            total_liabilities:totalLiabilities,
            net_worth: netWorth,
            liquid_assets_to_net_worth: LiquidityRatio
        }
        console.log('data sent is ', data)
        await axios
            .post('https://api.capitalflo.co/api/users/financial_health',
                {
                    type:'liquidToNetworth',
                    details: data},
                {
                    headers: {
                        Authorization: `Bearer ${Tokens}`,
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            ).then((response) => {
                console.log(
                    "Total Expense successfully sent to the server:",
                    response.data
                );
                setShowPopup(true)
            }).catch((error) => {
                console.error("Error sending data to the server:", error);
            });
    }
    useEffect(() => {
        tokenize();
        fetchData();

    }, []);
    const handleValueChange = (event) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        const expandedValue = expandAbbreviatedNumber(event.target.value);
        if(event.target.name === 'liquid'){
            setLiquidAssets(expandedValue)
        }
        else if (event.target.name === 'assets'){
            setTotalAssets(expandedValue)
        }
        else if (event.target.name === 'liabilities'){
            setTotalLiabilities(expandedValue)
        }
    }
    const formatNumber = (number) => {
        const parts = number.toString().split(".");
        if (parts.length === 2) {
            // If there's a decimal part, limit it to two decimal places
            return parts[0] + "." + parts[1].slice(0, 2);
        } else {
            // If there's no decimal part, just return the original number
            return number.toString();
        }
    };
    const expandAbbreviatedNumber = (abbreviated) => {
        if (typeof abbreviated !== 'string') {
            return '';
        }

        const regex = /^([-+]?[0-9]*\.?[0-9]+)([KkLlCcRr]?)$/;
        const match = abbreviated.trim().match(regex);

        if (!match) {
            return '';
        }

        const number = parseFloat(match[1]);
        const suffix = match[2].toUpperCase();

        switch (suffix) {
            case 'K':
                return number * 1000;
            case 'L':
                return number * 100000;
            case 'C':
            case 'CR':
                return number * 10000000;
            default:
                return number;
        }
    };
    const abbreviateNumber = (number) => {
        if (isNaN(number) || !isFinite(number) || number === 0) {
            return '0'; // Handle NaN or Infinity case, return a default value
        }
        const sign = Math.sign(number);
        const absoluteNumber = Math.abs(number);

        const digits = absoluteNumber.toString().length;

        if (digits > 3 && digits <= 5) {
            // Abbreviate thousands
            const result = (sign * absoluteNumber / 1000);
            return result % 1 !== 0 ? formatNumber(result) + 'K' : result.toFixed(0) + 'K';
        } else if (digits >= 6 && digits <= 7) {
            // Abbreviate lakhs
            const result = (sign * absoluteNumber / 100000);
            return result % 1 !== 0 ? formatNumber(result) + 'L' : result.toFixed(0) + 'L';
        } else if (digits >= 8) {
            // Abbreviate crores
            const result = (sign * absoluteNumber / 10000000);
            return result % 1 !== 0 ? formatNumber(result) + 'Cr' : result.toFixed(0) + 'Cr';
        } else {
            // Default to the original number for smaller values
            return (sign * absoluteNumber).toFixed(0).trim();
        }
    };
    useEffect(() => {
        const calculateNetWorth = () => {
            const assets = parseFloat(totalAssets);
            const liability = parseFloat(totalLiabilities);
            if (!isNaN(liability) && !isNaN(assets) && liability !== 0) {
                setNetWorth((assets-liability).toFixed(2));
            } else {
                setNetWorth('');
            }
        };
        calculateNetWorth();
    }, [liquidAssets, totalAssets, totalLiabilities]);
    useEffect(()=>{
        const calculateLiquidity = () => {
            const net = parseFloat(netWorth)
            const liquid = parseFloat(liquidAssets)
            if (!isNaN(net) && !isNaN(liquid) && net !== 0 && liquid !== 0) {
                setLiquidityRatio((liquid *100/ net) .toFixed(2) );
            } else {
                setLiquidityRatio('');
            }
        }
        calculateLiquidity()
    },[netWorth, liquidAssets])

    useEffect(() => {
        if(parseInt(LiquidityRatio )>=15){
            setColor('green')
            setStatusCheck('All Good')
            setIcon(true)
            setIconW(false)
            setIconD(false)
            setWidth('130px')
        }
        else if (parseInt(LiquidityRatio)>=10 && parseInt(LiquidityRatio)<15){
            setColor('#f5ad05')
            setStatusCheck('Requires your Attention')
            setIconW(true)
            setIcon(false)
            setIconD(false)
            setWidth('290px')
        }
        else if(parseInt(LiquidityRatio)<10){
            setColor('#ab0505')
            setStatusCheck('Off Track')
            setIconD(true)
            setIcon(false)
            setIconW(false)
            setWidth('130px')
        }
    }, [LiquidityRatio]);
    const closePopup = () => {
        setShowDemoPopup(false);
    }
    const handleCancel = () => {
        setShowPopup(false)
    }
    if (loading) {
        return <div className='blog_loading_animation'><img src={LoadingAnimation} alt=""/></div>
    }
    return (
        <div style={{
            width: '100vw',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
        }
        }>

            <Logheader/>
            <div className="upperFh">
                <div className='FHArrow'>
                    <div className="financialHArrow">
                        <p>
                            <Link to={`https://capitalflo.co/financial-health`}>
                                <BsArrowLeft className="arraowsFh"/>
                            </Link>
                        </p>
                    </div>
                </div>
                <img src={liquid} alt=""/>
                <p>Liquid assets to net worth</p>
                <span onClick={showInfoFinancialHealth} >
                              <img src={titleIButton} alt="i" style={{width:'25px', height:'25px'}} />
                </span>
                <div
                    className={isInfoVisible? "info-popupFH visible" : "info-popupFH"}>
                    <p style={{lineHeight:'20px'}}>For liquidity & long term emergencies
                    </p>
                </div>
            </div>
            <div style={{marginTop:'80px', paddingBottom:'40px'}}>
                <form className='financial-form' >
                    <div style={{marginBottom:'30px', display:'flex', flexDirection:'column', gap:'2vh'}}>
                        <label style={{ fontSize:'24px', fontWeight:'800', fontStyle:'italic'}}>Formula = Liquid Assets / Net Worth</label>
                        <label style={{ fontSize:'24px', fontWeight:'800', fontStyle:'italic', color:'grey'}}>Net Worth = Total assets - Total liabilities</label>
                    </div>
                    <div className='emergencyFhFlex'>
                        <div className='emergencyFhLeft'>
                            <label >Liquid Assets</label>
                            <span onClick={showInfoLiquid}>
                                <GrCircleInformation style={{fontSize: '19px'}}/>
                            </span>
                            <div
                                className={info? "info-popupLiquid visible" : "info-popupLiquid"}>
                                <p style={{lineHeight:'20px'}}>any asset that can be converted to cash quickly ( 1 week)
                                    Ex. cash in bank, mutual funds, stocks,
                                </p>
                            </div>
                        </div>
                        <input className='emergencyFhRight' name="liquid" onChange={handleValueChange} value={isSaved===false?liquidAssets:abbreviateNumber(liquidAssets)} onFocus={() => {
                            console.log('changing is saved value to false')
                            setisSaved(false);
                        }}
                               onBlur={() => {
                                   console.log('changing is saved value to true')
                                   setisSaved(true);
                               }}/>
                    </div>
                    <div className='emergencyFhFlex'>
                        <label className='emergencyFhLeft'>Total Assets</label>
                        <input className='emergencyFhRight' name="assets" onChange={handleValueChange} value={isSaved===false?totalAssets:abbreviateNumber(totalAssets)} onFocus={() => {
                            console.log('changing is saved value to false')
                            setisSaved(false);
                        }}
                               onBlur={() => {
                                   console.log('changing is saved value to true')
                                   setisSaved(true);
                               }}/>
                    </div>
                    {showDemoPopup && <DemoBlockerPopup closePopup={closePopup} />}

                    <div className='emergencyFhFlex'>
                        <label className='emergencyFhLeft'>Total Liabilities</label>
                        <input className='emergencyFhRight' name="liabilities" onChange={handleValueChange} value={isSaved===false?totalLiabilities:abbreviateNumber(totalLiabilities)} onFocus={() => {
                            console.log('changing is saved value to false')
                            setisSaved(false);
                        }}
                               onBlur={() => {
                                   console.log('changing is saved value to true')
                                   setisSaved(true);
                               }}/>
                    </div>
                    <div className='emergencyFhFlex'>
                        <label className='emergencyFhLeft'>Net Worth</label>
                        <input className='emergencyFhRight' name=""  readOnly={true} value={isSaved===false?netWorth:abbreviateNumber(netWorth)} onFocus={() => {
                            console.log('changing is saved value to false')
                            setisSaved(false);
                        }}
                               onBlur={() => {
                                   console.log('changing is saved value to true')
                                   setisSaved(true);
                               }}/>
                    </div>
                    <div className='emergencyFhFlex'>
                        <label className='emergencyFhLeft' style={{fontWeight:'800'}}>Liquid assets to Net worth Ratio</label>
                        <input className='emergencyFhRight' value={LiquidityRatio?LiquidityRatio + "%":''} style={{fontWeight:'600'}}
                               readOnly/>
                    </div>
                    <div className='emergencyFhFlex'>
                        <label className='emergencyFhLeft' style={{fontWeight:'800', color:'green'}}>Good Amount</label>
                        <input className='emergencyFhRight' style={{border:'none'}} value={"15% or more"} readOnly={true}/>
                    </div>
                    <div className='feedbackFh'>
                        <label className='feedbackFhLeft' style={{fontWeight:'800'}}>Feedback</label>
                        <div className='resultFH'>
                            {icon && (
                                <img src={tick} alt='tick' style={{
                                    height:'25px',
                                    width:'25px',
                                    fill:'green',
                                    fontWeight: '800',
                                    animation: 'scaleAnimation 0.5s ease-in-out',
                                    objectFit:'contain'
                                }}/>
                            )}
                            {iconD && (
                                <MdOutlineDangerous
                                    style={{
                                        fontSize: '25px',
                                        color: color,
                                        fontWeight: '800',
                                        animation: 'scaleAnimation 0.5s ease-in-out'
                                    }}
                                />
                            )}
                            {iconW && (
                                <CgDanger
                                    style={{
                                        fontSize: '25px',
                                        color: color,
                                        fontWeight: '800',
                                        animation: 'scaleAnimation 0.5s ease-in-out'
                                    }}
                                />
                            )}
                            <textarea className='feedbackFhRight'  style={{color:"#424242", width:width,paddingLeft: '10px',}} value={statusCheck} readOnly={true}/>
                        </div>
                    </div>
                    <div>
                        <button style={{backgroundColor: hover ? '#005bb5' : '#004aad',
                            color: 'white',
                            border: 'none',
                            padding: '12px 20px',
                            fontSize: '22px',
                            cursor: 'pointer',
                            textAlign: 'center',
                            width: '100%',
                            borderRadius: '5px',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                            transition: 'background-color 0.3s ease, transform 0.3s ease',
                            transform: hover ? 'scale(1.02)' : 'scale(1)',
                        }}
                                onMouseOver={() => setHover(true)}
                                onMouseOut={() => setHover(false)}
                                onClick={handleSave}
                                type={'button'}
                        >Save </button>
                    </div>
                    {showPopup&&<WelcomePopup
                        message={"Details Submitted Successfully"}
                        onCancel={handleCancel}
                    />}
                </form>
            </div>
        </div>
    )
}
export default LiquidAssets;