import Logheader from "../PostHeader";
import DemoBlockerPopup from "./DemoBlockerPopup";
import goal from "../assets/img/goal.png";
import financial from '../assets/FinancialHealthNew.png'
import {GrCircleInformation} from "react-icons/gr";
import {Link} from "react-router-dom";
import React, {use, useEffect, useState} from "react";
import { BsArrowRight } from "react-icons/bs";
import emergency from '../assets/financialhealth/1.png'
import liquid from '../assets/financialhealth/2.png'
import annual from '../assets/financialhealth/3.png'
import debt from '../assets/financialhealth/4.png'
import anc from '../assets/financialhealth/5.png'
import tfp from '../assets/financialhealth/6.png'
import mnc from '../assets/financialhealth/7.png'
import savings from '../assets/financialhealth/8.png'
import liquidtoAnnual from '../assets/financialhealth/9.png'
import networth from '../assets/financialhealth/10.png'
import health from '../assets/financialhealth/11.png'
import life from '../assets/financialhealth/12.png'
import './FinancialHealth.css'
import axios from "axios";
import LoadingAnimation from "../assets/Loading.svg";
import { CgDanger } from "react-icons/cg";
import { MdOutlineDangerous } from "react-icons/md";
import tick from '../assets/financialhealth/tick.png'
import { Helmet } from "react-helmet";


function FinancialHealth () {
    const [isInfoVisible, setIsInfoVisible] = useState(false)
    const [Tokens, setTokens] = useState("")
    const [loading, setLoading] = useState(true);
    const [allFinancialResults, setAllFinancialResults] = useState({})
    const [finalResults, setFinalResults] = useState({
        emergency: '',
        liquid: '',
        annual: '',
        debt: '',
        anc: '',
        tfp: '',
        mnc: '',
        savings: '',
        liquidtoAnnual: '',
        networth: '',
        health: '',
        life: ''
    })
    const resultFunction =  (data) => {
        console.log('savings val', parseInt(data.emergency.emergency_fund_ratio ))
        let finalObj = {
            emergency: '',
            liquid: '',
            annual: '',
            debt: '',
            anc: '',
            tfp: '',
            mnc: '',
            savings: '',
            liquidtoAnnual: '',
            networth: '',
            health: '',
            life: ''
        }
        if (data.emergency !== 'null') {
            if (parseInt(data.emergency.emergency_fund_ratio) >= 3) {
                finalObj.emergency = 'green';
            } else if (parseInt(data.emergency.emergency_fund_ratio) < 3 && parseInt(data.emergency.emergency_fund_ratio) > 0) {
                finalObj.emergency = 'yellow';
            } else if(parseInt(data.emergency.emergency_fund_ratio) <= 0) {
                finalObj.emergency = 'red'; 
            }
        } 
        if (data.liquid!== 'null') {
            if (parseInt(data.liquid.liquid_assets_to_net_worth) >= 15) {
                finalObj.liquid = 'green';
            } else if (parseInt(data.liquid.liquid_assets_to_net_worth) >= 10 && parseInt(data.liquid.liquid_assets_to_net_worth) < 15) {
                finalObj.liquid = 'yellow';
            } else if(parseInt(data.liquid.liquid_assets_to_net_worth) < 10) {
                finalObj.liquid = 'red';
            }
        }
         if (data.annual!== 'null') {
            if (parseInt(data.annual.annual_debt_service) <=35) {
                finalObj.annual = 'green';
            } else if (parseInt(data.annual.annual_debt_service) >35 && parseInt(data.annual.annual_debt_service) <=45) {
                finalObj.annual = 'yellow';
            } else if(parseInt(data.annual.annual_debt_service) > 45) {
                finalObj.annual = 'red'; 
            }
        }
        if(data.debt!== 'null') {
            if (parseInt(data.debt.debt_to_assets) <= 50) {
                finalObj.debt = 'green';
            } else if (parseInt(data.debt.debt_to_assets) >50 && parseInt(data.debt.debt_to_assets) <=60) {
                finalObj.debt = 'yellow';
            } else if(parseInt(data.debt.debt_to_assets) > 60) {
                finalObj.debt = 'red'; 
            }
        }
        if(data.anc!== 'null') {
            if (parseInt(data.anc.annual_cashflow_position) >= 0) {
                finalObj.anc = 'green';
            } else if (parseInt(data.anc.annual_cashflow_position) <0) {
                finalObj.anc = 'yellow';
            } 
        }
        if(data.mnc!== 'null') {
            if (parseInt(data.mnc.monthly_cashflow_position) >= 0) {
                finalObj.mnc = 'green';
            } else if (parseInt(data.mnc.monthly_cashflow_position) <0) {
                finalObj.mnc = 'yellow';
            } 
        }
        if(data.tfp!== 'null'){
            if (parseInt(data.tfp.networth) >= 0) {
                finalObj.tfp = 'green';
            } else if (parseInt(data.tfp.networth) <0) {
                finalObj.tfp = 'yellow';
            } 
        }
        if(data.savings!== 'null') {
            if (parseInt(data.savings.savings_ratio) >= 10) {
                finalObj.savings = 'green';
            } else if (parseInt(data.savings.savings_ratio) <10 && parseInt(data.savings.savings_ratio) >= 5) {
                finalObj.savings = 'yellow';
            }
            else if(parseInt(data.savings.savings_ratio) < 5) {
                finalObj.savings = 'red'; 
            }
        }
        if(data.liquidtoAnnual!== 'null') {
            if (parseInt(data.liquidtoAnnual.liquid_assets_to_annual_expenses) >= 1) {
                finalObj.liquidtoAnnual = 'green';
            } else if (parseInt(data.liquidtoAnnual.liquid_assets_to_annual_expenses) <1) {
                finalObj.liquidtoAnnual = 'red';
            } 
        }
        if(data.networth!== 'null'){
            if (parseInt(data.networth.networth_to_annual_expense) >= 0) {
                finalObj.networth = 'green';
            } else if (parseInt(data.networth.networth_to_annual_expense) <0) {
                finalObj.networth = 'red';
            }
        }
        if(data.health!== 'null'){
            if(data.health.insurance_yes_or_no === 'yes'){
                finalObj.health = 'green';
            } else if(data.health.insurance_yes_or_no === 'no'){
                finalObj.health = 'yellow';
            }
        }
        if(data.life!== 'null'){
            if(data.life.insurance_yes_or_no === 'yes'){
                finalObj.life = 'green';
            } else if(data.life.insurance_yes_or_no === 'no'){
                finalObj.life = 'yellow';
            }
        }
        setFinalResults(finalObj)
    }
    useEffect(() => {
        console.log('FINALLYYYY',finalResults)
    }, [finalResults]);
    const tokenize = async () => {
        try {
            const response = await axios.get(
                "https://api.capitalflo.co/api/users/protect-user",
                {withCredentials: true}
            );
            console.log(response);

            if (response.data.message === "Cookie not found") {
                setTokens(null);
            } else {
                console.log(response.data.jwtToken);
                setTokens(response.data.jwtToken);
            }
        } catch (err) {
            console.log(err.response.data.message);
            setTokens(null);
        }
    };
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                "https://api.capitalflo.co/api/users/financial_health_get",
                {
                    headers: {
                        Authorization: `Bearer ${Tokens}`,
                    },
                    withCredentials: true,
                }
            );
            console.log("************")
            console.log("retreive:", response.data);
            console.log("************")
            if (response.data.message === "User not found") {
                console.log("user not found")
            } else {
                const allFinancialResults = {
                    emergency: response.data.emergency_fund_details || 'null',
                    liquid: response.data.liquid_assets_to_networth_details || 'null',
                    annual: response.data.annual_debt_service_details || 'null',
                    debt: response.data.debt_to_assets_ratio_details || 'null',
                    anc: response.data.annual_cashflow_position_details || 'null',
                    tfp: response.data.total_lifetime_position_details || 'null',
                    mnc: response.data.monthly_cashflow_position_details || 'null',
                    savings: response.data.savings_details || 'null',
                    liquidtoAnnual: response.data.liquid_assets_to_annual_expenses_details || 'null',
                    networth: response.data.networth_to_annual_expenses_details || 'null',
                    health: response.data.health_insurance_details || 'null',
                    life: response.data.life_insurance_details || 'null'
                };
                
                setAllFinancialResults(allFinancialResults);
                resultFunction(allFinancialResults)
                console.log(response.data)
                 }
            setLoading(false);
        } catch (err) {
            // setBudget(null);
            // setBudgetVariable(null);
            setLoading(false);
            console.log(err);
        }
    };
    useEffect(() => {
        console.log('DATA RECEIVED',allFinancialResults)

    },[allFinancialResults]);
    useEffect(() => {
        tokenize();
        fetchData();

    }, []);
    const showInfoFinancialHealth = () => {
        setIsInfoVisible(!isInfoVisible)
    }
    useEffect(() => {
        const closeIButton = (e) => {
            if(e.target.namespaceURI!=='http://www.w3.org/2000/svg'){
                setIsInfoVisible(false)
            }
        }
        document.addEventListener('click', closeIButton)
        return ( )=>document.body.removeEventListener('click', closeIButton)

    }, []);
    
    if (loading) {
        return <div className='blog_loading_animation'><img src={LoadingAnimation} alt=""/></div>
    }
    return (
      <div
        style={{
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Logheader />
        <Helmet>
                <title>Financial Health</title>
                <meta name="description"
                    content="check your financial health" />
            </Helmet>
        <div className="upperFh">
          <img src={financial} alt="" />
          <p>Financial Health & Safety</p>
          <span onClick={showInfoFinancialHealth}>
            <GrCircleInformation style={{ fontSize: "24px" }} />
          </span>
          <div
            className={
              isInfoVisible ? "info-popupGoals visible" : "info-popupGoals"
            }
          >
            <p style={{ lineHeight: "20px" }}>
              To understand how to use this feature go to user manual in
              settings or click
            </p>
            {/*<Link to='/products/usermanual#goals' style={{*/}
            {/*    color:'#004AAD',*/}
            {/*    paddingLeft:'5px',*/}
            {/*    fontSize:'18px'*/}
            {/*}}>Financial Health</Link>*/}
          </div>
          {/* <img src={add} alt="" /> */}
        </div>
        <div style={{ marginTop: "60px" }}>
          <form className="financial-form">
            <Link to={`/emergency-fund-ratio`}>
              <div className="outerFHFlex">
                <div className="imgFHContainer">
                  <img src={emergency} alt="" className="imageFH" />
                </div>
                <div className="middleFHContainer">
                  <h3 className="headingFH">Emergency Fund</h3>
                  <label className="labelFH">For short term emergencies</label>
                </div>
                <div className="arrowFHContainer">
                  {finalResults.emergency ? (
                    finalResults.emergency === "green" ? (
                      <img
                        src={tick}
                        alt="tick"
                        className="tickImageFH"

                      />
                    ) : finalResults.emergency === "yellow" ? (
                      <CgDanger
                      className="warningImageFH"

                      />
                    ) : (
                      <MdOutlineDangerous
                      className="dangerImageFH"

                      />
                    )
                  ) : (
                    <BsArrowRight className="arrowFH" />
                  )}
                </div>
              </div>
            </Link>
            <Link to={`/liquid-assets-to-networth`}>
              <div className="outerFHFlex">
                <div className="imgFHContainer">
                  <img src={liquid} alt="" className="imageFH" />
                </div>
                <div className="middleFHContainer">
                  <h3 className="headingFH">Liquid Assets to Net Worth</h3>
                  <label className="labelFH">
                    For liquidity & long term emergencies
                  </label>
                </div>
                <div className="arrowFHContainer">
                {finalResults.liquid ? (
                    finalResults.liquid === "green" ? (
                      <img
                        src={tick}
                        alt="tick"
                        className="tickImageFH"

                      />
                    ) : finalResults.liquid === "yellow" ? (
                      <CgDanger
                      className="warningImageFH"

                      />
                    ) : (
                      <MdOutlineDangerous
                      className="dangerImageFH"

                      />
                    )
                  ) : (
                    <BsArrowRight className="arrowFH" />
                  )}
                </div>
              </div>
            </Link>
            <Link to={`/annual-debt-service`}>
              <div className="outerFHFlex">
                <div className="imgFHContainer">
                  <img src={annual} alt="" className="imageFH" />
                </div>
                <div className="middleFHContainer">
                  <h3 className="headingFH">Annual Debt Service</h3>
                  <label className="labelFH">
                    Ability to repay debts in short term
                  </label>
                </div>
                <div className="arrowFHContainer">
                {finalResults.annual ? (
                    finalResults.annual === "green" ? (
                      <img
                        src={tick}
                        alt="tick"
                        className="tickImageFH"

                      />
                    ) : finalResults.annual === "yellow" ? (
                      <CgDanger
                      className="warningImageFH"

                      />
                    ) : (
                      <MdOutlineDangerous
                      className="dangerImageFH"

                      />
                    )
                  ) : (
                    <BsArrowRight className="arrowFH" />
                  )}
                </div>
              </div>
            </Link>
            <Link to={`/debt-to-assets`}>
              <div className="outerFHFlex">
                <div className="imgFHContainer">
                  <img src={debt} alt="" className="imageFH" />
                </div>
                <div className="middleFHContainer">
                  <h3 className="headingFH">Debt to Assets</h3>
                  <label className="labelFH">
                    Ability to repay debts in long term{" "}
                  </label>
                </div>
                <div className="arrowFHContainer">
                {finalResults.debt ? (
                    finalResults.debt === "green" ? (
                      <img
                        src={tick}
                        alt="tick"
                        className="tickImageFH"

                      />
                    ) : finalResults.debt === "yellow" ? (
                      <CgDanger
                      className="warningImageFH"

                      />
                    ) : (
                      <MdOutlineDangerous
                      className="dangerImageFH"

                      />
                    )
                  ) : (
                    <BsArrowRight className="arrowFH" />
                  )}
                </div>
              </div>
            </Link>
            <Link to={`/annual-cashflow-position`}>
              <div className="outerFHFlex">
                <div className="imgFHContainer">
                  <img src={anc} alt="" className="imageFH" />
                </div>
                <div className="middleFHContainer">
                  <h3 className="headingFH">Annual Cash Flow Position</h3>
                  <label className="labelFH">
                    To know the annual position (positive or negative)
                  </label>
                </div>
                <div className="arrowFHContainer">
                {finalResults.anc ? (
                    finalResults.anc === "green" ? (
                      <img
                        src={tick}
                        alt="tick"
                        className="tickImageFH"

                      />
                    ) : finalResults.anc === "yellow" ? (
                      <CgDanger
                      className="warningImageFH"

                      />
                    ) : (
                      <MdOutlineDangerous
                      className="dangerImageFH"

                      />
                    )
                  ) : (
                    <BsArrowRight className="arrowFH" />
                  )}
                </div>
              </div>
            </Link>
            <Link to={`/financial-networth`}>
              <div className="outerFHFlex">
                <div className="leftMostContainer">
                <div className="imgFHContainer">
                  <img src={tfp} alt="" className="imageFH" />
                </div>
                <div className="longParaFHContainer">
                  <h3 className="headingFH">
                    Total Lifetime Position or Net Worth
                  </h3>
                  <label className="labelFH" >
                    Snapshot of your entire financial life at one moment in
                    time, representing your financial health.
                  </label>
                </div>
                </div>
                
                <div className="arrowFHContainer">
                {finalResults.tfp ? (
                    finalResults.tfp === "green" ? (
                      <img
                        src={tick}
                        alt="tick"
                        className="tickImageFH"

                      />
                    ) : finalResults.tfp === "yellow" ? (
                      <CgDanger
                      className="warningImageFH"

                      />
                    ) : (
                      <MdOutlineDangerous
                      className="dangerImageFH"

                      />
                    )
                  ) : (
                    <BsArrowRight className="arrowFH" />
                  )}
                </div>
              </div>
            </Link>
            <Link to={`/monthly-cashflow-position`}>
              <div className="outerFHFlex">
                <div className="imgFHContainer">
                  <img src={mnc} alt="" className="imageFH" />
                </div>
                <div className="middleFHContainer">
                  <h3 className="headingFH">Monthly Cash Flow Position</h3>
                  <label className="labelFH">
                    To know the monthly position (positive or negative)
                  </label>
                </div>
                <div className="arrowFHContainer">
                {finalResults.mnc ? (
                    finalResults.mnc === "green" ? (
                      <img
                        src={tick}
                        alt="tick"
                        className="tickImageFH"

                      />
                    ) : finalResults.mnc === "yellow" ? (
                      <CgDanger
                      className="warningImageFH"

                      />
                    ) : (
                      <MdOutlineDangerous
                      className="dangerImageFH"

                      />
                    )
                  ) : (
                    <BsArrowRight className="arrowFH" />
                  )}
                </div>
              </div>
            </Link>
            <Link to={`/savings`}>
              <div className="outerFHFlex">
                <div className="imgFHContainer">
                  <img src={savings} alt="" className="imageFH" />
                </div>
                <div className="middleFHContainer">
                  <h3 className="headingFH">Savings</h3>
                  <label className="labelFH">For reaching goals</label>
                </div>
                <div className="arrowFHContainer">
                {finalResults.savings ? (
                    finalResults.savings === "green" ? (
                      <img
                        src={tick}
                        alt="tick"
                        className="tickImageFH"

                      />
                    ) : finalResults.savings === "yellow" ? (
                      <CgDanger
                      className="warningImageFH"

                      />
                    ) : (
                      <MdOutlineDangerous
                      className="dangerImageFH"

                      />
                    )
                  ) : (
                    <BsArrowRight className="arrowFH" />
                  )}
                </div>
              </div>
            </Link>
            <Link to={`/liquid-assets-to-annual-expenses`}>
              <div className="outerFHFlex">
                <div className="imgFHContainer">
                  <img src={liquidtoAnnual} alt="" className="imageFH" />
                </div>
                <div className="middleFHContainer">
                  <h3 className="headingFH">Liquid Assets / Annual Expenses</h3>
                  <label className="labelFH">No of years to take a break</label>
                </div>
                <div className="arrowFHContainer">
                {finalResults.liquidtoAnnual ? (
                    finalResults.liquidtoAnnual === "green" ? (
                      <img
                        src={tick}
                        alt="tick"
                        className="tickImageFH"
                      />
                    ) : finalResults.liquidtoAnnual === "yellow" ? (
                      <CgDanger
                      className="warningImageFH"
                      />
                    ) : (
                      <MdOutlineDangerous
                      className="dangerImageFH"

                      />
                    )
                  ) : (
                    <BsArrowRight className="arrowFH" />
                  )}
                </div>
              </div>
            </Link>
            <Link to={`/networth-to-annual-expenses`}>
              <div className="outerFHFlex">
              <div className="leftMostContainer">
              <div className="imgFHContainer">
                  <img src={networth} alt="" className="imageFH" />
                </div>
                <div className="longParaFHContainer">
                  <h3 className="headingFH">Net Worth / Annual Expenses</h3>
                  <label className="labelFH">
                    No of years to retire without income or take a break
                    (excluding inflation)
                  </label>
                </div>
              </div>
                <div className="arrowFHContainer">
                {finalResults.networth ? (
                    finalResults.networth === "green" ? (
                      <img
                        src={tick}
                        alt="tick"
                        className="tickImageFH"

                      />
                    ) : finalResults.networth === "yellow" ? (
                      <CgDanger
                      className="warningImageFH"

                      />
                    ) : (
                      <MdOutlineDangerous
                      className="dangerImageFH"

                      />
                    )
                  ) : (
                    <BsArrowRight className="arrowFH" />
                  )}
                </div>
              </div>
            </Link>
            <Link to={`/health-insurance`}>
              <div className="outerFHFlex">
                <div className="imgFHContainer">
                  <img src={health} alt="" className="imageFH" />
                </div>
                <div className="middleFHContainer">
                  <h3 className="headingFH">Health Insurance</h3>
                  <label className="labelFH">
                    To safeguard yourself against unexpected health events
                  </label>
                </div>
                <div className="arrowFHContainer">
                {finalResults.health ? (
                    finalResults.health === "green" ? (
                      <img
                        src={tick}
                        alt="tick"
                        className="tickImageFH"

                      />
                    ) : finalResults.health === "yellow" ? (
                      <CgDanger
                      className="warningImageFH"

                      />
                    ) : (
                      <MdOutlineDangerous
                      className="dangerImageFH"

                      />
                    )
                  ) : (
                    <BsArrowRight className="arrowFH" />
                  )}
                </div>
              </div>
            </Link>
            <Link to={`/life-insurance`}>
              <div className="outerFHFlex">
                <div className="imgFHContainer">
                  <img src={life} alt="" className="imageFH" />
                </div>
                <div className="middleFHContainer">
                  <h3 className="headingFH">Life Insurance</h3>
                  <label className="labelFH">
                    To safeguard your family against unexpected events.
                  </label>
                </div>
                <div className="arrowFHContainer">
                {finalResults.life ? (
                    finalResults.life === "green" ? (
                      <img
                        src={tick}
                        alt="tick"
                        className="tickImageFH"

                      />
                    ) : finalResults.life === "yellow" ? (
                      <CgDanger
                      className="warningImageFH"

                      />
                    ) : (
                      <MdOutlineDangerous
                      className="dangerImageFH"

                      />
                    )
                  ) : (
                    <BsArrowRight className="arrowFH" />
                  )}
                </div>
              </div>
            </Link>
          </form>
        </div>
      </div>
    );
}
export default FinancialHealth