import React, { useEffect, useState, useRef } from "react";
import TFP from "../../assets/img/TFP.png";
import add from "../../assets/img/addd.png";
// import goal from "../../assets/img/goal.png";
// import downArrow from "../../assets/img/downArrow.png";
import Logheader from "../../PostHeader.js";
import { GrCircleInformation } from "react-icons/gr";
import axios from "axios";
import LoadingAnimation from "../../assets/Loading.svg";
import "./Tfp.css";
import { Link } from "react-router-dom";
import { isDemoAccountUsed } from "../../utils/CheckIfDemo";
import DemoBlockerPopup from "../DemoBlockerPopup.js";
import { IoIosArrowDropdown } from "react-icons/io";
import { BsArrowRight } from "react-icons/bs";
import { Helmet } from 'react-helmet';


function Tfp() {
    const [SalaryAmount, setSalaryAmount] = useState("");
    const [DividendAmount, setDividendAmount] = useState("");
    const [IntrestAmount, setIntrestAmount] = useState("");
    const [BusinessAmount, setBusinessAmount] = useState("");

    const [EmergencyAmount, setEmergencyAmount] = useState("")
    const [LoanEmiAmount, setLoanEmiAmount] = useState("")
    const [InsuranceAmount, setInsuranceAmount] = useState("")
    const [RentAmount, setRentAmount] = useState("");
    const [BillsAmount, setBillsAmount] = useState("");
    const [ExpenseSavingsAmount, setExpenseSavingsAmount] = useState("")
    const [ExpenseInvestmentAmount, setExpenseInvestmentAmount] = useState("")
    const [TaxAmount, setTaxAmount] = useState("");
    const [VariableAmount, setVariableAmount] = useState("");

    const [SavingAmount, setSavingAmount] = useState("");
    const [MutualFundsAmount, setMutualFundsAmount] = useState("")
    const [StocksAmount, setStocksAmount] = useState("");
    const [BondsAmount, setBondsAmount] = useState("");
    const [VehicleAmount, setVehicleAmount] = useState("");
    const [RealEstateAmount, setRealEstateAmount] = useState("");
    const [InvestmentAmount, setInvestmentAmount] = useState("");

    const [LoanAmount, setLoanAmount] = useState("");
    const [CreditcardAmount, setCreditcardAmount] = useState("");
    const tfpSectionRef =  useRef(null)
    // const [EmergencyAmount, setEmergencyAmount] = useState("");
    // const [VacationAmount, setVacationAmount] = useState("");
    // const [VechicleAmount, setVechicleAmount] = useState("");
    // const [HomeAmount, setHomeAmount] = useState("");
    // const [EducationAmount, setEducationAmount] = useState("");
    //
    //
    // const [TargetEmergencyAmount, setTargetEmergencyAmount] = useState("");
    // const [TargetVacationAmount, setTargetVacationAmount] = useState("");
    // const [TargetVechicleAmount, setTargetVechicleAmount] = useState("");
    // const [TargetHomeAmount, setTargetHomeAmount] = useState("");
    // const [TargetEducationAmount, setTargetEducationAmount] = useState("");

    const [incomerows, setIncomeRows] = useState([]);
    const [expenserows, setExpenseRows] = useState([]);
    const [assetrows, setAssetRows] = useState([]);
    const [liabilitiesrows, setLiabilitiesRows] = useState([]);
    const [addgoalsrows, setAddgoalsRows] = useState([]);

    // const goalsSectionRef = useRef(null);

    const [totalSumIncome, setTotalSumIncome] = useState(0);
    const [totalSumExpenses, setTotalSumExpenses] = useState(0);
    const [totalSumAssets, setTotalSumAssets] = useState(0);
    const [totalSumLaibilities, setTotalSumLaibilities] = useState(0);

    const [deletedRows, setDeletedRows] = useState([]);
    const [isDeleted, setDeletedFlag] = useState(false);

    const [deletedExpenseRows, setDeletedExpenseRows] = useState([]);
    const [isExpenseDeleted, setExpenseDeletedFlag] = useState(false);

    const [deletedAssetRows, setDeletedAssetRows] = useState([]);
    const [isAssetDeleted, setAssetDeletedFlag] = useState(false);

    const [deletedLibilitiesRows, setDeletedLibilitiesRows] = useState([]);
    const [isLibilitiesDeleted, setLibilitiesDeletedFlag] = useState(false);

    // const [deletedGoalsRows, setDeletedGoalsRows] = useState([]);
    // const [isGoalsDeleted, setGoalsDeletedFlag] = useState(false);

    const [isTyping, setIsTyping] = useState(false);
    const [Tokens, setTokens] = useState("");

    const [isInfoVisibleTFP, setisInfoVisibleTFP] = useState(false);
    const [isInfoVisible, setIsInfoVisible] = useState(false);
    const [isInfoVisibleLoans, setIsInfoVisibleLoans] = useState(false);
    const [isInfoVisibleInvestments, setIsInfoVisibleInvestments] = useState(false);
    const [isInfoVisibleVariable, setIsInfoVisibleVariable] = useState(false);
    const [isInfoCashFlow, setisInfoCashFlow] = useState(false)
    const [isInfoNetWorth, setisInfoNetWorth] = useState(false);
    const [loading, setLoading] = useState(true);

    //abbreviation trigger
    const [isSaved, setisSaved] = useState(true);

    const [showDemoPopup, setShowDemoPopup] = useState(false);


    const showInfoNetWorth = () => {
        setisInfoNetWorth(true)
    }
    const hideInfoNetWorth = () => {
        setisInfoNetWorth(false)
    }
    const showInfoTotalFinancialPosition = () => {
        setisInfoVisibleTFP(!isInfoVisibleTFP)
    }
    const hideInfoTotalFinancialPosition = () => {
        setisInfoVisibleTFP(false)
    }
    const showInfo = () => {
        setIsInfoVisible(true);
    };

    const hideInfo = () => {
        setIsInfoVisible(false);
    };


    const showInfoLoans = () => {
        setIsInfoVisibleLoans(true);
    };

    const hideInfoLoans = () => {
        setIsInfoVisibleLoans(false);
    };

    const showInfoInvestments = () => {
        setIsInfoVisibleInvestments(true);
    }
    const hideInfoinvestments = () => {
        setIsInfoVisibleInvestments(false);
    }
    const showInfoVariable = () => {
        setIsInfoVisibleVariable(true);
    }
    const hideInfoVariable = () => {
        setIsInfoVisibleVariable(false);
    }
    const showInfoCashFlow = () => {
        setisInfoCashFlow(true);
    }
    const hideInfoCashFlow = () => {
        setisInfoCashFlow(false);
    }
    const handleInputChange = () => {
        // This function will be called whenever the user types in any input field
        setIsTyping(true); // Set isTyping to true to show the "Save" button
    };

    // function scrollToGoals() {
    //     const goalsHeading = document.getElementById('goals-heading');
    //     if (goalsHeading) {
    //         goalsHeading.scrollIntoView({behavior: 'smooth'});
    //     }
    // }

    const formatNumber = (number) => {
        const parts = number.toString().split(".");
        if (parts.length === 2) {
            // If there's a decimal part, limit it to two decimal places
            return parts[0] + "." + parts[1].slice(0, 2);
        } else {
            // If there's no decimal part, just return the original number
            return number.toString();
        }
    };
    const abbreviateNumber = (number) => {
        if (isNaN(number) || !isFinite(number) || number === 0) {
            return '0'; // Handle NaN or Infinity case, return a default value
        }
        const sign = Math.sign(number);
        const absoluteNumber = Math.abs(number);

        const digits = absoluteNumber.toString().length;

        if (digits > 3 && digits <= 5) {
            // Abbreviate thousands
            const result = (sign * absoluteNumber / 1000);
            return result % 1 !== 0 ? formatNumber(result) + 'K' : result.toFixed(0) + 'K';
        } else if (digits >= 6 && digits <= 7) {
            // Abbreviate lakhs
            const result = (sign * absoluteNumber / 100000);
            return result % 1 !== 0 ? formatNumber(result) + 'L' : result.toFixed(0) + 'L';
        } else if (digits >= 8) {
            // Abbreviate crores
            const result = (sign * absoluteNumber / 10000000);
            return result % 1 !== 0 ? formatNumber(result) + 'Cr' : result.toFixed(0) + 'Cr';
        } else {
            // Default to the original number for smaller values
            return (sign * absoluteNumber).toFixed(0).trim();
        }
    };
    const convertToNumeric = (abbreviatedAmount) => {
        const numericPart = parseInt(abbreviatedAmount, 10);
        const unit = abbreviatedAmount.slice(-1).toUpperCase();

        if (unit === "K") {
            return numericPart * 1000;
        } else if (unit === "L") {
            return numericPart * 100000;
        } else if (unit === "CR") {
            return numericPart * 10000000;
        } else {
            return numericPart;
        }
    };
    const handleSalaryAmountChange = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setSalaryAmount(intValue);
        } else {
            setSalaryAmount("");
        }
        handleInputChange();
    };

    const handleDividendAmountChange = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setDividendAmount(intValue);
        } else {
            setDividendAmount("");
        }
        handleInputChange();
    };

    // const handleIntrestAmountChange = (e) => {
    //     // Ensure that the entered value is a number (integer)
    //     const inputAmount = e.target.value.trim();
    //     const intValue = parseInt(inputAmount, 10);
    //     if (!isNaN(intValue)) {
    //         setIntrestAmount(intValue);
    //     } else {
    //         setIntrestAmount("");
    //     }
    //     handleInputChange();
    // };

    const handleBusinessAmountChange = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setBusinessAmount(intValue);
        } else {
            setBusinessAmount("");
        }
        handleInputChange();
    };
    const handleEmergencyAmountChange = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setEmergencyAmount(intValue);
        } else {
            setEmergencyAmount("");
        }
        handleInputChange();
    }
    const handleLoanEmiAmountChange = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setLoanEmiAmount(intValue);
        } else {
            setLoanEmiAmount("");
        }
        handleInputChange();
    }
    const handleInsuranceAmountChange = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setInsuranceAmount(intValue);
        } else {
            setInsuranceAmount("");
        }
        handleInputChange();
    }
    const handleRentAmountChange = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setRentAmount(intValue);
        } else {
            setRentAmount("");
        }
        handleInputChange();
    };

    const handleBillsAmountChange = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setBillsAmount(intValue);
        } else {
            setBillsAmount("");
        }
        handleInputChange();

    };
    const handleExpenseSavingAmount = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setExpenseSavingsAmount(intValue);
        } else {
            setExpenseSavingsAmount("");
        }
        handleInputChange();
    }
    const handleExpenseInvestmentAmount = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setExpenseInvestmentAmount(intValue);
        } else {
            setExpenseInvestmentAmount("");
        }
        handleInputChange();
    }
    const handleTaxAmountChange = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setTaxAmount(intValue);
        } else {
            setTaxAmount("");
        }
        handleInputChange();

    };

    const handleVariablAmountChange = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setVariableAmount(intValue);
        } else {
            setVariableAmount("");
        }
        handleInputChange();

    };

    const handleAssestsChange = (e, title) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        switch (title) {
            case 'BankSavings': {
                if (!isNaN(intValue)) {
                    setSavingAmount(intValue);
                } else {
                    setSavingAmount("");
                }
                break;
            }
            case 'MutualFunds': {
                if (!isNaN(intValue)) {
                    setMutualFundsAmount(intValue);
                } else {
                    setMutualFundsAmount("");
                }
                break;
            }
            case 'StocksEquity': {
                if (!isNaN(intValue)) {
                    setStocksAmount(intValue);
                } else {
                    setStocksAmount("");
                }
                break;
            }
            case 'BondsFixedIncome': {
                if (!isNaN(intValue)) {
                    setBondsAmount(intValue);
                } else {
                    setBondsAmount("");
                }
                break;
            }
            case 'Vehicle': {
                if (!isNaN(intValue)) {
                    setVehicleAmount(intValue);
                } else {
                    setVehicleAmount("");
                }
                break;
            }
            case 'RealEstate': {
                if (!isNaN(intValue)) {
                    setRealEstateAmount(intValue);
                } else {
                    setRealEstateAmount("");
                }
                break;
            }
            case 'Investments': {
                if (!isNaN(intValue)) {
                    setInvestmentAmount(intValue);
                } else {
                    setInvestmentAmount("");
                }
                break;
            }
            default:
                return;
        }
        handleInputChange();
    }

    const handleLoanAmountChange = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setLoanAmount(intValue);
        } else {
            setLoanAmount("");
        }
        handleInputChange();

    };

    const handleCreditcardAmountChange = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setCreditcardAmount(intValue);
        } else {
            setCreditcardAmount("");
        }
        handleInputChange();

    };


    // const handleEmergencyAmountChange = (e) => {
    //     // Ensure that the entered value is a number (integer)
    //     const inputAmount = e.target.value.trim();
    //     const intValue = parseInt(inputAmount, 10);
    //     if (!isNaN(intValue)) {
    //         setEmergencyAmount(intValue);
    //     } else {
    //         setEmergencyAmount("");
    //     }
    //     handleInputChange();
    //
    // };

    // const handleVacationAmountChange = (e) => {
    //     // Ensure that the entered value is a number (integer)
    //     const inputAmount = e.target.value.trim();
    //     const intValue = parseInt(inputAmount, 10);
    //     if (!isNaN(intValue)) {
    //         setVacationAmount(intValue);
    //     } else {
    //         setVacationAmount("");
    //     }
    //     handleInputChange();
    // };
    //
    // const handleVehicleAmountChange = (e) => {
    //     // Ensure that the entered value is a number (integer)
    //     const inputAmount = e.target.value.trim();
    //     const intValue = parseInt(inputAmount, 10);
    //     if (!isNaN(intValue)) {
    //         setVechicleAmount(intValue);
    //     } else {
    //         setVechicleAmount("");
    //     }
    //     handleInputChange();
    // };
    //
    // const handleHomeAmountChange = (e) => {
    //     // Ensure that the entered value is a number (integer)
    //     const inputAmount = e.target.value.trim();
    //     const intValue = parseInt(inputAmount, 10);
    //     if (!isNaN(intValue)) {
    //         setHomeAmount(intValue);
    //     } else {
    //         setHomeAmount("");
    //     }
    //     handleInputChange();
    //
    // };
    //
    // const handleEducationAmountChange = (e) => {
    //     // Ensure that the entered value is a number (integer)
    //     const inputAmount = e.target.value.trim();
    //     const intValue = parseInt(inputAmount, 10);
    //     if (!isNaN(intValue)) {
    //         setEducationAmount(intValue);
    //     } else {
    //         setEducationAmount("");
    //     }
    //     handleInputChange();
    //
    // };
    //
    // const handleTargetEducationAmountChange = (e) => {
    //     // Ensure that the entered value is a number (integer)
    //     const inputAmount = e.target.value.trim();
    //     const intValue = parseInt(inputAmount, 10);
    //     if (!isNaN(intValue)) {
    //         setTargetEducationAmount(intValue);
    //     } else {
    //         setTargetEducationAmount("");
    //     }
    //     handleInputChange();
    //
    // };
    //
    //
    // const handleTargetEmergencyAmountChange = (e) => {
    //     // Ensure that the entered value is a number (integer)
    //     const inputAmount = e.target.value.trim();
    //     const intValue = parseInt(inputAmount, 10);
    //     if (!isNaN(intValue)) {
    //         setTargetEmergencyAmount(intValue);
    //     } else {
    //         setTargetEmergencyAmount("");
    //     }
    //     handleInputChange();
    //
    // };
    //
    // const handleTargetVacationAmountChange = (e) => {
    //     // Ensure that the entered value is a number (integer)
    //     const inputAmount = e.target.value.trim();
    //     const intValue = parseInt(inputAmount, 10);
    //     if (!isNaN(intValue)) {
    //         setTargetVacationAmount(intValue);
    //     } else {
    //         setTargetVacationAmount("");
    //     }
    //     handleInputChange();
    // };
    //
    // const handleTargetVehicleAmountChange = (e) => {
    //     // Ensure that the entered value is a number (integer)
    //     const inputAmount = e.target.value.trim();
    //     const intValue = parseInt(inputAmount, 10);
    //     if (!isNaN(intValue)) {
    //         setTargetVechicleAmount(intValue);
    //     } else {
    //         setTargetVechicleAmount("");
    //     }
    //     handleInputChange();
    // };
    //
    // const handleTargetHomeAmountChange = (e) => {
    //     // Ensure that the entered value is a number (integer)
    //     const inputAmount = e.target.value.trim();
    //     const intValue = parseInt(inputAmount, 10);
    //     if (!isNaN(intValue)) {
    //         setTargetHomeAmount(intValue);
    //     } else {
    //         setTargetHomeAmount("");
    //     }
    //     handleInputChange();
    //
    // };

    const handleRowAdd = () => {
        const newRow = { title: "", amount: "" };
        setIncomeRows((prevRows) => {
            return [newRow, ...prevRows];
        });
    };

    const handleRowAddExpense = () => {
        const newRow = { title: "", amount: "" };
        setExpenseRows((prevRows) => {
            return [newRow, ...prevRows];
        });
    };

    const handleRowAddAsset = () => {
        const newRow = { title: "", amount: "" };
        setAssetRows((prevRows) => {
            return [newRow, ...prevRows];
        });
    };


    const handleRowAddsetLiabilitiesRows = () => {
        const newRow = { title: "", amount: "" };
        setLiabilitiesRows((prevRows) => {
            return [newRow, ...prevRows];
        });
    };

    // const handleRowAddsetAddgoalsRows = () => {
    //     const newRow = {title: "", amount: "", targetamount: ""};
    //     setAddgoalsRows((prevRows) => {
    //         return [newRow, ...prevRows];
    //     });
    // };


    const handleRowTitleChangeIncome = (index, e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        const updatedRows = [...incomerows];
        updatedRows[index].title = e.target.value;
        setIncomeRows(updatedRows);
    };
    const handleRowAmountChangeIncome = (index, e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        const updatedRows = [...incomerows];
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        updatedRows[index].amount = isNaN(intValue) ? "" : intValue;
        setIncomeRows(updatedRows);
    };

    const handleRowTitleChangeExpense = (index, e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        const updatedRows = [...expenserows];
        updatedRows[index].title = e.target.value;
        setExpenseRows(updatedRows);
    };
    const handleRowAmountChangeExpense = (index, e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        const updatedRows = [...expenserows];
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        updatedRows[index].amount = isNaN(intValue) ? "" : intValue;
        setExpenseRows(updatedRows);
    };

    const handleRowTitleChangeAsset = (index, e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        const updatedRows = [...assetrows];
        updatedRows[index].title = e.target.value;
        setAssetRows(updatedRows);
    };
    const handleRowAmountChangeAsset = (index, e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        const updatedRows = [...assetrows];
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        updatedRows[index].amount = isNaN(intValue) ? "" : intValue;
        setAssetRows(updatedRows);
    };

    const handleRowTitleChangeLiabilities = (index, e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        const updatedRows = [...liabilitiesrows];
        updatedRows[index].title = e.target.value;
        setLiabilitiesRows(updatedRows);
    };
    const handleRowAmountChangeLiabilities = (index, e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        const updatedRows = [...liabilitiesrows];
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        updatedRows[index].amount = isNaN(intValue) ? "" : intValue;
        setLiabilitiesRows(updatedRows);
    };

    // const handleRowTitleChangeAddgoals = (index, e) => {
    //     const updatedRows = [...addgoalsrows];
    //     updatedRows[index].title = e.target.value;
    //     setAddgoalsRows(updatedRows);
    // };
    // const handleRowAmountChangeAddgoals = (index, e) => {
    //     const updatedRows = [...addgoalsrows];
    //     // Ensure that the entered value is a number (integer)
    //     const inputAmount = e.target.value.trim();
    //     const intValue = parseInt(inputAmount, 10);
    //     updatedRows[index].amount = isNaN(intValue) ? "" : intValue;
    //     setAddgoalsRows(updatedRows);
    // };
    // const handleRowTargetAmountChangeAddgoals = (index, e) => {
    //     // Ensure that the entered value is a number (integer)
    //     const inputAmount = e.target.value.trim();
    //     const intValue = parseInt(inputAmount, 10);
    //     const updatedRows = [...addgoalsrows];
    //     updatedRows[index].targetamount = isNaN(intValue) ? "" : intValue; // Use TargetAmount property
    //     setAddgoalsRows(updatedRows);
    //     handleInputChange();
    // };

    const tokenize = async () => {
        try {
            const response = await axios.get(
                "https://api.capitalflo.co/api/users/protect-user",
                {withCredentials: true}
            );
            console.log(response);

            if (response.data.message === "Cookie not found") {
                setTokens(null);
            } else {
                console.log(response.data.jwtToken);
                setTokens(response.data.jwtToken);
            }
        } catch (err) {
            console.log(err.response.data.message);
            setTokens(null);
        }
    };
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                "https://api.capitalflo.co/api/users/total-financial-position",
                {
                    headers: {
                        Authorization: `Bearer ${Tokens}`,
                    },
                    withCredentials: true,
                }
            );
            console.log("retreive:", response.data);
            if (response.data.message === "User not found") {
                console.log("user not found")
            } else {
                console.log("retreive:", response.data);
                setSalaryAmount(response.data.totalFinalPostionIncome.Salary || "");
                setDividendAmount(response.data.totalFinalPostionIncome.Dividend || "");
                setIntrestAmount(response.data.totalFinalPostionIncome.Interest || "");
                setBusinessAmount(response.data.totalFinalPostionIncome.Business || "");
                const userDefinedIncome =
                    response.data.totalFinalPostionIncome.userDefinedIncomeData || [];
                setIncomeRows(userDefinedIncome);
                console.log("userdefi", userDefinedIncome)

                setInsuranceAmount(response.data.totalFinalPostionExpenses.Insurance || "")
                setEmergencyAmount(response.data.totalFinalPostionExpenses.Emergency || "")
                setLoanEmiAmount(response.data.totalFinalPostionExpenses.LoansEmi || "")
                setRentAmount(response.data.totalFinalPostionExpenses.Rent || "");
                setBillsAmount(response.data.totalFinalPostionExpenses.Bills || "");
                setExpenseSavingsAmount(response.data.totalFinalPostionExpenses.Savings || "")
                setExpenseInvestmentAmount(response.data.totalFinalPostionExpenses.Investment || "")
                setTaxAmount(response.data.totalFinalPostionExpenses.Taxes || "");
                setVariableAmount(response.data.totalFinalPostionExpenses.Variable || "");
                const userDefinedExpenses =
                    response.data.totalFinalPostionExpenses.userDefinedExpensesData || [];
                setExpenseRows(userDefinedExpenses);

                setSavingAmount(response.data.totalFinalPostionAssets.Savings || "");
                setMutualFundsAmount(response.data.totalFinalPostionAssets.MutualFunds || "");
                setStocksAmount(response.data.totalFinalPostionAssets.Stocks || "");
                setBondsAmount(response.data.totalFinalPostionAssets.Bonds || "");
                setVehicleAmount(response.data.totalFinalPostionAssets.Vehicle || "");
                setRealEstateAmount(response.data.totalFinalPostionAssets.RealEstate || "");
                setInvestmentAmount(response.data.totalFinalPostionAssets.Investments || "");
                const userDefinedAssets =
                    response.data.totalFinalPostionAssets.userDefinedAssetsData || [];
                setAssetRows(userDefinedAssets);


                setLoanAmount(response.data.totalFinalPostionLabilities.Loans || "");
                setCreditcardAmount(response.data.totalFinalPostionLabilities.Creditcard || "");
                const userDefinedLaibilities =
                    response.data.totalFinalPostionLabilities.userDefinedLaibilitiesData || [];
                setLiabilitiesRows(userDefinedLaibilities);

                setTotalSumIncome(response.data.totalFinalPostionTotal.incomeTotal || "");
                setTotalSumExpenses(response.data.totalFinalPostionTotal.expenseTotal || "");
                setTotalSumAssets(response.data.totalFinalPostionTotal.assetsTotal || "");
                setTotalSumLaibilities(response.data.totalFinalPostionTotal.laibilitiesTotal || "");


                // setEmergencyAmount(response.data.totalFinalPostionGoals.Emergencyfund || "");
                // setVacationAmount(response.data.totalFinalPostionGoals.Vacation || "");
                // setVechicleAmount(response.data.totalFinalPostionGoals.Vehicle || "");
                // setHomeAmount(response.data.totalFinalPostionGoals.Home || "");
                // setEducationAmount(response.data.totalFinalPostionGoals.Education || "");
                // setTargetEmergencyAmount(response.data.totalFinalPostionGoals.TargetEmergencyfund || "");
                // setTargetVacationAmount(response.data.totalFinalPostionGoals.TargetVacation || "");
                // setTargetVechicleAmount(response.data.totalFinalPostionGoals.TargetVehicle || "");
                // setTargetHomeAmount(response.data.totalFinalPostionGoals.TargetHome || "");
                // setTargetEducationAmount(response.data.totalFinalPostionGoals.TargetEducation || "");
                // const userDefinedGoals =
                //     response.data.totalFinalPostionGoals.userDefinedGoalsData || [];
                // setAddgoalsRows(userDefinedGoals);


                // setTotalSumIncome(response.data.totalFinalPostionTotal.incomeTotal || "");
                // setTotalSumExpenses(response.data.totalFinalPostionTotal.expenseTotal || "");
                // setTotalSumAssets(response.data.totalFinalPostionTotal.assetsTotal || "");
                // setTotalSumLaibilities(response.data.totalFinalPostionTotal.laibilitiesTotal || "");

                console.log("TotalIncome:", totalSumIncome)
                console.log("TotalExpense:", totalSumExpenses)
                console.log("TotalAsset:", totalSumAssets)
                console.log("TotalLaibiltites:", totalSumLaibilities)


                // setTotalSum(response.data.totalExpense.fixedVariableTotal || "");

                // setIncomeAmount(response.data.income || "");
            }
            setLoading(false)
        } catch (err) {
            // setBudget(null);
            // setBudgetVariable(null);
            setLoading(false)
            console.log(err);
        }
    };


    useEffect(() => {
        tokenize();
        fetchData();

    }, []);

    const handleSaveDataToDb = async () => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        setisSaved(true)
        setIsTyping(false);
        let total = []
        const income = [
            { title: "Salary", amount: SalaryAmount },
            { title: "Dividend", amount: DividendAmount },
            { title: "Interest", amount: IntrestAmount },
            { title: "Business", amount: BusinessAmount },

            ...incomerows, // Add user-defined fixed expenses here
        ];

        const expenses = [
            { title: "Emergency", amount: EmergencyAmount },
            { title: "LoansEmi", amount: LoanEmiAmount },
            { title: "Insurance", amount: InsuranceAmount },
            { title: "Rent", amount: RentAmount },
            { title: "Bills", amount: BillsAmount },
            { title: "Savings", amount: ExpenseSavingsAmount },
            { title: "Investment", amount: ExpenseInvestmentAmount },
            { title: "Taxes", amount: TaxAmount },
            { title: "Variable", amount: VariableAmount },
            ...expenserows,
        ];

        const assets = [
            { title: "Savings", amount: SavingAmount },
            { title: "MutualFunds", amount: MutualFundsAmount },
            { title: "Stocks", amount: StocksAmount },
            { title: "Bonds", amount: BondsAmount },
            { title: "Vehicle", amount: VehicleAmount },
            { title: "RealEstate", amount: RealEstateAmount },
            { title: "Investments", amount: InvestmentAmount },

            ...assetrows,
        ];

        const laibilities = [
            { title: "Loans", amount: LoanAmount },
            { title: "Creditcard", amount: CreditcardAmount },


            ...liabilitiesrows,
        ];

        // const goals = [
        //     {title: "Emergency fund", amount: EmergencyAmount, targetamount: TargetEmergencyAmount},
        //     {title: "Vacation", amount: VacationAmount, targetamount: TargetVacationAmount},
        //     {title: "Vehicle", amount: VechicleAmount, targetamount: TargetVechicleAmount},
        //     {title: "Home", amount: HomeAmount, targetamount: TargetHomeAmount},
        //     {title: "Education", amount: EducationAmount, targetamount: TargetEducationAmount},
        //     ...addgoalsrows,
        // ]

        const totalIncome = income.reduce(
            (acc, row) =>
                acc + (isNaN(parseInt(row.amount)) ? 0 : parseInt(row.amount)),
            0
        );
        console.log("Total Income:", totalIncome);

        const totalExpense = expenses.reduce(
            (acc, value) =>
                acc + (isNaN(parseInt(value.amount)) ? 0 : parseInt(value.amount)),
            0
        );
        console.log("Total Expense:", totalExpense);

        const totalAssets = assets.reduce(
            (acc, value) =>
                acc + (isNaN(parseInt(value.amount)) ? 0 : parseInt(value.amount)),
            0
        );
        console.log("Total Assets:", totalAssets);

        const totalLiabilities = laibilities.reduce(
            (acc, value) =>
                acc + (isNaN(parseInt(value.amount)) ? 0 : parseInt(value.amount)),
            0
        );
        console.log("Total Liabilities:", totalLiabilities);

        setTotalSumIncome(totalIncome);
        setTotalSumExpenses(totalExpense);
        setTotalSumAssets(totalAssets);
        setTotalSumLaibilities(totalLiabilities);


        // const totalSum = totalSumFixed + totalSumVariable;

        // Update the total sum state with the calculated total sum
        // setTotalSum(totalSum);


        // Combine all data into one object

        const data = {
            Salary: SalaryAmount,
            Dividend: DividendAmount,
            Interest: IntrestAmount,
            Business: BusinessAmount,
            // Save user-defined fixed expenses to the database
            userDefinedIncomeData: incomerows
        };

        // console.log("userDefinedFixedExpenses", fixedRows);

        // const totalData = {
        //   totalexpense: totalSum,
        // };


        const datav = {
            Emergency: EmergencyAmount,
            LoansEmi: LoanEmiAmount,
            Insurance: InsuranceAmount,
            Rent: RentAmount,
            Bills: BillsAmount,
            Savings: ExpenseSavingsAmount,
            Investment: ExpenseInvestmentAmount,
            Taxes: TaxAmount,
            Variable: VariableAmount,
            userDefinedExpensesData: expenserows,
        };
        // console.log(combinedData);


        const dataA = {
            Savings: SavingAmount,
            MutualFunds: MutualFundsAmount,
            Stocks: StocksAmount,
            Bonds: BondsAmount,
            Vehicle: VehicleAmount,
            RealEstate: RealEstateAmount,
            Investments: InvestmentAmount,
            userDefinedAssetsData: assetrows,

        }

        const dataL = {

            Loans: LoanAmount,
            Creditcard: CreditcardAmount,
            userDefinedLaibilitiesData: liabilitiesrows


        }

        // const dataG = {
        //
        //     Emergencyfund: EmergencyAmount,
        //     Vacation: VacationAmount,
        //     Vehicle: VechicleAmount,
        //     Home: HomeAmount,
        //     Education: EducationAmount,
        //     TargetEmergencyfund: TargetEmergencyAmount,
        //     TargetVacation: TargetVacationAmount,
        //     TargetVehicle: TargetVechicleAmount,
        //     TargetHome: TargetHomeAmount,
        //     TargetEducation: TargetEducationAmount,
        //     userDefinedGoalsData: addgoalsrows
        //
        //
        // }

        total = {
            incomeTotal: totalIncome,
            expenseTotal: totalExpense,
            assetsTotal: totalAssets,
            laibilitiesTotal: totalLiabilities
        };
        await axios
            .put(
                "https://api.capitalflo.co/api/users/total-financial-position-income",
                { data },
                {
                    headers: {
                        Authorization: `Bearer ${Tokens}`,
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            )
            .then((response) => {
                console.log("Data successfully sent to the server:", response.data);
            })
            .catch((error) => {
                console.error("Error sending data to the server:", error);
            });

        await axios
            .put(
                "https://api.capitalflo.co/api/users/total-financial-position-expense",
                { datav },
                {
                    headers: {
                        Authorization: `Bearer ${Tokens}`,
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            )
            .then((response) => {
                // Handle the response from the server if needed
                console.log("Data successfully sent to the server:", response.data);
            })
            .catch((error) => {
                // Handle errors if any
                console.error("Error sending data to the server:", error);
            });

        await axios
            .put(
                "https://api.capitalflo.co/api/users/total-financial-position-assest",
                { dataA },
                {
                    headers: {
                        Authorization: `Bearer ${Tokens}`,
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            )
            .then((response) => {
                console.log(
                    "Total Expense successfully sent to the server:",
                    response.data
                );
            })
            .catch((error) => {
                console.error("Error sending data to the server:", error);
            });

        await axios
            .put(
                "https://api.capitalflo.co/api/users/total-financial-position-labilities",
                { dataL },
                {
                    headers: {
                        Authorization: `Bearer ${Tokens}`,
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            )
            .then((response) => {
                console.log(
                    "Total Expense successfully sent to the server:",
                    response.data
                );
            })
            .catch((error) => {
                console.error("Error sending data to the server:", error);
            });

        // await axios
        //     .put(
        //         "https://api.capitalflo.co/api/users/totalfinalpostion-goals",
        //         {dataG},
        //         {
        //             headers: {
        //                 Authorization: `Bearer ${Tokens}`,
        //                 "Content-Type": "application/json",
        //             },
        //             withCredentials: true,
        //         }
        //     )
        //     .then((response) => {
        //         console.log(
        //             "Total Expense successfully sent to the server:",
        //             response.data
        //         );
        //     })
        //     .catch((error) => {
        //         console.error("Error sending data to the server:", error);
        //     });

        await axios
            .put(
                "https://api.capitalflo.co/api/users/total-financial-position-total",
                { total },
                {
                    headers: {
                        Authorization: `Bearer ${Tokens}`,
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            )
            .then((response) => {
                console.log(
                    "Total Expense successfully sent to the server:",
                    response.data
                );
            })
            .catch((error) => {
                console.error("Error sending data to the server:", error);
            });


    };


    const handleRowDeleteIncome = (index) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Show a confirmation dialog before deleting the row
        const confirmDelete = window.confirm("Confirm Delete?");

        if (confirmDelete) {
            // Create a copy of the incomerows state
            const updatedIncomeRows = [...incomerows];

            // Get the amount of the row to be deleted
            const deletedAmount = isNaN(updatedIncomeRows[index].amount)
                ? 0
                : parseInt(updatedIncomeRows[index].amount); // Parse the amount as an integer

            // Remove the row at the specified index
            updatedIncomeRows.splice(index, 1);

            // Update the state with the new rows
            setIncomeRows(updatedIncomeRows);

            // Update totalSumIncome by subtracting the deleted amount
            const updatedTotalSumIncome = isNaN(totalSumIncome)
                ? 0
                : totalSumIncome - deletedAmount; // Subtract the deleted amount
            setTotalSumIncome(updatedTotalSumIncome);

            // Update other states or perform necessary actions if needed

            setIsTyping(false);
            setDeletedFlag(true);
            setDeletedRows(updatedIncomeRows); // Use the updated rows
        }
    };


    useEffect(() => {
        if (isDeleted) {
            handleSaveDataToDb();
            setDeletedFlag(false);
        }
    }, [deletedRows]);

    const handleRowDeleteExpense = (index) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Show a confirmation dialog before deleting the row
        const confirmDelete = window.confirm("Confirm Delete?");

        if (confirmDelete) {
            // Create a copy of the incomerows state
            const updatedExpenseRows = [...expenserows];

            // Get the amount of the row to be deleted
            const deletedAmount = isNaN(updatedExpenseRows[index].amount)
                ? 0
                : parseInt(updatedExpenseRows[index].amount); // Parse the amount as an integer

            // Remove the row at the specified index
            updatedExpenseRows.splice(index, 1);

            // Update the state with the new rows
            setExpenseRows(updatedExpenseRows);

            // Update totalSumIncome by subtracting the deleted amount
            const updatedTotalSumExpense = isNaN(totalSumExpenses)
                ? 0
                : totalSumExpenses - deletedAmount; // Subtract the deleted amount
            setTotalSumExpenses(updatedTotalSumExpense);

            // Update other states or perform necessary actions if needed

            setIsTyping(false);
            setExpenseDeletedFlag(true);
            setDeletedExpenseRows(updatedExpenseRows); // Use the updated rows
        }
    };


    useEffect(() => {
        if (isExpenseDeleted) {
            handleSaveDataToDb();
            setExpenseDeletedFlag(false);
        }
    }, [deletedExpenseRows]);


    const handleRowDeleteAsset = (index) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Show a confirmation dialog before deleting the row
        const confirmDelete = window.confirm("Confirm Delete?");

        if (confirmDelete) {
            // Create a copy of the incomerows state
            const updatedAssetRows = [...assetrows];

            // Get the amount of the row to be deleted
            const deletedAmount = isNaN(updatedAssetRows[index].amount)
                ? 0
                : parseInt(updatedAssetRows[index].amount); // Parse the amount as an integer

            // Remove the row at the specified index
            updatedAssetRows.splice(index, 1);

            // Update the state with the new rows
            setAssetRows(updatedAssetRows);

            // Update totalSumIncome by subtracting the deleted amount
            const updatedTotalSumAsset = isNaN(totalSumAssets)
                ? 0
                : totalSumAssets - deletedAmount; // Subtract the deleted amount
            setTotalSumAssets(updatedTotalSumAsset);

            // Update other states or perform necessary actions if needed

            setIsTyping(false);
            setAssetDeletedFlag(true);
            setDeletedAssetRows(updatedAssetRows); // Use the updated rows
        }
    };


    useEffect(() => {
        if (isAssetDeleted) {
            handleSaveDataToDb();
            setAssetDeletedFlag(false);
        }
    }, [deletedAssetRows]);
    const [coords, setCoords] = useState({ x: 0, y: 0 });


    useEffect(() => {
        const handleWindowMouseMove = event => {
            setCoords({
                x: event.clientX,
                y: event.clientY,
            });
        };
        window.addEventListener('mousemove', handleWindowMouseMove);

        return () => {
            window.removeEventListener(
                'mousemove',
                handleWindowMouseMove,
            );
        };
    }, []);

    function roundToMultipleOf100(number) {
        return Math.round(number / 100) * 100;
    }
    const dynammicHeightSetter = () => {
        const scrollPosition = roundToMultipleOf100(window.pageYOffset + coords.y);
        return scrollPosition;
    }

    const handleRowDeleteLibilities = (index) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Show a confirmation dialog before deleting the row
        const confirmDelete = window.confirm("Confirm Delete?");

        if (confirmDelete) {
            // Create a copy of the incomerows state
            const updatedLibilitiesRows = [...liabilitiesrows];

            // Get the amount of the row to be deleted
            const deletedAmount = isNaN(updatedLibilitiesRows[index].amount)
                ? 0
                : parseInt(updatedLibilitiesRows[index].amount); // Parse the amount as an integer

            // Remove the row at the specified index
            updatedLibilitiesRows.splice(index, 1);

            // Update the state with the new rows
            setLiabilitiesRows(updatedLibilitiesRows);

            // Update totalSumIncome by subtracting the deleted amount
            const updatedTotalSumLibilities = isNaN(totalSumLaibilities)
                ? 0
                : totalSumLaibilities - deletedAmount; // Subtract the deleted amount
            setTotalSumLaibilities(updatedTotalSumLibilities);

            // Update other states or perform necessary actions if needed

            setIsTyping(false);
            setLibilitiesDeletedFlag(true);
            setDeletedLibilitiesRows(updatedLibilitiesRows); // Use the updated rows
        }
    };
    const scrollDown = () => {
        setTimeout(() => {
            tfpSectionRef?.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
        }, 100)    
    };
    useEffect(() => {
        if (isLibilitiesDeleted) {
            handleSaveDataToDb();
            setLibilitiesDeletedFlag(false);
        }
    }, [deletedLibilitiesRows]);
    useEffect(() => {
        const closeIButton = (e) => {
            if (e.target.namespaceURI !== 'http://www.w3.org/2000/svg') {
                setisInfoVisibleTFP(false)
            }
        }
        document.addEventListener('click', closeIButton)
        return () => document.body.removeEventListener('click', closeIButton)

    }, []);
    // const handleRowDeleteGoals = (index) => {
    //     // Show a confirmation dialog before deleting the row
    //     const confirmDelete = window.confirm("Confirm Delete?");
    //
    //     if (confirmDelete) {
    //         // Create a copy of the addgoalsrows state
    //         const updatedGoalsRows = [...addgoalsrows];
    //
    //         // Remove the row at the specified index
    //         updatedGoalsRows.splice(index, 1);
    //
    //         // Update the state with the new rows
    //         setAddgoalsRows(updatedGoalsRows);
    //
    //         // Update other states or perform necessary actions if needed
    //         setIsTyping(false);
    //         setGoalsDeletedFlag(true);
    //         setDeletedGoalsRows(updatedGoalsRows); // Use the updated rows
    //     }
    // };


    if (loading) {
        return <div className='blog_loading_animation'><img src={LoadingAnimation} alt="" /></div>
    }
    const closePopup = () => {
        setShowDemoPopup(false);
    }


    return (
        <div style={{
            width: '100vw',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            // alignContent: 'center',
            alignItems: 'center',
        }
        }>
            {/* <div className="sticky-container">
        <div className="savebackground">
          <div className="savedButton" onClick={handleSaveDataToDb}>Save Data</div>
        </div>
      </div> */}
            <Logheader />
            <Helmet>
                <title>Total financial position</title>
                <meta name="description"
                    content="Plan your financial goals with Total Financial Position" />
            </Helmet>
            {showDemoPopup && <DemoBlockerPopup closePopup={closePopup} />}
            <div className="topie">
                <img src={TFP} alt="" style={{
                    marginBottom: '33px'
                }} />
                <p style={{
                    textAlign: 'center',
                }}>Total Financial Position <br /> & Net Worth</p>
                <span onClick={showInfoTotalFinancialPosition} >
                    <div style={{ fontSize: '24px', paddingBottom: '27px' }}>
                        <GrCircleInformation />
                    </div>
                    <div className={isInfoVisibleTFP ? "info-popupTFP visible" : "info-popupTFP"} >
                        <p style={{ lineHeight: '20px' }}>To understand how to use this feature go to user manual in settings or click
                        </p>
                        <Link to='/products/usermanual#tfp' style={{
                            color: '#004AAD',
                            paddingLeft: '5px',
                            fontSize: '18px'
                        }}>Total Financial Position</Link>
                    </div>
                </span>
                {/* <img src={add} alt="" /> */}
                {/* <a>Goals</a> */}
            </div>


            {isTyping && (<div className="sticky-container">
                <div className="savebackground">
                    <div className="savedButton" onClick={handleSaveDataToDb}>Save Data</div>
                </div>
            </div>)}

            <div className="alltable">
                <div style={{display:'flex', flexDirection:'row',justifyContent:'space-between', width:'85vw'}}>
                    <div className="TFPsubheading">
                        <h4>Annual Cashflow Position</h4>
                    </div>
                    <BsArrowRight style={{fontSize:'33px', fontWeight:'400', marginBottom:'20px',cursor:'pointer',transform:'rotate(90deg)'}} onClick={scrollDown}/>
                </div>
                
                <div className="table-container">
                    <table>
                        <tr>
                            <th>Income
                            </th>
                            <th className="FirstRow">{" "}
                                <span>
                                    <img
                                        src={add}
                                        className="mainFirstRow"
                                        alt=""
                                        onClick={handleRowAdd}
                                    />
                                </span>
                            </th>
                        </tr>
                        {incomerows.map(
                            (
                                row,
                                index // Add slice() and reverse() here
                            ) => (
                                <tr key={index}>
                                    <td style={{ position: "relative" }}>
                                        <span
                                            onClick={() => handleRowDeleteIncome(index)}
                                            className="delete-cross-tfp"
                                        >
                                            &#10006; {/* Unicode character for cross sign */}
                                        </span>
                                        <input
                                            type="text"
                                            className="integer-input"
                                            placeholder="Income type"
                                            value={row.title}
                                            onChange={(e) => {
                                                handleRowTitleChangeIncome(index, e);
                                                //   if (e.target.value.trim() !== "") {
                                                //     row.isTitleEntered = true;
                                                //   } else {
                                                //     row.isTitleEntered = false;
                                                //   }
                                                handleInputChange();

                                            }}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            className="integer-input"
                                            placeholder="Enter amount"
                                            value={isSaved === false ? row.amount : abbreviateNumber(row.amount)}
                                            onChange={(e) => {
                                                handleRowAmountChangeIncome(index, e);
                                                handleInputChange();

                                            }}

                                            onFocus={() => {
                                                console.log('changing is saved value to false')
                                                setisSaved(false);
                                            }}
                                            onBlur={() => {
                                                console.log('changing is saved value to true')
                                                setisSaved(true);
                                            }}

                                        // disabled={!row.isTitleEntered} // Disable if title is not entered
                                        />
                                    </td>
                                </tr>
                            )
                        )}
                        <tr>
                            <td>Salary
                                <span onMouseOver={showInfo} onMouseOut={hideInfo}>
                                    <span>  </span>  <GrCircleInformation />
                                </span>
                                <div className={isInfoVisible ? "info-popupSalary visible" : "info-popupSalary"} style={{
                                    top: dynammicHeightSetter(),
                                }}>
                                    <p>Annual Salary Post Tax</p>
                                </div>
                            </td>
                            <td>
                                <input
                                    type="text"
                                    className="integer-input"
                                    placeholder="Enter amount"
                                    value={isSaved === false ? SalaryAmount : abbreviateNumber(SalaryAmount)}
                                    onChange={handleSalaryAmountChange}
                                    onFocus={() => {
                                        console.log('changing is saved value to false')
                                        setisSaved(false);
                                    }}
                                    onBlur={() => {
                                        console.log('changing is saved value to true')
                                        setisSaved(true);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Dividend/Interest</td>
                            <td>
                                <input
                                    type="text"
                                    className="integer-input"
                                    placeholder="Enter amount"
                                    value={isSaved === false ? DividendAmount : abbreviateNumber(DividendAmount)}
                                    onChange={handleDividendAmountChange}
                                    onFocus={() => {
                                        console.log('changing is saved value to false')
                                        setisSaved(false);
                                    }}
                                    onBlur={() => {
                                        console.log('changing is saved value to true')
                                        setisSaved(true);
                                    }}
                                />
                            </td>
                        </tr>
                        {/*<tr>*/}
                        {/*    <td>Interest</td>*/}
                        {/*    <td>*/}
                        {/*        <input*/}
                        {/*            type="text"*/}
                        {/*            className="integer-input"*/}
                        {/*            placeholder="Enter amount"*/}
                        {/*            value={IntrestAmount}*/}
                        {/*            onChange={handleIntrestAmountChange}*/}
                        {/*        />*/}
                        {/*    </td>*/}
                        {/*</tr>*/}
                        <tr>
                            <td>Business</td>
                            <td>
                                <input
                                    type="text"
                                    className="integer-input"
                                    placeholder="Enter amount"
                                    value={isSaved === false ? BusinessAmount : abbreviateNumber(BusinessAmount)}
                                    onChange={handleBusinessAmountChange}
                                    onFocus={() => {
                                        console.log('changing is saved value to false')
                                        setisSaved(false);
                                    }}
                                    onBlur={() => {
                                        console.log('changing is saved value to true')
                                        setisSaved(true);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="sumTotal">Total</td>
                            <td>{isSaved === false ? totalSumIncome : abbreviateNumber(totalSumIncome)}</td>
                        </tr>
                    </table>
                </div>
                <div className="table-container">
                    <table>
                        <tr>
                            <th>Expenses
                            </th>
                            <th className="FirstRow">{" "}
                                <span>
                                    <img
                                        src={add}
                                        className="mainFirstRow"
                                        alt=""
                                        onClick={handleRowAddExpense}
                                    />
                                </span>
                            </th>
                        </tr>
                        {expenserows.map(
                            (
                                row,
                                index // Add slice() and reverse() here
                            ) => (
                                <tr key={index}>
                                    <td style={{ position: "relative" }}>
                                        <span
                                            onClick={() => handleRowDeleteExpense(index)}
                                            className="delete-cross-tfp"
                                        >
                                            &#10006; {/* Unicode character for cross sign */}
                                        </span>
                                        <input
                                            type="text"
                                            className="integer-input"
                                            placeholder="Expense Type"
                                            value={row.title}
                                            onChange={(e) => {
                                                handleRowTitleChangeExpense(index, e);
                                                handleInputChange();

                                            }}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            className="integer-input"
                                            placeholder="Enter amount"
                                            value={isSaved === false ? row.amount : abbreviateNumber(row.amount)}
                                            onChange={(e) => {
                                                handleRowAmountChangeExpense(index, e);
                                                handleInputChange();

                                            }}
                                            onFocus={() => {
                                                console.log('changing is saved value to false')
                                                setisSaved(false);
                                            }}
                                            onBlur={() => {
                                                console.log('changing is saved value to true')
                                                setisSaved(true);
                                            }}
                                        />
                                    </td>
                                </tr>
                            )
                        )}
                        <tr>
                            <td>Loan/EMI</td>
                            <td>
                                <input
                                    type="text"
                                    className="integer-input"
                                    placeholder="Enter amount"
                                    value={isSaved === false ? LoanEmiAmount : abbreviateNumber(LoanEmiAmount)}
                                    onChange={handleLoanEmiAmountChange}

                                    onFocus={() => {
                                        console.log('changing is saved value to false')
                                        setisSaved(false);
                                    }}
                                    onBlur={() => {
                                        console.log('changing is saved value to true')
                                        setisSaved(true);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Insurance</td>
                            <td>
                                <input
                                    type="text"
                                    className="integer-input"
                                    placeholder="Enter amount"
                                    value={isSaved === false ? InsuranceAmount : abbreviateNumber(InsuranceAmount)}
                                    onChange={handleInsuranceAmountChange}

                                    onFocus={() => {
                                        console.log('changing is saved value to false')
                                        setisSaved(false);
                                    }}
                                    onBlur={() => {
                                        console.log('changing is saved value to true')
                                        setisSaved(true);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Rent</td>
                            <td>
                                <input
                                    type="text"
                                    className="integer-input"
                                    placeholder="Enter amount"
                                    value={isSaved === false ? RentAmount : abbreviateNumber(RentAmount)}
                                    onChange={handleRentAmountChange}

                                    onFocus={() => {
                                        console.log('changing is saved value to false')
                                        setisSaved(false);
                                    }}
                                    onBlur={() => {
                                        console.log('changing is saved value to true')
                                        setisSaved(true);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Bills/Utilities</td>
                            <td>
                                <input
                                    type="text"
                                    className="integer-input"
                                    placeholder="Enter amount"
                                    value={isSaved === false ? BillsAmount : abbreviateNumber(BillsAmount)}
                                    onChange={handleBillsAmountChange}

                                    onFocus={() => {
                                        console.log('changing is saved value to false')
                                        setisSaved(false);
                                    }}
                                    onBlur={() => {
                                        console.log('changing is saved value to true')
                                        setisSaved(true);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Emergency</td>
                            <td>
                                <input
                                    type="text"
                                    className="integer-input"
                                    placeholder="Enter amount"
                                    value={isSaved === false ? EmergencyAmount : abbreviateNumber(EmergencyAmount)}
                                    onChange={handleEmergencyAmountChange}

                                    onFocus={() => {
                                        console.log('changing is saved value to false')
                                        setisSaved(false);
                                    }}
                                    onBlur={() => {
                                        console.log('changing is saved value to true')
                                        setisSaved(true);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Savings</td>
                            <td>
                                <input
                                    type="text"
                                    className="integer-input"
                                    placeholder="Enter amount"
                                    value={isSaved === false ? ExpenseSavingsAmount : abbreviateNumber(ExpenseSavingsAmount)}
                                    onChange={handleExpenseSavingAmount}

                                    onFocus={() => {
                                        console.log('changing is saved value to false')
                                        setisSaved(false);
                                    }}
                                    onBlur={() => {
                                        console.log('changing is saved value to true')
                                        setisSaved(true);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Investment</td>
                            <td>
                                <input
                                    type="text"
                                    className="integer-input"
                                    placeholder="Enter amount"
                                    value={isSaved === false ? ExpenseInvestmentAmount : abbreviateNumber(ExpenseInvestmentAmount)}
                                    onChange={handleExpenseInvestmentAmount}

                                    onFocus={() => {
                                        console.log('changing is saved value to false')
                                        setisSaved(false);
                                    }}
                                    onBlur={() => {
                                        console.log('changing is saved value to true')
                                        setisSaved(true);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Taxes</td>
                            <td>
                                <input
                                    type="text"
                                    className="integer-input"
                                    placeholder="Enter amount"
                                    value={isSaved === false ? TaxAmount : abbreviateNumber(TaxAmount)}
                                    onChange={handleTaxAmountChange}

                                    onFocus={() => {
                                        console.log('changing is saved value to false')
                                        setisSaved(false);
                                    }}
                                    onBlur={() => {
                                        console.log('changing is saved value to true')
                                        setisSaved(true);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Variable
                                <span onMouseOver={showInfoVariable} onMouseOut={hideInfoVariable}>
                                    <span>  </span>  <GrCircleInformation />
                                </span>
                                <div
                                    className={isInfoVisibleVariable ? "info-popupVariable visible" : "info-popupVariable"} style={{
                                        top: dynammicHeightSetter(),
                                    }}>
                                    <p>Other types of Expenses</p>
                                </div>
                            </td>
                            <td>
                                <input
                                    type="text"
                                    className="integer-input"
                                    placeholder="Enter amount"
                                    value={isSaved === false ? VariableAmount : abbreviateNumber(VariableAmount)}
                                    onChange={handleVariablAmountChange}

                                    onFocus={() => {
                                        console.log('changing is saved value to false')
                                        setisSaved(false);
                                    }}
                                    onBlur={() => {
                                        console.log('changing is saved value to true')
                                        setisSaved(true);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="sumTotal">Total</td>
                            <td>{isSaved === false ? totalSumExpenses : abbreviateNumber(totalSumExpenses)}</td>
                        </tr>
                    </table>
                </div>
                <div className="alltable">
                    <table style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                        <th style={{ width: '100%' }}>
                            Annual Income - Annual Expenses
                            <span onMouseOver={showInfoCashFlow} onMouseOut={hideInfoCashFlow}
                                style={{ paddingLeft: '10px' }}>
                                <GrCircleInformation />
                            </span>
                            <div className={isInfoCashFlow ? "info-popupCashFlow visible" : "info-popupCashFlow"} style={{
                                top: dynammicHeightSetter()
                            }}>
                                <p>surplus(+) / deficit(-)</p>
                            </div>
                        </th>
                        <th style={{ width: '100%' }}>
                            {isSaved === false ? totalSumIncome - totalSumExpenses : abbreviateNumber(totalSumIncome - totalSumExpenses)}
                        </th>
                    </table>

                </div>
                <div style={{display:'flex', flexDirection:'row',justifyContent:'space-between', width:'85vw'}}>
                    <div className="TFPsubheading" ref = {tfpSectionRef} style={{marginBottom: '10px'}}>
                        <h4>Total Lifetime Position</h4>
                    </div>
                    <Link to={'/tfp-assets'} style={{ textDecoration: 'none' }}>
                    <BsArrowRight style={{fontSize:'35px', color:'black'}}/>
                    <p style={{color:'black'}}>Planner</p>
                    </Link>
                </div>
                
                <div className='alltable'>
                    <div className='table-container'>
                        <table>
                            <tr>
                                <th>Assets
                                </th>
                                <th className="FirstRow">{" "}
                                    <span>
                                        <img
                                            src={add}
                                            className="mainFirstRow"
                                            alt=""
                                            onClick={handleRowAddAsset}
                                        />
                                    </span>
                                </th>
                            </tr>
                            {assetrows.map(
                                (
                                    row,
                                    index // Add slice() and reverse() here
                                ) => (
                                    <tr key={index}>
                                        <td style={{ position: "relative" }}>
                                            <span
                                                onClick={() => handleRowDeleteAsset(index)}
                                                className="delete-cross-tfp"
                                            >
                                                &#10006; {/* Unicode character for cross sign */}
                                            </span>
                                            <input
                                                type="text"
                                                className="integer-input"
                                                placeholder="Asset Type"
                                                value={row.title}
                                                onChange={(e) => {
                                                    handleRowTitleChangeAsset(index, e);
                                                    handleInputChange();

                                                }}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                className="integer-input"
                                                placeholder="Enter amount"
                                                value={isSaved === false ? row.amount : abbreviateNumber(row.amount)}
                                                onChange={(e) => {
                                                    handleRowAmountChangeAsset(index, e);
                                                    handleInputChange();

                                                }}
                                                onFocus={() => {
                                                    console.log('changing is saved value to false')
                                                    setisSaved(false);
                                                }}
                                                onBlur={() => {
                                                    console.log('changing is saved value to true')
                                                    setisSaved(true);
                                                }}
                                            />
                                        </td>
                                    </tr>
                                )
                            )}
                            <tr>
                                <td>Cash/Bank Savings</td>
                                <td><input
                                    type="text"
                                    className="integer-input"
                                    placeholder="Enter amount"
                                    value={isSaved === false ? SavingAmount : abbreviateNumber(SavingAmount)}
                                    onChange={(e) => {
                                        handleAssestsChange(e, 'BankSavings')
                                    }}
                                    onFocus={() => {
                                        console.log('changing is saved value to false')
                                        setisSaved(false);
                                    }}
                                    onBlur={() => {
                                        console.log('changing is saved value to true')
                                        setisSaved(true);
                                    }}
                                /></td>
                            </tr>
                            <tr>
                                <td>Mutual Funds</td>
                                <td><input
                                    type="text"
                                    className="integer-input"
                                    placeholder="Enter amount"
                                    value={isSaved === false ? MutualFundsAmount : abbreviateNumber(MutualFundsAmount)}
                                    onChange={(e) => {
                                        handleAssestsChange(e, 'MutualFunds')
                                    }}
                                    onFocus={() => {
                                        console.log('changing is saved value to false')
                                        setisSaved(false);
                                    }}
                                    onBlur={() => {
                                        console.log('changing is saved value to true')
                                        setisSaved(true);
                                    }}
                                /></td>
                            </tr>
                            <tr>
                                <td>Stocks/Equity</td>
                                <td><input
                                    type="text"
                                    className="integer-input"
                                    placeholder="Enter amount"
                                    value={isSaved === false ? StocksAmount : abbreviateNumber(StocksAmount)}
                                    onChange={(e) => {
                                        handleAssestsChange(e, 'StocksEquity')
                                    }}
                                    onFocus={() => {
                                        console.log('changing is saved value to false')
                                        setisSaved(false);
                                    }}
                                    onBlur={() => {
                                        console.log('changing is saved value to true')
                                        setisSaved(true);
                                    }}
                                /></td>
                            </tr>
                            <tr>
                                <td>Bonds</td>
                                <td><input
                                    type="text"
                                    className="integer-input"
                                    placeholder="Enter amount"
                                    value={isSaved === false ? BondsAmount : abbreviateNumber(BondsAmount)}
                                    onChange={(e) => {
                                        handleAssestsChange(e, 'BondsFixedIncome')
                                    }}
                                    onFocus={() => {
                                        console.log('changing is saved value to false')
                                        setisSaved(false);
                                    }}
                                    onBlur={() => {
                                        console.log('changing is saved value to true')
                                        setisSaved(true);
                                    }}
                                /></td>
                            </tr>
                            <tr>
                                <td>Vehicle</td>
                                <td><input
                                    type="text"
                                    className="integer-input"
                                    placeholder="Enter amount"
                                    value={isSaved === false ? VehicleAmount : abbreviateNumber(VehicleAmount)}
                                    onChange={(e) => {
                                        handleAssestsChange(e, 'Vehicle')
                                    }}
                                    onFocus={() => {
                                        console.log('changing is saved value to false')
                                        setisSaved(false);
                                    }}
                                    onBlur={() => {
                                        console.log('changing is saved value to true')
                                        setisSaved(true);
                                    }}
                                /></td>
                            </tr>
                            <tr>
                                <td>Real estate</td>
                                <td><input
                                    type="text"
                                    className="integer-input"
                                    placeholder="Enter amount"
                                    value={isSaved === false ? RealEstateAmount : abbreviateNumber(RealEstateAmount)}
                                    onChange={(e) => {
                                        handleAssestsChange(e, 'RealEstate')
                                    }}
                                    onFocus={() => {
                                        console.log('changing is saved value to false')
                                        setisSaved(false);
                                    }}
                                    onBlur={() => {
                                        console.log('changing is saved value to true')
                                        setisSaved(true);
                                    }}
                                /></td>
                            </tr>
                            <tr>
                                <td>Investments
                                    <span onMouseOver={showInfoInvestments} onMouseOut={hideInfoinvestments}>
                                        <span>  </span>  <GrCircleInformation />
                                    </span>
                                    <div
                                        className={isInfoVisibleInvestments ? "info-popupInvestments visible" : "info-popupInvestments"} style={{
                                            top: dynammicHeightSetter()
                                        }}>
                                        <p>Other types of Investments</p>
                                    </div>
                                </td>
                                <td><input
                                    type="text"
                                    className="integer-input"
                                    placeholder="Enter amount"
                                    value={isSaved === false ? InvestmentAmount : abbreviateNumber(InvestmentAmount)}
                                    onChange={(e) => {
                                        handleAssestsChange(e, 'Investments')
                                    }}
                                    onFocus={() => {
                                        console.log('changing is saved value to false')
                                        setisSaved(false);
                                    }}
                                    onBlur={() => {
                                        console.log('changing is saved value to true')
                                        setisSaved(true);
                                    }}
                                /></td>
                            </tr>
                            <tr>
                                <td className="sumTotal">Total</td>
                                <td>{isSaved === false ? totalSumAssets : abbreviateNumber(totalSumAssets)}</td>
                            </tr>
                        </table>
                    </div>
                    <div className='table-container'>
                        <table>
                            <tr>
                                <th>Liabilities
                                </th>
                                <th className="FirstRow">{" "}
                                    <span>
                                        <img
                                            src={add}
                                            className="mainFirstRow"
                                            alt=""
                                            onClick={handleRowAddsetLiabilitiesRows}
                                        />
                                    </span>
                                </th>
                            </tr>
                            {liabilitiesrows.map(
                                (
                                    row,
                                    index // Add slice() and reverse() here
                                ) => (
                                    <tr key={index}>
                                        <td style={{ position: "relative" }}>
                                            <span
                                                onClick={() => handleRowDeleteLibilities(index)}
                                                className="delete-cross-tfp"
                                            >
                                                &#10006; {/* Unicode character for cross sign */}
                                            </span>
                                            <input
                                                type="text"
                                                className="integer-input"
                                                placeholder="Liability Type"
                                                value={row.title}
                                                onChange={(e) => {
                                                    handleInputChange();
                                                    handleRowTitleChangeLiabilities(index, e);
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                className="integer-input"
                                                placeholder="Enter amount"
                                                value={isSaved === false ? row.amount : abbreviateNumber(row.amount)}
                                                onChange={(e) => {
                                                    handleRowAmountChangeLiabilities(index, e);
                                                    handleInputChange();

                                                }}
                                                onFocus={() => {
                                                    console.log('changing is saved value to false')
                                                    setisSaved(false);
                                                }}
                                                onBlur={() => {
                                                    console.log('changing is saved value to true')
                                                    setisSaved(true);
                                                }}
                                            />
                                        </td>
                                    </tr>
                                )
                            )}
                            <tr>
                                <td>Loans
                                    <span onMouseOver={showInfoLoans} onMouseOut={hideInfoLoans}>
                                        <span>  </span>  <GrCircleInformation />
                                    </span>
                                    <div className={isInfoVisibleLoans ? "info-popupLoans visible" : "info-popupLoans"} style={{
                                        top: dynammicHeightSetter()
                                    }}>
                                        <p>Types of Loans (House, Vehicle, Education)</p>
                                        <p>Loan amt = total amt - paid amt = amount remaining to be added </p>
                                    </div>
                                </td>
                                <td><input
                                    type="text"
                                    className="integer-input"
                                    placeholder="Enter amount"
                                    value={isSaved === false ? LoanAmount : abbreviateNumber(LoanAmount)}
                                    onChange={handleLoanAmountChange}
                                    onFocus={() => {
                                        console.log('changing is saved value to false')
                                        setisSaved(false);
                                    }}
                                    onBlur={() => {
                                        console.log('changing is saved value to true')
                                        setisSaved(true);
                                    }}
                                /></td>

                            </tr>
                            <tr>
                                <td>Credit card</td>
                                <td><input
                                    type="text"
                                    className="integer-input"
                                    placeholder="Enter amount"
                                    value={isSaved === false ? CreditcardAmount : abbreviateNumber(CreditcardAmount)}
                                    onChange={handleCreditcardAmountChange}
                                    onFocus={() => {
                                        console.log('changing is saved value to false')
                                        setisSaved(false);
                                    }}
                                    onBlur={() => {
                                        console.log('changing is saved value to true')
                                        setisSaved(true);
                                    }}
                                /></td>
                            </tr>
                            <tr>
                                <td className="sumTotal">Total</td>
                                <td>{isSaved === false ? totalSumLaibilities : abbreviateNumber(totalSumLaibilities)}</td>
                            </tr>
                        </table>
                    </div>
                    <div className="alltable">
                        <table style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                            <th style={{ width: '100%' }}>Net worth
                                <span onMouseOver={showInfoNetWorth} onMouseOut={hideInfoNetWorth}>
                                    <span>  </span>  <GrCircleInformation />
                                </span>
                                <div className={isInfoNetWorth ? "info-popupNetWorth visible" : "info-popupNetWorth"} style={{
                                    top: dynammicHeightSetter()
                                }}>
                                    <p> Assets - Liability </p>
                                </div>
                            </th>
                            <th style={{ width: '100%' }}>
                                {isSaved === false ? totalSumAssets - totalSumLaibilities : abbreviateNumber(totalSumAssets - totalSumLaibilities)}
                            </th>
                        </table>
                    </div>
                    {/* {isTyping && (
            <div className="savebackground">
            <div className="savedButton">Save Data
            </div>
            </div>
          )} */}
                    {/* {isTyping && (
            <div className="savebackground">
              <div className="savedButtonBelow">
                <button onClick={handleSaveDataToDb}>Save Data </button>
              </div>
            </div>
          )}
          {isTyping && (
            <div className="savebackground">
              <div className="savedGoalsButton">
                <button onClick={handleSaveDataToDb}>Save Data </button>
              </div>
            </div>
          )} */}

                </div>
                <div id='goals-heading'>

                </div>
            </div>
            {/*<div></div>*/}
            {/*<div> </div>*/}

            {/*<div id='goals-heading'>*/}

            {/*</div>*/}
            {/*<div></div>*/}

            {/*<div className='bottomSpace'></div>*/}

        </div>
    );
}

export default Tfp;
