import React from 'react';
import './Monthselector.css';

function Monthselector({onSelectMonth}) {
    const currentDate = new Date();
    const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0');

    const selectedMonth = (e) => {
        const selectedValue = e.target.value;
        const selectedDate = new Date(currentDate.getFullYear(), selectedValue - 1, 1);
        const selectedMonthFormat = selectedDate.toLocaleString('default', {month: 'long'});

        // console.log(selectedValue);
        // console.log(selectedMonthFormat);
        onSelectMonth(selectedDate);
    };

    return (
        <div className='selectMonth'>
            <select name='month' defaultValue={currentMonth} onChange={selectedMonth}>
                <option value='01'>January</option>
                <option value='02'>February</option>
                <option value='03'>March</option>
                <option value='04'>April</option>
                <option value='05'>May</option>
                <option value='06'>June</option>
                <option value='07'>July</option>
                <option value='08'>August</option>
                <option value='09'>September</option>
                <option value='10'>October</option>
                <option value='11'>November</option>
                <option value='12'>December</option>
            </select>
        </div>
    );
}

export default Monthselector;
