import React from 'react'
import Header from '../PreHeader';
import './Faq.css';
import { Helmet } from 'react-helmet';

function Faq() {
    const structuredData = {
        "@context": "http://schema.org",
        "@type": "WebPage",
        "name": "FAQs",
        "description": "Frequently Asked Questions about Capitalflo",
        "url": "https://capitalflo.co/signup",
    };
    return (
        <div className='faqs-webpage'>
            <Helmet>
                <title>FAQs</title>
                <meta name="description"
                    content="Frequently Asked Questions about Capitalflo" />
                <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
            </Helmet>
            <Header />
            <div className='faq'>
                <div style={{ height: "5vh" }}></div>
                <h1>FAQs</h1>
                <h2>How does Capitalflo work?</h2>
                <p>Capitalflo helps in planning your finances! It works by providing a comprehensive financial
                    planning and management platform. The app then utilizes this data to:</p>
                {/* <p><br /></p> <p>Capitalflo helps in planning your finances! It works by providing a comprehensive financial
                    planning and management platform. Users input their financial information, including income,
                    expenses,
                    assets,
                    liabilities, and financial goals. The app then utilizes this data to:</p>
                <p><br /></p> */}
                <ul>
                    <li>- Help you understand your current financial situation and future potential</li>
                    {/* <li>- Track income, expenses, and investments.</li> */}
                    {/*<li>- Monitor your investment portfolios, receive insights into diversification, and set investment*/}
                    {/*    goals*/}
                    {/*</li>*/}
                    <li>- Set various financial goals, from saving for a home to planning for retirement</li>
                </ul>
                <p><br /></p>
                <h2>How do you create an account on Capitalflo?</h2>
                <p>Setting up an account on Capitaflo couldn't be easier.</p>
                <p><br /></p>
                <ul>
                    <li>- Download and install the app through the Google Play Store or App Store.</li>
                    <li>- Sign up with your mobile number or directly through your Google account.
                    </li>
                </ul>
                <p><br /></p>
                <p>All done!</p>
                <div style={{ height: "25vh" }}></div>
            </div>
        </div>
    )
}

export default Faq


