import React, {useEffect, useState} from 'react';
import Header from '../PreHeader';
import './Blogs.css';
import Postcard from '../blog/components/Postcard';
import {fetchData} from '../blog/query';
import PostWidget from "../blog/components/PostWidget";
import FeaturedPost from "../blog/components/FeaturedPost";
import {Link} from "react-router-dom";
import {BsFillArrowRightCircleFill} from "react-icons/bs";
import {Helmet} from "react-helmet";
import LoadingAnimation from "../assets/Loading.svg";

function Blogs(slug) {
    const [posts, setPosts] = useState([]);
    const [checkdata, setCheckdata] = useState(false);
    const [searchTerm, setSearchTerm] = useState(''); // State for search term


    let fetchedPosts;
    let filteredPosts;


    useEffect(() => {
        async function fetchPosts() {
            fetchedPosts = await fetchData();
            if (fetchedPosts) {
                setPosts(fetchedPosts);
                setCheckdata(true);
            }
        }

        fetchPosts();
    }, []); // Empty dependency array ensures this effect runs once on component mount


    console.log("postsArray:", posts);

    // Function to handle search input changes
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    if (checkdata === true) {
        console.log("PostDetails:", posts);
        filteredPosts = posts.filter((edge) =>
            edge.title.toLowerCase().includes(searchTerm.toLowerCase())
        );

        let lastPost = null;
        if (filteredPosts.length > 0) {
            lastPost = filteredPosts[filteredPosts.length - 1];
        }
        const structuredData = {
            "@context": "http://schema.org",
            "@type": "WebPage",
            "name": "Blogs",
            "description": "Take charge of your finances with Capitalflo's blog. Our blog articles helps you understand personal finance for a brighter financial future.",
            "url": "https://capitalflo.co/blog/home",
            // Add more properties as needed
        };

        return (
            <div className="blogsContainer">
                <Helmet>
                    <title>Blogs</title>
                    <meta name="description"
                          content="Take charge of your finances with Capitalflo's blog. Our blog articles helps you understand personal finance for a brighter financial future."/>
                    <script async src="https://www.googletagmanager.com/gtag/js?id=G-HN9W8ZF9K8"></script>
                    <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
                    <script>
                        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-HN9W8ZF9K8');
        `}
                    </script>
                </Helmet>
                <Header/>
                <div className="blogsContainerInside">
                    <input
                        type="text"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="searchBar"
                    />
                    <div className='blogs'>
                        {lastPost && (
                            <div className="lastPost">
                                <Postcard post={lastPost}/>
                            </div>
                        )}
                    </div>
                    <div className='featuredPost'>
                        <FeaturedPost/>
                    </div>
                    <div className='postWidget'>
                        <PostWidget/>
                    </div>
                    <div className='viewAllBlogs' style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '20px'
                    }}>
                        <Link to='/blog/all' className='featuredpost-widget-link' style={{marginRight: '10px'}}>View
                            All</Link>
                        <BsFillArrowRightCircleFill/>
                    </div>
                </div>

                {/*{lastPost && (*/}
                {/*    <div className="lastPost">*/}
                {/*        <h2>Last Post</h2>*/}
                {/*        <Postcard post={lastPost} />*/}
                {/*    </div>*/}
                {/*)}*/}
                <div className='copyright-blog'>Copyright 2023 — Capitalflo</div>
            </div>
        );
    } else {
        return <div className='blog_loading_animation'><img src={LoadingAnimation} alt=""/></div>

    }
}

export default Blogs;
