import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {Link} from "react-router-dom";
import './PostWidget.css';


import {fetchData, getComments, getRecentPosts} from '../query';

const PostWidget = ({categories, slug}) => {
    const [relatedPosts, setRelatedPosts] = useState([]);
    const [checkRecentPost, setRecentPost] = useState(false);

    const [posts, setPosts] = useState([]);
    const [checkdata, setCheckdata] = useState(false);
    const [searchTerm, setSearchTerm] = useState(''); // State for search term

    const [commentsfetch, setCommentsfetch] = useState([]);
    const [checkComments, setCheckComments] = useState(false);
    const [checkCommentsLength, setCheckCommentsLength] = useState([]);

    let fetchedPosts;

    useEffect(() => {
        async function fetchPosts() {
            fetchedPosts = await fetchData();
            if (fetchedPosts) {
                setPosts(fetchedPosts);
                setCheckdata(true);
            }
        }

        fetchPosts();
    }, []);

    useEffect(() => {
        // if (slug) {
        //     getSimilarPosts(categories, slug).then((result) => {
        //         setRelatedPosts(result);
        //     });
        // }

        getRecentPosts().then((result) => {
            result.shift();
            console.log("Recent posts:", result);
            setRelatedPosts(result);
            setRecentPost(true)
        });

    }, [slug]);


    useEffect(() => {
        if (Array.isArray(relatedPosts)) {
            // Create an object to store comments count by slug
            const commentsCountBySlug = {};

            // Create an array of promises for fetching comments count
            const commentCountPromises = relatedPosts.map((post) => {
                return getComments(post.slug)
                    .then((result) => {
                        // Store comments count in the object using the slug as the key
                        commentsCountBySlug[post.slug] = result.comments.length;
                    })
                    .catch((error) => {
                        console.error(`Error fetching comments for slug ${post.slug}:`, error);
                    });
            });

            // Use Promise.all to fetch all comments counts and update state
            Promise.all(commentCountPromises)
                .then(() => {
                    // Update the comments count state with the object
                    setCheckCommentsLength(commentsCountBySlug);
                })
                .catch((error) => {
                    console.error('Error fetching comments counts:', error);
                });
        }
    }, [relatedPosts]);

    console.log("postwidgetComment:", checkCommentsLength)
    const getFinalExcerpt = (post) => {
        const excerptWords = post.excerpt.split(' ');
        const truncatedExcerpt = excerptWords.slice(0, 30).join(' ');

        return `${truncatedExcerpt} ...`;
    }

    if (checkRecentPost === true) {
        return (
            <div className='post-widget-container'>
                <div className='recentPostHeader'>
                    <div className='view-all-recents'>
                        <div className='view-all-title'>
                            <h3 className='post-widget-title'>{slug ? 'Related Posts' : 'Recent Posts'}</h3>
                        </div>
                    </div>
                </div>
                {relatedPosts.map((post, index) => (
                    <Link to={`/blog/${post.slug}`} className="recent-widget-item" key={index}>

                        <div className='post-widget-image'>
                            <img
                                alt={post.title}
                                // height="60px"
                                // width="60px"
                                className="align-middle rounded-full"
                                src={post.featuredImage.url}
                                onClick={() => {
                                    window.location.href = `/blog/${post.slug}`
                                }}
                            />
                        </div>
                        <div className='post-widget-details'>
                            <div className="recentPostDetails">
                                <div className="recentPostTitle">
                                    <Link to={`/blog/${post.slug}`} className='post-widget-link' key={index}>
                                        {post.title}
                                    </Link>
                                    <a className="excerptDetails">{getFinalExcerpt(post)}</a>
                                </div>
                                <div className="recentPostExcerpt">
                                    <div className='commentsLengthBlue'>
                                        <div style={{
                                            background: '#004aad',
                                            color: 'white',
                                            paddingRight: '5px',
                                            paddingLeft: '5px',
                                            marginRight: '5px'
                                        }}>
                                            {checkCommentsLength[post.slug] || 0} {' '}
                                        </div>
                                        comments
                                    </div>
                                    <p className='post-widget-date'>{moment(post.createdAt).format('MMM DD, YYYY')}</p>
                                </div>
                            </div>
                            {index !== relatedPosts.length - 1}
                            <hr className='horizontal-line'/>
                        </div>
                    </Link>
                ))}
            </div>
        );
    }
}

export default PostWidget;
