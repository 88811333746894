import React from 'react';
import './GoogleSignInButton.css'
import gicon from '../assets/img/Gicon.png'
import { FcGoogle } from "react-icons/fc";
import { auth, provider } from './configure';
import { signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import mixpanel from "../utils/Mixpanel";

function GoogleSignInButton({ type }) {
    const navigate = useNavigate();

    const handleCallbackResponse = async () => {
        try {
            const { user } = await signInWithPopup(auth, provider);
            const { email, uid } = user;

            localStorage.setItem('email', email);
            localStorage.setItem('id', uid);

            console.log(user.emailVerified);
            console.log(user.providerId);
            console.log(user.uid);

            if (email) {
                try {
                    const response = await axios.post(
                        'https://api.capitalflo.co/api/users/google-sign-in',
                        {
                            email: localStorage.getItem('email'),
                            id: localStorage.getItem('id'),
                        },
                        { withCredentials: true }
                    );
                    console.log('RESPONSSEE DATA', response);
                    if (response.data.token) {
                        console.log(response.data.token);
                        localStorage.clear();
                        if (!response.data.createdAt) {
                            await localStorage.setItem("firstTimeSignUp", "true");
                            console.log("^^^^^^^^^^^^^^^^^^^^^")
                            console.log('new user', localStorage.getItem("firstTimeSignUp"))
                            console.log("^^^^^^^^^^^^^^^^^^^^^")
                        }
                        if (type === 'login') {
                            mixpanel.track('Logged In Web', { 'Login Type': 'Google' });
                        } else if (type === 'signup') {
                            mixpanel.track('Signed Up Web', { 'Signup Type': 'Google' });
                        }
                        document.cookie = `jwt=${response.data.token}; path=/;`;
                        document.cookie = `jwt=${response.data.token}; domain=.capitalflo.co; path=/;`;
                        document.cookie = `jwt=${response.data.token}; domain=capitalflo.co; path=/;`;
                        document.cookie = `jwt=${response.data.token}; domain=api.capitalflo.co; path=/;`;
                        navigate("/services");
                    }
                } catch (error) {
                    console.log(error.response.data.message);
                }
            }

            localStorage.removeItem('email');
            localStorage.removeItem('id');

            console.log('calling');
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div onClick={handleCallbackResponse}>
            <div className='google-wrapper'>
                <div id="customBtn" className="customGPlusSignIn">
                    <FcGoogle style={{ width: '25px', height: '35px' }} />
                    <span className="google-buttonText">Sign in with Google</span>
                </div>
            </div>
        </div>
    );
}

export default GoogleSignInButton;
