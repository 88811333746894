import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {fetchData, getComments} from '../blog/query'; // Assuming you have a query function to fetch all blog posts
import './featuredAll.css'
import {BsDot} from "react-icons/bs";
import Header from '../PreHeader';
import {Link} from "react-router-dom";
import Helmet from "react-helmet";



const AllBlogsPage = () => {
    const [posts, setPosts] = useState([]);
    const [checkCommentsLength, setCheckCommentsLength] = useState([]);


    useEffect(() => {
        async function fetchPosts() {
            const allPosts = await fetchData();
            setPosts(allPosts);
        }

        fetchPosts();
    }, []);
    useEffect(() => {
        if (Array.isArray(posts)) {
            const commentsCountBySlug = {};
            const commentCountPromises = posts.map((post) => {
                return getComments(post.slug)
                    .then((result) => {
                        commentsCountBySlug[post.slug] = result.comments.length;
                    })
                    .catch((error) => {
                        console.error(`Error fetching comments for slug ${post.slug}:`, error);
                    });
            });

            Promise.all(commentCountPromises)
                .then(() => {
                    setCheckCommentsLength(commentsCountBySlug);
                })
                .catch((error) => {
                    console.error('Error fetching comments counts:', error);
                });
        }
    }, [posts]);
    const sortedPosts = posts.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB - dateA;
    });
    const structuredData = {
        "@context": "http://schema.org",
        "@type": "WebPage",
        "name": "All Blogs",
        "description": "Check out all of our Blogs. We write about finance, technology, and more.",
        "url": "https://capitalflo.co/blog/all",
    };

    return (
        <div className='featured-all-webpage' style={{width: '100vw'}}>
             <Helmet>
                <title>All Blogs | Capitalflo</title>
                <meta name="description"
                      content="Check out all of our Blogs. We write about finance, technology, and more."/>
                <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
            </Helmet>
            <Header/>
            <div className='featured-all-title'>
                <h3>All Blogs</h3>
            </div>
            <div className="grid-container-all">
                {sortedPosts.map((item) => (
                    <Link to={`/blog/${item.slug}`} key={item.id} className="grid-all-item">
                        <div className="grid-all-item-header">
                            <img src={item.featuredImage.url} alt="Item"/>
                            <div className="grid-all-item-info">
                                <p>{moment(item.createdAt).format('MMM DD, YYYY')}</p>
                                <BsDot/>
                                <p>{checkCommentsLength[item.slug] || 0} comments</p>
                            </div>
                        </div>
                        <h3>{item.title}</h3>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default AllBlogsPage;
