import React, { useEffect, useState } from 'react';
import Header from '../PreHeader'
// import Header from '../Header'
import './Homepage.css'
import logo from '../assets/img/footerlogo.png'
import fintech from '../assets/img/icons and logo banner.png'
import JordansJourney from '../assets/img/JordansJourney.png'
import apple from "../assets/storeimg/App Store.png";
import google from "../assets/storeimg/Google Play.png";
import { FaClock } from "react-icons/fa6";


import { IoLogoLinkedin } from 'react-icons/io';
import { AiFillInstagram } from 'react-icons/ai';
import { BsYoutube } from 'react-icons/bs';
import { RiTwitterXFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// import mockUpVideo from "https://capitalflophotos.s3.ap-south-1.amazonaws.com/Our+Solution.gif"
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Usedrop, UsedropReset, UsedropUpdate } from '../ReleaseContext';
import { CiCalendar } from "react-icons/ci";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
// import PhoneInput from 'react-phone-input-2'

// import 'react-phone-input-2/lib/bootstrap.css'
import { IoShieldCheckmark } from "react-icons/io5";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IoMdArrowBack } from "react-icons/io";


function Home() {

    useEffect(() => {
        AOS.init({
            duration: 1100, // Animation duration
            easing: 'ease', // Easing option
            once: true // Only animate elements once
        });
    }, []);

    useEffect(() => {
        if (window.location.hash.includes("book-a-free-call")) {
            console.log(window.location.hash);
            setTimeout(() => {
                document.querySelector("#book-a-call-main-listing").scrollIntoView({ behavior: "instant" });
            }, 200); // Adjust the delay as needed
            setBookACallPopup(true);
        }
    }, []);


    const subscribeEmail = async (email) => {
        try {
            const response = await fetch('https://api.capitalflo.co/api/users/blog-subscribers', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: email }),
            });

            const data = await response.json();
            console.log(data); // You can handle the response data here
            if (data.message === 'User is already subscribed') {
                alert('You are already subscribed!');
                window.location.href = "https://capitalflo.co/blog/home";
            } else if (data.message === 'Subscribed Successfully') {
                alert('Thank you for subscribing!');
                window.location.href = "https://capitalflo.co/blog/home";
            } else {
                alert('Sorry but failed to subscribe. Please try again.');
                window.location.href = "https://capitalflo.co/blog/home";
            }
        } catch (error) {
            console.error('Failed to subscribe:', error);
        }
    };

    const handleSubscribe = async (event) => {
        event.preventDefault();
        const email = event.target.querySelector('input[type="email"]').value;
        await subscribeEmail(email);
    }


    const companyData = [
        {
            name: 'Kotak',
            logo: 'https://capitalflophotos.s3.ap-south-1.amazonaws.com/banklist/5.png'
        },
        {
            name: 'HDFC Bank',
            logo: 'https://capitalflophotos.s3.ap-south-1.amazonaws.com/banklist/1.png'
        },
        {
            name: 'Axis Bank',
            logo: 'https://capitalflophotos.s3.ap-south-1.amazonaws.com/banklist/3.png'
        },
        {
            name: 'ICICI Bank',
            logo: 'https://capitalflophotos.s3.ap-south-1.amazonaws.com/banklist/4.png'
        },
        {
            name: 'SBI Bank',
            logo: 'https://capitalflophotos.s3.ap-south-1.amazonaws.com/banklist/6.png'
        },
        {
            name: 'Bank of Baroda',
            logo: 'https://capitalflophotos.s3.ap-south-1.amazonaws.com/banklist/2.png'
        },
        {
            name: 'Punjab National Bank',
            logo: 'https://capitalflophotos.s3.ap-south-1.amazonaws.com/banklist/7.png'

        },
    ];

    const mockUpData = [
        {
            name: 'Home Screen',
            img: 'https://capitalflophotos.s3.ap-south-1.amazonaws.com/mockup-ios/Homepage+service+page.png'
        },
        {
            name: 'Bank Statement',
            img: 'https://capitalflophotos.s3.ap-south-1.amazonaws.com/mockup-ios/bank+statement.png'
        },
        {
            name: 'Statement Insights',
            img: 'https://capitalflophotos.s3.ap-south-1.amazonaws.com/mockup-ios/statement+insights.png'
        },
        {
            name: 'Financial Health and Safety',
            img: 'https://capitalflophotos.s3.ap-south-1.amazonaws.com/mockup-ios/financial+health+and+safety.png'
        },
        {
            name: 'Total Financial Position',
            img: 'https://capitalflophotos.s3.ap-south-1.amazonaws.com/mockup-ios/total+financial+position.png'
        },
        {
            name: 'Plan Budget',
            img: 'https://capitalflophotos.s3.ap-south-1.amazonaws.com/mockup-ios/plan+budget.png'
        },
        {
            name: 'Achieve Goals',
            img: 'https://capitalflophotos.s3.ap-south-1.amazonaws.com/mockup-ios/+goals.png'
        },
    ];
    const responsive_capitalflo_screens = {
        desktop: {
            breakpoint: { max: 3000, min: 900 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 900, min: 464 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    const responsive_bank_logos = {
        desktop: {
            breakpoint: { max: 3000, min: 900 },
            items: 4,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 900, min: 464 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    const structuredData = {
        "@context": "http://schema.org",
        "@type": "WebPage",
        "name": "Capitalflo- The financial planning of a new generation!",
        "description": "Capitalflo is your personal financial planning application customized for your needs only.",
        "url": "https://capitalflo.co/",
    };
    useEffect(() => {
        if (window.innerWidth <= 600) {
            document.getElementById('video-container').innerHTML = '<iframe src="https://www.youtube.com/embed/GGDSSEfGR-4" title="New video title" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" allowfullscreen></iframe>';
        } else {
            document.getElementById('video-container').innerHTML = '<iframe src="https://www.youtube.com/embed/UQmC0vYd9zA" title="How we can help you?" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" allowfullscreen></iframe>';
        }
    }, []);
    const drop = Usedrop();
    const dropping = UsedropUpdate();
    const droppie = UsedropReset();

    const [bookACallPopup, setBookACallPopup] = useState(false);
    const [bookACallTimePopup, setBookACallTimePopup] = useState(false);
    const [bookACallContactInfoPopup, setBookACallContactInfoPopup] = useState(false);
    const [dateValue, setDateValue] = useState(new Date(new Date().setDate(new Date().getDate() + 2)));
    const [phoneValue, setPhoneValue] = useState("");
    const [nameValue, setNameValue] = useState("");
    const [emailValue, setEmailValue] = useState("");
    const [queriesValue, setQueriesValue] = useState("");
    const [queryOptions, setQueryOptions] = useState("");
    const moveToSelectTime = () => {
        if (!queryOptions || !queriesValue) {
            toast.info('All fields are mandatory.', {
                position: "bottom-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            return
        }
        setBookACallTimePopup(true);
    }

    const moveToContactInfo = () => {
        if (!dateValue) {
            toast.error('Please select a suitable date.', {
                position: "bottom-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            return
        } else if (dateValue.getFullYear() === new Date().getFullYear() &&
            dateValue.getMonth() === new Date().getMonth() &&
            dateValue.getDate() === new Date().getDate()) {
            toast.info('You have selected today. We will reach out to you shortly.')
        }
        setBookACallTimePopup(false);
        setBookACallContactInfoPopup(true);
    }
    const bookACall = async (name, email, phone, date, queryType, query) => {
        try {
            const response = await fetch('https://api.capitalflo.co/api/users/book-a-call', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    name: name,
                    phone: phone,
                    date: date,
                    queryType: queryType,
                    query: query
                }),
            });

            const data = await response.json();
            console.log(data);
            if (data.message.includes("success")) {
                toast.success("Thank you for booking a call. We will reach out to you shortly.")
                setQueriesValue("");
                setQueryOptions("");
            }
        } catch (error) {
            console.error('Failed to book a call', error);
        }
    };
    const scheduleEvent = async () => {
        if (!nameValue || !emailValue || !phoneValue) {
            toast.info('All fields are mandatory.', {
                position: "bottom-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            return
        }
        const bookACallFormData = {
            name: nameValue,
            email: emailValue,
            phone: phoneValue,
            date: dateValue.toString(),
            queryType: queryOptions,
            query: queriesValue
        }
        console.log(bookACallFormData);
        await bookACall(nameValue, emailValue, phoneValue, dateValue.toString(), queryOptions, queriesValue);
        setBookACallPopup(false);
        setBookACallTimePopup(false);
        setBookACallContactInfoPopup(false);
    }
    const closeBookACallPopup = () => {
        setBookACallPopup(false);
        setBookACallTimePopup(false);
        setBookACallContactInfoPopup(false);
    }
    const registerBookACallClick = async () => {
        try {
            const response = await fetch('https://api.capitalflo.co/api/users/book-a-call-clicked', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (error) {
            console.error('Failed to register book a call click', error);
        }
    }

    const handleRadioChange = (value) => {
        setQueryOptions(value);
    };
    return (
        <div>
            <div onClick={droppie}>
                <Helmet>
                    <title>Capitalflo- The financial planning of a new generation!</title>
                    <meta name="description"
                        content="Capitalflo is your personal financial planning application customized for your needs only." />
                    <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
                    <script async src="https://www.googletagmanager.com/gtag/js?id=G-HN9W8ZF9K8"></script>
                    <script>
                        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-HN9W8ZF9K8');
        `}
                    </script>
                </Helmet>
                <Header />
                <div className={'home-page-body'}>
                    <ToastContainer
                        position="bottom-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="dark"
                    />

                    <div className='top-container'>
                        <div className='left-top-container'>
                            {/*<div className='HeroText' data-aos="fade-right">*/}
                            <div className='HeroText'>
                                <h1>The Financial Planning of a New Generation!</h1>
                            </div>
                            <div>
                                <div className='playstationTop'>
                                    <Link to="https://apps.apple.com/us/app/capitalflo/id6468636479"
                                        target="_blank"><img
                                            src={apple}
                                            alt="" /></Link>
                                    <Link className='googleplayImg'
                                        to="https://play.google.com/store/apps/details?id=com.capitalfloandroid"
                                        target="_blank"><img src={google} alt="" /></Link>
                                </div>
                            </div>
                        </div>
                        <div className='home-page-top-right'>
                            <Carousel
                                additionalTransfrom={0}
                                arrows
                                autoPlay={false}
                                autoPlaySpeed={2000}
                                centerMode={false}
                                className=""
                                containerClass="container"
                                dotListClass=""
                                draggable
                                focusOnSelect={false}
                                infinite={false}
                                itemClass=""
                                keyBoardControl
                                minimumTouchDrag={80}
                                pauseOnHover
                                renderArrowsWhenDisabled={false}
                                renderButtonGroupOutside={false}
                                renderDotsOutside={false}
                                responsive={responsive_capitalflo_screens}
                                rewind={true}
                                rewindWithAnimation={true}
                                rtl={false}
                                shouldResetAutoplay
                                showDots={false}
                                sliderClass=""
                                slidesToSlide={1}
                                swipeable
                            >
                                {mockUpData.map((screen, index) => (
                                    <div key={index} className='image-container-mockup'>
                                        <img src={screen.img} alt={screen.name}
                                            className='screen-img' />
                                        {/*<p>{screen.name}</p>*/}
                                    </div>
                                ))}

                            </Carousel>
                        </div>
                    </div>

                    {/* <div className='mid-video-container'>
                        <p>Your financial future is worth investing in.</p>
                        <div class="products_video-homepage">
                            <div id="video-container"></div>
                        </div>
                    </div> */}
                    <div className='gif-our-solution-container'>
                        <img src={"https://capitalflophotos.s3.ap-south-1.amazonaws.com/Our+Solution.jpg"} alt="gif" className='gif' />
                    </div>
                    <div className='mid-video-container'>
                        <p>Your financial future is worth investing in.</p>
                        {/* <p>YOUR FINANCIAL FUTURE IS WORTH INVESTING IN.</p> */}
                        <div class="products_video-homepage">
                            <div id="video-container"></div>
                        </div>
                    </div>
                    <div className='mid-body' id='mid-body'>
                        {/*<div className='mid-img-container' data-aos="fade-in" data-aos-duration="500">*/}
                        <div className='mid-full-img-container'>
                            <img src={JordansJourney} alt=""></img>
                        </div>
                    </div>


                    <div className="book-a-call-main-listing" id='book-a-call-main-listing'>
                        <div className="left-section">
                            <h2>Free consultation<br /></h2>
                            <div className="office">
                                <img src="https://capitalflophotos.s3.ap-south-1.amazonaws.com/animations+for+Capitalflo.png" alt="Office" className="office-image" />
                            </div>
                        </div>
                        <div className="right-section">
                            <h3>Financial Planning made easy</h3>
                            <p>With Capitalflo, understand your finances, get answers to your burning questions and buy a financial plan all at the same place .</p>
                            <ul>
                                <li><strong>No salespeople</strong><br />You won’t be talking to pushy salesmen. You’ll only be engaging with exceptional advisors.</li>
                                <li><strong>30 min consultation</strong><br />Good advice takes time. And you will have our undivided attention for at least 30 mins.</li>
                                <li><strong>No spam ever</strong><br />We won't call you when you least expect it. Capitalflo comes with a spam-free guarantee.</li>
                                <li><strong>It's free</strong><br />Our consultations are free and we won’t harass you to buy from us.</li>
                            </ul>
                            <div className="buttons">
                                <button className="book-call" onClick={() => {
                                    setBookACallPopup(true)
                                    registerBookACallClick()
                                }}><CiCalendar style={{ marginRight: '5px' }} />Book a free call</button>
                            </div>
                        </div>
                    </div>
                    {bookACallPopup && <div className="book-a-call-popup" id="book-a-call-popup" onClick={() => closeBookACallPopup()}>
                        <div className="book-a-call-popup-content" onClick={(e) => e.stopPropagation()}>
                            <div className="book-a-call-popup-content-left">
                                {/* {!bookACallTimePopup && !bookACallContactInfoPopup && <div className='capitalflo'>Capitalflo</div>} */}
                                {bookACallTimePopup && <div className='capitalflo_back_arrow' onClick={() => setBookACallTimePopup(false)}><IoMdArrowBack /></div>}
                                {bookACallContactInfoPopup && <div className='capitalflo_back_arrow' onClick={() => {
                                    setBookACallContactInfoPopup(false)
                                    setBookACallTimePopup(true)
                                }}><IoMdArrowBack /></div>}
                                <h3>Free Consultation</h3>
                                <p className='time-30-mins'><FaClock /> 30 Min
                                </p>
                                <p>This is a free intro call for all your queries. </p>
                                <p>Due to our high volume of calls, we will get back to you in 24 to 48 hours.</p>
                            </div>
                            {!bookACallTimePopup && !bookACallContactInfoPopup &&
                                (<div className="book-a-call-popup-content-right">
                                    <h3>Query Regarding </h3>
                                    <div className="radio-buttons-query-options">
                                        <div className="each-radio-button-container">
                                            <input type="radio" id="app" name="type" value="app"
                                                checked={
                                                    queryOptions ===
                                                    "app"
                                                }
                                                onChange={() =>
                                                    handleRadioChange(
                                                        "app"
                                                    )
                                                } />
                                            <label htmlFor="app">Our App</label>
                                        </div>
                                        <div className="each-radio-button-container">
                                            <input type="radio" id="advices" name="type" value="advices"
                                                checked={
                                                    queryOptions ===
                                                    "advices"
                                                }
                                                onChange={() =>
                                                    handleRadioChange(
                                                        "advices"
                                                    )
                                                } />
                                            <label htmlFor="advices">Financial Planning Advice</label>
                                        </div>
                                    </div>
                                    <p>Please let us know your query* </p>
                                    <textarea placeholder="" className="query-text-area" required onChange={(e) => setQueriesValue(e.target.value)} value={queriesValue} />
                                    <button className="select-time-button" onClick={() => moveToSelectTime()}>Select time</button>
                                </div>)}
                            {bookACallTimePopup &&
                                (
                                    (<div className="book-a-call-popup-content-right">
                                        {window.innerWidth <= 600 ? (<div className="book-a-call-popup-mobile-right">
                                            <h3>Select Date & Time </h3>
                                            <Calendar
                                                value={dateValue}
                                                onChange={setDateValue}
                                                minDate={new Date(new Date().setDate(new Date().getDate() + 2))}
                                                maxDate={new Date(new Date().setDate(new Date().getDate() + 60))}
                                                next2Label={''}
                                                prev2Label={''}
                                                className={'calendar_select_date'}
                                            />
                                        </div>
                                        ) : (
                                            <>
                                                <h3>Select Date & Time </h3>
                                                <Calendar
                                                    value={dateValue}
                                                    onChange={setDateValue}
                                                    minDate={new Date(new Date().setDate(new Date().getDate() + 2))}
                                                    maxDate={new Date(new Date().setDate(new Date().getDate() + 60))}
                                                    next2Label={''}
                                                    prev2Label={''}
                                                    className={'calendar_select_date'}
                                                />
                                            </>
                                        )}
                                        <button className="select-time-button" style={{ marginTop: '20px' }} onClick={() => moveToContactInfo()}>Confirm</button>
                                    </div>)
                                )}
                            {bookACallContactInfoPopup &&
                                (
                                    (<div className="book-a-call-popup-content-right" style={{ width: window.innerWidth >= 600 ? '70%' : '100%' }}>
                                        <div className="book-a-call-popup-contact-info-right">
                                            <h3>Enter Contact Details</h3>
                                            <p>Name *</p>
                                            <input type="text" value={nameValue} onChange={(e) => setNameValue(e.target.value)} required />
                                            <p>Email *</p>
                                            <input type="email" value={emailValue} onChange={(e) => setEmailValue(e.target.value)} required />
                                            <p>Phone Number *</p>
                                            <input
                                                type="tel"
                                                value={phoneValue}
                                                onChange={(e) => {
                                                    const newValue = e.target.value.replace(/\D/g, ''); // remove non-numeric characters
                                                    setPhoneValue(newValue);
                                                }}
                                                required
                                            />                                            <div className='green-message'>
                                                <IoShieldCheckmark style={{ color: 'green', fontSize: '4.5rem' }} />
                                                <p className='green-message-text'>Capitalflo offers a completely <span style={{ fontWeight: 'bold', color: 'green' }}>spam-free</span> experience. We will never contact you unless you request us to do so. </p>
                                            </div>
                                        </div>
                                        <button className="select-time-button" style={{ marginTop: '20px' }} onClick={() => scheduleEvent()}>Schedule event</button>
                                    </div>)
                                )}
                        </div>
                    </div>}



                    {/*<div className='mid-body' data-aos="fade-down">*/}
                    <div className='mid-body'>
                        <div className='mid-hero-text'>
                            <p>All Your Finances In One Place</p>
                        </div>
                        {/*<div className='mid-img-container' data-aos="fade-in" data-aos-duration="500">*/}
                        <div className='mid-img-container'>
                            <img src={fintech} alt=""></img>
                        </div>
                    </div>
                </div>


                {/* <div className='carousel-div'>
                    <div className='mid-hero-text'>
                        <p>Discover the banks and financial institutions our product can analyze for you</p>
                    </div>
                    <Carousel
                        responsive={responsive_bank_logos}
                        ssr
                        infinite={false}
                        containerClass="container-with-dots"
                        itemClass="image-item"
                        autoPlay={false}
                        autoPlaySpeed={2000}
                        partialVisible
                        pauseOnHover={false}
                        showDots={false}
                    >
                        {companyData.map((company, index) => (
                            <div key={index}>
                                <img src={company.logo} alt={company.name} className='bank-carousel' style={{}} />
                            </div>
                        ))}
                    </Carousel>
                </div> */}
                <div className='bank-ss-div'>
                    <div className='mid-hero-text'>
                        <p>Discover your monthly spending insights in 2 min with our bank statement analyser!</p>
                    </div>
                    <div className='bank-ss-container'>
                        <img src={'https://capitalflophotos.s3.ap-south-1.amazonaws.com/mockup-ios/bank+statement.png'} alt="" />
                        <img src={'https://capitalflophotos.s3.ap-south-1.amazonaws.com/mockup-ios/statement+insights.png'} alt="" />
                    </div>
                </div>
                <div className='carousel-div'>
                    <div className='mid-hero-text'>
                        <p>Spending Insights available for these banks :</p>
                    </div>
                    <Carousel
                        responsive={responsive_bank_logos}
                        ssr
                        infinite={false}
                        containerClass="container-with-dots"
                        itemClass="image-item"
                        autoPlay={false}
                        autoPlaySpeed={2000}
                        partialVisible
                        pauseOnHover={false}
                        showDots={false}
                    >
                        {companyData.map((company, index) => (
                            <div key={index}>
                                <img src={company.logo} alt={company.name} className='bank-carousel' style={{}} />
                            </div>
                        ))}
                    </Carousel>
                </div>


                <div className='home-blog-subscribe'>
                    <form onSubmit={handleSubscribe}>
                        <input type="email" placeholder="Enter your email for our latest blogs" />
                        <button type="submit">Subscribe</button>
                    </form>
                </div>

                <footer className='footerContainer'>
                    <div className='footer-top'>
                        <div className='footer-top-left'>
                            <img src={logo} alt="" />
                            <p>Your Personal Financial Planner</p>
                        </div>
                        <div className='playstation'>
                            <Link to="https://apps.apple.com/us/app/capitalflo/id6468636479" target="_blank"><img
                                src={apple}
                                alt="" /></Link>
                            <Link className='googleplayImg'
                                to="https://play.google.com/store/apps/details?id=com.capitalfloandroid"
                                target="_blank"><img
                                    src={google}
                                    alt="" /></Link>
                        </div>
                    </div>
                    <div className='footer-mid'>
                        <div className='footer-mid-left'>
                            <div className='footer-mid-left-list'>
                                <ul>
                                    {/*<li><Link to="https://capitalflo.co/contactUs">Contact us</Link></li>*/}
                                    {/*<li><Link to="https://capitalflo.co/faqs">FAQS</Link></li>*/}
                                    {/*<li><Link to="https://capitalflo.co/blog/home">Blog</Link></li>*/}
                                    {/*<li><Link to="https://capitalflo.co/blog/home">Subscribe</Link></li>*/}
                                </ul>
                            </div>
                        </div>
                        <div className='footer-mid-right'>
                            <div className='footer-mid-top-right'>
                                <ul>
                                    <li><Link to="https://capitalflo.co/contactUs">Contact us</Link></li>
                                    <li><Link to="https://capitalflo.co/faqs">FAQS</Link></li>
                                </ul>
                            </div>
                            <div className='footersocial'>
                                <ul>
                                    {/* <li></li> */}
                                    <li><Link to="https://www.linkedin.com/company/capitalflo/"
                                        target="_blank"><IoLogoLinkedin
                                            className='linkedin' /></Link></li>
                                    <li><Link to="https://www.instagram.com/capitalflo.co/"
                                        target="_blank"><AiFillInstagram
                                            className='instagram' /></Link></li>
                                    <li><Link to="https://twitter.com/Capitalfloclub" target="_blank"><RiTwitterXFill
                                        className='twitter' /></Link></li>
                                    <li><Link to="https://www.youtube.com/@Capitalflo" target="_blank"><BsYoutube
                                        className='youtube' /></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='lining'>
                        {/*<hr/>*/}
                    </div>

                    <div className='footer-bottom'>
                        <div className='footer-bottom-left'>
                            <div className='copyrightSM'>
                                <p>Copyright © 2023 Silicon Murphy (OPC) Private Limited.</p>
                                <p>All rights reserved.</p>
                            </div>
                        </div>
                        <div className='footer-bottom-right'>
                            <ul>
                                <li><Link to="https://capitalflo.co/terms-of-service">Terms of Service</Link></li>
                                <li><Link to="https://capitalflo.co/privacy_policy">Privacy Policy</Link></li>
                            </ul>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    )
}

export default Home
