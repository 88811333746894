import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Services.css'
import transaction from '../assets/img/Userhome1.png'
import budget from '../assets/img/Userhome3.png'
import Bank from '../assets/img/BankStatement.png'
import TFP from '../assets/img/TFP.png'
import insights from '../assets/img/insights.png'
import goal from '../assets/goals.png'
import discover from '../assets/img/discover.png'
import financialAdvisor from '../assets/img/Userhome4-removebg-preview.png'
import Loghead from '../PostHeader';
import { Link } from 'react-router-dom';
import ResponsiveModal from '../ResponsiveModal';
import mixpanel from "../utils/Mixpanel";
import WelcomePopup from "./WelcomePopup";
import financial from '../assets/FinancialHealthNew.png';
import "./Transaction.css";
import apple from "../assets/storeimg/App Store.png";
import google from "../assets/storeimg/Google Play.png";
import Logheader from "../PostHeader";
import {GrCircleInformation} from "react-icons/gr";
import { Helmet } from 'react-helmet';


function Services() {
    const [isModalOpen, setModalOpen] = useState(true);
    // const [isModalOpen, setModalOpen] = useState(false);
    // const [isModalOpen, setModalOpen] = useState(false);
    const [Tokens, setTokens] = useState('');
    const [showDemoHeader, setShowDemoHeader] = useState(false);
    const [headerHeight, setHeaderHeight] = useState(100);
    const [showPopup, setShowPopup] = useState(false)
    const openModal = () => {
        setModalOpen(false);
    };

    const closeModal = () => {
        setModalOpen(false);
    };


    const callingGmail = async () => {
        await gmailCall();
    }
    useEffect(() => {
        const demoAccountSetupTime = localStorage.getItem('demoAccountSetupTime');
        if (demoAccountSetupTime) {
            setShowDemoHeader(true);
        }
    })

    const handleCancel = () => {
        setShowPopup(false)
    }

    const checkFirstTimeSignUp = async () => {
        console.log("firstTimeSignUp____________________", await localStorage.getItem("firstTimeSignUp"))
        if (await localStorage.getItem("firstTimeSignUp") === 'true') {
            setShowPopup(true)
            await localStorage.setItem("firstTimeSignUp", "false")
            console.log('!@#$%^&*()!@#$%^&*()    FIRST TIME     !@#$%^&*()!@#$%^&*()')
        } else {
            console.log('!@#$%^&*()!@#$%^&*()    NOT FIRST TIME     !@#$%^&*()!@#$%^&*()')
        }
    }

    useEffect(() => {
        // setShowPopup(true)
        checkFirstTimeSignUp()
        const tokenize = async () => {
            try {
                const response = await axios.get('https://api.capitalflo.co/api/users/protect-user', { withCredentials: true });
                console.log('response');
                console.log(response);
                if (response.data.message === 'Cookie not found') {
                    setTokens(null);
                } else {
                    console.log(response.data.jwtToken);
                    setTokens(response.data.jwtToken);
                }
            } catch (err) {
                console.log(err.response.data.message);
                setTokens(null);
            }
        }
        tokenize()
    })

    const tryNow = () => {
        document.cookie = `jwt=; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
        localStorage.clear();
        window.location.href = `/login`;
    }


    const gmailCall = async () => {
        mixpanel.track("Web Gmail Auth Clicked");
        window.location.href = `https://api.capitalflo.co/api/users/authorize?token=${Tokens}`;
    };

    useEffect(() => {
        const headerElement = document.getElementById('post-header');
        const headerHeight = headerElement.clientHeight ? headerElement.clientHeight : 100;
        setHeaderHeight(headerHeight);

    })

    return (
        <div style={{
            width: '100vw',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
        }
        }>
            {/* <Header user={true} /> */}
            <Loghead />
            <Helmet>
                <title>Services</title>
                <meta name="description"
                    content="Home page for Capital Flo" />
            </Helmet>
            <div style={{ marginTop: headerHeight }}></div>
            {showDemoHeader &&
                (
                    <div className='demo-header'>
                        <div className='demo-header-content'>
                            <p>ℹ️ You are currently using a <b>Demo Account.</b> To experience our product, sign up now</p>
                            <button onClick={tryNow} className='try-now'>Try Now →</button>
                        </div>
                    </div>
                )}

            <div className='servicesContainer'>
                <div className='services services-custom' >


                    {/*<Link to="https://capitalflo.co/spending">*/}
                    {/*    <div className='spending'>*/}
                    {/*        <img src={spending} alt=""/>*/}
                    {/*        <p>Spending Insights</p>*/}
                    {/*    </div>*/}
                    {/*</Link>*/}

                    <Link to="https://capitalflo.co/bank-statements">
                        <div className='budget' >
                            <img src={Bank} alt="" />
                            <p>Bank Statement</p>
                        </div>
                    </Link>

                    <Link to="https://capitalflo.co/bank-statement-insights">
                        <div className='budget'>
                            <img src={insights} alt="" style={{ marginBottom: '10px' }} />
                            <p>Bank Statement Insights</p>
                        </div>
                    </Link>

                    <Link to="https://capitalflo.co/plan-budget">
                        <div className='budget'>
                            <img src={budget} alt="" style={{ marginBottom: '10px' }} />
                            <p>Plan Budget</p>
                        </div>
                    </Link>

                    <Link to="https://capitalflo.co/total-financial-position">
                        <div className='TFP_Services'>
                            <img src={TFP} alt="" />
                            <p >Total Financial Position</p>
                            <p >& Net Worth</p>
                        </div>
                    </Link>
                    {showPopup && <WelcomePopup
                        message={"Welcome to Capitalflo"}
                        onCancel={handleCancel}
                    />}
                    <Link to='https://capitalflo.co/financial-health'>
                        <div className='budget'>
                            <img src={financial} alt="" style={{ marginBottom: '10px' }} />
                            <p>Financial Health</p>
                            <p >& Safety</p>
                        </div>
                    </Link>
                    <Link to="https://capitalflo.co/goals">
                        <div className='budget'>
                            <img src={goal} alt="" style={{ marginBottom: '10px' }} />
                            <p>Goals</p>
                        </div>
                    </Link>
                    <Link to='https://capitalflo.co/financial-advisor'>
                        <div className='chartered_account'>
                            <img src={financialAdvisor} alt="" />
                            <p >Chartered Accountant (CA)</p>
                            <p >& Financial Advisor</p>
                        </div>
                    </Link>
                    {/* <Link to='https://capitalflo.co/transaction'>
                        <div className='budget'>
                            <img src={transaction} alt="" />
                            <p>Transactions</p>
                        </div>
                    </Link> */}
                </div>
            </div>

            {/*<div className='track'>*/}
            {/*    <div className='arrow arrowmob'>*/}
            {/*        <img src={arraow} alt="" className="arrowimg arrowimgmob"></img>*/}
            {/*    </div>*/}
            {/*    <div onClick={callingGmail} className='trackBtn '>Auto Track Expense*/}
            {/*        /!* <div className='googlesigntryforfree'> *!/*/}
            {/*        <GoogleSignInButton/>*/}
            {/*        /!* </div> *!/*/}
            {/*    </div>*/}
            {/*    <div onClick={callingGmail} className='trackBtnMob'>*/}
            {/*        Auto Track Expense*/}
            {/*        <GoogleSignInButton style={{paddingLeft: '0px', paddingRight: "5px"}}/>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <ResponsiveModal isOpen={isModalOpen} onClose={closeModal} />

        </div>
    )
}

export default Services
