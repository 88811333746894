import React, {useEffect, useState} from 'react';
import logo from './assets/img/footerlogo.png';
import './Logheader.css'
import axios from 'axios';
import {FaUserAlt} from 'react-icons/fa';
import {IconContext} from "react-icons";
import {Link, useLocation} from 'react-router-dom';


function Loginheader(props) {
    const [account, setaccount] = useState(false);
    const [Tokens, setTokens] = useState('');
    const [email, setEmail] = useState('');
    const [banks, setBanks] = useState([]);

    const Logout = () => {
        console.log('logout');
        setTokens('');
        setEmail('');
        const cookieName = 'api.capitalflo.co';

        document.cookie = `${cookieName}=; expires=0; path=/;`;
    };

    const tokenize = async () => {
        try {
            const response = await axios.get(
                "https://api.capitalflo.co/api/users/protect-user",
                {withCredentials: true}
            );
            if (response.data.message === "Cookie not found") {
                setTokens(null);
            } else {
                setTokens(response.data.jwtToken);
            }
        } catch (err) {
            console.log(err.response.data.message);
            setTokens(null);
        }
    };

    const setUserEmail = async () => {
        try {
            const response = await axios.get(
                `https://api.capitalflo.co/api/users/userprofile`,
                {
                    headers: {
                        Authorization: `Bearer ${Tokens}`,
                    },
                    withCredentials: true
                }
            );
            if (response.data.message === "User not found") {
                setEmail(null);
            } else {
                console.log(response.data);
                setEmail(response.data.email);
                setBanks(response.data.banks || []);
            }
        } catch (err) {
            setEmail(null);
        }
    };

    const location = useLocation();

    useEffect(() => {

        if (location.pathname !== '/authentication') {
            return;
        } else {
            tokenize();
            setUserEmail();
        }
    }, []);

    const accprofile = () => {
        setaccount(!account);
    };

    const checker = () => {
        console.log(banks);
    };

    return (
        <div className='fixed-header'> {/* Add a wrapper for the fixed header */}
            <div className='postloghead'>
                <div className='logolog'>
                    <Link to='https://capitalflo.co'>
                        <img src={logo} alt='logo' onClick={checker}/>
                    </Link>
                </div>
                <div className='servicehome HomeNone'>
                    <li>
                        <Link to='https://capitalflo.co/services'>Home</Link>
                    </li>
                </div>
                <div className='profilelog'>
                    <IconContext.Provider
                        value={{color: '#1C98ED', size: '30px', className: 'userprofileIconlog'}}
                    >
                        <div onClick={accprofile}>
                            <Link to='https://capitalflo.co/profile'>
                                <FaUserAlt/>
                            </Link>
                        </div>
                    </IconContext.Provider>
                </div>
            </div>
        </div>
    );
}

export default Loginheader;
