import React, { useEffect, useState } from 'react';
import './FinancialAdvisor.css';
import Loghead from "../PostHeader";
import { CiLock } from "react-icons/ci";
import LoadingAnimation from "../assets/Loading.svg";
import { Helmet } from 'react-helmet';
import { GrCircleInformation } from "react-icons/gr";
import { isDemoAccountUsed } from '../utils/CheckIfDemo';
import DemoBlockerPopup from './DemoBlockerPopup';

const FinancialAdvisor = () => {
    const [isUnlocked, setIsUnlocked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isInfoVisibleFA, setisInfoVisibleFA] = useState(false);
    const [showDemoPopup, setShowDemoPopup] = useState(false);

    const handleUnlock = () => {
        // Implement the logic to handle the unlock action, e.g., initiate the payment process
        // Once the payment is successful, update the state to unlock the content
        setIsUnlocked(true);
    };
    const showInfoFA = () => {
        setisInfoVisibleFA(true)
    }
    const hideInfoFA = () => {
        setisInfoVisibleFA(false)
    }

    useEffect(() => {
        if (isDemoAccountUsed()) {
            return;
        }
        const rzpPaymentForm = document.getElementById("rzp_payment_form");

        if (!rzpPaymentForm.hasChildNodes()) {
            const script = document.createElement("script");
            script.src = "https://checkout.razorpay.com/v1/payment-button.js";
            script.async = true;
            script.dataset.payment_button_id = "pl_Nafq5Mu0N4e9mz";
            rzpPaymentForm.appendChild(script);
        }
    });
    const closePopup = () => {
        setShowDemoPopup(false);
    }

    return (
        <div style={{
            width: '100vw',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
        }
        }>
            {/* <Header user={true} /> */}
            <Loghead/>
            <Helmet>
                <title>Financial Advisor</title>
                <meta name="description"
                    content="Advisor" />
            </Helmet>
            <Loghead />
            {showDemoPopup && <DemoBlockerPopup closePopup={closePopup} />}
            <div style={{
                display: 'flex',
                flexDirection: 'row'
            }}>
                <p style={{
                    fontSize: '35px',
                    fontWeight: 'bold',
                    marginTop: '150px',
                    textAlign: 'center'
                }}>Chartered Accountant (CA)<br />   & Financial Advisor</p>
                <span onMouseOver={showInfoFA} onMouseOut={hideInfoFA}>
                    <div style={{ fontSize: '24px', paddingLeft: '10px', marginTop: '24vh' }}>
                        <GrCircleInformation />
                    </div>
                    <div
                        className={isInfoVisibleFA ? "isInfoVisibleFA visible" : "isInfoVisibleFA"}>
                        <p>
                        </p>
                    </div>
                </span>
            </div>
            {loading ? (<div className='blog_loading_animation'><img src={LoadingAnimation} alt="" /></div>) :
                (
                    <div className='main-body-div'>
                        {isUnlocked ? (
                            <div className="unlocked-content"> {/* Apply the unlocked content style */}
                                <h2>Financial Advisor</h2>
                                <p>This feature is coming soon.</p>
                            </div>
                        ) : (
                            <div className="locked-content"> {/* Apply the locked content style */}
                                <div className='locked-content-heading'>
                                    <h2>Financial Advisor</h2>
                                    <CiLock />
                                </div>
                                <p>This content is locked. Pay to unlock</p>
                                {!isDemoAccountUsed() ? (
                                    <form id="rzp_payment_form" ></form>

                                ) : (
                                    <button style={{ backgroundColor: '#004aad', color: 'white', fontWeight: 'bold', padding: '10px 22px', borderRadius: '5px', cursor: 'pointer' }} onClick={() => setShowDemoPopup(true)}>Pay Now</button>
                                )}
                            </div>
                        )}
                    </div>
                )}
        </div>
    );
};
export default FinancialAdvisor;
