import React, {useEffect, useReducer, useRef, useState} from 'react';
import goal from "../assets/img/goal.png";
import add from "../assets/img/addd.png";
import {GrCircleInformation} from "react-icons/gr";
import axios from "axios";
import "./Bank/Tfp.css";
import "../Logheader"
import Logheader from "../PostHeader";
import LoadingAnimation from "../assets/Loading.svg";
import {Link} from "react-router-dom";
import { isDemoAccountUsed } from '../utils/CheckIfDemo';
import DemoBlockerPopup from './DemoBlockerPopup';
import { elements } from 'chart.js';
import { Helmet } from 'react-helmet';
function Goals() {
    const goalsSectionRef = useRef(null);
    const [isInfoVisibleEmergency, setIsInfoVisibleEmergency] = useState(false);
    const[isInfoVisibleGoals, setisInfoVisibleGoals] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [goalsSet, setGoalsSet] = useState(false);
    const [addgoalsrows, setAddgoalsRows] = useState([]);
    const [isGoalsDeleted, setGoalsDeletedFlag] = useState(false);
    const [deletedGoalsRows, setDeletedGoalsRows] = useState([]);
    const [EmergencyAmount, setEmergencyAmount] = useState("");
    const [TargetEmergencyAmount, setTargetEmergencyAmount] = useState("");
    const [VacationAmount, setVacationAmount] = useState("");
    const [TargetVacationAmount, setTargetVacationAmount] = useState("");
    const [VechicleAmount, setVechicleAmount] = useState("");
    const [TargetVechicleAmount, setTargetVechicleAmount] = useState("");
    const [HomeAmount, setHomeAmount] = useState("");
    const [TargetHomeAmount, setTargetHomeAmount] = useState("");
    const [EducationAmount, setEducationAmount] = useState("");
    const [TargetEducationAmount, setTargetEducationAmount] = useState("");
    const [RetirementAmount, setRetirementAmount] = useState("");
    const [TargetRetirementAmount, setTargetRetirementAmount] = useState("");
    const [MarriageAmount, setMarriageAmount] = useState("");
    const [TargetMarriageAmount, setTargetMarriageAmount] = useState("");
    const [Tokens, setTokens] = useState("")
    const [targetTotal, setTargetTotal] = useState("");
    const [savedTotal, setSavedTotal] = useState("");
    const [balanceTotal, setBalanceTotal] = useState("");
    const [goalsBoxSize, setGoalsBoxSize] = useState({
        emergency: false,
        vacation: false,
        vehicle: false,
        home: false,
        education: false,
        marriage: false,
        retirement: false
    })
    const [otherGoals, setOtherGoals] = useState([]);
    const initialState = {
        EmergencyTerm: "",
        VacationTerm: "",
        VechicleTerm: "",
        HomeTerm: "",
        EducationTerm: "",
        RetirementTerm: "",
        MarriageTerm: "",
        OtherGoalsTerms: [],
    };
    const date = new Date();
    const year = date.getFullYear();
    const [customfocus, handleCustomFocus] = useState(false)
    const [loading, setLoading] = useState(true);
    const [showDemoPopup, setShowDemoPopup] = useState(false);

    const reducer = (state, action) => {
        switch (action.type) {
            case 'SET_EMERGENCY_TERM':
                return {...state, EmergencyTerm: action.payload};
            case 'SET_VACATION_TERM':
                return {...state, VacationTerm: action.payload};
            case 'SET_VECHICLE_TERM':
                return {...state, VechicleTerm: action.payload};
            case 'SET_HOME_TERM':
                return {...state, HomeTerm: action.payload};
            case 'SET_EDUCATION_TERM':
                return {...state, EducationTerm: action.payload};
            case 'SET_RETIREMENT_TERM':
                return {...state, RetirementTerm: action.payload};
            case 'SET_MARRIAGE_TERM':
                return {...state, MarriageTerm: action.payload};
            case 'OTHER_GOALS':
                return {...state, OtherGoalsTerms: action.payload};
            case 'DELETE_OTHER_GOALS':
                const goalToDelete = action.payload;

                // Create a new array without the goal to delete
                const updatedOtherGoals = state.OtherGoalsTerms.filter(
                    goal => goal.title !== goalToDelete.title
                );

                // Return the updated state
                return {...state, OtherGoalsTerms: updatedOtherGoals};

        }
    };
    const inititalSelection = {
        EmergencyOptions: "",
        VacationOptions: "",
        VechicleOptions: "",
        HomeOptions: "",
        EducationOptions: "",
        RetirementOptions: "",
        MarriageOptions: "",
        OtherGoalsOptions: [],
    }
    const optionChanger = (state, action) => {
        switch (action.type) {
            case 'SET_EMERGENCY_OPTION':
                return {...state, EmergencyOptions: action.payload};
            case 'SET_VACATION_OPTION':
                return {...state, VacationOptions: action.payload};
            case 'SET_VEHICLE_OPTION':
                return {...state, VechicleOptions: action.payload};
            case 'SET_HOME_OPTION':
                return {...state, HomeOptions: action.payload};
            case 'SET_EDUCATION_OPTION':
                return {...state, EducationOptions: action.payload};
            case 'SET_RETIREMENT_OPTION':
                return {...state, RetirementOptions: action.payload};
            case 'SET_MARRIAGE_OPTION':
                return {...state, MarriageOptions: action.payload};
            case 'OTHER_GOALS_OPTION':
                return {...state, OtherGoalsOptions: action.payload};
            case 'DELETE_OPTION':
                const goalToDelete = action.payload;
                // Create a new array without the goal to delete
                const updatedOtherGoals = state.OtherGoalsOptions.filter(
                    goal => goal.title !== goalToDelete.title
                );
                // Return the updated state
                return {...state, OtherGoalsOptions: updatedOtherGoals};
        }
    }


    const [state, dispatch] = useReducer(reducer, initialState)
    const [selectedOption, changeFunction] = useReducer(optionChanger, inititalSelection)
    const [isSaved, setisSaved] = useState(true);

    const balanceCal = (target , saved) => {
        return (parseInt(target) || 0) - (parseInt(saved) || 0);
    }
    useEffect(() => {
        const total = 
        (parseInt(TargetEmergencyAmount) || 0) +
        (parseInt(TargetEducationAmount) || 0) +
        (parseInt(TargetHomeAmount) || 0) +
        (parseInt(TargetMarriageAmount) || 0) +
        (parseInt(TargetRetirementAmount) || 0) +
        (parseInt(TargetVechicleAmount) || 0) +
        (parseInt(TargetVacationAmount) || 0);
        const addgoalsTotal = addgoalsrows.reduce((sum, goal) => sum + goal.targetamount, 0);
        const emergencyBalance = balanceCal(TargetEmergencyAmount, EmergencyAmount);
        const educationBalance = balanceCal(TargetEducationAmount, EducationAmount);
        const homeBalance = balanceCal(TargetHomeAmount, HomeAmount);
        const marriageBalance = balanceCal(TargetMarriageAmount, MarriageAmount);
        const retirementBalance = balanceCal(TargetRetirementAmount, RetirementAmount);
        const vechicleBalance = balanceCal(TargetVechicleAmount, VechicleAmount);
        const vacationBalance = balanceCal(TargetVacationAmount, VacationAmount);
        const addgoalsBalance  = addgoalsrows.reduce((sum, goal) => sum + ((goal.targetamount || 0)-(goal.amount|| 0)), 0);
        console.log('Target Sum',total+addgoalsTotal)
        console.log('BALANCE',emergencyBalance+educationBalance+homeBalance+marriageBalance+retirementBalance+vechicleBalance+vacationBalance+addgoalsBalance)
        setBalanceTotal(emergencyBalance+educationBalance+homeBalance+marriageBalance+retirementBalance+vechicleBalance+vacationBalance+addgoalsBalance)
        setTargetTotal(total+addgoalsTotal)
    },[TargetEducationAmount,TargetEmergencyAmount,TargetHomeAmount,TargetMarriageAmount,TargetRetirementAmount,TargetVechicleAmount,TargetVacationAmount, addgoalsrows])
    useEffect(() => {
        const total = 
        (parseInt(EmergencyAmount) || 0) +
        (parseInt(EducationAmount) || 0) +
        (parseInt(HomeAmount) || 0) +
        (parseInt(RetirementAmount) || 0) +
        (parseInt(MarriageAmount) || 0) +
        (parseInt(VechicleAmount) || 0) +
        (parseInt(VacationAmount) || 0);
        const addgoalsTotal = addgoalsrows.reduce((sum, goal) => sum + goal.amount, 0);
        const emergencyBalance = balanceCal(TargetEmergencyAmount, EmergencyAmount);
        const educationBalance = balanceCal(TargetEducationAmount, EducationAmount);
        const homeBalance = balanceCal(TargetHomeAmount, HomeAmount);
        const marriageBalance = balanceCal(TargetMarriageAmount, MarriageAmount);
        const retirementBalance = balanceCal(TargetRetirementAmount, RetirementAmount);
        const vechicleBalance = balanceCal(TargetVechicleAmount, VechicleAmount);
        const vacationBalance = balanceCal(TargetVacationAmount, VacationAmount);
        const addgoalsBalance  = addgoalsrows.reduce((sum, goal) => sum + ((goal.targetamount || 0)-(goal.amount|| 0)), 0);
        console.log('Target Sum',total+addgoalsTotal)
        console.log('BALANCE',emergencyBalance+educationBalance+homeBalance+marriageBalance+retirementBalance+vechicleBalance+vacationBalance+addgoalsBalance)
        console.log('Amount Sum',total+addgoalsTotal)
        setSavedTotal(total+addgoalsTotal)
        setBalanceTotal(emergencyBalance+educationBalance+homeBalance+marriageBalance+retirementBalance+vechicleBalance+vacationBalance+addgoalsBalance)
    },[EducationAmount,EmergencyAmount,HomeAmount,MarriageAmount,RetirementAmount,VechicleAmount,VacationAmount, addgoalsrows])

 const handleInputChange = () => {
        // This function will be called whenever the user types in any input field
        setIsTyping(true); // Set isTyping to true to show the "Save" button
    };
    const showInfoEmergency = () => {
        setIsInfoVisibleEmergency(true);
    };
    const hideInfoEmergency = () => {
        setIsInfoVisibleEmergency(false);
    };
    const showInfoGoals = () => {
        setisInfoVisibleGoals(!isInfoVisibleGoals);
    }
    const hideInfoGoals = () => {
        setisInfoVisibleGoals(false);
    }

    const handleEmergencyTerm = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setGoalsBoxSize((prevState) => ({
                ...prevState,
                emergency: true
            }))
            e.target.blur()
        } else {
            setGoalsBoxSize((prevState) => ({
                ...prevState,
                emergency: false
            }))
        }

        if (!isNaN(intValue)) {
            dispatch({type: 'SET_EMERGENCY_TERM', payload: intValue});
            changeFunction({type: 'SET_EMERGENCY_OPTION', payload: intValue});
        } else {
            dispatch({type: 'SET_EMERGENCY_TERM', payload: ""});
            changeFunction({type: 'SET_EMERGENCY_OPTION', payload: ""});
        }
        handleInputChange();
    };
    const handleVacationTerm = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setGoalsBoxSize((prevState) => ({
                ...prevState,
                vacation: true
            }))
            e.target.blur()
        } else {
            setGoalsBoxSize((prevState) => ({
                ...prevState,
                vacation: false
            }))
        }
        if (!isNaN(intValue)) {
            dispatch({type: 'SET_VACATION_TERM', payload: intValue});
            changeFunction({type: 'SET_VACATION_OPTION', payload: intValue});
        } else {
            dispatch({type: 'SET_VACATION_TERM', payload: ""});
            changeFunction({type: 'SET_VACATION_OPTION', payload: ""});
        }
        handleInputChange();

    };
    const handleVehicleTerm = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setGoalsBoxSize((prevState) => ({
                ...prevState,
                vehicle: true
            }))
            e.target.blur()
        } else {
            setGoalsBoxSize((prevState) => ({
                ...prevState,
                vehicle: false
            }))
        }
        if (!isNaN(intValue)) {
            dispatch({type: 'SET_VECHICLE_TERM', payload: intValue});
            changeFunction({type: 'SET_VEHICLE_OPTION', payload: intValue});
        } else {
            dispatch({type: 'SET_VECHICLE_TERM', payload: ""});
            changeFunction({type: 'SET_VEHICLE_OPTION', payload: ""});

        }
        handleInputChange();

    };

    const handleHomeTerm = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setGoalsBoxSize((prevState) => ({
                ...prevState,
                home: true
            }))
            e.target.blur()
        } else {
            setGoalsBoxSize((prevState) => ({
                ...prevState,
                home: false
            }))
        }
        if (!isNaN(intValue)) {
            dispatch({type: 'SET_HOME_TERM', payload: intValue});
            changeFunction({type: 'SET_HOME_OPTION', payload: intValue});
        } else {
            dispatch({type: 'SET_HOME_TERM', payload: ""});
            changeFunction({type: 'SET_HOME_OPTION', payload: ""});

        }
        handleInputChange();

    };
    const handleEducationTerm = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setGoalsBoxSize((prevState) => ({
                ...prevState,
                education: true
            }))
            e.target.blur()
        } else {
            setGoalsBoxSize((prevState) => ({
                ...prevState,
                education: false
            }))
        }
        if (!isNaN(intValue)) {
            dispatch({type: 'SET_EDUCATION_TERM', payload: intValue});
            changeFunction({type: 'SET_EDUCATION_OPTION', payload: intValue});

        } else {
            dispatch({type: 'SET_EDUCATION_TERM', payload: ""});
            changeFunction({type: 'SET_EDUCATION_OPTION', payload: ""});

        }
        handleInputChange();

    };
    const handleMarriageTerm = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setGoalsBoxSize((prevState) => ({
                ...prevState,
                marriage: true
            }))
            e.target.blur()
        } else {
            setGoalsBoxSize((prevState) => ({
                ...prevState,
                marriage: false
            }))
        }
        if (!isNaN(intValue)) {
            dispatch({type: 'SET_MARRIAGE_TERM', payload: intValue});
            changeFunction({type: 'SET_MARRIAGE_OPTION', payload: intValue});

        } else {
            dispatch({type: 'SET_MARRIAGE_TERM', payload: ""});
            changeFunction({type: 'SET_MARRIAGE_OPTION', payload: ""});

        }
        handleInputChange();
    }

    const handleRetirementTerm = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setGoalsBoxSize((prevState) => ({
                ...prevState,
                retirement: true
            }))
            e.target.blur()
        } else {
            setGoalsBoxSize((prevState) => ({
                ...prevState,
                retirement: false
            }))
        }
        if (!isNaN(intValue)) {
            dispatch({type: 'SET_RETIREMENT_TERM', payload: intValue});
            changeFunction({type: 'SET_RETIREMENT_OPTION', payload: intValue});
        } else {
            dispatch({type: 'SET_RETIREMENT_TERM', payload: ""});
            changeFunction({type: 'SET_RETIREMENT_OPTION', payload: ""});
        }
        handleInputChange();
    }

    useEffect(() => {
        console.log("other goals", otherGoals)
        console.log("STATE: ", state)
    }, [otherGoals, state]);

    const handleGoalsTerm = (index, title, e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        const updatedRows = [...addgoalsrows];
        if (!isNaN(intValue)) {
            setOtherGoals((prevGoals) => {
                return prevGoals.map((goal) => {
                    // Check if the title is a key in the object
                    if (Object.keys(goal).includes(title)) {
                        return {[title]: true};
                    }
                    return goal;
                });
            });
            e.target.blur();
        } else {
            setOtherGoals((prevGoals) => {
                return prevGoals.map((goal) => {
                    // Check if the title is a key in the object
                    if (Object.keys(goal).includes(title)) {
                        return {[title]: false};
                    }
                    return goal;
                });
            });
        }
        updatedRows[index].term = intValue;
        if (!isNaN(intValue)) {
            setGoalsSet(true);
        } else {
            setGoalsSet(false);
        }
        setAddgoalsRows(updatedRows);
        const updatedOtherGoalsTerms = addgoalsrows.map((goal) => ({
            title: goal.title,
            term: goal.term,
        }));
        changeFunction({type: 'OTHER_GOALS_OPTION', payload: updatedOtherGoalsTerms})
        dispatch({type: 'OTHER_GOALS', payload: updatedOtherGoalsTerms})
        handleInputChange();
    }


    const handleRowAddsetAddgoalsRows = () => {
        const newRow = {title: "", amount: "", targetamount: "", term: ""};
        setAddgoalsRows((prevRows) => {
            return [newRow, ...prevRows];
        });
    };

    const handleRowTitleChangeAddgoals = (index, e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        const updatedRows = [...addgoalsrows];
        updatedRows[index].title = e.target.value;
        setAddgoalsRows(updatedRows);

        const newGoal = {
            [updatedRows[index].title]: false,
        }

        setOtherGoals((prevGoals) => [...prevGoals, newGoal]);

    };

    const tokenize = async () => {
        try {
            const response = await axios.get(
                "https://api.capitalflo.co/api/users/protect-user",
                {withCredentials: true}
            );
            console.log(response);

            if (response.data.message === "Cookie not found") {
                setTokens(null);
            } else {
                console.log(response.data.jwtToken);
                setTokens(response.data.jwtToken);
            }
        } catch (err) {
            console.log(err.response.data.message);
            setTokens(null);
        }
    };
    useEffect(() => {
        console.log("add goals rows : ", addgoalsrows)
        console.log("selected options are : ", selectedOption)
    }, [addgoalsrows, selectedOption]);
    const handleRowDeleteGoals = (index) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Show a confirmation dialog before deleting the row
        const confirmDelete = window.confirm("Confirm Delete?");

        if (confirmDelete) {
            // Create a copy of the addgoalsrows state
            const updatedGoalsRows = [...addgoalsrows];

            // Remove the row at the specified index
            const deletedRow = updatedGoalsRows.splice(index, 1);
            console.log(deletedRow)
            // Update the state with the new rows
            setAddgoalsRows(updatedGoalsRows);
            const otherGoalsDeleted = otherGoals.filter(obj => !Object.keys(obj).includes(deletedRow[0].title))
            setOtherGoals(otherGoalsDeleted)
            dispatch({type: 'DELETE_OTHER_GOALS', payload: {title: deletedRow[0].title}})
            changeFunction({type: 'DELETE_OPTION', payload: {title: deletedRow[0].title}})

            // Update other states or perform necessary actions if needed
            setIsTyping(false);
            setGoalsDeletedFlag(true);
            setDeletedGoalsRows(updatedGoalsRows); // Use the updated rows
        }
    };

    const handleRowAmountChangeAddgoals = (index, e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        const updatedRows = [...addgoalsrows];
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        updatedRows[index].amount = isNaN(intValue) ? "" : intValue;
        setAddgoalsRows(updatedRows);
    };

    const handleEmergencyAmountChange = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setEmergencyAmount(intValue);
        } else {
            setEmergencyAmount("");
        }
        handleInputChange();

    };
    const formatNumber = (number) => {
        const parts = number.toString().split(".");
        if (parts.length === 2) {
            // If there's a decimal part, limit it to two decimal places
            return parts[0] + "." + parts[1].slice(0, 2);
        } else {
            // If there's no decimal part, just return the original number
            return number.toString();
        }
    };
    const abbreviateNumber = (number) => {
        if (isNaN(number) || !isFinite(number) || number === 0) {
            return '0'; // Handle NaN or Infinity case, return a default value
        }
        const sign = Math.sign(number);
        const absoluteNumber = Math.abs(number);

        const digits = absoluteNumber.toString().length;

        if (digits > 3 && digits <= 5) {
            // Abbreviate thousands
            const result = (sign * absoluteNumber / 1000);
            return result % 1 !== 0 ? formatNumber(result) + 'K' : result.toFixed(0) + 'K';
        } else if (digits >= 6 && digits <= 7) {
            // Abbreviate lakhs
            const result = (sign * absoluteNumber / 100000);
            return result % 1 !== 0 ? formatNumber(result) + 'L' : result.toFixed(0) + 'L';
        } else if (digits >= 8) {
            // Abbreviate crores
            const result = (sign * absoluteNumber / 10000000);
            return result % 1 !== 0 ? formatNumber(result) + 'Cr' : result.toFixed(0) + 'Cr';
        } else {
            // Default to the original number for smaller values
            return (sign * absoluteNumber).toFixed(0).trim();
        }
    };
    const handleRowTargetAmountChangeAddgoals = (index, e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        const updatedRows = [...addgoalsrows];
        updatedRows[index].targetamount = isNaN(intValue) ? "" : intValue; // Use TargetAmount property
        setAddgoalsRows(updatedRows);
        handleInputChange();
    };
    const handleTargetEmergencyAmountChange = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setTargetEmergencyAmount(intValue);
        } else {
            setTargetEmergencyAmount("");
        }
        handleInputChange();
    };
    const handleVacationAmountChange = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setVacationAmount(intValue);
        } else {
            setVacationAmount("");
        }
        handleInputChange();
    };
    const handleTargetVacationAmountChange = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setTargetVacationAmount(intValue);
        } else {
            setTargetVacationAmount("");
        }
        handleInputChange();
    };
    const handleVehicleAmountChange = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setVechicleAmount(intValue);
        } else {
            setVechicleAmount("");
        }
        handleInputChange();
    };
    const handleTargetVehicleAmountChange = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setTargetVechicleAmount(intValue);
        } else {
            setTargetVechicleAmount("");
        }
        handleInputChange();
    };
    const handleHomeAmountChange = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setHomeAmount(intValue);
        } else {
            setHomeAmount("");
        }
        handleInputChange();

    };
    const handleTargetHomeAmountChange = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setTargetHomeAmount(intValue);
        } else {
            setTargetHomeAmount("");
        }
        handleInputChange();

    };
    const handleEducationAmountChange = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setEducationAmount(intValue);
        } else {
            setEducationAmount("");
        }
        handleInputChange();

    };
    const handleTargetEducationAmountChange = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setTargetEducationAmount(intValue);
        } else {
            setTargetEducationAmount("");
        }
        handleInputChange();

    };
    const handleRetirementAmountChange = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setRetirementAmount(intValue);
        } else {
            setRetirementAmount("");
        }
        handleInputChange();

    };
    const handleTargetRetirementAmountChange = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setTargetRetirementAmount(intValue);
        } else {
            setTargetRetirementAmount("");
        }
        handleInputChange();

    };
    const handleMarriageAmountChange = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setMarriageAmount(intValue);
        } else {
            setMarriageAmount("");
        }
        handleInputChange();

    };
    const handleTargetMarriageAmountChange = (e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        if (!isNaN(intValue)) {
            setTargetMarriageAmount(intValue);
        } else {
            setTargetMarriageAmount("");
        }
        handleInputChange();

    };
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                "https://api.capitalflo.co/api/users/achieve-goals",
                {
                    headers: {
                        Authorization: `Bearer ${Tokens}`,
                    },
                    withCredentials: true,
                }
            );
            console.log("************")
            console.log("retreive:", response.data);
            console.log("************")
            if (response.data.message === "User not found") {
                console.log("user not found")
            } else {

                setEmergencyAmount(response.data.goalsObject.goals_data.Emergencyfund || "");
                setVacationAmount(response.data.goalsObject.goals_data.Vacation || "");
                setVechicleAmount(response.data.goalsObject.goals_data.Vehicle || "");
                setHomeAmount(response.data.goalsObject.goals_data.Home || "");
                setEducationAmount(response.data.goalsObject.goals_data.Education || "");
                setRetirementAmount(response.data.goalsObject.goals_data.Retirement || "");
                setMarriageAmount(response.data.goalsObject.goals_data.Marriage || "");
                setTargetEmergencyAmount(response.data.goalsObject.goals_data.TargetEmergencyfund || "");
                setTargetVacationAmount(response.data.goalsObject.goals_data.TargetVacation || "");
                setTargetVechicleAmount(response.data.goalsObject.goals_data.TargetVehicle || "");
                setTargetHomeAmount(response.data.goalsObject.goals_data.TargetHome || "");
                setTargetEducationAmount(response.data.goalsObject.goals_data.TargetEducation || "");
                setTargetRetirementAmount(response.data.goalsObject.goals_data.TargetRetirement || "");
                setTargetMarriageAmount(response.data.goalsObject.goals_data.TargetMarriage || "");
                const userDefinedGoals =
                    response.data.goalsObject.goals_data.userDefinedGoalsData || [];
                setAddgoalsRows(userDefinedGoals);
                const defaultGoalsDuration = response.data.goalsObject.goals_duration || "";

                Object.keys(defaultGoalsDuration).forEach((key) => {
                    let title = "";
                    if (key !== "OtherGoalsTerms") {
                        if (defaultGoalsDuration[key] !== "") {
                            console.log(defaultGoalsDuration[key])
                            switch (key) {
                                case 'EducationTerm': {
                                    title = 'education';
                                    dispatch({type: 'SET_EDUCATION_TERM', payload: defaultGoalsDuration[key]});
                                    changeFunction({type: 'SET_EDUCATION_OPTION', payload: defaultGoalsDuration[key]})
                                    break;
                                }
                                case 'EmergencyTerm': {
                                    title = 'emergency';
                                    dispatch({type: 'SET_EMERGENCY_TERM', payload: defaultGoalsDuration[key]});
                                    changeFunction({type: 'SET_EMERGENCY_OPTION', payload: defaultGoalsDuration[key]})
                                    break;
                                }
                                case 'HomeTerm': {
                                    title = 'home';
                                    dispatch({type: 'SET_HOME_TERM', payload: defaultGoalsDuration[key]});
                                    changeFunction({type: 'SET_HOME_OPTION', payload: defaultGoalsDuration[key]})
                                    break;
                                }
                                case 'MarriageTerm': {
                                    title = 'marriage';
                                    dispatch({type: 'SET_MARRIAGE_TERM', payload: defaultGoalsDuration[key]});
                                    changeFunction({type: 'SET_MARRIAGE_OPTION', payload: defaultGoalsDuration[key]})
                                    break;
                                }
                                case 'RetirementTerm': {
                                    title = 'retirement';
                                    dispatch({type: 'SET_RETIREMENT_TERM', payload: defaultGoalsDuration[key]});
                                    changeFunction({type: 'SET_RETIREMENT_OPTION', payload: defaultGoalsDuration[key]})
                                    break;
                                }
                                case 'VacationTerm': {
                                    title = 'vacation';
                                    dispatch({type: 'SET_VACATION_TERM', payload: defaultGoalsDuration[key]});
                                    changeFunction({type: 'SET_VACATION_OPTION', payload: defaultGoalsDuration[key]})
                                    break;
                                }
                                case 'VechicleTerm': {
                                    title = 'vehicle';
                                    dispatch({type: 'SET_VECHICLE_TERM', payload: defaultGoalsDuration[key]});
                                    changeFunction({type: 'SET_VEHICLE_OPTION', payload: defaultGoalsDuration[key]})
                                    break;
                                }
                                default:
                                    console.log("out of bounds")
                            }

                            setGoalsBoxSize((prevState) => ({
                                ...prevState,
                                [title]: true,
                            }));
                        } else {
                            setGoalsBoxSize((prevState) => ({
                                ...prevState,
                                [title]: false
                            }));
                        }
                    } else {
                        let otherGoalsArr = []
                        defaultGoalsDuration[key].forEach((element) => {
                            otherGoalsArr.push(element)
                            if (element.term) {
                                setOtherGoals((prevGoals) => [...prevGoals, {
                                    [element.title]: true
                                }]);
                            } else {
                                setOtherGoals((prevGoals) => [...prevGoals, {
                                    [element.title]: false
                                }]);
                            }
                        })
                        console.log("other goals array", otherGoalsArr)
                        setAddgoalsRows((prevState) =>
                            prevState.map((element) => ({
                                ...element,
                                term: otherGoalsArr.find((item) => item.title === element.title)?.term || element.term,
                            }))
                        );
                        dispatch({type: 'OTHER_GOALS', payload: otherGoalsArr});
                        changeFunction({type: 'OTHER_GOALS_OPTION', payload: otherGoalsArr})


                    }
                });
            }
            setLoading(false);
        } catch (err) {
            // setBudget(null);
            // setBudgetVariable(null);
            setLoading(false);
            console.log(err);
        }
    };
    useEffect(() => {
        tokenize();
        fetchData();

    }, []);
    const handleSaveDataToDb = async () => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        setisSaved(true)
        setIsTyping(false);

        const goals = [
            {title: "Emergency fund", amount: EmergencyAmount, targetamount: TargetEmergencyAmount},
            {title: "Vacation", amount: VacationAmount, targetamount: TargetVacationAmount},
            {title: "Vehicle", amount: VechicleAmount, targetamount: TargetVechicleAmount},
            {title: "Home", amount: HomeAmount, targetamount: TargetHomeAmount},
            {title: "Education", amount: EducationAmount, targetamount: TargetEducationAmount},
            {title: "Retirement", amount: RetirementAmount, targetamount: TargetRetirementAmount},
            {title: "Marriage", amount: MarriageAmount, targetamount: TargetMarriageAmount},
            ...addgoalsrows,
        ]


        // const totalSum = totalSumFixed + totalSumVariable;

        // Update the total sum state with the calculated total sum
        // setTotalSum(totalSum);


        // Combine all data into one object

        const dataGBeforeTrim = {

            Emergencyfund: EmergencyAmount,
            Vacation: VacationAmount,
            Vehicle: VechicleAmount,
            Home: HomeAmount,
            Education: EducationAmount,
            Retirement: RetirementAmount,
            Marriage: MarriageAmount,
            TargetEmergencyfund: TargetEmergencyAmount,
            TargetVacation: TargetVacationAmount,
            TargetVehicle: TargetVechicleAmount,
            TargetHome: TargetHomeAmount,
            TargetEducation: TargetEducationAmount,
            TargetRetirement: TargetRetirementAmount,
            TargetMarriage: TargetMarriageAmount,
            userDefinedGoalsData: addgoalsrows

        }
        const dataG = {}
        for (const key in dataGBeforeTrim) {
            if (key !== 'userDefinedGoalsData') {
                dataG[key] = dataGBeforeTrim[key] === "" ? 0 : dataGBeforeTrim[key];
            } else {
                // For userDefinedGoalsData, handle null values in the array of objects
                dataG[key] = dataGBeforeTrim[key].map(obj => ({
                    ...obj,
                    amount: obj.amount === "" ? 0 : obj.amount,
                    targetamount: obj.targetamount === "" ? 0 : obj.targetamount
                }));
            }
        }

        const userGoalsData = {
            dataG: dataG, // Assuming dataG is an array or an object
            terms: state, // Assuming state is an object
        };
        await axios
            .put(
                "https://api.capitalflo.co/api/users/achieve-goals",
                {userGoalsData},
                {
                    headers: {
                        Authorization: `Bearer ${Tokens}`,
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            )
            .then((response) => {
                console.log(
                    "Total Expense successfully sent to the server:",
                    response.data
                );
            })
            .catch((error) => {
                console.error("Error sending data to the server:", error);
            });

    }

        useEffect(() => {
            const closeIButton = (e) => {
                if(e.target.namespaceURI!=='http://www.w3.org/2000/svg'){
                    setisInfoVisibleGoals(false)
                }
            }
            document.addEventListener('click', closeIButton)
            return ( )=>document.body.removeEventListener('click', closeIButton)

        }, []);
    const convertToNumeric = (abbreviatedAmounta) => {
        if(abbreviatedAmounta !== null) {
            const abbreviatedAmount = abbreviatedAmounta.toString()
            const numericPart = parseInt(abbreviatedAmount, 10);
            const unit = abbreviatedAmount.slice(-1).toUpperCase();

            if (unit === "K") {
                return numericPart * 1000;
            } else if (unit === "L") {
                return numericPart * 100000;
            } else if (unit === "CR") {
                return numericPart * 10000000;
            } else {
                return numericPart;
            }
        }
        else {
            return 0;
        }
    };

    useEffect(() => {
        if (isGoalsDeleted) {
            handleSaveDataToDb();
            setGoalsDeletedFlag(false);
        }
    }, [deletedGoalsRows]);
    

    const handleGoalFocus = (e) => {
        handleCustomFocus(e)
    }

    if (loading) {
        return <div className='blog_loading_animation'><img src={LoadingAnimation} alt=""/></div>
    }
    const closePopup = () => {
        setShowDemoPopup(false);
    }

    return (
        <div style={{
            width: '100vw',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
        }
        }>
            <Logheader/>
            <Helmet>
                <title>Goals</title>
                <meta name="description"
                    content="Plan your financial goals" />
            </Helmet>
            {showDemoPopup && <DemoBlockerPopup closePopup={closePopup} />}
            {isTyping && (<div className="sticky-container">
                <div className="goalsSaveButton">
                    <div className="savedButton" onClick={handleSaveDataToDb}>Save Data</div>
                </div>
            </div>)}
            <div className="topietwo">
                <img src={goal} alt=""/>
                <p>Goals</p>
                <span onClick={showInfoGoals} >
                                <GrCircleInformation style={{fontSize: '24px'}}/>
                              </span>
                <div
                    className={isInfoVisibleGoals ? "info-popupGoals visible" : "info-popupGoals"}>
                    <p style={{lineHeight:'20px'}}>To understand how to use this feature go to user manual in settings or click
                    </p>
                    <Link to='/products/usermanual#goals' style={{
                        color:'#004AAD',
                        paddingLeft:'5px',
                        fontSize:'18px'
                    }}>Achieve Goals</Link>
                </div>
                {/* <img src={add} alt="" /> */}
            </div>
            <div className="inform">
                <div>
                    <p>Short Term</p>
                    <p>0-2 years</p>
                </div>
                <div>
                    <p>Medium Term</p>
                    <p>2-5 years</p>
                </div>
                <div>
                    <p>Long Term</p>
                    <p>5-20 years</p>
                </div>
            </div>
            <div className="goaltable" ref={goalsSectionRef}>
                <table>
                    <tr>
                        <th className="FirstRow">Goals{" "}
                            <span>
                                <img
                                    src={add}
                                    className="mainFirstRow"
                                    alt=""
                                    onClick={handleRowAddsetAddgoalsRows}
                                />
                            </span>

                        </th>
                        <th>Target</th>
                        <th>Saved</th>
                        <th>Balance</th>

                    </tr>
                    {addgoalsrows.map(
                        (
                            row,
                            index // Add slice() and reverse() here
                        ) => (
                            <tr key={index}>
                                <td style={{position: "relative"}}>
                  <span
                      onClick={() => handleRowDeleteGoals(index)}
                      className="delete-cross-tfp"
                  >
                    &#10006; {/* Unicode character for cross sign */}
                  </span>
                                    <input
                                        type="text"
                                        className="integer-input"
                                        placeholder="Goal Type"
                                        value={row.title}
                                        onChange={(e) => {
                                            handleRowTitleChangeAddgoals(index, e);

                                        }}
                                    />
                                    {row.title &&
                                        <select className='goalTerm'
                                                onFocus={(e) => {
                                                    handleGoalFocus(row.title)
                                                }}

                                                onBlur={(e) => {
                                                    handleCustomFocus('')
                                                }}
                                                value={
                                                    selectedOption.OtherGoalsOptions.find(
                                                        (option) => option.title === row.title
                                                    )?.term
                                                }
                                                style={{
                                                    width: otherGoals.find((goal) => goal[row.title]) ? '60px' : '30px',
                                                    marginTop: otherGoals.find((goal) => goal[row.title]) ? '' : '10px',
                                                    paddingTop: otherGoals.find((goal) => goal[row.title]) ? '10px' : '',
                                                    paddingBottom: otherGoals.find((goal) => goal[row.title]) ? '10px' : ''
                                                }}
                                            // onClick={setGoalsSet(true)}
                                                onChange={(e) => {
                                                    handleGoalsTerm(index, row.title, e)
                                                }}>
                                            <option value="">{(customfocus === row.title) ? '-' : null}</option>
                                            <option
                                                value={year+1}>{customfocus === row.title ? '1y: ' : null}{year+1}</option>
                                            <option
                                                value={year + 2}>{customfocus === row.title ? '2y: ' : null}{year + 2}</option>
                                            <option
                                                value={year + 3}>{customfocus === row.title ? '3y: ' : null}{year + 3}</option>
                                            <option
                                                value={year + 4}>{customfocus === row.title ? '4y: ' : null}{year + 4}</option>
                                            <option
                                                value={year + 5}>{customfocus === row.title ? '5y: ' : null}{year + 5}</option>
                                            <option
                                                value={year + 10}>{customfocus === row.title ? '10y: ' : null}{year + 10}</option>
                                            <option
                                                value={year + 15}>{customfocus === row.title ? '15y: ' : null}{year + 15}</option>
                                            <option
                                                value={year + 20}>{customfocus === row.title ? '20y: ' : null}{year + 20}</option>
                                        </select>}
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className="integer-input"
                                        placeholder="Enter amount"
                                        value={isSaved===false?row.targetamount:abbreviateNumber(row.targetamount)}
                                        onChange={(e) => {
                                            handleRowTargetAmountChangeAddgoals(index, e);
                                            handleInputChange();

                                        }}
                                        onFocus={() => {
                                            console.log('changing is saved value to false')
                                            setisSaved(false);
                                        }}
                                        onBlur={() => {
                                            console.log('changing is saved value to true')
                                            setisSaved(true);
                                        }}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className="integer-input"
                                        placeholder="Enter amount"
                                        value={isSaved===false?row.amount:abbreviateNumber(row.amount)}
                                        onChange={(e) => {
                                            handleRowAmountChangeAddgoals(index, e);
                                            handleInputChange();

                                        }}
                                        onFocus={() => {
                                            console.log('changing is saved value to false')
                                            setisSaved(false);
                                        }}
                                        onBlur={() => {
                                            console.log('changing is saved value to true')
                                            setisSaved(true);
                                        }}
                                    />
                                </td>
                                <td >
                                    <div className="balanceContainer">

                                    {(() => {
                                        const amountNumeric = convertToNumeric(row.amount);
                                        const targetAmountNumeric = convertToNumeric(row.targetamount);
                                        return amountNumeric === 0 && targetAmountNumeric === 0
                                            ? ""
                                            : isSaved === false
                                                ? parseInt(row.targetamount === 0 || row.targetamount === '' ? 0 : row.targetamount) -
                                                parseInt(row.amount === 0 || row.amount === '' ? 0 : row.amount)
                                                : abbreviateNumber(
                                                    parseInt(row.targetamount === 0 || row.targetamount === '' ? 0 : row.targetamount) -
                                                    parseInt(row.amount === 0 || row.amount === '' ? 0 : row.amount)
                                                );
                                    })()}
                                    {
                                        row.amount > row.targetamount ? <div style={{color:'#ab0505', fontSize:'15px', height:'100%', textAlign:'center'}}>Target amount cannot be less than saved amount</div> :''
                                    }
                                    </div>
                                </td>

                            </tr>
                        )
                    )}
                    <tr>
                        <td>
                            Emergency fund
                            <span onMouseOver={showInfoEmergency} onMouseOut={hideInfoEmergency}>
                                <span>  </span>
                                <GrCircleInformation/>
                              </span>
                            <div
                                className={isInfoVisibleEmergency ? "info-popupEmergency visible" : "info-popupEmergency"}>
                                <p>3-6 months of monthly expenses</p>
                            </div>
                            <select value={selectedOption.EmergencyOptions} className='goalTerm' style={{
                                width: goalsBoxSize.emergency ? '60px' : '30px',
                                paddingTop: goalsBoxSize.emergency ? '10px' : '',
                                paddingBottom: goalsBoxSize.emergency ? '10px' : ''
                            }} onChange={handleEmergencyTerm}
                                // onFocus={handleDefaultGoalsFocus('EMERGENCY')}
                                    onFocus={(e) => {
                                        handleGoalFocus('emergency')
                                    }}

                                    onBlur={(e) => {
                                        handleCustomFocus('')
                                    }}
                            >
                                <option value="">{(customfocus === 'emergency') ? '-' : null}</option>
                                <option value={year+1}>{customfocus === 'emergency' ? '1y: ' : null}{year+1}</option>
                                <option
                                    value={year + 2}>{customfocus === 'emergency' ? '2y: ' : null}{year + 2}</option>
                                <option
                                    value={year + 3}>{customfocus === 'emergency' ? '3y: ' : null}{year + 3}</option>
                                <option
                                    value={year + 4}>{customfocus === 'emergency' ? '4y: ' : null}{year + 4}</option>
                                <option
                                    value={year + 5}>{customfocus === 'emergency' ? '5y: ' : null}{year + 5}</option>
                                <option
                                    value={year + 10}>{customfocus === 'emergency' ? '10y: ' : null}{year + 10}</option>
                                <option
                                    value={year + 15}>{customfocus === 'emergency' ? '15y: ' : null}{year + 15}</option>
                                <option
                                    value={year + 20}>{customfocus === 'emergency' ? '20y: ' : null}{year + 20}</option>
                            </select>
                        </td>
                        <td><input
                            type="text"
                            className="integer-input"
                            placeholder="Enter amount"
                            value={isSaved===false?TargetEmergencyAmount:abbreviateNumber(TargetEmergencyAmount)}
                            onChange={handleTargetEmergencyAmountChange}
                            onFocus={() => {
                                console.log('changing is saved value to false')
                                setisSaved(false);
                            }}
                            onBlur={() => {
                                console.log('changing is saved value to true')
                                setisSaved(true);
                            }}
                        /></td>
                        <td><input
                            type="text"
                            className="integer-input"
                            placeholder="Enter amount"
                            value={isSaved===false?EmergencyAmount:abbreviateNumber(EmergencyAmount)}
                            onChange={handleEmergencyAmountChange}
                            onFocus={() => {
                                console.log('changing is saved value to false')
                                setisSaved(false);
                            }}
                            onBlur={() => {
                                console.log('changing is saved value to true')
                                setisSaved(true);
                            }}
                        /></td>

                        <td>
                            <div className="balanceContainer">
                            {(() => {
                                const amountNumeric = convertToNumeric(EmergencyAmount);
                                const targetAmountNumeric = convertToNumeric(TargetEmergencyAmount);

                                return amountNumeric === 0 && targetAmountNumeric === 0
                                    ? ""
                                    : isSaved === false
                                        ? parseInt(TargetEmergencyAmount === 0 || TargetEmergencyAmount === '' ? 0 : TargetEmergencyAmount) -
                                        parseInt(EmergencyAmount === 0 || EmergencyAmount === '' ? 0 : EmergencyAmount)
                                        : abbreviateNumber(
                                            parseInt(TargetEmergencyAmount === 0 || TargetEmergencyAmount === '' ? 0 : TargetEmergencyAmount) -
                                            parseInt(EmergencyAmount === 0 || EmergencyAmount === '' ? 0 : EmergencyAmount)
                                        );
                            })()}
                            {
                                        EmergencyAmount > TargetEmergencyAmount ? <div style={{color:'#ab0505', fontSize:'15px', height:'100%', textAlign:'center'}}>Target amount cannot be less than saved amount</div> :''
                                    }
                            </div>
                            
                        </td>

                    </tr>
                    <tr>
                        <td>Vacation
                            <select value={selectedOption.VacationOptions} className='goalTerm' style={{
                                width: goalsBoxSize.vacation ? '60px' : '30px',
                                paddingTop: goalsBoxSize.vacation ? '10px' : '',
                                paddingBottom: goalsBoxSize.vacation ? '10px' : ''
                            }} onChange={handleVacationTerm}
                                    onFocus={(e) => {
                                        handleGoalFocus('vacation')
                                    }}
                                    onBlur={(e) => {
                                        handleCustomFocus('')
                                    }}
                            >
                                <option value="">{(customfocus === 'vacation') ? '-' : null}</option>
                                <option value={year+1}>{customfocus === 'vacation' ? '1y: ' : null}{year+1}</option>
                                <option value={year + 2}>{customfocus === 'vacation' ? '2y: ' : null}{year + 2}</option>
                                <option value={year + 3}>{customfocus === 'vacation' ? '3y: ' : null}{year + 3}</option>
                                <option value={year + 4}>{customfocus === 'vacation' ? '4y: ' : null}{year + 4}</option>
                                <option value={year + 5}>{customfocus === 'vacation' ? '5y: ' : null}{year + 5}</option>
                                <option
                                    value={year + 10}>{customfocus === 'vacation' ? '10y: ' : null}{year + 10}</option>
                                <option
                                    value={year + 15}>{customfocus === 'vacation' ? '15y: ' : null}{year + 15}</option>
                                <option
                                    value={year + 20}>{customfocus === 'vacation' ? '20y: ' : null}{year + 20}</option>
                            </select>
                        </td>

                        <td><input
                            type="text"
                            className="integer-input"
                            placeholder="Enter amount"
                            value={isSaved===false?TargetVacationAmount:abbreviateNumber(TargetVacationAmount)}
                            onChange={handleTargetVacationAmountChange}
                            onFocus={() => {
                                console.log('changing is saved value to false')
                                setisSaved(false);
                            }}
                            onBlur={() => {
                                console.log('changing is saved value to true')
                                setisSaved(true);
                            }}
                        /></td>
                        <td><input
                            type="text"
                            className="integer-input"
                            placeholder="Enter amount"
                            value={isSaved===false?VacationAmount:abbreviateNumber(VacationAmount)}
                            onChange={handleVacationAmountChange}
                            onFocus={() => {
                            console.log('changing is saved value to false')
                            setisSaved(false);
                        }}
                            onBlur={() => {
                                console.log('changing is saved value to true')
                                setisSaved(true);
                            }}
                        /></td>
                        <td>
                            <div className='balanceContainer'>
                            {(() => {
                                const amountNumeric = convertToNumeric(VacationAmount);
                                const targetAmountNumeric = convertToNumeric(TargetVacationAmount);

                                return amountNumeric === 0 && targetAmountNumeric === 0
                                    ? ""
                                    : isSaved === false
                                        ? parseInt(TargetVacationAmount === 0 || TargetVacationAmount === '' ? 0 : TargetVacationAmount) -
                                        parseInt(VacationAmount === 0 || VacationAmount === '' ? 0 : VacationAmount)
                                        : abbreviateNumber(
                                            parseInt(TargetVacationAmount === 0 || TargetVacationAmount === '' ? 0 : TargetVacationAmount) -
                                            parseInt(VacationAmount === 0 || VacationAmount === '' ? 0 : VacationAmount)
                                        );
                            })()}
                            {
                                        VacationAmount > TargetVacationAmount ? <div style={{color:'#ab0505', fontSize:'15px', height:'100%', textAlign:'center'}}>Target amount cannot be less than saved amount</div> :''
                                    }
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Vehicle
                            <select value={selectedOption.VechicleOptions} className='goalTerm' style={{
                                width: goalsBoxSize.vehicle ? '60px' : '30px',
                                paddingTop: goalsBoxSize.vehicle ? '10px' : '',
                                paddingBottom: goalsBoxSize.vehicle ? '10px' : ''
                            }} onChange={handleVehicleTerm}
                                    onFocus={(e) => {
                                        handleGoalFocus('vehicle')
                                    }}
                                    onBlur={(e) => {
                                        handleCustomFocus('')
                                    }}>
                                <option value="">{(customfocus === 'vehicle') ? '-' : null}</option>
                                <option value={year+1}>{customfocus === 'vehicle' ? '1y: ' : null}{year+1}</option>
                                <option value={year + 2}>{customfocus === 'vehicle' ? '2y: ' : null}{year + 2}</option>
                                <option value={year + 3}>{customfocus === 'vehicle' ? '3y: ' : null}{year + 3}</option>
                                <option value={year + 4}>{customfocus === 'vehicle' ? '4y: ' : null}{year + 4}</option>
                                <option value={year + 5}>{customfocus === 'vehicle' ? '5y: ' : null}{year + 5}</option>
                                <option
                                    value={year + 10}>{customfocus === 'vehicle' ? '10y: ' : null}{year + 10}</option>
                                <option
                                    value={year + 15}>{customfocus === 'vehicle' ? '15y: ' : null}{year + 15}</option>
                                <option
                                    value={year + 20}>{customfocus === 'vehicle' ? '20y: ' : null}{year + 20}</option>
                            </select>
                        </td>
                        <td><input
                            type="text"
                            className="integer-input"
                            placeholder="Enter amount"
                            value={isSaved===false?TargetVechicleAmount:abbreviateNumber(TargetVechicleAmount)}
                            onChange={handleTargetVehicleAmountChange}
                            onFocus={() => {
                                console.log('changing is saved value to false')
                                setisSaved(false);
                            }}
                            onBlur={() => {
                                console.log('changing is saved value to true')
                                setisSaved(true);
                            }}
                        /></td>
                        <td><input
                            type="text"
                            className="integer-input"
                            placeholder="Enter amount"
                            value={isSaved===false?VechicleAmount:abbreviateNumber(VechicleAmount)}
                            onChange={handleVehicleAmountChange}
                            onFocus={() => {
                                console.log('changing is saved value to false')
                                setisSaved(false);
                            }}
                            onBlur={() => {
                                console.log('changing is saved value to true')
                                setisSaved(true);
                            }}
                        /></td>
                        <td>
                            <div className='balanceContainer'>
                            {(() => {
                                const amountNumeric = convertToNumeric(VechicleAmount);
                                const targetAmountNumeric = convertToNumeric(TargetVechicleAmount);

                                return amountNumeric === 0 && targetAmountNumeric === 0
                                    ? ""
                                    : isSaved === false
                                        ? parseInt(TargetVechicleAmount === 0 || TargetVechicleAmount === '' ? 0 : TargetVechicleAmount) -
                                        parseInt(VechicleAmount === 0 || VechicleAmount === '' ? 0 : VechicleAmount)
                                        : abbreviateNumber(
                                            parseInt(TargetVechicleAmount === 0 || TargetVechicleAmount === '' ? 0 : TargetVechicleAmount) -
                                            parseInt(VechicleAmount === 0 || VechicleAmount === '' ? 0 : VechicleAmount)
                                        );
                            })()}
                            {
                                        VechicleAmount > TargetVechicleAmount ? <div style={{color:'#ab0505', fontSize:'15px', height:'100%', textAlign:'center'}}>Target amount cannot be less than saved amount</div> :''
                                    }
                                    </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Home
                            <select value={selectedOption.HomeOptions} className='goalTerm' style={{
                                width: goalsBoxSize.home ? '60px' : '30px',
                                paddingTop: goalsBoxSize.home ? '10px' : '',
                                paddingBottom: goalsBoxSize.home ? '10px' : ''
                            }} onChange={handleHomeTerm}
                                    onFocus={(e) => {
                                        handleGoalFocus('home')
                                    }}
                                    onBlur={(e) => {
                                        handleCustomFocus('')
                                    }}>
                                <option value="">{(customfocus === 'home') ? '-' : null}</option>
                                <option value={year+1}>{customfocus === 'home' ? '1y: ' : null}{year+1}</option>
                                <option value={year + 2}>{customfocus === 'home' ? '2y: ' : null}{year + 2}</option>
                                <option value={year + 3}>{customfocus === 'home' ? '3y: ' : null}{year + 3}</option>
                                <option value={year + 4}>{customfocus === 'home' ? '4y: ' : null}{year + 4}</option>
                                <option value={year + 5}>{customfocus === 'home' ? '5y: ' : null}{year + 5}</option>
                                <option value={year + 10}>{customfocus === 'home' ? '10y: ' : null}{year + 10}</option>
                                <option value={year + 15}>{customfocus === 'home' ? '15y: ' : null}{year + 15}</option>
                                <option value={year + 20}>{customfocus === 'home' ? '20y: ' : null}{year + 20}</option>
                            </select>
                        </td>
                        <td><input
                            type="text"
                            className="integer-input"
                            placeholder="Enter amount"
                            value={isSaved===false?TargetHomeAmount:abbreviateNumber(TargetHomeAmount)}
                            onChange={handleTargetHomeAmountChange}
                            onFocus={() => {
                                console.log('changing is saved value to false')
                                setisSaved(false);
                            }}
                            onBlur={() => {
                                console.log('changing is saved value to true')
                                setisSaved(true);
                            }}
                        /></td>
                        <td><input
                            type="text"
                            className="integer-input"
                            placeholder="Enter amount"
                            value={isSaved===false?HomeAmount:abbreviateNumber(HomeAmount)}
                            onChange={handleHomeAmountChange}
                            onFocus={() => {
                                console.log('changing is saved value to false')
                                setisSaved(false);
                            }}
                            onBlur={() => {
                                console.log('changing is saved value to true')
                                setisSaved(true);
                            }}
                        /></td>
                        <td>
                            <div className='balanceContainer'>
                            {(() => {
                                const amountNumeric = convertToNumeric(HomeAmount);
                                const targetAmountNumeric = convertToNumeric(TargetHomeAmount);

                                return amountNumeric === 0 && targetAmountNumeric === 0
                                    ? ""
                                    : isSaved === false
                                        ? parseInt(TargetHomeAmount === 0 || TargetHomeAmount === '' ? 0 : TargetHomeAmount) -
                                        parseInt(HomeAmount === 0 || HomeAmount === '' ? 0 : HomeAmount)
                                        : abbreviateNumber(
                                            parseInt(TargetHomeAmount === 0 || TargetHomeAmount === '' ? 0 : TargetHomeAmount) -
                                            parseInt(HomeAmount === 0 || HomeAmount === '' ? 0 : HomeAmount)
                                        );
                            })()}
                            {
                                        HomeAmount > TargetHomeAmount ? <div style={{color:'#ab0505', fontSize:'15px', height:'100%', textAlign:'center'}}>Target amount cannot be less than saved amount</div> :''
                                    }
                                    </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Education
                            <select value={selectedOption.EducationOptions} className='goalTerm' style={{
                                width: goalsBoxSize.education ? '60px' : '30px',
                                paddingTop: goalsBoxSize.education ? '10px' : '',
                                paddingBottom: goalsBoxSize.education ? '10px' : ''
                            }} onChange={handleEducationTerm}
                                    onFocus={(e) => {
                                        handleGoalFocus('education')
                                    }}
                                    onBlur={(e) => {
                                        handleCustomFocus('')
                                    }}
                            >
                                <option value="">{(customfocus === 'education') ? '-' : null}</option>
                                <option value={year+1}>{customfocus === 'education' ? '1y: ' : null}{year+1}</option>
                                <option
                                    value={year + 2}>{customfocus === 'education' ? '2y: ' : null}{year + 2}</option>
                                <option
                                    value={year + 3}>{customfocus === 'education' ? '3y: ' : null}{year + 3}</option>
                                <option
                                    value={year + 4}>{customfocus === 'education' ? '4y: ' : null}{year + 4}</option>
                                <option
                                    value={year + 5}>{customfocus === 'education' ? '5y: ' : null}{year + 5}</option>
                                <option
                                    value={year + 10}>{customfocus === 'education' ? '10y: ' : null}{year + 10}</option>
                                <option
                                    value={year + 15}>{customfocus === 'education' ? '15y: ' : null}{year + 15}</option>
                                <option
                                    value={year + 20}>{customfocus === 'education' ? '20y: ' : null}{year + 20}</option>
                            </select>
                        </td>
                        <td><input
                            type="text"
                            className="integer-input"
                            placeholder="Enter amount"
                            value={isSaved===false?TargetEducationAmount:abbreviateNumber(TargetEducationAmount)}
                            onChange={handleTargetEducationAmountChange}
                            onFocus={() => {
                                console.log('changing is saved value to false')
                                setisSaved(false);
                            }}
                            onBlur={() => {
                                console.log('changing is saved value to true')
                                setisSaved(true);
                            }}
                        /></td>
                        <td><input
                            type="text"
                            className="integer-input"
                            placeholder="Enter amount"
                            value={isSaved===false?EducationAmount:abbreviateNumber(EducationAmount)}
                            onChange={handleEducationAmountChange}
                            onFocus={() => {
                                console.log('changing is saved value to false')
                                setisSaved(false);
                            }}
                            onBlur={() => {
                                console.log('changing is saved value to true')
                                setisSaved(true);
                            }}
                        /></td>
                        <td>
                            <div className='balanceContainer'>
                            {(() => {
                                const amountNumeric = convertToNumeric(EducationAmount);
                                const targetAmountNumeric = convertToNumeric(TargetEducationAmount);

                                return amountNumeric === 0 && targetAmountNumeric === 0
                                    ? ""
                                    : isSaved === false
                                        ? parseInt(TargetEducationAmount === 0 || TargetEducationAmount === '' ? 0 : TargetEducationAmount) -
                                        parseInt(EducationAmount === 0 || EducationAmount === '' ? 0 : EducationAmount)
                                        : abbreviateNumber(
                                            parseInt(TargetEducationAmount === 0 || TargetEducationAmount === '' ? 0 : TargetEducationAmount) -
                                            parseInt(EducationAmount === 0 || EducationAmount === '' ? 0 : EducationAmount)
                                        );
                            })()}
                            {
                                        EducationAmount > TargetEducationAmount ? <div style={{color:'#ab0505', fontSize:'15px', height:'100%', textAlign:'center'}}>Target amount cannot be less than saved amount</div> :''
                                    }
                            </div>        
                        </td>

                    </tr>
                    <tr>
                        <td>Marriage
                            <select value={selectedOption.MarriageOptions} className='goalTerm' style={{
                                width: goalsBoxSize.marriage ? '60px' : '30px',
                                paddingTop: goalsBoxSize.marriage ? '10px' : '',
                                paddingBottom: goalsBoxSize.marriage ? '10px' : ''
                            }} onChange={handleMarriageTerm}
                                    onFocus={(e) => {
                                        handleGoalFocus('marriage')
                                    }}
                                    onBlur={(e) => {
                                        handleCustomFocus('')
                                    }}>
                                <option value="">{(customfocus === 'marriage') ? '-' : null}</option>
                                <option value={year+1}>{customfocus === 'marriage' ? '1y: ' : null}{year+1}</option>
                                <option value={year + 2}>{customfocus === 'marriage' ? '2y: ' : null}{year + 2}</option>
                                <option value={year + 3}>{customfocus === 'marriage' ? '3y: ' : null}{year + 3}</option>
                                <option value={year + 4}>{customfocus === 'marriage' ? '4y: ' : null}{year + 4}</option>
                                <option value={year + 5}>{customfocus === 'marriage' ? '5y: ' : null}{year + 5}</option>
                                <option
                                    value={year + 10}>{customfocus === 'marriage' ? '10y: ' : null}{year + 10}</option>
                                <option
                                    value={year + 15}>{customfocus === 'marriage' ? '15y: ' : null}{year + 15}</option>
                                <option
                                    value={year + 20}>{customfocus === 'marriage' ? '20y: ' : null}{year + 20}</option>
                            </select>
                        </td>
                        <td><input
                            type="text"
                            className="integer-input"
                            placeholder="Enter amount"
                            value={isSaved===false?TargetMarriageAmount:abbreviateNumber(TargetMarriageAmount)}
                            onChange={handleTargetMarriageAmountChange}
                            onFocus={() => {
                                console.log('changing is saved value to false')
                                setisSaved(false);
                            }}
                            onBlur={() => {
                                console.log('changing is saved value to true')
                                setisSaved(true);
                            }}
                        /></td>
                        <td><input
                            type="text"
                            className="integer-input"
                            placeholder="Enter amount"
                            value={isSaved===false?MarriageAmount:abbreviateNumber(MarriageAmount)}
                            onChange={handleMarriageAmountChange}
                            onFocus={() => {
                                console.log('changing is saved value to false')
                                setisSaved(false);
                            }}
                            onBlur={() => {
                                console.log('changing is saved value to true')
                                setisSaved(true);
                            }}
                        /></td>
                        <td>
                            <div className='balanceContainer'>
                            {(() => {
                                const amountNumeric = convertToNumeric(MarriageAmount);
                                const targetAmountNumeric = convertToNumeric(TargetMarriageAmount);

                                return amountNumeric === 0 && targetAmountNumeric === 0
                                    ? ""
                                    : isSaved === false
                                        ? parseInt(TargetMarriageAmount === 0 || TargetMarriageAmount === '' ? 0 : TargetMarriageAmount) -
                                        parseInt(MarriageAmount === 0 || MarriageAmount === '' ? 0 : MarriageAmount)
                                        : abbreviateNumber(
                                            parseInt(TargetMarriageAmount === 0 || TargetMarriageAmount === '' ? 0 : TargetMarriageAmount) -
                                            parseInt(MarriageAmount === 0 || MarriageAmount === '' ? 0 : MarriageAmount)
                                        );
                            })()}
                            {
                                        MarriageAmount > TargetMarriageAmount ? <div style={{color:'#ab0505', fontSize:'15px', height:'100%', textAlign:'center'}}>Target amount cannot be less than saved amount</div>  :''
                                    }
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Retirement
                            <select value={selectedOption.RetirementOptions} className='goalTerm' style={{
                                width: goalsBoxSize.retirement ? '60px' : '30px',
                                paddingTop: goalsBoxSize.retirement ? '10px' : '',
                                paddingBottom: goalsBoxSize.retirement ? '10px' : ''
                            }} onChange={handleRetirementTerm}
                                    onFocus={(e) => {
                                        handleGoalFocus('retirement')
                                    }}
                                    onBlur={(e) => {
                                        handleCustomFocus('')
                                    }}>
                                <option value="">{(customfocus === 'retirement') ? '-' : null}</option>
                                <option value={year+1}>{customfocus === 'retirement' ? '1y: ' : null}{year+1}</option>
                                <option
                                    value={year + 2}>{customfocus === 'retirement' ? '2y: ' : null}{year + 2}</option>
                                <option
                                    value={year + 3}>{customfocus === 'retirement' ? '3y: ' : null}{year + 3}</option>
                                <option
                                    value={year + 4}>{customfocus === 'retirement' ? '4y: ' : null}{year + 4}</option>
                                <option
                                    value={year + 5}>{customfocus === 'retirement' ? '5y: ' : null}{year + 5}</option>
                                <option
                                    value={year + 10}>{customfocus === 'retirement' ? '10y: ' : null}{year + 10}</option>
                                <option
                                    value={year + 15}>{customfocus === 'retirement' ? '15y: ' : null}{year + 15}</option>
                                <option
                                    value={year + 20}>{customfocus === 'retirement' ? '20y: ' : null}{year + 20}</option>
                            </select>
                        </td>
                        <td><input
                            type="text"
                            className="integer-input"
                            placeholder="Enter amount"
                            value={isSaved===false?TargetRetirementAmount:abbreviateNumber(TargetRetirementAmount)}
                            onChange={handleTargetRetirementAmountChange}
                            onFocus={() => {
                                console.log('changing is saved value to false')
                                setisSaved(false);
                            }}
                            onBlur={() => {
                                console.log('changing is saved value to true')
                                setisSaved(true);
                            }}
                        /></td>
                        <td><input
                            type="text"
                            className="integer-input"
                            placeholder="Enter amount"
                            value={isSaved===false?RetirementAmount:abbreviateNumber(RetirementAmount)}
                            onChange={handleRetirementAmountChange}
                            onFocus={() => {
                                console.log('changing is saved value to false')
                                setisSaved(false);
                            }}
                            onBlur={() => {
                                console.log('changing is saved value to true')
                                setisSaved(true);
                            }}
                        /></td>
                        <td>
                            <div className='balanceContainer'>
                            {(() => {
                                const amountNumeric = convertToNumeric(RetirementAmount);
                                const targetAmountNumeric = convertToNumeric(TargetRetirementAmount);

                                return amountNumeric === 0 && targetAmountNumeric === 0
                                    ? ""
                                    : isSaved === false
                                        ? parseInt(TargetRetirementAmount === 0 || TargetRetirementAmount === '' ? 0 : TargetRetirementAmount) -
                                        parseInt(RetirementAmount === 0 || RetirementAmount === '' ? 0 : RetirementAmount)
                                        : abbreviateNumber(
                                            parseInt(TargetRetirementAmount === 0 || TargetRetirementAmount === '' ? 0 : TargetRetirementAmount) -
                                            parseInt(RetirementAmount === 0 || RetirementAmount === '' ? 0 : RetirementAmount)
                                        );
                            })()}
                            {
                                        RetirementAmount > TargetRetirementAmount ? <div style={{color:'#ab0505', fontSize:'15px', height:'100%', textAlign:'center'}}>Target amount cannot be less than saved amount</div> :''
                                    }
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{fontWeight:'bold'}}>
                            Total All
                        </td>
                        <td style={{fontWeight:'bold', paddingLeft:'35px'}}  >
                            {isSaved === false?parseInt(targetTotal):abbreviateNumber(targetTotal)}
                        </td>
                        <td style={{fontWeight:'bold', paddingLeft:'35px'}}  >
                            {isSaved === false?parseInt(savedTotal):abbreviateNumber(savedTotal)}
                        </td>
                        <td style={{fontWeight:'bold'}}  >
                            {isSaved === false?parseInt(balanceTotal):abbreviateNumber(balanceTotal)}
                        </td>
                    </tr>

                </table>
            </div>
        </div>
    )

}

export default Goals;
