import React, {useEffect, useState} from 'react';
import {getComments, submitComment} from '../query';
import './CommentForm.css'; // Import the CSS file for CommentsForm

const CommentsForm = ({slug}) => {
    const [error, setError] = useState(false);
    const [localStorage, setLocalStorage] = useState(null);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [formData, setFormData] = useState({name: null, email: null, comment: null, storeData: false});
    const [isTyping, setIsTyping] = useState(false);
    const [commentsfetch, setCommentsfetch] = useState([]);
    const [checkComments, setCheckComments] = useState(false);


    useEffect(() => {
        getComments(slug).then((result) => {

            setCommentsfetch(result);
            setCheckComments(true)
        });
    }, []);

    useEffect(() => {
        setLocalStorage(window.localStorage);
        const initialFormData = {
            name: window.localStorage.getItem('name'),
            email: window.localStorage.getItem('email'),
            storeData: window.localStorage.getItem('name') || window.localStorage.getItem('email'),
        };
        setFormData(initialFormData);
    }, []);

    const onInputChange = (e) => {
        const {target} = e;
        if (target.type === 'checkbox') {
            setFormData((prevState) => ({
                ...prevState,
                [target.name]: target.checked,
            }));
        } else {
            setFormData((prevState) => ({
                ...prevState,
                [target.name]: target.value,
            }));
        }
        setIsTyping(true);
    };

    const handlePostSubmission = () => {
        setError(false);
        const {name, email, comment, storeData} = formData;
        if (!name || !email || !comment) {
            setError(true);
            return;
        }
        const commentObj = {
            name,
            email,
            comment,
            slug,
        };

        if (storeData) {
            localStorage.setItem('name', name);
            localStorage.setItem('email', email);
        } else {
            localStorage.removeItem('name');
            localStorage.removeItem('email');
        }

        submitComment(commentObj)
            .then((res) => {
                if (res.createdAt) {
                    if (!storeData) {
                        formData.name = '';
                        formData.email = '';
                    }
                    formData.comment = '';
                    setFormData((prevState) => ({
                        ...prevState,
                        ...formData,
                    }));
                    setShowSuccessMessage(true);
                    setTimeout(() => {
                        setShowSuccessMessage(false);
                    }, 3000);
                }
            });
    };

    return (
        <div className="commentsFormContainer">
            <h3 className="text-xl mb-8 font-semibold border-b pb-4">Post a comment</h3>


            <div className="form_name_container">
                <label htmlFor="name" className="form_name">Name (required)</label>
                <input
                    type="text"
                    value={formData.name}
                    onChange={onInputChange}
                    className="nameInput"
                    name="name"
                    id="name"
                    style={{width: "30ch"}}
                    required={true}
                />
            </div>
            <div className="form_name_container">
                <label htmlFor="email" className="form_name">Email (will not be published) (required)</label>
                <input
                    type="email"
                    value={formData.email}
                    onChange={onInputChange}
                    className="emailInput"
                    name="email"
                    id="email"
                    style={{width: "30ch"}}
                    required={true}
                />
            </div>
            <div className="form_name_container">
                <label htmlFor="comment" className="form_name">Comment</label>
                <textarea
                    value={formData.comment}
                    onChange={onInputChange}
                    className="commentInput"
                    name="comment"
                    id="comment"
                    required={true}
                />
            </div>
            <div className="grid grid-cols-1 gap-4 mb-4">
                <div>
                    <input className="checkBoxblog" checked={formData.storeData} onChange={onInputChange}
                           type="checkbox" id="storeData" name="storeData" value="true"/>
                    <label className="lableblog" htmlFor="storeData"> Save my name, email in this browser for the next
                        time I comment.</label>
                </div>
            </div>

            {error && <p className="text-xs text-red-500">All fields are mandatory</p>}
            {isTyping && (
                <div className="postbutton">
                    <button type="button" onClick={handlePostSubmission} className="postcomment">Post Comment</button>
                    {showSuccessMessage &&
                        <span className="text-xl float-right font-semibold mt-3 text-green-500">Comment submitted for review</span>}
                </div>
            )}
        </div>
    );
};

export default CommentsForm;
