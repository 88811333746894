import React, {useEffect} from 'react'

import './Privacy.css'
import {Link} from 'react-router-dom'

function Privacy() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])


    return (
        <div className='privacyboundry'>
            <div className='header'>
                <h3>CAPITALFLO</h3>
                <button className='privacyBtn' onClick={() => window.location.href = 'https://www.capitalflo.co'}>Go
                    Back to website
                </button>
            </div>
            <div>
                <h1>Privacy Policy</h1>
                {/* <p>Last modified: October 31, 2022</p> */}
                <div className='privacyContent'>
                    <p> We at Capitalflo, which operates under the legal entity Silicon Murphy (OPC) Private Limited,
                        are committed to protecting the privacy and security of your personal information. Your privacy
                        is important to us, and maintaining your trust is paramount.

                        <br/><br/>
                        <h2 className='miniheadlines'>Limited Use Disclosure</h2>
                        Capitalflo’s use and transfer to any other app of information received from Google APIs will
                        adhere to <Link to="https://developers.google.com/terms/api-services-user-data-policy">Google
                            API Services User Data Policy</Link>, including the Limited Use requirements.
                        <br/><br/>
                        <h2 className='miniheadlines'>1. Information we collect</h2>
                        The following information is collected by or on behalf of Capitalflo.
                        <br/><br/>
                        <h2 className='miniheadlines'>1.1 Information You provide us</h2>
                        When you start using the Capitalflo website or app, we ask you to provide certain information as
                        part of the registration process, and in the course of your interface with the Capitalflo. We
                        will collect this information through various means and in various places through the Capitalflo
                        App, including account registration forms, contact us forms, or when you otherwise interact with
                        Capiatlflo including at customer support.
                        <br/><br/>
                        At the time of registration, we ask for the following personal information. Name (First Name,
                        Last Name); Mobile Number; Email ID.
                        Pursuant to the services consumed by You from time to time, we may explicitly seek additional
                        information including access to certain additional information such as SMS and email contact
                        details. Prior to accessing any such additional information, explicit consent shall be sought
                        from you. Please note that even after consent has been provided by you, we only read
                        transactional or promotional emails and SMS and do not open, access, or read any personal emails
                        and SMS.
                        . You may choose to provide such information if you wish to use the services provided by the
                        App.
                        <br/>
                        We will also maintain a record of the information you provide to us when using customer support
                        services.
                        <br/><br/><br/>
                        <h2 className='miniheadlines'>1.2 Information created when You use the Capitalflo App</h2>
                        We collect information relating to your use of our website/app through the use of various
                        technologies. This includes transaction details related to your use of our services including
                        the type of services you requested, amount and other related transactional and financial
                        information. Further, when you visit our website/app, we may log certain information such as
                        your IP address, browser type, mobile operating system, manufacturer and model of your mobile
                        device, geolocation, preferred language, access time, and time spent. We will also collect
                        information about the pages you view within our sites and other actions you take while visiting
                        our website/app.
                        <br/><br/>
                        We also maintain some records of users who contact us for support, for the purpose of responding
                        to such queries and other related activities. However, we do not provide this information to any
                        third party without your permission, or utilize the same for any purposes not set out hereunder.
                        <br/><br/>
                        <h2 className='miniheadlines'>1.3 Information We Collect from Third Parties</h2>
                        We may, on your consent, request certain third parties to provide information about you to
                        further personalize your experience on our website/app, and provide certain services that cannot
                        be accessed by all users of the website/app.
                        <br/><br/><br/>
                        If you choose to link and connect your email account with Capitalflo, we may access the said
                        account for purposes including collecting your expense transaction details such as merchant
                        name, amount spent, date and time and such other financial and transactional information to be
                        able to do the following:
                        <br/><br/>
                        1. Manage all your transaction information at one place including reminding you of the total
                        expenses made.
                        <br/><br/>
                        2. Offer spending insights customised for you;
                        <br/><br/>
                        3. Provide an insight into your spending pattern(s) to ensure you are aware of your spending and
                        can make wiser decisions basis the same;
                        <br/><br/>
                        4. Identify utility billers and insurers / insurance service providers you transact with to
                        fetch your utility bill and/or insurance details;
                        <br/><br/>
                        5. Curate specific financial/investment/other products for you based on your financial
                        transactions, investments, and past financial behaviour.
                        <br/><br/>
                        Capitalflo’s access to the email account(s) is authorized through the email provider’s access
                        mechanism. If you permit the Capitalflo website/app to track your bank card accounts, the
                        Capitalflo website/app will securely store account details for each of your email accounts,
                        including your sign-in user name and authorisation tokens for tracked accounts.
                        <br/><br/>
                        You can choose to enable us to access one or more of your email accounts by explicitly
                        consenting to each single account separately. Please note that your consent to any of the above
                        is purely voluntary. You can de-link the access to your email any time you wish.
                        <br/><br/>
                        We only read emails related to financial services such as credit card statements, loan
                        statements, insurance policies, biller details etc and do not open, read or access any personal
                        e-mails. For the sake of clarity, Capitalflo employs automated processes for accessing and
                        analysing information provided by you; where we may need to use our algorithm to access a
                        password-protected document utilizing information provided by you. For further information on
                        how we process email and the security protocol we follow click here.
                        <br/><br/>
                        We may receive additional information about you, such as information to help detect fraud and
                        safety issues, from third party service providers and/or partners, and combine it with
                        information we have about you. We may receive information about you and your activities through
                        partnerships, or about your experiences and interactions from our partner ad networks.
                        <br/><br/>
                        <h2 className='miniheadlines'>2. How We Use the Information we collect</h2>
                        We may use, store and process the information provided by you to (1) improve the services
                        offered through the Capitalflo App, (2) create and maintain a trusted and safe environment on
                        Capitalflo (such as complying with our legal obligations and compliance with our policies) and
                        (3) provide, personalise, measure and improve our products and services.
                        <br/><br/>
                        The information collected through different channels, allows us to collect statistics about our
                        website/app usage and effectiveness, personalise your experience whilst you are on our
                        website/app,as well as customize our interactions with you and to enhance the scope of the App
                        Services. The following paragraphs describe in more detail how we use your personal information.
                        <br/><br/>
                        2.1 Providing, understanding, and improving service offered through the Capitalflo App
                        Any information provided by you will be used for creating and updating your account and
                        processing your transaction(s) or for any other purposes for which you have granted access to
                        such information to us, based on your interaction with the Capitalflo App.
                        <br/><br/>
                        To complete a financial transaction, we may share financial information (such as credit card
                        details or other payment mode details) provided by you with authorised third-parties, for
                        instance, our business partners, financial teams/institutions, or postal/ government authorities
                        involved in fulfilment of the said transactions, if any. This does not include any information
                        collected from e-mails. In connection with a financial transaction, we may also contact you as
                        part of our customer satisfaction surveys or for market research purposes.
                        <br/><br/>
                        We may use the information collected to perform internal operations necessary to provide our
                        services, including to troubleshoot software bugs and operational problems, to conduct data
                        analysis, testing and research and to monitor and analyse usage and activity trends. We process
                        this personal information for these purposes given our legitimate interest in improving the App
                        Services.
                        <br/><br/>
                        In order to expand the scope of our services, we may from time to time, seek additional
                        information from you. Any such collection of additional information and documents shall be
                        subject to an explicit & purpose specific consent sought from all Users.
                        <br/><br/>
                        Capitalflo will securely store account details for each of your credit card accounts, including
                        your sign-in user name and authorisation tokens for tracked accounts. This information will be
                        used to enable the Capitalflo website/app to automatically access your applicable credit card
                        statements to analyze, extract, and store information securely from such accounts for use on the
                        Capitalflo app or website.
                        <br/><br/>
                        <h2 className='miniheadlines'>2.2 Safety, security and resolution of issues</h2>
                        We may use your personal information, created as part of payment services availed by you, to
                        ensure that your access and use of payment services is in compliance with our legal obligations
                        (such as anti-money laundering regulations). We may share such information, with our advisors,
                        third party service partners and providers for a seamless experience for you.
                        <br/><br/>
                        We may use the information to create and maintain a safe environment and use the same to detect
                        and prevent fraud, span, abuse, security incidents and other harmful activity.
                        <br/><br/>
                        We use the information we collect (including recordings of customer support calls) to assist you
                        when you contact our customer support services to investigate and resolve your queries, monitor
                        and improve our customer support responses. Certain online transactions may involve us calling
                        you. This may also involve online chats. Please be aware that it is our general practice to
                        monitor and in some cases record such interactions for staff training or quality assurance
                        purposes or to retain evidence of a particular transaction or interaction.
                        <br/><br/>
                        We intend to protect your personal information and to maintain its accuracy as confirmed by you.
                        We implement reasonable physical, administrative, and technical safeguards to help us protect
                        your personal information from unauthorised access, use, and disclosure. For example, we encrypt
                        all sensitive personal information such as bank transaction information when we transmit such
                        information over the internet. We also require that our commercial partners and vendors protect
                        such information from unauthorised access, use, and disclosure.
                        <br/><br/>
                        We blend security at multiple steps within our products with state of the art technology to
                        ensure our systems maintain strong security measures. The overall data and privacy security
                        design allows us defend our systems ranging from low hanging issue up to sophisticated attacks.
                        You can read more about it here.
                        <br/><br/>
                        We are committed to protecting your data as if it were our own. You can find more details here.
                        If you are a security enthusiast or a researcher and you have found a possible security
                        vulnerability on Capitalflo products, we encourage you to report the issue to us responsibly.
                        You could submit a bug report to us at admin@capitalflo.co with detailed steps required to
                        reproduce the vulnerability. We shall put best of our efforts to investigate and fix the
                        legitimate issues in a reasonable time frame, meanwhile, requesting you not to publicly disclose
                        it.
                        <br/><br/>
                        <h2 className='miniheadlines'>2.3 Sharing and Disclosure of data with Third Parties</h2>
                        As required by law, at times we might be required to disclose your personal information
                        including personal, transactional and financial information to relevant regulatory, and
                        governmental authorities and also to our advisors such as law firms and audit firms while
                        responding to request from the regulatory authorities. In some cases, when we believe that such
                        disclosure is necessary to protect our rights, or the rights of others, or to comply with a
                        judicial proceeding, court order, or legal process served on our website/app we would share such
                        information pursuant to a lawful request from law enforcement agencies.
                        <br/><br/><br/>
                        Subject to explicit and prior consent from a you, we may use information created by your use of
                        our App, not including information collected from other sources such as e-mails etc. for
                        marketing purposes. This consent is purely voluntary and you may at any time choose not to
                        receive marketing materials from us by following the unsubscribe instructions included in each
                        e-mail you may receive, by indicating so when we call you, or by contacting us directly.
                        Further, if you want to remove your contact information from all our lists and newsletters,
                        please click on the unsubscribe button on the emailers or send an email request to
                        admin@capitalflo.co
                        <br/><br/>
                        Subject to explicit and prior consent from you, we may disclose certain information that is
                        created by your use of our App to Capitalflo’s subsidiaries, affiliate entities and partners
                        that are not acting as our suppliers or business partners. For the sake of clarity, we do not
                        sell or lease such information.
                        <br/><br/>
                        Some of our campaigns/programmes/related events may be co-branded, that is sponsored by both the
                        third parties and us. If you sign up for such campaigns/programmes/related events, please note
                        that your information may also be collected by and shared with those third parties. We urge you
                        to familiarise yourself with their privacy policies to gain an understanding of the manner in
                        which they will handle information about you.
                        <br/><br/>
                        We may display targeted or targeted third party online advertisements on the Capitalflo
                        website/app. We may also advertise our activities and organizational goals on other
                        websites/apps. We may collaborate with other website/app operators as well as network
                        advertisers to do so. We request you to read and understand such concerned third party privacy
                        policies to understand their practices relating to advertising, including what type of
                        information they may collect about your internet usage. No personally identifiable information
                        is shared with any third party online advertiser or website or app as part of any such activity.
                        Capitalflo does not provide any information relating to your usage to such website operators or
                        network advertisers.
                        <br/><br/>
                        During your use of the App, you may come across links to third party websites/apps that are not
                        affiliated with Capitalflo. Capitalflo is not responsible for the privacy practices or the
                        content of those other websites, or for any acts/ omissions by such third parties in the course
                        of your transaction with them.
                        <br/><br/>
                        <h2 className='miniheadlines'>3. Account/Card Deletion</h2>
                        We provide all our Users an option to request the deletion a specific account through the
                        support section on the Capitalflo App. Following such request, we delete all such information
                        related to the specific account including but not limited to profile information, card data,
                        transaction details, reward details, referrals details, statement details, google Oauth sessions
                        etc. that we are not required to retain.
                        <br/><br/>
                        In certain circumstances, we may be unable to delete your account, such as if there is any
                        outstanding dispute, loan products availed through the Capitalflo App or unresolved claims
                        pending on your card/account. However, upon resolution of the issue preventing deletion, the
                        information is immediately deleted and can’t be recovered thereafter. Note that we may retain
                        certain information if necessary for our own legitimate business interests such as fraud
                        prevention and enhancing users’ safety and security or to fulfil our legal and contractual
                        obligations and compliance requirements.
                        <br/><br/>
                        You also have the option to request for an account or card deactivation/archival instead. This
                        will temporarily block your access to Capitalflo App until you send a re-activation request on
                        admin@capitalflo.co and successfully re-activate your account.
                        <br/><br/>
                        <h2 className='miniheadlines'>4. Cookies</h2>
                        We use data collection devices such as “cookies”, etc. on certain parts of the App to help
                        analyse the App Services, user interaction with the App, measure promotional effectiveness, and
                        promote trust and safety. For the sake of clarity, “cookies” are small files placed on your
                        device hard-drive/storage that assist us in providing the services offered through the App.
                        Please be informed that we offer certain features via the App that are only available through
                        the use of a “cookie”.
                        <br/><br/>
                        We also use cookies to allow you to enter a particular password less frequently during a
                        session. Cookies also help us obtain information that assists us in curating the Services more
                        targeted to your interests. You are always free to decline our cookies if your device permits,
                        although in that case you may not be able to use certain features on the app and you may be
                        required to provide a password more frequently during a session.
                        <br/><br/>
                        <h2 className='miniheadlines'>5. Changes to Privacy Policy</h2>
                        Capitalflo reserves the right to change this policy from time to time. Any changes shall be
                        effective immediately upon the posting of the revised Privacy Policy. We encourage you to
                        periodically review this page for latest information on our privacy practices.
                        <br/><br/>
                        <h2 className='miniheadlines'>6. Privacy Questions and Access</h2>
                        If you have questions, concerns, or suggestions regarding our Privacy Policy, we can be reached
                        using the contact information on our “Contact Us” page or at admin@capitalflo.co. In certain
                        cases, you may have the ability to view or edit your personal information online. In the event
                        your information is not accessible online and you wish to obtain a copy of particular
                        information you provided to us, or if you become aware the information is incorrect and you
                        would like us to correct it, please contact us immediately.
                        <br/><br/>
                        Before we are able to provide you with any information or correct any inaccuracies, however, we
                        may ask you to verify your identity and to provide other details to ascertain your identity and
                        to help us to respond to your request. We will contact you within 30 days of your request.
                        <br/><br/>
                        <h2 className='miniheadlines'>7. Data Sharing with Third Parties and Privacy of Internal Account
                            Information</h2>
                        <br/>
                        <h2 className='miniheadlines'>7.1 Data Transmission to Our Backend Route</h2>
                        At Capitalflo, we prioritize the privacy and security of your personal information, including
                        internal account details. We would like to provide transparency about how your data is handled
                        when transmitted to our backend server route, "https://api.capitalflo.co."
                        <br/><br/>
                        <h2 className='miniheadlines'>Data Handling and Transmission:</h2>
                        When you use the Capitalflo app, certain information, including internal account details, may be
                        transmitted to our backend server route for processing and storage. This information may include
                        but is not limited to user account credentials, transactional data, and other sensitive
                        information necessary for the functioning of our services.
                        <br/><br/>
                        <h2 className='miniheadlines'>Encryption and Security Measures:</h2>
                        We take data security seriously. Any data transmitted between the Capitalflo app and our backend
                        server route is encrypted using industry-standard protocols to protect it from unauthorized
                        access or interception. This encryption helps ensure that your information remains confidential
                        during transmission.
                        <br/><br/>
                        <h2 className='miniheadlines'>Purpose of Data Transmission:</h2>
                        The data transmitted to our backend server route serves specific purposes related to providing
                        our services to you. This may include processing transactions, account management, personalized
                        experiences, and other functionalities offered through the Capitalflo app.
                        <br/><br/>
                        <h2 className='miniheadlines'>User Consent and Control:</h2>
                        Before any data transmission occurs,
                        we obtain explicit consent from you. You have the option to grant or deny access to specific
                        types of data being transmitted to our backend server route. This allows you to maintain control
                        over the information being shared.
                        <br/><br/>
                        <h2 className='miniheadlines'>Third-Party Access:</h2>
                        Please note that the data transmitted to our backend server route is intended solely for the
                        purpose of enhancing your experience and providing our services. We do not share this data with
                        third parties except as required by law or as described in this privacy policy.
                        <br/><br/>
                        <h2 className='miniheadlines'>Continuous Enhancement:</h2>
                        Our commitment to data privacy extends
                        to our backend server route. We continuously monitor and improve our security measures to ensure
                        that your information remains protected during transmission and storage.
                        <br/><br/>
                        We appreciate your trust in Capitalflo, and we are dedicated to maintaining the security and
                        privacy of your internal account information. If you have any questions or concerns about how
                        your data is transmitted and handled, please feel free to contact us at admin@capitalflo.co.
                        <br/><br/>
                        <h2 className='miniheadlines'>8. Compliances</h2>
                        Google API Disclosure
                        <br/>
                        Capitalflo’s use of information received from Google APIs will adhere to Google API Services
                        User Data Policy, including the Limited Use requirements.
                        <br/><br/>
                    </p>
                </div>
            </div>
            <div className='bottomSpace'></div>
        </div>
    )
}

export default Privacy
