import React, {useEffect, useRef} from 'react';
import './Editcard.css';
import {useLocation} from 'react-router-dom';

const Card = (props) => {
    const location = useLocation();

    // Create a ref for the component you want to scroll
    const foregroundRef = useRef(null);

    useEffect(() => {
        // Scroll to the bottom of the component when the location changes
        if (foregroundRef.current) {
            foregroundRef.current.scrollIntoView({behavior: 'smooth', block: 'end'});
        }
    }, [location]);

    return (
        <div className='editcard editmodal'>
            <div className="scrollable-container" ref={foregroundRef}>
                {props.children}
            </div>
        </div>
    );
};

export default Card;
