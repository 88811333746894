import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import axios from "axios";
import "./Bankstatements.css";
import "../Transaction.css";
import { BiRupee } from "react-icons/bi";
import { CgTag } from "react-icons/cg";
import Transactionmodel from "../Transactionmodel";
import ModalEdit from "../ModalEdit";
import Logheader from "../../PostHeader.js";
import { RiErrorWarningLine } from "react-icons/ri";
import { BsArrowRight } from "react-icons/bs";
import allCategories from "../../assets/icons";
import { Link } from "react-router-dom";
import LoadingAnimation from "../../assets/Loading.svg";
import mixpanel from "../../utils/Mixpanel";
import StatementsSelector from "../StatementSelector";
import { GrCircleInformation } from "react-icons/gr";
import Bank from '../../assets/img/BankStatement.png'
import { isDemoAccountUsed } from "../../utils/CheckIfDemo.js";
import DemoBlockerPopup from "../DemoBlockerPopup.js";
import { Helmet } from "react-helmet";
function Bankstatements() {
    const [isLoading, setIsLoading] = useState(false);
    const [addtxts, setaddtxts] = useState(false);
    const [edittxt, setedittxt] = useState(false);
    const [selectedexp, setselectedexp] = useState(null);
    const [altxtdta, setAllTxnData] = useState(null);
    const [mindate, setMindate] = useState(null);
    const [maxdate, setMaxdate] = useState(null);
    const [settotalDaily, settotalDailySpent] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [password, setPassword] = useState('');
    const [askForPassword, setAskForPassword] = useState(false);
    const [isPasswordCorrect, setIsPasswordCorrect] = useState(true);
    const [Tokens, setTokens] = useState("");
    const [selectedBank, setSelectedBank] = useState("");
    const [uploaded, setUploaded] = useState(false);
    const [message, setMessage] = useState("Upload your Bank Statement to view your Spending Insights.");
    const [errorProcessing, setErrorProcessing] = useState("");
    const [isProcessing, setIsProcessing] = useState(false);
    const [bankError, setBankError] = useState({});
    const [estimatedEndTime, setEstimatedEndTime] = useState(null);
    const [startTime, setStartTime] = useState(new Date());
    const [TimeLeft, setTimeLeft] = useState(-1);
    const [uploadEnabled, setUploadEnabled] = useState(true);
    const [pageLoaded, setPageLoaded] = useState(false);
    const [count, setCount] = useState(0);
    const [showWaitMessage, setShowWaitMessage] = useState(false);
    const [statement, setStatement] = useState('');
    const [isInfoVisibleBankStatements, setisInfoVisibleBankStatements] = useState(false)
    const [showDemoPopup, setShowDemoPopup] = useState(false);
    const statementsSelectorRef = useRef(null);
    const handleBankChange = (event) => {
        if (isDemoAccountUsed()) {
            setUploadEnabled(false);
            setShowDemoPopup(true);
            return;
        }
        if (event.target.value === 'select') {
            setSelectedBank("");
        } else {
            setSelectedBank(event.target.value);
        }
    };


    const txtdtas = [];

    const txtdetailing = (e) => {
        const listNum = e.target.dataset.index;
        setselectedexp(altxtdta[listNum]);
        setedittxt(true);
    };


    // const addtxt = () => {
    //   setaddtxts(true);
    // };

    const popupHadler = () => {
        setaddtxts(false);
    };

    const popupEditxt = () => {
        setedittxt(false);
    };

    const getingtxt = (details) => {
    };
    useEffect(() => {
        const dropContainer = document.getElementById("dropcontainer");
        const fileInput = document.getElementById("images");

        const handleDragOver = (e) => {
            e.preventDefault();
        };

        const handleDragEnter = () => {
            dropContainer.classList.add("drag-active");
        };

        const handleDragLeave = () => {
            dropContainer.classList.remove("drag-active");
        };

        const handleDrop = (e) => {
            if (isDemoAccountUsed()) {
                setUploadEnabled(false);
                setShowDemoPopup(true);
                return;
            }
            e.preventDefault();
            dropContainer.classList.remove("drag-active");
            fileInput.files = e.dataTransfer.files;
        };

        dropContainer.addEventListener("dragover", handleDragOver);
        dropContainer.addEventListener("dragenter", handleDragEnter);
        dropContainer.addEventListener("dragleave", handleDragLeave);
        dropContainer.addEventListener("drop", handleDrop);

        // Clean up event listeners when the component is unmounted
        return () => {
            dropContainer.removeEventListener("dragover", handleDragOver);
            dropContainer.removeEventListener("dragenter", handleDragEnter);
            dropContainer.removeEventListener("dragleave", handleDragLeave);
            dropContainer.removeEventListener("drop", handleDrop);
        }
    }, []);

    const marchant = true;


    const tokenize = async () => {
        try {
            const response = await axios.get(
                "https://api.capitalflo.co/api/users/protect-user",
                { withCredentials: true }
            );
            if (response.data.message === "Cookie not found") {
                setTokens(null);
            } else {
                setTokens(response.data.jwtToken);
            }
        } catch (err) {
            console.log(err.response.data.message);
            setTokens(null);
        }
    };

    useEffect(() => {
        tokenize();
        if (isDemoAccountUsed()) {
            setUploadEnabled(false);
            setMessage("You are currently using a Demo Account. To experience our product, sign up now!");
        }
    });

    const handleFileChange = async (event) => {
        if (isDemoAccountUsed()) {
            setUploadEnabled(false);
            setShowDemoPopup(true);
            return;
        }
        const file = event.target.files[0];
        setSelectedFile(file);
    };
    const handlePasswordInput = async (e) => {
        setPassword(e.target.value)
    };

    const CustomPopup = ({ message, onClose }) => {
        return (
            <div className="popup">
                <p>{message}</p>
                <button onClick={onClose}>Confirm</button>
            </div>
        );
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    }

    const [showPopup, setShowPopup] = useState(false);

    function refresh() {
        {
            if (uploaded === true) {
                let timeToRefresh;
                if (selectedBank === 'KOTAK') {
                    timeToRefresh = 60 * 1000 * 2.5;
                }
                if (selectedBank === 'ICICI') {
                    timeToRefresh = 1000 * 15;
                } else {
                    timeToRefresh = 60 * 1000 * 1;
                }
                setTimeout(() => {
                    window.location.reload();
                }, timeToRefresh);
            }
        }
    }

    useEffect(() => {
        const fetchStatement = async () => {
            const statementRetrieved = JSON.parse(localStorage.getItem('Statement'));
            console.log('Current Statement number', statementRetrieved);
            if (statementRetrieved) {
                console.log("retreived bank is ", statementRetrieved);
                if (statementsSelectorRef.current) {
                    await statementsSelectorRef.current.onSelectStatement({ target: { value: { statementRetrieved } } });
                }
                await setStatement(statementRetrieved);
                localStorage.removeItem('Statement');
            } else {
                setStatement(1);
            }
        }
        fetchStatement()
    }, []);


    useEffect(() => {
        if (startTime) {
            const startTimeDate = new Date(startTime); // Convert the start time to a Date object
            const endTime = new Date(startTimeDate.getTime() + 3 * 60 * 1000); // Add 3 minutes
            setEstimatedEndTime(endTime);
            console.log("endTime" + estimatedEndTime);
        }
    }, [startTime]);

    useEffect(() => {
        refresh();
    }, [uploaded]);

    useEffect(() => {
        if (errorProcessing) {
            setMessage("There was an error processing! Please try again.");
        } else if (!errorProcessing && isProcessing) {
            setMessage("Processing your Transactions. Please wait for one to five minutes.");
            const vh = window.innerHeight;
            const scrollPosition = vh * 0.5; // 45% of viewport height
            window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
        } else if (!errorProcessing && !isProcessing) {
            setMessage("Upload your Bank Statement to view your Spending Insights.");
        }
    }, [isProcessing, errorProcessing]);

    const incrementCounter = () => {
        setCount(count + 1);
    };

    useEffect(() => {
        incrementCounter();
        if (count >= 3) {
            if (bankError.message) {
                console.log("----------------Error------------------");
                setErrorProcessing("There was an error processing! Please try again.");
            } else if (!bankError.message && estimatedEndTime && altxtdta && altxtdta.length <= 5) {
                const currentTime = new Date();
                const timeLeft = estimatedEndTime - currentTime;
                setTimeLeft(timeLeft);
                console.log("timeLeft" + timeLeft)
                if (timeLeft >= 0) {
                    setIsProcessing(true);
                    setUploadEnabled(false);
                    console.log("timeLeft" + timeLeft)
                    setTimeout(async () => {
                        window.location.reload();
                    }, timeLeft);

                } else {
                    setIsProcessing(false);
                    setUploadEnabled(true);
                }
            }
        }
    }, [estimatedEndTime, bankError]);


    const uploading = async () => {
        if (isDemoAccountUsed()) {
            setUploadEnabled(false);
            setShowDemoPopup(true);
            return;
        }
        if (!uploaded && uploadEnabled) {
            try {
                if (selectedFile && selectedBank) {
                    const file = selectedFile;
                    const formData = new FormData();
                    formData.append('file', file);
                    formData.append('password', password);
                    formData.append('fileName', file.name);
                    let pdfValidatorResponse = null;
                    try {
                        pdfValidatorResponse = await axios.post('https://api.capitalflo.co/api/users/upload/validate-pdf', formData, {
                            withCredentials: true,
                            headers: {
                                Authorization: `Bearer ${Tokens}`,
                                'Content-Type': 'multipart/form-data'
                            },
                        });
                    } catch (err) {
                        setAskForPassword(true);
                        return
                    }
                    if (pdfValidatorResponse.data.isPasswordProtected || pdfValidatorResponse.status === 500 || pdfValidatorResponse.data.message === 'Unsupported encryption algorithm.') {
                        if (askForPassword && isPasswordCorrect && !pdfValidatorResponse.data.message.includes('Unsupported')) {
                            console.log('ooops')
                            setIsPasswordCorrect(false);
                            return
                            // else if (pdfValidatorResponse.data.message === 'Unsupported encryption algorithm.' && askForPassword) {
                            //     console.log('hereeeeeeeeeeeeeeeeee11')
                            //     setAskForPassword(false);
                            //     setIsPasswordCorrect(true);
                            //     if (selectedFile && selectedBank) {
                            //         setUploaded(true);
                            //     }
                        } else if (askForPassword && !isPasswordCorrect && !pdfValidatorResponse.data.message.includes('Unsupported')) {
                            console.log('hereeeeeeeeeeeeeeeeee')
                            setIsPasswordCorrect(true);
                            await new Promise(resolve => setTimeout(resolve, 100));
                            setIsPasswordCorrect(false)
                            return;
                        } else {
                            console.log(pdfValidatorResponse.data.message)
                            console.log(askForPassword)
                            if (pdfValidatorResponse.data.message.includes('Unsupported') && askForPassword) {
                                setAskForPassword(false);
                                if (selectedFile && selectedBank) {
                                    setUploaded(true);
                                }
                            } else {
                                setAskForPassword(true);
                                console.log("Stopping upload, password protected");
                                return;
                            }
                        }
                    } else {
                        setAskForPassword(false);
                        if (selectedFile && selectedBank) {
                            setUploaded(true);
                        }
                    }
                    console.log("Going to upload");
                    mixpanel.track(" Web Statement Uploaded");
                    setAllTxnData(null);
                    setMindate(null);
                    setMaxdate(null);
                    setIsProcessing(true);
                    setMindate(null)
                    const vh = window.innerHeight;
                    const scrollPosition = vh * 0.5; // 45% of viewport height
                    window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
                    let URL;
                    if (selectedBank === 'KOTAK') {
                        URL = `https://api.capitalflo.co/api/users/upload/kotak-upload`;
                    }
                    if (selectedBank === 'HDFC') {
                        URL = `https://api.capitalflo.co/api/users/upload/hdfc-upload`;
                    }
                    if (selectedBank === 'SBI') {
                        URL = `https://api.capitalflo.co/api/users/upload/sbi-upload`;
                    }
                    if (selectedBank === 'ICICI') {
                        URL = `https://api.capitalflo.co/api/users/upload/icici-upload`;
                    }
                    setErrorProcessing(false);
                    setMessage("Processing your Transactions. Please wait for one to five minutes.");
                    await axios.post(URL, formData, {
                        withCredentials: true,
                        headers: {
                            Authorization: `Bearer ${Tokens}`,
                            'Content-Type': 'multipart/form-data'
                        },
                    });
                } else {
                    setShowPopup(true);
                }
            } catch
            (err) {
                console.log(err);
            }
        } else {
            setShowWaitMessage(true);
        }
    }
        ;

    useEffect(() => {
        if (showWaitMessage) {
            setTimeout(() => {
                setShowWaitMessage(false);
            }, 3000);
        }
    }, [showWaitMessage]);

    useLayoutEffect(() => {
        if (Tokens) {
            setIsLoading(true);
            setTimeout(() => {
                getAllTransactions();
                console.log('----->', statement)
            }, 500);
        }
    }, [Tokens, statement]);

    const getAllTransactions = async () => {
        try {
            setIsLoading(true);
            const response = await axios.post(
                `https://api.capitalflo.co/api/users/statement-all-transaction`,
                {
                    statement: statement
                },
                {
                    headers: {
                        Authorization: `Bearer ${Tokens}`,
                    },
                    withCredentials: true
                }
            );
            setAllTxnData((response.data).slice(0, (response.data).length - 5));
            setMindate(response.data[response.data.length - 2].minDate);
            setMaxdate(response.data[response.data.length - 1].maxDate);
            setStartTime(response.data[response.data.length - 5]);
            if (response.data[response.data.length - 3]) {
                setBankError(response.data[response.data.length - 3]);
            } else {
                setBankError({
                    message: "",
                    time: "1970-03-25T00:00:00.000+00:00",
                });
            }

            if (response.data.length > 5) {
                console.log(response.data.length > 5 ? "Bank transactions are there" : ":( Still Processing");
                setIsProcessing(false);
                setUploadEnabled(true);
                setTimeLeft(-1);
                setMessage("");
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };
    const selectedStatement = (e) => {
        const selectedValue = e.target.value;
        handleStatementChange(selectedValue);
        setStatement(selectedValue)
    }
    const handleStatementChange = (statementNo) => {
        setStatement(statementNo);
        console.log("set statement no", statementNo);
    }
    const showInfoBankStatements = () => {
        setisInfoVisibleBankStatements(!isInfoVisibleBankStatements)
    }
    const hideInfoBankStatements = () => {
        setisInfoVisibleBankStatements(false)
    }
    const ScrollOnCondition = ({ altxtdta }) => {
        const elementRef = useRef(null);

        useEffect(() => {
            if (altxtdta && altxtdta.length > 5 && !pageLoaded) {
                const scrollPositionSession = localStorage.getItem('scrollPosition');
                if (scrollPositionSession) {
                    setTimeout(() => {
                        window.scrollTo({
                            top: parseInt(scrollPositionSession),
                            behavior: 'smooth'
                        });
                        localStorage.removeItem('scrollPosition');
                        setPageLoaded(true);
                    }, 100);
                } else {
                    const vh = window.innerHeight;
                    const scrollPosition = vh * 0.5; // 45% of viewport height
                    window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
                    setPageLoaded(true);
                }
            }
        }, []);

        return (
            <p className="titlesBank" id="titlesBank" ref={elementRef}>
                Bank Transactions
            </p>
        );
    };
    const closePopup = () => {
        setShowDemoPopup(false);
    }


    useEffect(() => {
        const closeIButton = (e) => {
            if (e.target.namespaceURI !== 'http://www.w3.org/2000/svg') {
                setisInfoVisibleBankStatements(false)
            }
        }
        document.addEventListener('click', closeIButton)
        return () => document.body.removeEventListener('click', closeIButton)

    }, []);
    return (
        <React.Fragment>
            <div style={{
                width: '100vw',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
            }
            }>  {showDemoPopup && <DemoBlockerPopup closePopup={closePopup} />}
                {altxtdta && altxtdta.length > 5 ? <ScrollOnCondition altxtdta={altxtdta} /> : null}
                {showPopup && (
                    <CustomPopup
                        message="Please select a bank and a valid .pdf file"
                        onClose={handleClosePopup}
                    />
                )}
                {addtxts && (
                    <Transactionmodel Ongoing={getingtxt} onConfirm={popupHadler} />
                )}
                {edittxt && (
                    <>
                        <ModalEdit
                            Currentstatement={statement}
                            onConfirm={popupEditxt}
                            expense={selectedexp}
                        />
                    </>
                )}
                <Logheader />
                <Helmet>
                <title>Bank Statements</title>
                <meta name="description"
                    content="check your bank statement" />
            </Helmet>
                <div className='mainheader' >
                    <h2>Bank Statement</h2>

                    <span onClick={showInfoBankStatements} >
                        <div style={{ fontSize: '24px', paddingLeft: '10px', paddingBottom: '35px' }}>
                            <GrCircleInformation />
                        </div>
                        <div
                            className={isInfoVisibleBankStatements ? "isInfoVisibleBankStatements visible" : "isInfoVisibleBankStatements"}>
                            <p>To understand how to use this feature go to user manual in settings or click
                            </p>
                            <Link to='/products/usermanual#statement' style={{
                                color: '#004AAD'
                            }}>Statement Analyzer</Link>
                        </div>
                    </span>
                </div>
                <div className="transactionpage">
                    <div className='selectBank'>
                        <form>
                            <label for="banks">Choose your Bank:</label>
                            <select name="banks" id="banks" onChange={handleBankChange}>
                                <option value="select">select</option>
                                <option value="KOTAK">KOTAK</option>
                                <option value="HDFC">HDFC</option>
                                <option value="SBI">SBI</option>
                                <option value="ICICI">ICICI</option>
                            </select>
                            <br />
                        </form>
                    </div>
                    <div className="uploadContainer">
                        <label htmlFor="images" className="drop-container" id="dropcontainer">
                            {/*<span className="drop-title">Drop files here</span>*/}
                            {/*or*/}
                            <input
                                type="file"
                                id="images"
                                accept=".pdf"
                                required
                                onChange={handleFileChange}
                            />
                            {askForPassword && (
                                <div
                                    className="modal-password"
                                    onClick={() => {
                                        setAskForPassword(false)
                                        setPassword('')
                                        setIsPasswordCorrect(true)
                                    }} // Closes modal when clicking background
                                >
                                    <div
                                        className="modal-body-password"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                        }} // Stops background click action when clicking the modal itself
                                    >
                                        <h2>PDF Upload</h2>
                                        <p>Your PDF is password-protected. To process the transactions, kindly provide
                                            us
                                            the password.</p><p>Rest assured, we won't save your password and your
                                                privacy is our
                                                top priority.</p>
                                        <label htmlFor="password">Password: </label>
                                        <input
                                            type="text"
                                            id="password"
                                            value={password}
                                            required
                                            onChange={handlePasswordInput}
                                            className={!isPasswordCorrect ? 'shake' : ''}
                                            style={!isPasswordCorrect ? { border: '1px solid red' } : {}}
                                        />
                                        <button onClick={uploading}>Upload</button>
                                    </div>
                                </div>
                            )}
                            <div className="butt">
                                <button onClick={uploading}>Upload</button>
                            </div>
                            {showWaitMessage &&
                                <div className="drop-message">Please wait unitil processing is completed.</div>}
                        </label>
                    </div>
                    <div className="subheading">
                        <div className="insightarraow">
                            <p>

                                <Link to="/bank-statement-insights">
                                    <BsArrowRight className="arraows" />
                                </Link>
                            </p>
                            <Link to={`https://capitalflo.co/bank-statement-insights`}>Statement <br />Insights</Link>
                        </div>
                        <div className="mainpathBank">
                            <div className="massive">
                                <p className="titlesBank" id="titlesBank">Bank Transactions</p>
                                {/*{mindate && maxdate && maxdate != "01/01/1970" && maxdate != null &&*/}
                                {/*    <p className="titlesBank" id="titlesBank"*/}
                                {/*       style={{fontSize: "18px", color: "#424242", fontWeight: "500"}}>{fileName}</p>*/}
                                {/*}*/}
                                <div className='statement-selecter-all'>
                                    {/*<StatementsSelector ref={statementsSelectorRef} onSelectStatement={handleStatementChange} defaultStatement={statement}/>*/}
                                    <div className='selectMonth'>
                                        <select name='month' value={statement} onChange={selectedStatement}>
                                            <option value='1'>Statement 1</option>
                                            <option value='2'>Statement 2</option>
                                            {/*<option value='03'>Statement 3</option>*/}
                                            {/*<option value='04'>Statement 4</option>*/}
                                            {/*<option value='05'>Statement 5</option>*/}
                                            {/*<option value='06'>Statement 6</option>*/}
                                            {/*<option value='07'>Statement 7</option>*/}
                                        </select>
                                    </div>
                                </div>
                                {mindate && maxdate && maxdate != "01/01/1970" && maxdate != null &&
                                    <div className="monthDta">
                                        <p>
                                            From: <span>{mindate}</span>
                                        </p>
                                        <p>
                                            To: <span>{maxdate}</span>
                                        </p>
                                    </div>}
                                {mindate && maxdate && maxdate != "01/01/1970" && maxdate != null &&
                                    <p className="titlesBank" id="titlesBank"
                                        style={{
                                            fontSize: "18px",
                                            color: "#424242",
                                            fontWeight: "500"
                                        }}>{altxtdta[0] ? altxtdta[0].fileName : 'statement.pdf'}</p>
                                }
                            </div>
                            {isProcessing && (
                                <div className='loading-animation'>
                                    <img src={LoadingAnimation} alt="" />
                                    <div>
                                        {message === "Processing your Transactions. Please wait for one to five minutes." && message}
                                    </div>
                                </div>
                            )}
                            {isLoading ? (
                                <div className='loading-animation'>
                                    <img src={LoadingAnimation} alt="" />
                                    {/*<div>*/}
                                    {/*    {isProcessing && message === "Processing your Transactions. Please wait for one to five minutes." && message}*/}
                                    {/*</div>*/}
                                </div>
                            ) : (
                                altxtdta && altxtdta.length > 5 ? (
                                    altxtdta.map((each, i) => {
                                        if (!each.amount) {
                                            return null;
                                        }
                                        let CATEGORY;
                                        if (each.category) {
                                            CATEGORY = each.category;
                                        } else {
                                            CATEGORY = "Enter Category";
                                        }
                                        const matchingCategory = allCategories.find(
                                            (item) => item.category === CATEGORY
                                        );
                                        const imageSrc = matchingCategory ? matchingCategory.image : "";

                                        return (
                                            <React.Fragment key={i}>

                                                <div className="listexpenseBank" onClick={txtdetailing}>
                                                    <div className="bankcount">
                                                        <p>{i + 1}</p>
                                                    </div>
                                                    <div
                                                        className={each.notes ? "expense" : ""}
                                                        data-index={i}
                                                    >
                                                        <div
                                                            className={
                                                                each.notes ? "innerExpense" : "expensenonnotes"
                                                            }
                                                            data-index={i}
                                                        >
                                                            <div data-index={i}>
                                                                {!marchant ? (
                                                                    <div className="retry" data-index={i}>
                                                                        <a href="#">
                                                                            <img src={refresh} alt="" />
                                                                        </a>
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                <div className="date" data-index={i}>
                                                                    {each.category == "Enter Category" ? (
                                                                        <RiErrorWarningLine
                                                                            data-index={i}
                                                                            className="warning"
                                                                        />
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                    <div className="innerdate" data-index={i}>
                                                                        {each.date}
                                                                    </div>
                                                                    <div className="innertime" data-index={i}>
                                                                        {each.time}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={
                                                                    each.notes ? "merchant" : "merchantnonnotes"
                                                                }
                                                                data-index={i}
                                                            >
                                                                <p className="merchantcapital" data-index={i}>
                                                                    {each.merchantName}
                                                                </p>
                                                                <hr />
                                                                {!marchant ? (
                                                                    <div className="entercat" data-index={i}>
                                                                        <a href="#">Enter Category</a>
                                                                    </div>
                                                                ) : (
                                                                    <div className="bottommerchant" data-index={i}>
                                                                        <div className="merch">
                                                                            {/* <IconContext.Provider value={{ color: '#000', size: '38px', className: "shopIcon" }}> */}

                                                                            {each.category == "Enter Category" ? (
                                                                                ""
                                                                            ) : (
                                                                                <div className="shopIcon"
                                                                                    data-index={i}>
                                                                                    <a href="#">
                                                                                        <img
                                                                                            src={imageSrc}
                                                                                            alt=""
                                                                                            data-index={i}
                                                                                        />
                                                                                    </a>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        {each.category == "Enter Category" ? (
                                                                            <div className="shopEnter" data-index={i}>
                                                                                {each.category}
                                                                            </div>
                                                                        ) : (
                                                                            <div className="shop" data-index={i}>
                                                                                {each.category}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="price" data-index={i}>
                                                                <span data-index={i}>
                                                                    <BiRupee data-index={i} />
                                                                </span>
                                                                {Math.round(each.amount)}
                                                            </div>
                                                        </div>

                                                        {each.notes ? (
                                                            <div className="noting" data-index={i}>
                                                                <div className="alignnote" data-index={i}>
                                                                    <CgTag className="notetag" data-index={i} />
                                                                    <p data-index={i}>{each.notes}</p>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {/*
                      <div className="noting" data-index={i}>
                       <div className="alignnote" data-index={i}>
                        <CgTag className="notetag" data-index={i}/>
                        <p data-index={i}>Coffee and tea</p>
                       </div>
                      </div>  */}
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        );
                                    })
                                ) : (
                                    <div className='mainMessage'>
                                        <div>
                                            {message != "Processing your Transactions. Please wait for one to five minutes." && message}
                                        </div>
                                    </div>
                                ))}
                            <div className='bottomSpace'></div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Bankstatements;
