import React from 'react';
import './Contact.css';
import {Link} from 'react-router-dom';
import {AiOutlineArrowLeft} from 'react-icons/ai';
import Loginheader from '../PostHeader';

function Contact() {
    return (
        <div style={{
            width: '100vw',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
        }
        }><Loginheader/>
            <div className='backBtn'>
                <Link to="/services">
                    <AiOutlineArrowLeft className='backicon'/>
                    Go back
                </Link>
            </div>
            <div className='contact'>
                <div className='contacting'>
                    <p>Email</p>
                    <p>: <a href="mailto:support@capitalflo.co" style={{color: "#2b80f0"}}>support@capitalflo.co</a></p>
                </div>
            </div>
        </div>
    );
}

export default Contact;
