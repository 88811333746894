import React, { useEffect, useState, useRef, useReducer } from "react";
import TFP from "../../assets/img/TFP.png";
import add from "../../assets/img/addd.png";
// import goal from "../../assets/img/goal.png";
// import downArrow from "../../assets/img/downArrow.png";
import Logheader from "../../PostHeader.js";
import { GrCircleInformation } from "react-icons/gr";
import axios from "axios";
import LoadingAnimation from "../../assets/Loading.svg";
import "./Tfp.css";
import { Link } from "react-router-dom";
import { isDemoAccountUsed } from "../../utils/CheckIfDemo";
import DemoBlockerPopup from "../DemoBlockerPopup.js";
import { IoIosArrowDropdown } from "react-icons/io";
import { Title } from "chart.js";
import { BsArrowRight } from "react-icons/bs";
import assetLogo from '../../assets/asset_planner.png'
import { Helmet } from "react-helmet";
const TfpAssets = () => {
    const [SavingAmount, setSavingAmount] = useState("");
    const [MutualFundsAmount, setMutualFundsAmount] = useState("")
    const [StocksAmount, setStocksAmount] = useState("");
    const [BondsAmount, setBondsAmount] = useState("");
    const [VehicleAmount, setVehicleAmount] = useState("");
    const [RealEstateAmount, setRealEstateAmount] = useState("");
    const [InvestmentAmount, setInvestmentAmount] = useState("");

    const [LoanAmount, setLoanAmount] = useState("");
    const [CreditcardAmount, setCreditcardAmount] = useState("");
    const tfpSectionRef =  useRef(null)
    // const [EmergencyAmount, setEmergencyAmount] = useState("");
    // const [VacationAmount, setVacationAmount] = useState("");
    // const [VechicleAmount, setVechicleAmount] = useState("");
    // const [HomeAmount, setHomeAmount] = useState("");
    // const [EducationAmount, setEducationAmount] = useState("");
    //
    //
    // const [TargetEmergencyAmount, setTargetEmergencyAmount] = useState("");
    // const [TargetVacationAmount, setTargetVacationAmount] = useState("");
    // const [TargetVechicleAmount, setTargetVechicleAmount] = useState("");
    // const [TargetHomeAmount, setTargetHomeAmount] = useState("");
    // const [TargetEducationAmount, setTargetEducationAmount] = useState("");

    const [incomerows, setIncomeRows] = useState([]);
    const [expenserows, setExpenseRows] = useState([]);
    const [assetrows, setAssetRows] = useState([]);
    const [percentagesAssets, setPercentagesAssets] = useState([]);
    const [liabilitiesrows, setLiabilitiesRows] = useState([]);
    const [addgoalsrows, setAddgoalsRows] = useState([]);

    // const goalsSectionRef = useRef(null);

    const [totalSumIncome, setTotalSumIncome] = useState(0);
    const [totalSumExpenses, setTotalSumExpenses] = useState(0);
    const [totalSumAssets, setTotalSumAssets] = useState(0);
    const [totalSumLaibilities, setTotalSumLaibilities] = useState(0);

    const [deletedRows, setDeletedRows] = useState([]);
    const [isDeleted, setDeletedFlag] = useState(false);

    const [deletedAssetRows, setDeletedAssetRows] = useState([]);
    const [isAssetDeleted, setAssetDeletedFlag] = useState(false);

    // const [deletedGoalsRows, setDeletedGoalsRows] = useState([]);
    // const [isGoalsDeleted, setGoalsDeletedFlag] = useState(false);

    const [isTyping, setIsTyping] = useState(false);
    const [Tokens, setTokens] = useState("");
    const [loading, setLoading] = useState(true);

    //abbreviation trigger
    const [isSaved, setisSaved] = useState(true);

    //REDUCER
    const initialState = {
        BankSavingsTerm: "",
        MutualFundsTerm: "",
        StocksTerm: "",
        BondsTerm: "",
        VehicleTerm: "",
        RealEstateTerm: "",
        InvestmentTerm: "",
        OtherAssetsTerms: [],
    };
    const reducer = (state, action) => {
        switch (action.type) {
            case 'SET_SAVINGS_TERM':
                return {...state, BankSavingsTerm: action.payload};
            case 'SET_MUTUAL_FUNDS_TERM':
                return {...state, MutualFundsTerm: action.payload};
            case 'SET_STOCKS_TERM':
                return {...state, StocksTerm: action.payload};
            case 'SET_BONDS_TERM':
                return {...state, BondsTerm: action.payload};
            case 'SET_VEHICLE_TERM':
                return {...state, VehicleTerm: action.payload};
            case 'SET_REAL_ESTATE_TERM':
                return {...state, RealEstateTerm: action.payload};
            case 'SET_INVESTMENTS_TERM':
                return {...state, InvestmentTerm: action.payload};
            case 'OTHER_GOALS':
                return {...state, OtherAssetsTerms: action.payload};
            case 'DELETE_OTHER_GOALS':
                const goalToDelete = action.payload;

                // Create a new array without the goal to delete
                const updatedOtherGoals = state.OtherAssetsTerms.filter(
                    goal => goal.title !== goalToDelete.title
                );

                // Return the updated state
                return {...state, OtherAssetsTerms: updatedOtherGoals};

        }
    };
    const [state, dispatch] = useReducer(reducer, initialState)
    //DEMO
    const [showDemoPopup, setShowDemoPopup] = useState(false);
    const tokenize = async () => {
        try {
            const response = await axios.get(
                "https://api.capitalflo.co/api/users/protect-user",
                {withCredentials: true}
            );
            console.log(response);

            if (response.data.message === "Cookie not found") {
                setTokens(null);
            } else {
                console.log(response.data.jwtToken);
                setTokens(response.data.jwtToken);
            }
        } catch (err) {
            console.log(err.response.data.message);
            setTokens(null);
        }
    };
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                "https://api.capitalflo.co/api/users/total-financial-position",
                {
                    headers: {
                        Authorization: `Bearer ${Tokens}`,
                    },
                    withCredentials: true,
                }
            );
            console.log("retreive:", response.data);
            if (response.data.message === "User not found") {
                console.log("user not found")
            } else {
                setSavingAmount(response.data.totalFinalPostionAssets.Savings || "");
                setMutualFundsAmount(response.data.totalFinalPostionAssets.MutualFunds || "");
                setStocksAmount(response.data.totalFinalPostionAssets.Stocks || "");
                setBondsAmount(response.data.totalFinalPostionAssets.Bonds || "");
                setVehicleAmount(response.data.totalFinalPostionAssets.Vehicle || "");
                setRealEstateAmount(response.data.totalFinalPostionAssets.RealEstate || "");
                setInvestmentAmount(response.data.totalFinalPostionAssets.Investments || "");
                const userDefinedAssets =
                    response.data.totalFinalPostionAssets.userDefinedAssetsData || [];
                setAssetRows(userDefinedAssets);
                const userDefinedIncome =
                    response.data.totalFinalPostionIncome.userDefinedIncomeData || [];
                setIncomeRows(userDefinedIncome);
                setTotalSumIncome(response.data.totalFinalPostionTotal.incomeTotal || "");
                setTotalSumExpenses(response.data.totalFinalPostionTotal.expenseTotal || "");
                setTotalSumAssets(response.data.totalFinalPostionTotal.assetsTotal || "");
                setTotalSumLaibilities(response.data.totalFinalPostionTotal.laibilitiesTotal || "");
                console.log("TotalAsset:", totalSumAssets)
            }
            //asset planning data 
            const responseAsset = await axios.post(
                "https://api.capitalflo.co/api/users/save-asset-planner",
                {},
                {
                    headers: {
                        Authorization: `Bearer ${Tokens}`,
                    },
                    withCredentials: true,
                }
            );
            console.log("asset:", responseAsset.data.data.terms);
            if(responseAsset.data.message === "User not found"){
                console.log('User not found')
            } else {
                dispatch({type: "SET_SAVINGS_TERM", payload: responseAsset.data.data.terms.BankSavingsTerm});
                dispatch({type: "SET_MUTUAL_FUNDS_TERM", payload: responseAsset.data.data.terms.MutualFundsTerm});
                dispatch({type: "SET_STOCKS_TERM", payload: responseAsset.data.data.terms.StocksTerm});
                dispatch({type: "SET_BONDS_TERM", payload: responseAsset.data.data.terms.BondsTerm});
                dispatch({type: "SET_VEHICLE_TERM", payload: responseAsset.data.data.terms.VehicleTerm});
                dispatch({type: "SET_REAL_ESTATE_TERM", payload: responseAsset.data.data.terms.RealEstateTerm});
                dispatch({type: "SET_INVESTMENTS_TERM", payload: responseAsset.data.data.terms.InvestmentTerm});
                dispatch({type: "OTHER_GOALS", payload: responseAsset.data.data.terms.OtherAssetsTerms});
            }
            setLoading(false)
        } catch (err) {
            setLoading(false)
            console.log(err);
        }
    };

    useEffect(() => {
        tokenize();
        fetchData();

    }, []);


    const formatNumber = (number) => {
        const parts = number.toString().split(".");
        if (parts.length === 2) {
            // If there's a decimal part, limit it to two decimal places
            return parts[0] + "." + parts[1].slice(0, 2);
        } else {
            // If there's no decimal part, just return the original number
            return number.toString();
        }
    };
    const abbreviateNumber = (number) => {
        if (isNaN(number) || !isFinite(number) || number === 0) {
            return '0'; // Handle NaN or Infinity case, return a default value
        }
        const sign = Math.sign(number);
        const absoluteNumber = Math.abs(number);

        const digits = absoluteNumber.toString().length;

        if (digits > 3 && digits <= 5) {
            // Abbreviate thousands
            const result = (sign * absoluteNumber / 1000);
            return result % 1 !== 0 ? formatNumber(result) + 'K' : result.toFixed(0) + 'K';
        } else if (digits >= 6 && digits <= 7) {
            // Abbreviate lakhs
            const result = (sign * absoluteNumber / 100000);
            return result % 1 !== 0 ? formatNumber(result) + 'L' : result.toFixed(0) + 'L';
        } else if (digits >= 8) {
            // Abbreviate crores
            const result = (sign * absoluteNumber / 10000000);
            return result % 1 !== 0 ? formatNumber(result) + 'Cr' : result.toFixed(0) + 'Cr';
        } else {
            // Default to the original number for smaller values
            return (sign * absoluteNumber).toFixed(0).trim();
        }
    };
    const convertToNumeric = (abbreviatedAmount) => {
        const numericPart = parseInt(abbreviatedAmount, 10);
        const unit = abbreviatedAmount.slice(-1).toUpperCase();

        if (unit === "K") {
            return numericPart * 1000;
        } else if (unit === "L") {
            return numericPart * 100000;
        } else if (unit === "CR") {
            return numericPart * 10000000;
        } else {
            return numericPart;
        }
    };
    const handleInputChange = () => {
        // This function will be called whenever the user types in any input field
        setIsTyping(true); // Set isTyping to true to show the "Save" button
    };

    const handleAssestsChange = (e, title) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        switch (title) {
            case 'BankSavings': {
                if (!isNaN(intValue)) {
                    setSavingAmount(intValue);
                } else {
                    setSavingAmount("");
                }
                break;
            }
            case 'MutualFunds': {
                if (!isNaN(intValue)) {
                    setMutualFundsAmount(intValue);
                } else {
                    setMutualFundsAmount("");
                }
                break;
            }
            case 'StocksEquity': {
                if (!isNaN(intValue)) {
                    setStocksAmount(intValue);
                } else {
                    setStocksAmount("");
                }
                break;
            }
            case 'BondsFixedIncome': {
                if (!isNaN(intValue)) {
                    setBondsAmount(intValue);
                } else {
                    setBondsAmount("");
                }
                break;
            }
            case 'Vehicle': {
                if (!isNaN(intValue)) {
                    setVehicleAmount(intValue);
                } else {
                    setVehicleAmount("");
                }
                break;
            }
            case 'RealEstate': {
                if (!isNaN(intValue)) {
                    setRealEstateAmount(intValue);
                } else {
                    setRealEstateAmount("");
                }
                break;
            }
            case 'Investments': {
                if (!isNaN(intValue)) {
                    setInvestmentAmount(intValue);
                } else {
                    setInvestmentAmount("");
                }
                break;
            }
            default:
                return;
        }
        handleInputChange();
    }
    const handleRowAddAsset = () => {
        const newRow = { title: "", amount: "" };
        setAssetRows((prevRows) => {
            return [newRow, ...prevRows];
        });
    };
    const handleRowTitleChangeAsset = (index, e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        const updatedRows = [...assetrows];
        updatedRows[index].title = e.target.value;
        setAssetRows(updatedRows);
    };
    const handleRowAmountChangeAsset = (index, title, e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        const updatedRows = [...assetrows];
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        const intValue = parseInt(inputAmount, 10);
        updatedRows[index].amount = isNaN(intValue) ? "" : intValue;
        setAssetRows(updatedRows);
    };
    const handleSaveDataToDb = async () => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        setisSaved(true)
        setIsTyping(false);
        let total = []

        const assets = [
            { title: "Savings", amount: SavingAmount },
            { title: "MutualFunds", amount: MutualFundsAmount },
            { title: "Stocks", amount: StocksAmount },
            { title: "Bonds", amount: BondsAmount },
            { title: "Vehicle", amount: VehicleAmount },
            { title: "RealEstate", amount: RealEstateAmount },
            { title: "Investments", amount: InvestmentAmount },

            ...assetrows,
        ];

        const totalIncome = totalSumIncome
        console.log("Total Income:", totalIncome);

        const totalExpense = totalSumExpenses
    
        console.log("Total Expense:", totalExpense);

        const totalAssets = assets.reduce(
            (acc, value) =>
                acc + (isNaN(parseInt(value.amount)) ? 0 : parseInt(value.amount)),
            0
        );
        console.log("Total Assets:", totalAssets);

        const totalLiabilities = totalSumLaibilities
        console.log("Total Liabilities:", totalLiabilities);


        setTotalSumAssets(totalAssets);

        const dataA = {
            Savings: SavingAmount,
            MutualFunds: MutualFundsAmount,
            Stocks: StocksAmount,
            Bonds: BondsAmount,
            Vehicle: VehicleAmount,
            RealEstate: RealEstateAmount,
            Investments: InvestmentAmount,
            userDefinedAssetsData: assetrows,

        }

        // const dataG = {
        //
        //     Emergencyfund: EmergencyAmount,
        //     Vacation: VacationAmount,
        //     Vehicle: VechicleAmount,
        //     Home: HomeAmount,
        //     Education: EducationAmount,
        //     TargetEmergencyfund: TargetEmergencyAmount,
        //     TargetVacation: TargetVacationAmount,
        //     TargetVehicle: TargetVechicleAmount,
        //     TargetHome: TargetHomeAmount,
        //     TargetEducation: TargetEducationAmount,
        //     userDefinedGoalsData: addgoalsrows
        //
        //
        // }

        total = {
            incomeTotal: totalIncome,
            expenseTotal: totalExpense,
            assetsTotal: totalAssets,
            laibilitiesTotal: totalLiabilities
        };
        const AssetPlanner  = {
            term:state,
            percentage: percentagesAssets
        }

        console.log('FINAL ASSET PLANNER', AssetPlanner)
        await axios.
            post('https://api.capitalflo.co/api/users/save-asset-planner',
            AssetPlanner,
            {
                headers: {
                    Authorization: `Bearer ${Tokens}`,
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            })
            .then((response) => {
                console.log(
                    "Asset pLanner successfully sent to the server:",
                    response.data
                );
            })
            .catch((error) => {
                console.error("Error sending Asset pLanner to the server:", error);
            });
        

        await axios
            .put(
                "https://api.capitalflo.co/api/users/total-financial-position-assest",
                { dataA },
                {
                    headers: {
                        Authorization: `Bearer ${Tokens}`,
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            )
            .then((response) => {
                console.log(
                    "Total Expense successfully sent to the server:",
                    response.data
                );
            })
            .catch((error) => {
                console.error("Error sending data to the server:", error);
            });

        await axios
            .put(
                "https://api.capitalflo.co/api/users/total-financial-position-total",
                { total },
                {
                    headers: {
                        Authorization: `Bearer ${Tokens}`,
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            )
            .then((response) => {
                console.log(
                    "Total Expense successfully sent to the server:",
                    response.data
                );
            })
            .catch((error) => {
                console.error("Error sending data to the server:", error);
            });


    };
    const [otherGoals, setOtherGoals] = useState([]);
    const handleRowAmountChange = (index, e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = parseInt(e.target.value.trim());
        const intValue = parseInt(inputAmount, 10);
        const updatedRows = [...assetrows];
        updatedRows[index].amount = isNaN(intValue) ? "" : intValue; 
        setAssetRows(updatedRows);
        handleInputChange();
    };

    const handleUserDefinedTimeFrame = (index, title, e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        const inputAmount = e.target.value;
        console.log(inputAmount)
        const updatedRows = [...assetrows];
        updatedRows[index].term = inputAmount;
        const updatedOtherAssetsTerms = assetrows.map((goal) => ({
            title: goal.title,
            term:goal.term,
            
        }));
        dispatch({type: 'OTHER_GOALS', payload: updatedOtherAssetsTerms})
        handleInputChange();
    }
    const handleRowPercentageChange = (index, e) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Ensure that the entered value is a number (integer)
        const inputAmount = e.target.value.trim();
        console.log('PERCENTAGEEE',inputAmount)
        const updatedRows = [...assetrows];
        updatedRows[index].percentage = isNaN(inputAmount) ? "" : inputAmount;
        setAssetRows(updatedRows);
    }

    useEffect(() => {
        console.log('asset rows ', assetrows)
    },[assetrows])
    const handleAssetTimeFrame = (e, title) => {
        console.log('TERRMM',e.target.value, title)
        switch(title) {
            case "BankSavings":
                dispatch({type: 'SET_SAVINGS_TERM', payload: e.target.value});
                break;
            case "mutualFunds":
                dispatch({type: 'SET_MUTUAL_FUNDS_TERM', payload: e.target.value});
                break;
            case "Stocks":
                dispatch({type: 'SET_STOCKS_TERM', payload: e.target.value});
                break;
            case "Bonds":
                dispatch({type: 'SET_BONDS_TERM', payload: e.target.value});
                break;
            case "Vehicle":
                dispatch({type: 'SET_VEHICLE_TERM', payload: e.target.value});
                break;
            case "RealEstate":
                dispatch({type: 'SET_REAL_ESTATE_TERM', payload: e.target.value});
                break;
            case "Investments":
                dispatch({type: 'SET_INVESTMENTS_TERM', payload: e.target.value});
                break;
        }
        handleInputChange();
    }
    useEffect(() => {
        console.log('STATE VALUE RNNNN', state)
    },[state])
    const handleRowDeleteAsset = (index) => {
        if (isDemoAccountUsed()) {
            setShowDemoPopup(true);
            return;
        }
        // Show a confirmation dialog before deleting the row
        const confirmDelete = window.confirm("Confirm Delete?");

        if (confirmDelete) {
            // Create a copy of the incomerows state
            const updatedAssetRows = [...assetrows];

            // Get the amount of the row to be deleted
            const deletedAmount = isNaN(updatedAssetRows[index].amount)
                ? 0
                : parseInt(updatedAssetRows[index].amount); // Parse the amount as an integer

            // Remove the row at the specified index
            updatedAssetRows.splice(index, 1);

            // Update the state with the new rows
            setAssetRows(updatedAssetRows);

            // Update totalSumIncome by subtracting the deleted amount
            const updatedTotalSumAsset = isNaN(totalSumAssets)
                ? 0
                : totalSumAssets - deletedAmount; // Subtract the deleted amount
            setTotalSumAssets(updatedTotalSumAsset);

            // Update other states or perform necessary actions if needed

            setIsTyping(false);
            setAssetDeletedFlag(true);
            setDeletedAssetRows(updatedAssetRows); // Use the updated rows
        }
    };

    useEffect(() => {
        let updatedRows = [...assetrows];
        // updatedRows.percentage = isNaN(assetrows.amount/totalSumAssets*100) ? "" : assetrows.amount/totalSumAssets*100;
        updatedRows = updatedRows.forEach((row) => {
            row.percentage = isNaN(row.amount/totalSumAssets*100) ? "" : row.amount/totalSumAssets*100
        })
        console.log('Updated Rows',updatedRows)
        let percentageAssets = {
            savings:(SavingAmount/totalSumAssets*100).toFixed(1)+"%",
            mutualFunds:(MutualFundsAmount/totalSumAssets*100).toFixed(1)+"%",
            stocks:(StocksAmount/totalSumAssets*100).toFixed(1)+"%",
            bonds:(BondsAmount/totalSumAssets*100).toFixed(1)+"%",
            vehicle:(VehicleAmount/totalSumAssets*100).toFixed(1)+"%",
            realEstate:(RealEstateAmount/totalSumAssets*100).toFixed(1)+"%",
            investments:(InvestmentAmount/totalSumAssets*100).toFixed(1)+"%",
            userDefinedAssets:assetrows.map((row) => {
                return {
                    title: row.title,
                    percentage: (row.percentage).toFixed(1)+"%"
                }
            })
        }
        setPercentagesAssets(percentageAssets)
    },[totalSumAssets])

    useEffect(() => {
        const totalUserDefined = assetrows.reduce(
            (acc, value) =>
                acc + (isNaN(parseInt(value.amount)) ? 0 : parseInt(value.amount)),
            0
        );
        const total = Number(SavingAmount) + Number(MutualFundsAmount) + Number(StocksAmount) + Number(BondsAmount )+ Number(VehicleAmount) + Number(RealEstateAmount) + Number(InvestmentAmount)
        console.log('total asset value',totalUserDefined,total)
        setTotalSumAssets(Number(totalUserDefined) + Number(total))
        
    },[assetrows,SavingAmount,MutualFundsAmount,StocksAmount,BondsAmount,VehicleAmount,RealEstateAmount,InvestmentAmount])
    

    useEffect(() => {
        if (isAssetDeleted) {
            handleSaveDataToDb();
            setAssetDeletedFlag(false);
        }
    }, [deletedAssetRows]);
    if (loading) {
        return <div className='blog_loading_animation'><img src={LoadingAnimation} alt="" /></div>
    }
    const closePopup = () => {
        setShowDemoPopup(false);
    }

    return (
        <div style={{
            width: '100vw',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            // alignContent: 'center',
            alignItems: 'center',
        }
        }>
            {/* <div className="sticky-container">
        <div className="savebackground">
          <div className="savedButton" onClick={handleSaveDataToDb}>Save Data</div>
        </div>
      </div> */}
            <Logheader />
            <Helmet>
                <title>Asset Planner</title>
                <meta name="description"
                    content="Plan your assets" />
            </Helmet>
            {showDemoPopup && <DemoBlockerPopup closePopup={closePopup} />}
            <div className="topie-AssetPlanner">
            <img src={assetLogo} alt="" />

                <p style={{
                    textAlign: 'center',
                }}>Asset Planner </p>
<GrCircleInformation style={{ fontSize: '24px'}}/>

                {/* <a>Goals</a> */}
            </div>


            {isTyping && (<div className="sticky-container">
                <div className="savebackground">
                    <div className="savedButton" onClick={handleSaveDataToDb}>Save Data</div>
                </div>
            </div>)}

            <div className="alltable-assets">
                    <div className='table-container-Assets'>
                        <table>
                            <tr>
                                <th>Assets
                                <span>
                                        <img
                                            src={add}
                                            className="mainFirstRow"
                                            alt=""
                                            onClick={handleRowAddAsset}
                                        />
                                    </span>
                                </th>
                                <th className="FirstRow">{" "}
                                    Amount
                                </th>
                                <th className="FirstRow">{" "}
                                    Percentage of Total Assets
                                </th>
                                <th className="FirstRow">{" "}
                                    Asset Timeframe
                                </th>
                            </tr>
                            {assetrows.map(
                                (
                                    row,
                                    index // Add slice() and reverse() here
                                ) => (
                                    <tr key={index}>
                                        <td style={{ position: "relative" }}>
                                            <span
                                                onClick={() => handleRowDeleteAsset(index)}
                                                className="delete-cross-tfp"
                                            >
                                                &#10006; {/* Unicode character for cross sign */}
                                            </span>
                                            <input
                                                type="text"
                                                className="integer-input"
                                                placeholder="Asset Type"
                                                value={row.title}
                                                onChange={(e) => {
                                                    handleRowTitleChangeAsset(index, e);
                                                    handleInputChange();

                                                }}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                className="integer-input"
                                                placeholder="Enter amount"
                                                value={isSaved === false ? row.amount : abbreviateNumber(row.amount)}
                                                onChange={(e) => {
                                                    handleRowAmountChange(index, e);
                                                    handleInputChange();

                                                }}
                                                onFocus={() => {
                                                    console.log('changing is saved value to false')
                                                    setisSaved(false);
                                                }}
                                                onBlur={() => {
                                                    console.log('changing is saved value to true')
                                                    setisSaved(true);
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <input className="integer-input" value={isNaN(row.amount*100/totalSumAssets)?'':(row.amount * 100 / totalSumAssets).toFixed(1)+'%'} readOnly={true}/>
                                        </td>
                                        <td >
                                            <select
                                                style={{ width: '100%', height:'5vh', fontSize: '15px'}}
                                                onChange={(e) => handleUserDefinedTimeFrame(index, row.title,e)}
                                                value={state.OtherAssetsTerms.find(
                                                    (option) => option.title === row.title
                                                )?.term}
                                            >
                                                <option value="" style={{height: '500px'}}>Select</option>
                                                <option value="short term">Short Term (0-2 years)</option>
                                                <option value="medium term">Medium Term (2-5 years)</option>
                                                <option value="long term">Long Term (5-20 years)</option>
                                            </select>
                                        </td>
                                    </tr>
                                )
                            )}
                            <tr>
                                <td>Cash/Bank Savings</td>
                                <td><input
                                    type="text"
                                    className="integer-input"
                                    placeholder="Enter amount"
                                    value={isSaved === false ? SavingAmount : abbreviateNumber(SavingAmount)}
                                    onChange={(e) => {
                                        handleAssestsChange(e, 'BankSavings')
                                    }}
                                    onFocus={() => {
                                        console.log('changing is saved value to false')
                                        setisSaved(false);
                                    }}
                                    onBlur={() => {
                                        console.log('changing is saved value to true')
                                        setisSaved(true);
                                    }}
                                /></td>
                                <td> <input className="integer-input" value={isNaN(SavingAmount * 100 / totalSumAssets)?'':(SavingAmount * 100 / totalSumAssets).toFixed(1)+'%'} readOnly={true}/>
                                </td>
                                <td>
                                <select
                                                style={{ width: '100%', height:'5vh', fontSize: '15px'}}
                                                onChange={(e) => handleAssetTimeFrame(e, 'BankSavings')}
                                                value={state.BankSavingsTerm}
                                            >
                                                <option value="" style={{height: '500px'}}>Select </option>
                                                <option value="short term">Short Term (0-2 years)</option>
                                                <option value="medium term">Medium Term (2-5 years)</option>
                                                <option value="long term">Long Term (5-20 years)</option>
                                            </select>
                                </td>
                            </tr>
                            <tr>
                                <td>Mutual Funds</td>
                                <td><input
                                    type="text"
                                    className="integer-input"
                                    placeholder="Enter amount"
                                    value={isSaved === false ? MutualFundsAmount : abbreviateNumber(MutualFundsAmount)}
                                    onChange={(e) => {
                                        handleAssestsChange(e, 'MutualFunds')
                                    }}
                                    onFocus={() => {
                                        console.log('changing is saved value to false')
                                        setisSaved(false);
                                    }}
                                    onBlur={() => {
                                        console.log('changing is saved value to true')
                                        setisSaved(true);
                                    }}
                                /></td>
                                <td> <input className="integer-input" value={isNaN(MutualFundsAmount * 100 / totalSumAssets)?'':(MutualFundsAmount * 100 / totalSumAssets).toFixed(1)+'%'} readOnly={true}/>
                                </td>
                                <td>
                                <select
                                                style={{ width: '100%', height:'5vh', fontSize: '15px'}}
                                                onChange={(e) => handleAssetTimeFrame(e, 'mutualFunds')}
                                                value={state.MutualFundsTerm}
                                            >
                                                <option value="" style={{height: '500px'}}>Select </option>
                                                <option value="short term">Short Term (0-2 years)</option>
                                                <option value="medium term">Medium Term (2-5 years)</option>
                                                <option value="long term">Long Term (5-20 years)</option>
                                            </select>
                                </td>
                            </tr>
                            <tr>
                                <td>Stocks/Equity</td>
                                <td><input
                                    type="text"
                                    className="integer-input"
                                    placeholder="Enter amount"
                                    value={isSaved === false ? StocksAmount : abbreviateNumber(StocksAmount)}
                                    onChange={(e) => {
                                        handleAssestsChange(e, 'StocksEquity')
                                    }}
                                    onFocus={() => {
                                        console.log('changing is saved value to false')
                                        setisSaved(false);
                                    }}
                                    onBlur={() => {
                                        console.log('changing is saved value to true')
                                        setisSaved(true);
                                    }}
                                /></td>
                                <td> <input className="integer-input" value={isNaN(StocksAmount * 100 / totalSumAssets)?'':(StocksAmount * 100 / totalSumAssets).toFixed(1)+'%'} readOnly={true}/>
                                </td>
                                <td>
                                <select
                                                style={{ width: '100%', height:'5vh', fontSize: '15px'}}
                                                onChange={(e) => handleAssetTimeFrame(e, 'Stocks')}
                                                value={state.StocksTerm}
                                            >
                                                <option value="" >Select </option>
                                                <option value="short term">Short Term (0-2 years)</option>
                                                <option value="medium term">Medium Term (2-5 years)</option>
                                                <option value="long term">Long Term (5-20 years)</option>
                                            </select>
                                </td>
                            </tr>
                            <tr>
                                <td>Bonds</td>
                                <td><input
                                    type="text"
                                    className="integer-input"
                                    placeholder="Enter amount"
                                    value={isSaved === false ? BondsAmount : abbreviateNumber(BondsAmount)}
                                    onChange={(e) => {
                                        handleAssestsChange(e, 'BondsFixedIncome')
                                    }}
                                    onFocus={() => {
                                        console.log('changing is saved value to false')
                                        setisSaved(false);
                                    }}
                                    onBlur={() => {
                                        console.log('changing is saved value to true')
                                        setisSaved(true);
                                    }}
                                /></td>
                                <td> <input className="integer-input" value={isNaN(BondsAmount * 100 / totalSumAssets)?'':(BondsAmount * 100 / totalSumAssets).toFixed(1)+'%'} readOnly={true}/>
                                </td>
                                <td>
                                <select
                                                style={{ width: '100%', height:'5vh', fontSize: '15px'}}
                                                onChange={(e) => handleAssetTimeFrame(e, 'Bonds')}
                                                value={state.BondsTerm}
                                            >
                                                <option value="" style={{height: '500px'}}>Select</option>
                                                <option value="short term">Short Term (0-2 years)</option>
                                                <option value="medium term">Medium Term (2-5 years)</option>
                                                <option value="long term">Long Term (5-20 years)</option>
                                            </select>
                                </td>
                            </tr>
                            <tr>
                                <td>Vehicle</td>
                                <td><input
                                    type="text"
                                    className="integer-input"
                                    placeholder="Enter amount"
                                    value={isSaved === false ? VehicleAmount : abbreviateNumber(VehicleAmount)}
                                    onChange={(e) => {
                                        handleAssestsChange(e, 'Vehicle')
                                    }}
                                    onFocus={() => {
                                        console.log('changing is saved value to false')
                                        setisSaved(false);
                                    }}
                                    onBlur={() => {
                                        console.log('changing is saved value to true')
                                        setisSaved(true);
                                    }}
                                /></td>
                                <td> <input className="integer-input" value={isNaN(VehicleAmount * 100 / totalSumAssets)?'':(VehicleAmount * 100 / totalSumAssets).toFixed(1)+'%'} readOnly={true}/>
                                </td>
                                <td>
                                <select
                                                style={{ width: '100%', height:'5vh', fontSize: '15px'}}
                                                onChange={(e) => handleAssetTimeFrame(e, 'Vehicle')}
                                                value={state.VehicleTerm}
                                            >
                                                <option value="" style={{height: '500px'}}>Select </option>
                                                <option value="short term">Short Term (0-2 years)</option>
                                                <option value="medium term">Medium Term (2-5 years)</option>
                                                <option value="long term">Long Term (5-20 years)</option>
                                            </select>
                                </td>
                            </tr>
                            <tr>
                                <td>Real estate</td>
                                <td><input
                                    type="text"
                                    className="integer-input"
                                    placeholder="Enter amount"
                                    value={isSaved === false ? RealEstateAmount : abbreviateNumber(RealEstateAmount)}
                                    onChange={(e) => {
                                        handleAssestsChange(e, 'RealEstate')
                                    }}
                                    onFocus={() => {
                                        console.log('changing is saved value to false')
                                        setisSaved(false);
                                    }}
                                    onBlur={() => {
                                        console.log('changing is saved value to true')
                                        setisSaved(true);
                                    }}
                                /></td>
                                <td> <input className="integer-input" value={isNaN(RealEstateAmount * 100 / totalSumAssets)?'':(RealEstateAmount * 100 / totalSumAssets).toFixed(1)+'%'} readOnly={true}/>
                                </td>
                                <td>
                                <select
                                                style={{ width: '100%', height:'5vh', fontSize: '15px'}}
                                                onChange={(e) => handleAssetTimeFrame(e, 'RealEstate')}
                                                value={state.RealEstateTerm}
                                            >
                                                <option value="" style={{height: '500px'}}>Select</option>
                                                <option value="short term">Short Term (0-2 years)</option>
                                                <option value="medium term">Medium Term (2-5 years)</option>
                                                <option value="long term">Long Term (5-20 years)</option>
                                            </select>
                                </td>
                            </tr>
                            <tr>
                                <td>Investments
                                </td>
                                <td><input
                                    type="text"
                                    className="integer-input"
                                    placeholder="Enter amount"
                                    value={isSaved === false ? InvestmentAmount : abbreviateNumber(InvestmentAmount)}
                                    onChange={(e) => {
                                        handleAssestsChange(e, 'Investments')
                                    }}
                                    onFocus={() => {
                                        console.log('changing is saved value to false')
                                        setisSaved(false);
                                    }}
                                    onBlur={() => {
                                        console.log('changing is saved value to true')
                                        setisSaved(true);
                                    }}
                                /></td>
                                <td> <input className="integer-input" value={isNaN(InvestmentAmount * 100 / totalSumAssets)?'':(InvestmentAmount * 100 / totalSumAssets).toFixed(1)+'%'} readOnly={true}/>
                                </td>
                                <td>
                                <select
                                                style={{ width: '100%', height:'5vh', fontSize: '15px'}}
                                                onChange={(e) => handleAssetTimeFrame(e, 'Investments')}
                                                value={state.InvestmentTerm}
                                            >
                                                <option value="" style={{height: '500px'}}>Select</option>
                                                <option value="short term">Short Term (0-2 years)</option>
                                                <option value="medium term">Medium Term (2-5 years)</option>
                                                <option value="long term">Long Term (5-20 years)</option>
                                            </select>
                                </td>
                            </tr>
                            <tr>
                                <td className="sumTotal">Total</td>
                                <td><input className="integer-input"value={isSaved === false ? totalSumAssets : abbreviateNumber(totalSumAssets)}/></td>
                                <td><input className="integer-input"value={100+'%'}/></td>
                                <td>
                                </td>
                            </tr>
                        </table>
                    </div>
            </div>
            {/*<div></div>*/}
            {/*<div> </div>*/}

            {/*<div id='goals-heading'>*/}

            {/*</div>*/}
            {/*<div></div>*/}

            {/*<div className='bottomSpace'></div>*/}

        </div>
    );
}

export default TfpAssets