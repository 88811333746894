//
// import './FeaturedPost.css'; // Import the CSS file for styling
//
// import React, { useState, useEffect } from 'react';
// import moment from 'moment';
// import {Link} from "react-router-dom";
//
//
// import {getSimilarPosts, getRecentPosts, getComments, fetchData} from '../query';
// import styles from './PostWidget.css'; // Import the CSS module
//
// const FeaturedPost = ({ categories, slug }) => {
//     const [relatedPosts, setRelatedPosts] = useState([]);
//     const [checkRecentPost, setRecentPost] = useState(false);
//
//     const [posts, setPosts] = useState([]);
//     const [checkdata, setCheckdata] = useState(false);
//     const [searchTerm, setSearchTerm] = useState(''); // State for search term
//
//     const [commentsfetch, setCommentsfetch] = useState([]);
//     const [checkComments, setCheckComments] = useState(false);
//     const [checkCommentsLength, setCheckCommentsLength] = useState([]);
//
//     let fetchedPosts;
//
//     useEffect(() => {
//         async function fetchPosts() {
//             fetchedPosts = await fetchData();
//             if (fetchedPosts) {
//                 setPosts(fetchedPosts);
//                 setCheckdata(true);
//             }
//         }
//
//         fetchPosts();
//     }, []);
//
//     useEffect(() => {
//         // if (slug) {
//         //     getSimilarPosts(categories, slug).then((result) => {
//         //         setRelatedPosts(result);
//         //     });
//         // }
//
//         getRecentPosts().then((result) => {
//             setRelatedPosts(result);
//             setRecentPost(true)
//         });
//
//     }, [slug]);
//
//
//
//     useEffect(() => {
//         if (Array.isArray(relatedPosts.posts)) {
//             // Create an object to store comments count by slug
//             const commentsCountBySlug = {};
//
//             // Create an array of promises for fetching comments count
//             const commentCountPromises = relatedPosts.posts.map((post) => {
//                 return getComments(post.slug)
//                     .then((result) => {
//                         // Store comments count in the object using the slug as the key
//                         commentsCountBySlug[post.slug] = result.comments.length;
//                     })
//                     .catch((error) => {
//                         console.error(`Error fetching comments for slug ${post.slug}:`, error);
//                     });
//             });
//
//             // Use Promise.all to fetch all comments counts and update state
//             Promise.all(commentCountPromises)
//                 .then(() => {
//                     // Update the comments count state with the object
//                     setCheckCommentsLength(commentsCountBySlug);
//                 })
//                 .catch((error) => {
//                     console.error('Error fetching comments counts:', error);
//                 });
//         }
//     }, [relatedPosts]);
//
//
//
//     //
//     console.log("postwidgetComment:",checkCommentsLength)
//
//
//     // ...
//
//     if (checkRecentPost === true) {
//         // Filter the relatedPosts.posts array based on comments count
//         const postsWithEnoughComments = relatedPosts.posts.filter((post) => {
//             return (checkCommentsLength[post.slug] || 0) > 3;
//         });
//
//         return (
//             <div className='post-widget-container'>
//                 <h3 className='post-widget-title'>{'Featured Posts'}</h3>
//                 {postsWithEnoughComments.map((post, index) => (
//                     <div key={index} className='post-widget-item'>
//                         <div className='post-widget-image'>
//                             <img
//                                 alt={post.title}
//                                 height="60px"
//                                 width="60px"
//                                 className="align-middle rounded-full"
//                                 src={post.featuredImage.url}
//                             />
//                         </div>
//                         <div className='post-widget-details'>
//                             <div className= "recentPostDetails">
//                                 <div className="recentPostTitle">
//                                     <Link to={`/blog/${post.slug}`} className='post-widget-link' key={index}>
//                                         {post.title}
//                                     </Link>
//                                     <a className="excerptDetails">{post.excerpt}</a>
//                                 </div>
//                                 <div className="recentPostExcerpt">
//                                     <a>
//                                         {checkCommentsLength[post.slug] || 0} {' '}comments
//                                     </a>
//                                     <p className='post-widget-date'>{moment(post.createdAt).format('MMM DD, YYYY')}</p>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 ))}
//             </div>
//         );
//     }
//
//
//
// };
//
//
//
// export default FeaturedPost;
import './RecommendedPost.css';
import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {Link} from "react-router-dom";
import {fetchData, getComments, getRecommendedPosts} from '../query';
import {BsDot, BsFillArrowRightCircleFill} from "react-icons/bs";


const RecommendedPosts = ({category, slug}) => {
    const [relatedPosts, setRelatedPosts] = useState([]);
    const [checkRecentPost, setRecentPost] = useState(false);
    const [posts, setPosts] = useState([]);
    const [checkdata, setCheckdata] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [commentsfetch, setCommentsfetch] = useState([]);
    const [checkComments, setCheckComments] = useState(false);
    const [checkCommentsLength, setCheckCommentsLength] = useState([]);

    let fetchedPosts;

    useEffect(() => {
        async function fetchPosts() {
            fetchedPosts = await fetchData();
            if (fetchedPosts) {
                setPosts(fetchedPosts);
                setCheckdata(true);
            }
        }

        fetchPosts();
    }, []);

    useEffect(() => {
        getRecommendedPosts(category, slug).then((result) => {
            setRelatedPosts(result);
            setRecentPost(true);
        });
    }, [slug]);

    useEffect(() => {
        if (Array.isArray(relatedPosts)) {
            const commentsCountBySlug = {};
            const commentCountPromises = relatedPosts.map((post) => {
                return getComments(post.slug)
                    .then((result) => {
                        commentsCountBySlug[post.slug] = result.comments.length;
                    })
                    .catch((error) => {
                        console.error(`Error fetching comments for slug ${post.slug}:`, error);
                    });
            });

            Promise.all(commentCountPromises)
                .then(() => {
                    setCheckCommentsLength(commentsCountBySlug);
                })
                .catch((error) => {
                    console.error('Error fetching comments counts:', error);
                });
        }
    }, [relatedPosts]);

    if (checkRecentPost === true && Array.isArray(relatedPosts)) {
        const postsWithEnoughComments = relatedPosts
            .filter((post) => {
                return (checkCommentsLength[post.slug] || 0) >= 0;
            })
            .sort((postA, postB) => {
                const commentsA = checkCommentsLength[postA.slug] || 0;
                const commentsB = checkCommentsLength[postB.slug] || 0;
                return commentsB - commentsA;
            })
            .slice(0, 3);

        return (
            <div className='post-widget-container-recommended'>
                <div className='featuredPostHeader-recommended'>
                    <div className='viewAll-recommended'>
                        <div className='view-all-title-recommended'>
                            <h3>Recommended</h3>
                        </div>
                        <div onClick={() => {
                            // window.location.href = `/blog/featured`
                        }}>
                            {/*<Link to={`/blog/featured}`}>*/}

                            <Link to='/blog/featured' className='featuredpost-widget-link-recommended'
                                  style={{marginRight: '10px'}}>View
                                All</Link>
                            <BsFillArrowRightCircleFill/>
                            {/*</Link>*/}
                        </div>
                    </div>
                </div>
                <div className="grid-container-recommended">
                    {postsWithEnoughComments.map((item) => (
                        <Link to={`/blog/${item.slug}`} className="grid-item-recommended" key={item.id}>
                            <div className="grid-item-header-recommended">
                                <img src={item.featuredImage.url} alt="Item"/>
                                <div className="grid-item-info-recommended">
                                    <p>{moment(item.createdAt).format('MMM DD, YYYY')}</p>
                                    <BsDot/>
                                    <p>{checkCommentsLength[item.slug] || 0} comments</p>
                                </div>
                            </div>
                            <h3>{item.title}</h3>
                        </Link>
                    ))}
                </div>
            </div>
        );
    }

    return null;
};

export default RecommendedPosts;
