import React, {useContext, useState} from "react";
import axios from 'axios';

const ReleaseContext = React.createContext()
const ReleaseUpdateContext = React.createContext()
const ReleaseResetContext = React.createContext()

const TokenizeContext = React.createContext();

export function Usedrop() {
    return useContext(ReleaseContext)
}

export function useTokenize() {
    return useContext(TokenizeContext);
}

export function
UsedropUpdate() {
    return useContext(ReleaseUpdateContext)
}

export function UsedropReset() {
    return useContext(ReleaseResetContext);
}

export function ReleaseProvider({children}) {
    const [tokens, setTokens] = useState(null);
    const [drop, setdrop] = useState(false);

    const dropping = () => {
        setdrop(!drop);
        console.log('dropping');
    };

    const resetDrop = () => {
        if (drop) {
            setdrop(false);
            console.log('reset drop');
        }
    };


    const tokenize = async () => {
        try {
            const response = await axios.get(
                "https://api.capitalflo.co/api/users/protect-user",
                {withCredentials: true}
            );
            console.log(response);
            if (response.data.message === "Cookie not found") {
                setTokens(null);
            } else {
                console.log(response.data.jwtToken);
                setTokens(response.data.jwtToken);
            }
        } catch (err) {
            console.log(err.response.data.message);
            setTokens(null);
        }
    };


    return (
        <ReleaseContext.Provider value={drop}>
            <ReleaseUpdateContext.Provider value={dropping}>
                <ReleaseResetContext.Provider value={resetDrop}>
                    <TokenizeContext.Provider value={tokens}>
                        {children}
                    </TokenizeContext.Provider>
                </ReleaseResetContext.Provider>
            </ReleaseUpdateContext.Provider>
        </ReleaseContext.Provider>
    );
}
