import React from 'react';
import Edittransaction from './Edittransaction';
import './ModalEdit.css';

function ModalEdit(props) {
    const closeeditwindow = () => {
        props.onConfirm();
    };


    return (
        <div>
            {/* <div className='editoverlay' onClick={closeeditwindow}></div> */}

            <Edittransaction className="modal-container" expense={props.expense} onConfirm={props.onConfirm} Currentstatement = {props.Currentstatement}
            />
        </div>
    );
}

export default ModalEdit;
